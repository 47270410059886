<template>
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.5L7 6.5L13 1.5" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "InputArrow"
}
</script>

<style scoped>

</style>