<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 2.39L16 7V16H2V7L9 2.38V2.39ZM9 0L0 5.93V18H18V5.93L9 0Z" fill="#BAC5C8"/>
    <path d="M11 11V16H7V11H11ZM13 9H5V18H13V9Z" fill="#BAC5C8"/>
  </svg>

</template>

<script>
export default {
name: "HomeIcon"
}
</script>

<style scoped>

</style>