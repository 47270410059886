<template>
<div class = "balance-popup">
    <div class="popup-title">{{ $t('balance_popup.title_balance_popup') }}</div>

    <div class ="input-wrapper" style = "margin-bottom:30px">
        <label for = "balance">{{ $t('balance_popup.enter_recharge') }}</label>
        <input type = "text" v-model="sum" id="balance">
    </div>
    <button class = "base-button" @click = "topup">
        {{ $t('balance_popup.add_balance') }}
    </button>
</div>
</template>

<script>
export default {
    name: "BalancePopup",
    emits:['close'],
    data(){
        return{
            sum:0
        }
    },
    methods:{
        topup(){
            this.$store.dispatch('balance/add',this.sum).then(
                ()=>{
                    //console.log(this.sum+' balance added');
                    this.$emit('close');
                }
            ).catch(e=>console.error(e));
        }
    }
}
</script>

<style scoped>

</style>
