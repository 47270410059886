<script>
export default {
    name: "notificationIcon"
}
</script>

<template>
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_12161_24331)">
            <path d="M7.97952 1.70996C8.54393 1.70996 9.10281 1.82247 9.62425 2.04106C10.1457 2.25965 10.6195 2.58005 11.0186 2.98396C11.4177 3.38787 11.7343 3.86737 11.9502 4.3951C12.1662 4.92283 12.2774 5.48845 12.2774 6.05966V8.71646H3.68164V6.05966C3.68164 4.90605 4.13445 3.79969 4.94046 2.98396C5.74647 2.16823 6.83965 1.70996 7.97952 1.70996V1.70996Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M12.27 8.71631H3.6822L2.18555 11.7295H13.7986L12.27 8.71631Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M4.85156 14.1436C5.10137 14.7769 5.53306 15.3199 6.0909 15.7024C6.64874 16.085 7.30711 16.2894 7.98093 16.2894C8.65476 16.2894 9.31311 16.085 9.87095 15.7024C10.4288 15.3199 10.8605 14.7769 11.1103 14.1436" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
        </g>
        <defs>
            <clipPath id="clip0_12161_24331">
                <rect width="16" height="18" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<style scoped lang="scss">

</style>
