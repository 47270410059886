<template>
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_10215_31660)">
            <path d="M8.99999 12.7147L1.06659 4.15768L2.82957 0.862549H15.188L16.9334 4.15768L8.99999 12.7147Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M12.526 2.55334H6.97259C6.57434 2.55562 6.19321 2.71203 5.91243 2.98841C5.63165 3.26479 5.47405 3.63867 5.47406 4.02838V4.02838C5.47637 4.41889 5.63592 4.79278 5.9181 5.06891C6.20029 5.34505 6.58234 5.50118 6.9814 5.50345H11.0451C11.4448 5.50345 11.8282 5.65883 12.1109 5.93546C12.3936 6.21208 12.5524 6.58728 12.5524 6.97849V6.97849C12.5536 7.17251 12.5154 7.36483 12.4401 7.54431C12.3647 7.72378 12.2537 7.88684 12.1135 8.02404C11.9733 8.16124 11.8067 8.26987 11.6233 8.3436C11.4399 8.41732 11.2433 8.45467 11.0451 8.45353H5.01569" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
        </g>
        <defs>
            <clipPath id="clip0_10215_31660">
                <rect width="18" height="14" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
  name: "adminIcon"
}
</script>

<style scoped>

</style>
