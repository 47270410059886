<template>
<div class = "pay-schedule">
  <div class = "pay-schedule__title tc">{{ $t('investments_page.payment_schedule') }}</div>
  <div class = "pay-schedule__items">
    <div
        v-for="(item,i) in schedule" :key="i"
        class = "pay-schedule__item d-flex jb ac">
       <div class = "pay-schedule__item-date">{{formatDate(item.pay_need_dt,'date')}}</div>
       <div class = "pay-schedule__item-sum">{{item.montly_payment}} $</div>
      <div class = "pay-schedule__item-parts">{{item.parts_count+' '+partsNameRus(item.parts_count)}}</div>
       <div class = "pay-schedule__item-action tc">
         <button v-if="i===0"
                 class = "base-button d-flex jc ac"
                 @click = "buyPackage(false)"
         >
             {{ $t('investments_page.pay') }}
         </button>
       </div>
    </div>
  </div>
  <div class = "tc">
    <button
        @click="buyPackage(true)"
        class = "pay-schedule__btn base-button">{{ $t('edit_package_popups.add_package_popup') }}</button>
  </div>
</div>
</template>

<script>
import {formatDate} from "@/utils/helpers";
export default {
  name: "PaymentSchedulePopup",
  props:{
    schedule:Array
  },
  methods:{
    formatDate,
    partsNameRus(num){
      if (num%10 ===1) return this.$t('shares.title_share');
      if (num%10<5) return this.$t('shares.title_shares');
      return this.$t('shares.title_sharess')
    },
    buyPackage(full=false){
      const pack = this.schedule[0];
      this.$store.commit('popups/openPopup',{name:'AddPackagePopup',props:{pack: {
            cost:pack.montly_payment,
            full_payment:full,
            guid:pack.packet_guid,
            remained_pays:(pack.total_parts-pack.paid_parts)
          }},classes:'mini'});
    }
  }
}
</script>

<style scoped lang="scss">
.pay-schedule{
  &__title{
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 40px;
  }
  &__items{
    margin-bottom: 30px;
  }
  &__item{
    font-size: 16px;
    height: 50px;
    &-date{
      width: 40%;
      min-width: 250px;
    }
    &-sum{
      font-weight: 600;
    }
    &-action{
      width: 150px;
      flex:0 0 auto;
      .base-button{
        height: 40px;
        padding: 15px 25px;
      }
    }
    &+&{
      border-top:1px solid #dedede;
    }
  }
}
</style>
