<template>
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8156 3.16797H19.668V17H1.66797V3.16797H8.52036" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M10.6671 5.33512C11.8532 5.33512 12.8148 4.36468 12.8148 3.16757C12.8148 1.97046 11.8532 1 10.6671 1C9.48105 1 8.51953 1.97046 8.51953 3.16757C8.51953 4.36468 9.48105 5.33512 10.6671 5.33512Z" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M7.71078 11.0717C8.72034 11.0717 9.53875 10.2457 9.53875 9.22678C9.53875 8.20785 8.72034 7.38184 7.71078 7.38184C6.70122 7.38184 5.88281 8.20785 5.88281 9.22678C5.88281 10.2457 6.70122 11.0717 7.71078 11.0717Z" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M4.78125 14.6307C4.78125 13.8472 5.08961 13.0959 5.63848 12.5419C6.18735 11.988 6.93178 11.6768 7.70801 11.6768C8.48423 11.6768 9.22866 11.988 9.77753 12.5419C10.3264 13.0959 10.6347 13.8472 10.6347 14.6307" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M12.5547 9.05566H17.1396" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M12.5547 12.6748H17.1396" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "VerPersonal"
}
</script>

<style scoped>

</style>
