<template>
  <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9621 3.43741C13.4641 1.87671 11.4329 1 9.31514 1C7.19734 1 5.16619 1.87671 3.66819 3.43741C2.92788 4.20379 2.34034 5.1153 1.93939 6.11943C1.53845 7.12356 1.33203 8.20047 1.33203 9.28814C1.33203 10.3758 1.53845 11.4527 1.93939 12.4568C2.34034 13.461 2.92788 14.3725 3.66819 15.1389L9.32012 21L14.9621 15.1909C15.7124 14.4251 16.3085 13.5108 16.7154 12.5017C17.1224 11.4925 17.332 10.4089 17.332 9.31416C17.332 8.21946 17.1224 7.13578 16.7154 6.12664C16.3085 5.11751 15.7124 4.20324 14.9621 3.43741V3.43741ZM9.32012 13.4315C8.53013 13.4315 7.75788 13.1873 7.10102 12.7297C6.44416 12.2722 5.9322 11.6218 5.62988 10.8609C5.32756 10.1 5.24845 9.26269 5.40257 8.45491C5.5567 7.64713 5.93711 6.90514 6.49573 6.32276C7.05434 5.74038 7.76605 5.34378 8.54087 5.18311C9.31569 5.02243 10.1188 5.1049 10.8487 5.42008C11.5785 5.73526 12.2024 6.269 12.6413 6.9538C13.0802 7.6386 13.3144 8.44371 13.3144 9.26731C13.3145 10.3628 12.9005 11.4141 12.1622 12.1936C11.4239 12.9731 10.4207 13.4178 9.37006 13.4315H9.32012Z" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "VerPasport"
}
</script>

<style scoped>

</style>