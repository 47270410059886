<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 3.8999H1.01V9.0899H16.98V14.4399H0" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M6.83984 0V18" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M11.1602 0V18" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "DollarIcon"
}
</script>

<style scoped>

</style>