import axios from "@/utils/axios";

export default {
    namespaced: true,
    state: () => ({
        certificates: [],
        investmentAgreement:null,
        partnerAgreement:null,
    }),
    getters: {
    },
    mutations: {
        setCertificates(state, list) {
            state.certificates = list;
            state.investmentAgreement = list.find(el=>{
                return el.type==='invest_agreement'
            });
            state.partnerAgreement = list.find(el=>{
                return el.type==='partner_agreement'
            })
        },
        updateInvestmentAgreement(state,payload){
            state.investmentAgreement = Object.assign({},state.investmentAgreement,payload)
        },
        updatePartnerAgreement(state,payload){
            state.partnerAgreement = Object.assign({},state.partnerAgreement,payload)
        }
    },
    actions: {
        getCertificatesList({commit}) {
            // console.log(date);
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/certificates/get/').then(response => {
                    commit('setCertificates', response.data);
                    resolve(response);
                }).catch(error => reject(error));
            });
        },
        submitCertificate(ctx,id){
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/certificates/submit/',{id:id}).then(response => {
                    resolve(response);
                }).catch(error => reject(error));
            });
        },
        getCertificate(ctx,id){
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/certificates/get/'+id).then(response => {
                    //console.log(response);
                    resolve(response);
                }).catch(error => reject(error));
            });
        }
    }
}
