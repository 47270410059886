import Vuex from 'vuex';
import user from "./store/user";
import balance from "./store/balance";
import packages from "./store/packages";
import popups from "./store/popups";
import team from "./store/team";
import planes from "./store/planes";
import payments from "./store/payments";
import referral from "./store/referral";
import news from "./store/news";
import admin from "@/store/admin";
import certificates from "@/store/certificates";
import screen from "@/store/screen";
const store = new Vuex.Store({
  modules: {
      admin,
      user,
      balance,
      packages,
      popups,
      team,
      planes,
      payments,
      referral,
      news,
      certificates,
      screen
  }
});
export default store;
