<template>
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.334 5.96058L6.00065 1.42725L0.667317 5.96058" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>  </svg>

</template>

<script>
export default {
name: "TriangleDown"
}
</script>

<style scoped>

</style>