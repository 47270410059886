import axios from "@/utils/axios";
export default {
    namespaced: true,
    state: () => ({
        packageList: [],
        addedPackages:[],
        partsTransactions:[]
    }),
    getters: {
        getAllPackages(state){
            return state.packageList;
        },
        getMyPackages(state){
            return state.addedPackages;
        },
        getTransactions(state){
            return state.partsTransactions;
        },
        getPackageById:(state)=>(id)=>{
            return state.packageList.find(el=>el.id===id);
        }
    },
    mutations: {
        setTransactions(state, payload) {
            state.partsTransactions = payload;
        },
        setPackages(state,list){
            state.packageList=list;
        },
        setAddedPackages(state,list){
            state.addedPackages=list;
        },
        addPackage(state,id){
            state.addedPackages.push(id);
        },
        addTransactions(state,payload){
            state.partsTransactions = state.partsTransactions.concat(payload);
        }
    },
    actions: {
        buy({commit,dispatch},data){
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/packages/buy',data).then(response =>{
                    const parts = response.data.parts;
                    commit('user/setUserPartsInfo', parts,{root:true});
                    if (response.data.transactions)
                        commit('addTransactions',response.data.transactions);
                    dispatch('getList');
                    resolve(response);
                }).catch(error => reject(error));
            });
        },
        payInstallment({commit,dispatch},data){
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/packages/pay_installment',data).then(response =>{
                    //console.log('chotam',response);
                    const parts = response.data.parts;
                    commit('user/setUserPartsInfo', parts,{root:true});
                    if (response.data.transactions)
                        commit('addTransactions',response.data.transactions);
                    dispatch('getList');
                    resolve(response);
                }).catch(error => reject(error));
            });
        },
        //TODO: разный запрос на myPackages и allPackages
        getList({commit}){
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/packages/get').then(response => {
                    commit('setPackages',response.data.packages);
                    commit('setAddedPackages',response.data.added);
                    resolve(response);
                }).catch(error => reject(error));
            });
        },
        fetchPartTransactions({commit},payload){
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/transactions/getparts/',{params:payload}).then(({data}) => {
                    commit('setTransactions',data);
                    resolve(data);
                }).catch(error => reject(error));
            });
        }
    }
}
