<template>
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 12.3174V22.1339H12V12.3174L21.8165 12.3174V10.3174L12 10.3174V0.500977H10V10.3174L0.183594 10.3174V12.3174L10 12.3174Z" fill="#BAC5C8"/>
  </svg>

</template>

<script>
export default {
name: "CrestIcon"
}
</script>

<style scoped>

</style>