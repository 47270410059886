<template>
    <div class="sidebar-notifications">
        <span v-if="counter !== 0" class="notification-counter">{{ formattedCounter }}</span>
        <NotificationIcon />
    </div>
</template>

<script>
import NotificationIcon from "@/assets/icons/notificationIcon.vue";

export default {
    data() {
        return {
            counter: 100
        };
    },
    computed: {
        formattedCounter() {
            return this.counter > 99 ? "99+" : this.counter.toString();
        }
    },
    components: {
        NotificationIcon
    }
};
</script>



<style scoped lang="scss">
@use "~@/styles/mixins.scss" as *;
.sidebar{
    &-notifications{
        display:none;
        width: 40px;
        height: 40px;
        //display: flex;
        align-items: center;
        justify-content: center;
        background: #686d73;
        border-radius: 16px 0 0 16px;
        align-self: flex-start;
        cursor: pointer;
        position: relative;
        @include respond(1280px){
            align-self: flex-end;
        }
        @include respond($laptop){
            border-radius: 16px;
        }
    }
}
.notification-counter{
    background: #008CE0;
    border-radius: 12px;
    padding: 3px 5px 3px 6px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: -5px;
}
</style>
