import axios from "@/utils/axios";

export default {
    namespaced: true,
    state: () => ({
        newsList: [],
        page: 1,
        total_pages: 1,
        base_param: {
            'per_page' : 12,
            page: 1,
            '_embed' : 'wp:featuredmedia',
            // 'after' : '2022-01-01T00:00:00',
            // 'before' : '2022-12-31T23:59:59',
            // 'search' : 'виктор',
        },
        searched: false,
        is_loading: false,

        one_news: '',
        one_news1: '',
    }),
    getters: {

    },
    mutations: {
        setNews(state, newsList) {
            state.newsList = newsList;
        },
        setPage(state, page) {
            state.page = page
        },
        setTotalPages(state, totalPages) {
            state.total_pages = totalPages
        },
        setSearched(state, bool) {
            state.searched = bool
        },
        setLoading(state, bool) {
            state.is_loading = bool
        },

        setOneNews(state, data) {
            state.one_news = data;
        },
    },
    actions: {
        getNews({state, commit}, options={param:{}}) {
            let more = false
            // eslint-disable-next-line
            if (options.hasOwnProperty('more')){
                more = options.more;
            }

            let params = {
                ...state.base_param,
                ...options.param,
            }
            if (more) {
                commit('setPage', state.page + 1)
                params = {...params, page : state.page}
            }

            // eslint-disable-next-line
            commit('setSearched', params.hasOwnProperty('search'));

            commit('setLoading', true);
            return new Promise((resolve, reject) => {
                axios.get('https://protribel.com/wp-json/wp/v2/news',
                    {params}
                ).then(response => {
                    const {data} = response;
                    // commit('setTotalPages', Math.ceil(response.headers['x-wp-total'] / state.per_page));
                    commit('setTotalPages', response.headers['x-wp-totalpages']);
                    if (more) {
                        commit('setNews', [...state.newsList, ...data]);
                    }else{
                        commit('setNews', data);
                    }
                    resolve(data);
                    commit('setLoading', false);
                }).catch(error => {
                    commit('setLoading', false);
                    reject(error);
                });
            });

        },

        getOneNews({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.get('https://protribel.com/wp-json/wp/v2/news/'+id+'?_embed=wp:featuredmedia'
                ).then(response => {
                    const {data} = response;
                    commit('setOneNews', data);
                    resolve(data);
                }).catch(error => {
                    reject(error);
                });
            });

        }

    }
}
