<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 4.4502V16.0002H2V4.4502H16ZM18 2.4502H0V18.0002H18V2.4502Z" fill="#BAC5C8"/>
    <path d="M5.37109 0V6.63" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M12.6289 0V6.63" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M4.30078 10.9502H13.7008" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "EventsIcon"
}
</script>

<style scoped>

</style>