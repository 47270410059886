<template>
    <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.64 11.9299L7.57 17.9999L1.5 11.9299" stroke="#3E76B5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.57031 1V17.83" stroke="#3E76B5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

</template>

<script>
export default {
name: "ArrowToBottom"
}
</script>

<style scoped>

</style>
