<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99922 7.9667C8.82176 7.9667 10.2992 6.48924 10.2992 4.6667C10.2992 2.84416 8.82176 1.3667 6.99922 1.3667C5.17668 1.3667 3.69922 2.84416 3.69922 4.6667C3.69922 6.48924 5.17668 7.9667 6.99922 7.9667Z" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M1.71484 14.3331C1.71484 12.9319 2.27147 11.5881 3.26229 10.5973C4.25311 9.60644 5.59695 9.0498 6.99817 9.0498C8.3994 9.0498 9.74324 9.60644 10.7341 10.5973C11.7249 11.5881 12.2815 12.9319 12.2815 14.3331" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "VerAddress"
}
</script>

<style scoped>

</style>