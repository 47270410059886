<template>
    <div v-if="user">
        <PopupWrapper/>
        <MainMenu/>
        <div class="content-container">
            <div class="content d-flex fc">
                <transition name="fademain" mode="out-in">
                    <div :key="$route.name.split('.')[0]">
                        <router-view>
                        </router-view>
                    </div>
                </transition>
                <MainFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "@/components/mainMenu/MainMenu";
import MainFooter from "@/components/mainFooter/mainFooter";
import {mapState, mapActions, mapGetters} from "vuex";
import PopupWrapper from "@/components/popups/PopupWrapper";


export default {
    name: "DashboardLayout",
    components: {PopupWrapper, MainFooter, MainMenu},
    data() {
        return {
            intervalFunction: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.data,
        }),
        ...mapGetters({
            verifyStatus: 'user/getVerificationStatus'
        })
    },
    methods: {
        ...mapActions({
            incrementVisits: 'referral/incrementVisits'
        }),
        handleResize(){
            this.$store.commit('screen/setWidth',window.innerWidth);
        }

    },
    created() {
        const refLink = this.$route.query.ref;
        if (refLink) {
            this.incrementVisits(refLink);
            window.localStorage.setItem('ref-link', refLink);
        }

        if (!this.user) {
            this.$store.dispatch('user/authentificate').then(() => {
                this.$store.dispatch('balance/getTransactions');
            }).finally(() => {
                if (this.user === null) {
                    this.$router.push({name: 'login'});
                }
            });

        }

        this.intervalFunction = setInterval(() => {
            if (this.verifyStatus === 'pending') {
                this.$store.dispatch('user/checkVerificationStatus').then((data) => {

                        if (data.data === 'not_verified' || data.data === 'verified') {
                            this.$store.dispatch('user/authentificate').then(this.$store.commit('popups/openPopup', {
                                name: 'DefaultModal',
                                props: {text: 'Ваш статус верификации был изменен!'}
                            }));
                        }
                    }
                )
            }
        }, 300000)

    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
        clearInterval(this.intervalFunction);
    }

}
</script>

<style scoped>

</style>
