<template>
    <div class="wrapper">
        <div class="block-header">
            <div class="block-avatar" v-if="photo">
                <img :src="/file/ + photo"/>
            </div>
            <div class="block-avatar" v-else>
                <avatar-icon/>
            </div>
            <div class="block-data">
                <div class="block-name" v-if="full_name" v-html="full_name">
                </div>
                <div class="block-name" v-else>
                    {{ first_name }} {{ second_name }}
                </div>
                <div class="block-status" v-if="level_name">
                    {{ $t('partner_level.'+level_name) }}
                </div>
                <div class="block-status" v-else>
                    {{ $t('partners_team_page.inviter') }}
                </div>
            </div>
        </div>
        <div class="block-data">
            <div class="block-top">
                <div class="block-icon">
                    <phone-icon/>
                </div>
                <div class="block-phone">
                    <div class="block-number">
                        +{{ phone }}
                    </div>
                    <div class="block-copy"
                         :class="animated === phone ? 'bounce animated' : ''"
                         @animationend="animated = null"
                         @click="copy(phone)">
                        <copy-icon/>
                    </div>
                </div>
            </div>
            <div class="block-bottom">
                <div class="block-icon">
                    <chat-icon/>
                </div>
                <div class="block-email">
                    <div class="block-mail">
                        {{ email }}
                    </div>
                    <div class="block-copy"
                         :class="animated === email ? 'bounce animated' : ''"
                         @animationend="animated = null"
                         @click="copy(email)">
                        <copy-icon/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

//import AvatarIcon from "@/assets/icons/AvatarIcon.vue";
import PhoneIcon from "@/assets/icons/PhoneIcon.vue";
import CopyIcon from "@/assets/icons/copyIcon.vue";
import ChatIcon from "@/assets/icons/ChatsIcon.vue";
import {mapState} from "vuex";
import AvatarIcon from "@/assets/icons/AvatarIcon.vue";

export default {
    name: "ContactsModal",
    components: {
        AvatarIcon, ChatIcon, CopyIcon, PhoneIcon, //AvatarIcon
        },
    data() {
        return {
            animated: null,
        }
    },
    props: {
        full_name: Array,
        level_name: Array,
        email: Array,
        phone: Array,
        photo: Array,
        first_name : Array,
        second_name : Array,
    },
    computed: {
        ...mapState({
            statuses: state => state.planes.statuses,
        }),
    },
    methods: {
        copy(text) {
            navigator.clipboard.writeText(text);
            this.animated = text;
        },
    },
}
</script>


<style lang="scss" scoped>

.wrapper {
    display: grid;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

.block {
    justify-content: center;
    gap: 20px;

    &-header {
        display: inline-flex;
        gap: 10px;
    }

    &-avatar{
        width: 60px;
        height: 60px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }

    &-name {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.44px;
    }

    &-status {
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
    }

    &-data {
        display: grid;
        gap: 5px;
    }

    &-top {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    &-bottom {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    &-phone {
        display: flex;
        gap: 20px;

    }

    &-email {
        display: flex;
        gap: 20px;
    }

    &-copy {
        display: flex;
        right: 50px;
        cursor: pointer;
    }
}



.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.animated.bounceIn,.animated.bounceOut,.animated.flipOutX,.animated.flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .75s
}

@-webkit-keyframes bounce {
    0%,20%,53%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    40%,43% {
        -webkit-transform: translate3d(0,-30px,0);
        transform: translate3d(0,-30px,0)
    }

    40%,43%,70% {
        -webkit-animation-timing-function: cubic-bezier(.755,.05,.855,.06);
        animation-timing-function: cubic-bezier(.755,.05,.855,.06)
    }

    70% {
        -webkit-transform: translate3d(0,-15px,0);
        transform: translate3d(0,-15px,0)
    }

    90% {
        -webkit-transform: translate3d(0,-4px,0);
        transform: translate3d(0,-4px,0)
    }
}

@keyframes bounce {
    0%,20%,53%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    40%,43% {
        -webkit-transform: translate3d(0,-30px,0);
        transform: translate3d(0,-30px,0)
    }

    40%,43%,70% {
        -webkit-animation-timing-function: cubic-bezier(.755,.05,.855,.06);
        animation-timing-function: cubic-bezier(.755,.05,.855,.06)
    }

    70% {
        -webkit-transform: translate3d(0,-15px,0);
        transform: translate3d(0,-15px,0)
    }

    90% {
        -webkit-transform: translate3d(0,-4px,0);
        transform: translate3d(0,-4px,0)
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom
}
</style>
