Компонент для текстовых инпутов и Select
- обворачивается в div с классом .field_wrap, если нужно добавить дополнительно - то можно указать в пропсе :class_wrap
- инпут имеет класс .field, если нужно добавить дополнительно - то можно указать в пропсе :class_input
- Если пропс :type указан как password, то автоматически подставляется иконка глаза, клик по которой показывает пароль
- если не передать в пропсе :placeholder, то placeholder подтянется из label, если последний указан
- если указан пропс :autocomplete="'off'", то блокировка автозаполнения решается через readonly. Подробнее: https://qna.habr.com/q/146779
-

<template>
    <div :class="class_wrap_">
        <label v-if="label !== ''" v-show="show_label">{{label}}</label>
        <input v-if="type !== 'select'"
               :value="modelValue"
               :type="type_"
               :placeholder="placeholder_"
               class="field"
               :class="class_input"
               :autocomplete="autocomplete"
               @input="updateInput"
               @focus="inputFocused"
               @blur="inputBlurred"
               :readonly="readonly_"
        />

        <div v-if="type === 'select'"
             class="field select_title"
             :class="[class_input, {'open': selectOpened}]"
             @click="!readonly?selectOpened=!selectOpened:selectedOpened = false"
             ref="selectTitle"
        >{{modelValue !== '' ? selectOptions[modelValue] : text}}</div>
        <ul class="select_options"
            v-if="type === 'select'"
            v-show="selectOpened">
            <li v-for="(option_name, option_val) in selectOptions" :key="option_val"
                @click="updateInput(option_val)">
                {{option_name}}
            </li>
        </ul>

        <span class="icon" v-if="icon">{{icon}}</span>

        <EyeIcon v-if="type === 'password'"
                 @click="show_password = !show_password"
                 :class="{active:show_password}" />

        <svg v-if="type === 'select'"
             width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 5L11 1" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
        </svg>

        <slot></slot>
    </div>

</template>


<script>
import EyeIcon from '@/assets/icons/EyeIcon.vue'

export default {
    name: "TextField",
    components: {
        EyeIcon,
    },
    props:{
        modelValue: {
            type: [String, Number],
            default: '',
        },
        select_text: {
            type: [String, Number],
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: [String, Number]
        },
        name: {
            type: String,
            default: '',
        },
        class_wrap: {
            type: String,
            default: '',
        },
        class_input: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
        },
        label: {
            type: [String, Number],
            default: '',
        },
        icon:{
            type: String,
        },

        selectOptions: {
            type: Object,
            default(){
                return {}
            },
        },

    },
    data() {
        return {
            show_label: false,
            show_password: false,
            placeholder_: '',
            data_placeholder: '',
            focused: false,
            readonly_: false,

            selectOpened: false,
        }
    },
    watch:{
      label(val){
        if (this.focused) this.data_placeholder = val;
        else this.placeholder_ = val;
      }
    },
    computed:{
        text() {
          if (this.select_text === ''){
              return this.$t('common.select_value');
          } return this.select_text
        },
        class_wrap_(){
            let class_ = 'field_wrap';
            if (this.type==='password') {
                class_ += ' with_icon';
            }
            if (this.class_wrap !== ''){
                class_ += ' '+this.class_wrap;
            }
            return class_;
        },
        type_(){
            if (this.type === 'password'){
                return this.show_password?'text':'password';
            }else{
                return this.type;
            }
        }
    },
    updated() {
        if (this.type === 'select') {
            this.show_label = true;
        }else{

            if (!this.modelValue) {
                 (this.focused === true)?this.show_label = true:this.show_label = false;
            }else{
                this.show_label = true;
            }
        }
    },
    mounted() {
        if (this.modelValue === null){
            this.$emit('update:modelValue', '');
        }

        if (this.type === 'select'){
            this.show_label = true;
            document.addEventListener('click', this.handleClickOutside);
        }

        if (this.placeholder === undefined && this.label !== undefined){
            this.placeholder_ = this.label;
        }else{
            this.placeholder_ = this.placeholder;
        }

        if (this.readonly === true || this.autocomplete === 'off'){
            this.readonly_ = true;
        }


    },
    beforeUnmount() {
        if (this.type === 'select'){
            document.removeEventListener('click', this.handleClickOutside);
        }
    },
    methods:{
        inputFocused(){
            this.focused = true;
            this.data_placeholder = this.placeholder_;
            this.placeholder_ = '';
            this.readonly_ = this.readonly;
        },
        inputBlurred(){
            this.focused = false;
            this.placeholder_ = this.data_placeholder;
        },
        updateInput(event){
            if (typeof event === 'object' ){
                this.$emit('update:modelValue', event.target.value);
            }else{
                this.$emit('update:modelValue', event);
            }
        },
        handleClickOutside(event) {
            if (!this.$refs.selectTitle.contains(event.target)) {
                this.selectOpened = false;
            }
        },
    }
}

</script>

<style lang="scss" scoped>
@import "./src/styles/form.scss";


.field.select_title {
    padding: 25px 30px 11px;
    cursor: pointer;
    &.open{
        border-radius: 16px 16px 0 0;
    }
}
.select_options{
    position: absolute;
    border-top: 1px solid #c3c3c3;
    width: 100%;
    top: 60px;
    background: #ebeff1;
    border-radius: 0 0 16px 16px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    overflow: hidden;
    z-index: 2;
    max-height:250px;
    overflow-y:auto;

    box-shadow: 0px 3px 3px -1px #2a2a2a47;
    li {
        padding: 9px 9px 9px 30px;
        cursor: pointer;
        &:hover{
            background: #d0d9dd;
        }
    }
}
input::placeholder{
    text-align:center;
}


svg.active{
    stroke: #3E76B5;
}


</style>
