<template>
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_10112_6634)">
            <path d="M24 48.5C37.2548 48.5 48 37.7548 48 24.5C48 11.2452 37.2548 0.5 24 0.5C10.7452 0.5 0 11.2452 0 24.5C0 37.7548 10.7452 48.5 24 48.5Z" fill="#BAC5C8"/>
            <path d="M8.93768 25.6743C11.7433 24.1289 14.8751 22.8391 17.8013 21.5427C22.8355 19.4193 27.8897 17.3327 32.9949 15.3901C33.9881 15.0591 35.7729 14.7355 35.9479 16.2075C35.8519 18.2911 35.4579 20.3625 35.1875 22.4339C34.5013 26.9891 33.7079 31.5287 32.9345 36.0689C32.6679 37.5811 30.7735 38.3641 29.5613 37.3963C26.6481 35.4283 23.7125 33.4799 20.8365 31.4663C19.8945 30.5091 20.7681 29.1343 21.6093 28.4507C24.0087 26.0861 26.5533 24.0771 28.8273 21.5903C29.4407 20.1091 27.6283 21.3575 27.0305 21.7401C23.7457 24.0037 20.5413 26.4055 17.0781 28.3949C15.3091 29.3687 13.2473 28.5365 11.4791 27.9929C9.89368 27.3367 7.57048 26.6753 8.93748 25.6745L8.93768 25.6743Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_10112_6634">
                <rect width="48" height="48" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
name: "telegramIcon"
}
</script>

<style scoped>

</style>
