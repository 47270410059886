<template>

    <div class="pay_method_wrap">
        <div class="small_btn">{{ $t('pay_popup.title_pay_popup') }}</div>
        <div class="exchange_rates_wrap ismobile">
            <div class="t">{{ $t('wallet_page.exchange_rate') }}</div>
            <ExchangeRates/>
        </div>

        <div class="topblock d-flex">

            <div class="amount">
                <h3>{{name}}</h3>
                <div class="field_wrap amount_field with_icon" :class="{'amount_field_disabled':invoice!==false}">
                    <input class="field gray"
                           v-model="amount"
                           type="text" placeholder="0"
                           :disabled="invoice!==false"
                    >
<!--                    :value="amount_usd"-->
<!--                    @input="updateAmount"-->
                    <span class="icon">$</span>
                    <div class="exchange_rates_wrap">
                        <p class="t">{{ $t('wallet_page.exchange_rate') }}</p>
                        <ExchangeRates/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="type=='cryptadium'">
            <div class="method_param">
                <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                {{ $t('pay_popup.commission') }}  <br>
                {{ $t('pay_popup.enrolment_deadline') }}  <br>
            </div>

            <div class="method_info">
                <p class="t">{{ $t('pay_popup.important_information') }}</p>
                <p>{{ $t('pay_popup.point_1') }}</p>
                <p style="color: #e51111;">{{ $t('pay_popup.point_2') }}</p>
                <p>{{ $t('pay_popup.point_3') }}</p>
            </div>


            <div class="agree">
                <input class="gray" id="agree" type="checkbox">
                <label for="agree" @click="agree = !agree"><span>{{ $t('pay_popup.accept_conditions') }} {{name}}</span></label>
            </div>

<!--            <form action="https://dashboard.cryptadium.com/Payment" method="post" class="d-flex">-->
<!--                <input type="hidden" name="Test" :value="invoice.test" />-->
<!--                <input type="hidden" name="email" :value="invoice.email" />-->
<!--                <input type="hidden" name="amountusd" :value="invoice.amountusd" />-->
<!--                <input type="hidden" name="currency" :value="invoice.currency" />-->
<!--                <input type="hidden" name="ShopId" :value="invoice.ShopId" />-->
<!--                <input type="hidden" name="ClientId" :value="invoice.ClientId" />-->
<!--                <input type="hidden" name="BillingId" :value="invoice.BillingId" />-->
<!--                <button class="base-button" type="submit" :disabled="!agree || amountErr" >{{ $t('pay_popup.continue') }}</button>-->
<!--            </form>-->

            <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>

            <form v-if="invoice" :action="invoice.html_form_action" method="post" class="d-flex">
                <div v-html="invoice.html_form"></div>
                <button class="base-button" type="submit" :disabled="!agree || amountErr" >{{ $t('pay_popup.continue') }}</button>
            </form>

        </div>

        <div v-if="type=='payeer'">

            <div class="method_param">
                <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                Комиссия: от 0.5%<br>
                Срок зачисления: мгновенно  <br>
            </div>

            <div class="agree">
                <input class="gray" id="agree" type="checkbox">
                <label for="agree" @click="agree = !agree">{{ $t('pay_popup.accept_conditions') }} {{name}}</label>
            </div>

            <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>

            <form v-if="invoice" :action="invoice.html_form_action" method="post" class="d-flex">
                <div v-html="invoice.html_form"></div>
                <button class="base-button" type="submit" :disabled="!agree || amountErr" >{{ $t('pay_popup.continue') }}</button>
            </form>

        </div>


        <div v-if="type=='testpay'">
            <p>&nbsp;</p>
            <button v-if="testpay_done==0" :disabled="testpay_done>0" class="base-button" type="submit" @click="testpay_done=1;test_pay()">Начислить</button>
            <div v-if="testpay_done==2">
                Платеж зачислен <a href="/wallet">Перейти в кошелек</a>
            </div>
        </div>



        <div v-if="name=='Payeer Wallet'">
            <div>
                <div class="method_param">
                    <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                    Комиссия: 0.5%<br>
                    Срок зачисления: до 48 часов <br>
                </div>

                <div class="method_info">
                    <p>1. Переведите средства, на счет получателя, в системе {{name}}:</p>
                    <ul>
                        <li>Перейдите в раздел «Перевести» </li>
                        <li>Введите номер кошелька, который отобразится на следующем шаге</li>
                        <li>ВАЖНО! Обязательно <b>в поле Комментарий укажите свой e-mail</b>, который вы используете в личном кабинете!</li>
                        <li>В поле <b>Сумма</b> укажите сумму, на которую вы пополняете счет. Валюта USD.</li>
                        <li>В поле Итого будет которая спишется с вашего кошелька, с учетом комиссии. Валюта на ваше усмотрение.</li>
                    </ul>
                    <p>
                        2. Внимание! Счета для приёма средств периодически меняются!
                        Повторная отправка средств без проверки реквизитов запрещена и может привести к потере средств
                        (не зависит он нас). При следующем пополнении через payeer начните процедуру с этой страницы.</p>
                    <p>3. После совершения перевода, сделайте скриншот страницы в системе {{name}}, и храните его получения средств на личном счете.</p>
                    <p>4. Нажмите на кнопку "Я ОПЛАТИЛ" расположенную ниже.</p>
                    <p>Дополнительные данные отправлять не требуется! При нажатии кнопки "Я ОПЛАТИЛ",
                        Вы подтверждаете факт успешного перевода в {{name}}, мы проверим зачисление средств
                        и проведем зачисление платежа на основной счет в личном кабинете.</p>
                    <p> Обратитесь в техническую поддержку если средства не будут зачислены в течении 24 часов.</p>
                </div>

                <div class="agree">
                    <input class="gray" id="agree" type="checkbox">
                    <label for="agree" @click="agree=!agree">{{ $t('pay_popup.accept_conditions') }} {{name}}</label>
                </div>
                <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>
            </div>
        </div>
        <div v-if="name=='Fast Payment System'">
            <div>
                <div class="method_param">
                    <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                    Комиссия: 0.5%<br>
                    Срок зачисления: до 48 часов <br>
                </div>

                <div class="method_info">
                    <p>Соблюдайте стандартную процедуру перевода через СБП внутри личного кабинета Сбербанк Онлайн согласно инструкции для оплаты счета, которая откроется после нажатия кнопки «ПРОДОЛЖИТЬ»!:</p>
                   <p>Вы можете использовать и другие банки.</p>
                    <p>После нажатия кнопки «ПРОДОЛЖИТЬ» откроется окно с инструкцией для оплаты счета!</p>
                </div>

                <div class="agree">
                    <input class="gray" id="agree" type="checkbox">
                    <label for="agree" @click="agree=!agree">{{ $t('pay_popup.accept_conditions') }} {{name}}</label>
                </div>
                <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>
            </div>
        </div>
        <div v-if="name == 'MasterCard' || name == 'Mir'">
            <div>
                <div class="method_param sm">
                    <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                    Комиссия: оплачивает отправитель (переводы внутри СБЕРБАНКА до 50 000 руб. без процентов)<br>
                    Срок зачисления: до 48 часов <br>
                </div>

                <div class="method_info">
                    <p>Соблюдайте стандартную процедуру перевода через личный кабинет СберОнлайн согласно инструкции для оплаты счета, которая откроется после нажатия кнопки «ПРОДОЛЖИТЬ»!

                    </p>
                    <p>Вы можете использовать и другие банки.</p>
                </div>

                <div class="agree">
                    <input class="gray" id="agree" type="checkbox">
                    <label for="agree" @click="agree=!agree">{{ $t('pay_popup.accept_conditions') }} {{name}}</label>
                </div>
                <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>
            </div>
        </div>
        <div v-if="name == 'Qiwi'">
            <div>
                <div class="method_param sm">
                    <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                    Комиссия: оплачивает отправитель (переводы внутри Qiwi без процентов)<br>
                    Срок зачисления: до 48 часов <br>
                </div>

                <div class="method_info">
                    <p>Соблюдайте стандартную процедуру перевода через личный кабинет Qiwi согласно инструкции для оплаты счета, которая откроется после нажатия кнопки «ПРОДОЛЖИТЬ»!</p>

                </div>

                <div class="agree">
                    <input class="gray" id="agree" type="checkbox">
                    <label for="agree" @click="agree=!agree">{{ $t('pay_popup.accept_conditions') }} {{name}}</label>
                </div>
                <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>
            </div>
        </div>
        <div v-if="name == 'YooMoney'">
            <div>
                <div class="method_param sm">
                    <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                    Комиссия: 5% оплачивает отправитель<br>
                    Срок зачисления: до 48 часов <br>
                </div>

                <div class="method_info">
                    <p>Внимание! Реквизиты (кошелек) для приема средств периодически меняются! Повторная отправка средств без проверки реквизитов запрещена и может привести к потере средств (не зависит от нас). </p>
<p>Соблюдайте стандартную процедуру перевода через личный кабинет YooMoney согласно инструкции для оплаты счета, которая откроется после нажатия кнопки «ПРОДОЛЖИТЬ»!</p>

                </div>

                <div class="agree">
                    <input class="gray" id="agree" type="checkbox">
                    <label for="agree" @click="agree=!agree">{{ $t('pay_popup.accept_conditions') }} {{name}}</label>
                </div>
                <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>

            </div>
        </div>
        <div v-if="name == 'Perfect Money'">
            <h4>Необходимо добавить текст для перфектмоней</h4>
        </div>
        <!--
        <div v-if="type=='PaymentSystem'">
            <div>
                <div class="method_param">
                    <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                    Комиссия: 0.5%<br>
                    Срок зачисления: до 48 часов <br>
                </div>

                <div class="method_info">
                    <p>1. Переведите средства, на счет получателя, в системе {{name}}:</p>
                    <ul>
                        <li>Перейдите в раздел «Перевести» </li>
                        <li>Введите номер кошелька, который отобразится на следующем шаге</li>
                        <li>ВАЖНО! Обязательно <b>в поле Комментарий укажите свой e-mail</b>, который вы используете в личном кабинете!</li>
                        <li>В поле <b>Сумма</b> укажите сумму, на которую вы пополняете счет. Валюта USD.</li>
                        <li>В поле Итого будет которая спишется с вашего кошелька, с учетом комиссии. Валюта на ваше усмотрение.</li>
                    </ul>
                    <p>
                        2. Внимание! Счета для приёма средств периодически меняются!
                        Повторная отправка средств без проверки реквизитов запрещена и может привести к потере средств
                        (не зависит он нас). При следующем пополнении через {{name}} начните процедуру с этой страницы.</p>
                    <p>3. После совершения перевода, сделайте скриншот страницы в системе {{name}}, и храните его получения средств на личном счете.</p>
                    <p>4. Нажмите на кнопку "Я ОПЛАТИЛ" расположенную ниже.</p>
                    <p>Дополнительные данные отправлять не требуется! При нажатии кнопки "Я ОПЛАТИЛ",
                        Вы подтверждаете факт успешного перевода в payeer, мы проверим зачисление средств
                        и проведем зачисление платежа на основной счет в личном кабинете.</p>
                    <p> Обратитесь в техническую поддержку если средства не будут зачислены в течении 24 часов.</p>
                </div>

                <div class="agree">
                    <input class="gray" id="agree" type="checkbox">
                    <label for="agree" @click="agree=!agree">{{ $t('pay_popup.accept_conditions') }} {{name}}</label>
                </div>
                <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr" @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>
            </div>
        </div>
        <div v-if="type=='m_yoomoney'||!type">
            <div>
                <div class="method_param1">
                    Курс: 1 USD = {{courses.USD}} RUB <br>
                    Сумма для перевода: <b>{{ amount_rub }} RUB</b>
                </div>

                <div class="method_param">
                    <span :class="{'red': amountErr }">{{ $t('pay_popup.min_sum') }} {{minimum}} $</span> <br>
                    Максмальная сумма: 400$ ??<br>
                    Комиссия: 0%  ??<br>
                    Срок зачисления: до 48 часов <br>

                </div>

                <div class="method_info">
                    <p>1. Переведите средства, на счет получателя, {{name}}</p>
                </div>

                <div class="agree">
                    <input class="gray" id="agree" type="checkbox">
                    <label for="agree" @click="agree=!agree" >{{ $t('pay_popup.accept_conditions') }} {{currency}}</label>
                </div>

                <button v-if="invoice===false" class="base-button btn_continue mt-8 ml-auto" :disabled="!agree || amountErr"
                        @click="create_invoice()">{{ $t('pay_popup.continue') }}</button>
            </div>
        </div>
-->


    </div>
</template>


<script>
import { useI18n } from "vue-i18n";
import ExchangeRates from "@/components/ExchangeRates.vue";
import {mapState} from "vuex";
// import ExchangeRates from "@/components/ExchangeRates";

export default {
    name: "PayMethodModal",
    components: {
        ExchangeRates
        // ExchangeRates
    },
    props: {
        minimum: {
            type: Number,
            default: 0,
        },
        amount_usd: {
            type: [Number, String],
            default: 0,
        },
        currency: {
            type: [Number, String],
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
        name:String
    },
    setup(){
        const {t} = useI18n({useScope: 'global'});
        return {t};
    },
    data() {
        return {
            amount: 0,
            invoice: false,
            agree: false,
            amountErr: false,
            testpay_done: 0,
            i_have_paid: false,

        }
    },
    computed: {
        ...mapState({
            courses: state => state.balance.courses,
        }),
        amount_rub(){
            return Math.ceil(this.amount * this.courses.USD);
        }
    },
    watch:{
        amount(){
            this.amount = this.amount.toString().replace(/[^0-9.]/g, '')
                                        .replace(/^0+/, '')
                                        .replace(/([,.])[,.]+/g, '$1')
                                        .replace(/^[^\d]*(\d+([.,]\d{0,2})?).*$/g, '$1')
                                        .substring(0, 10);

            this.amountErr = (parseFloat(this.amount) < parseFloat(this.minimum))||(this.amount===0);
            if (this.invoice !== false){
                this.invoice.amountusd = this.amount;
            }
        }
    },
    methods: {
        test_pay(){
            this.$store.dispatch('payments/createTestpay', {
                amount: this.amount,
            }).then(() => {
                this.testpay_done = 2;
            }).catch(e => {
                console.log('catch', e);
            });
        },

        create_invoice(){

            let invoice_data = {
                amount_usd: this.amount,
                currency: this.currency,
                type: this.type
            }

            if (this.type==='m_yoomoney'){
                invoice_data.current_currency = 'RUB';
                invoice_data.current_amount = this.amount_rub;
            }

            this.$store.dispatch('payments/createInvoice', invoice_data).then((data) => {
                // console.log('data', data);
                this.invoice = data;
                if (!this.type||this.type==='m_payeer'||this.type==='m_yoomoney'||this.type==='CreditCard'||this.type==='PaymentSystem'){
                    this.$router.push({name:'manual-payment',params:{id:data.request_id}});
                    setTimeout(()=>this.$emit('close'),500);
                }

            }).catch(e => {
                console.log('catch', e);
            });
        },

        Paid(request_id){
            this.$store.dispatch('payments/iHavePaid', {
                amount: this.amount_usd,
                currency: this.currency,
                order_id: request_id,
                //name: this.name

            }).then((data) => {
                console.log('data', data);
                // this.invoice = data;
                this.i_have_paid = true;
            }).catch(e => {
                console.log('catch', e);
            });
        }


    },
    mounted(){
        this.amount = this.amount_usd;
        console.log(this.amount_usd);
        //
        // if (this.type != 'testpay'){
        //     this.$store.dispatch('payments/createInvoice', {
        //         amount_usd: this.amount_usd,
        //         currency: this.currency,
        //         type: this.type
        //
        //     }).then((data) => {
        //         // console.log('data', data);
        //         this.invoice = data;
        //     }).catch(e => {
        //         console.log('catch', e);
        //     });
        // }

        if (!this.courses) {
            this.$store.dispatch('balance/getCourses');
        }


    }
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;
@import "./src/styles/form.scss";


.small_btn{
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: rgba(46, 51, 56, 0.5);
    background: #EBEFF1;
    border-radius: 15px;
    padding: 6px 16px;
    display: inline-block;
    margin-bottom: 32px;
@include respond(750px){
    margin-bottom: 20px;
}
}

.topblock{
    margin-bottom: 15px;
    gap: 90px;
    align-items: flex-end;
    @include respond(800px){
        flex-wrap:wrap;
        gap:20px;
    }
}
.exchange_rates_wrap {
    display: flex;
    align-items: center;
    gap: 12px;
    position: absolute;
    left: 105%;
    bottom: 17px;
    @include respond(750px){
        display: none;
    }
    &.ismobile {
        display: none;
        position: static;
        @include respond(750px) {
            display: flex;
            margin-bottom: 25px;
        }
        @include respond(510px) {
            flex-direction: column;
            align-items: flex-start;
            &::v-deep(.currency-item){
                font-size: 13px;
            }
        }
    }
    .t{
        width: max-content;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        @include respond(600px){
            font-size: 13px;
        }
    }

}

.exchange-rates{
    display: flex;
    gap: 4px;
    &::v-deep .currency-item{
        white-space: nowrap;
        padding: 2px 17px;
        height: 27px;
        display: grid;
        place-items: center;
        font-weight: 500;
        font-size: 18px;
        background: #EBEFF1;
        border-radius: 13.5px;
        line-height: 140%;
        @include respond($mobile){

        }
    }
    &::v-deep .divider{
        display:none;
    }
}

.field{
    width: 100%;
    max-width: 220px;
    position: relative;
    background: #FFFFFF;
    border-radius: 16px;
    border: none;
    outline: none;
    padding: 18px 30px 18px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    @include respond(750px){
        max-width: 100%;
    }
    @include respond(600px){
        height: 40px;
        font-size: 13px;
        min-width: 280px;
    }
}

.amount {
    order: -1;
    flex-wrap: wrap;
    margin-bottom: 0;
    h3{
        width: 100%;
        margin: 0 0 30px;
        font-size: 28px;
        max-width: 200px;
        @include respond(750px){
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 18px;
        }
    }
}
.red{
    color: #FF3D00;
}

.method_param {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 30px;
    &.sm{
        font-size: 14px;
    }
    @include respond(600px){
        font-size: 13px;
        margin-bottom: 20px;
    }
}

.method_info {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    .t{
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
    }
    p {
        margin-bottom: 20px;
        @include respond(600px){
            margin-bottom: 5px;

        }
    }
    @include respond(600px){
        font-size: 13px;
        :last-child{
            margin-bottom: 20px;
        }
    }
}
.agree{
    margin-top:5px;
    margin-bottom:10px;
    label{
        span{
            @include respond(600px){
                font-size: 13px;
            }
        }
    }
}
form{
    .base-button {
        margin-top: 33px;
        margin-left: auto;
        @include respond(600px){
            margin-right: auto;
            width: 75%;
        }
        @include respond(500px){
            width: 100%;
        }
    }
}
</style>
