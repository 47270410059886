<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M26.9406 16L20.1406 24L26.9406 32" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
    </svg>

</template>


<script>
    export default {
        name: "StepBackIcon"
    }
</script>

