<template>
    <div class="wrapper">
        <div class="modal_title">
            {{$t('transferModals.p2pTransfer')}}
        </div>
        <div class="p2p-transfer__title">
            {{$t('transferModals.emailReceiverText')}}
        </div>
        <div class="p2p-transfer__id">
            <TextField v-model="email"

                       :class_input="'gray'"
                       :label="$t('transferModals.emailReceiver')"
                       :readonly="isLoading"
            ></TextField>

            <div v-if="errorMessage" class="red-alert error-amount">{{ errorMessage }}</div>
        </div>
        <div class="p2p-transfer__confirm">
            <button :disabled="isLoading" class="base-button" @click="findUser">
                <LoaderAnimation v-if="isLoading"/>
                {{$t('common.next')}}
            </button>
        </div>
        <div class="p2p-transfer__decline">
            <div class="cancel" @click="close">{{$t('common.decline')}}</div>

        </div>

    </div>
</template>

<script>
import TextField from "@/components/common/TextField";
import LoaderAnimation from "@/assets/animations/LoaderAnimation";
import {mapGetters} from "vuex";

export default {
    name: "PartnerToPartnerTransferUserSelect",
    components: {LoaderAnimation, TextField},
    data() {
        return {
            isLoading: false,
            email: '',
            errorMessage: '',

        }


    },
    computed: {
        ...mapGetters({
            user: 'user/userGetter',
            allowWithdrawal: 'balance/allowedWithdrawalByRule200'
        })

    },
    methods: {
        close() {
            if (this.isLoading) return;
            this.$emit('close');
        },
        findUser() {
            if (this.isLoading) return;
            this.isLoading = true;
            this.$store.dispatch('user/getUserByEmail', {email: this.email}).then((response) => {
                if (this.user.id ===response.id){
                    this.errorMessage = this.$t('transferModals.emailReceiverText')
                }
                else {
                    this.$store.commit('popups/openPopup', {
                        name: 'PartnerToPartnerSend',
                        props: {id: response.id, userName: response.userName, userSecondName: response.second_name}
                    })
                }

            }).catch(() => {
                this.errorMessage = this.$t('transferModals.userNotFound')
            }).finally(() => {
                this.isLoading = false
            })
        }

    },
    mounted() {
        if (!this.allowWithdrawal) {
            this.$store.commit('popups/openPopup', {
                name: 'DefaultModal',
                props: {text: this.$t('transferModals.min200')}
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.p2p {
    &-transfer {
        &__title {
            margin-bottom: 16px;
        }
        &__id {
            margin-bottom: 30px;
        }

        &__confirm {
            .base-button {
                width: 100%;
            }
        }

        &__decline {
            .cancel {
                margin-top: 10px;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                color: #BAC5C8;
                text-align: center;
                text-decoration: underline;
            }
        }

    }

}
</style>
