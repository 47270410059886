<template>
    <div class="exchange-rates" v-if="coursesCheck">
        <div class="currency-item">{{ coursesCheck['USD'] }}&nbsp;$</div>
        <span class="divider">|</span>
        <div class="currency-item">{{ coursesCheck['EUR'] }}&nbsp;€</div>
        <span class="divider">|</span>
        <div class="currency-item">{{ coursesCheck['GBP'] }}&nbsp;£</div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "ExchangeRates",
    data: () => {
        return {

        }
    },
    computed: {
        ...mapState({
            courses: state => state.balance.courses,
        }),
        coursesCheck(){
            if (this.courses) return this.courses;
            return Object.entries({
                'EUR':'-',
                'GBP':'-',
                'USD':'-'
            });
        }
    },
    mounted() {
        if (!this.courses) {
            this.$store.dispatch('balance/getCourses');
        }

    }
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

</style>
