import axios from "@/utils/axios";

export default {
    namespaced: true,
    state: () => ({
        statuses: [],
        statuses_color: {
            "1" : "#38C1C2",  // Партнёр
            "2" : "#3BA5CC",  // Специалист
            "3" : "#3E76B5",  // Мастер
            "4" : "#3B59CC",  // Эксперт
            "5" : "#4138C2",  // Профессионал
            "6" : "#9638C2",  // Президент
        },
    }),
    getters: {

    },
    mutations: {
        setStatuses(state, statuses){
            state.statuses = statuses;
        },
    },
    actions: {
        async getStatuses({commit}) {
            try{
                const response = await axios.get(
                    '/api/v1/planes/get/',
                );
                response.data.status.forEach((element) => {
                    if (
                        element.user_personal_invest >= element.your_invest &&
                        element.user_team_invest >= element.team_invest &&
                        element.user_team_count >= element.condition_referral_count
                    ){
                        element.done = 1;
                    }
                })
                commit('setStatuses', response.data.status);
            } catch (e) {
                console.log(e);
            } finally {
                //
            }
        },

    }
}
