export const countries = [
    {
        shortcode: "AU", // Австралия
        name: "regions_lib.AU",
        regions: [
            {
                name: "regions_lib.2", // Австралийская столичная территория
                shortcode: "2",
            },
            {
                name: "regions_lib.7", // Виктория
                shortcode: "7",
            },
            {
                name: "regions_lib.5", // Западная Австралия
                shortcode: "5",
            },
            {
                name: "regions_lib.6", // Квинсленд
                shortcode: "6",
            },
            {
                name: "regions_lib.8", // Новый Южный Уэльс
                shortcode: "8",
            },
            {
                name: "regions_lib.1", // Северная территория
                shortcode: "1",
            },
            {
                name: "regions_lib.3", // Тасмания
                shortcode: "3",
            },
            {
                name: "regions_lib.4", // Южная Австралия
                shortcode: "4",
            },
        ]
    },
    {
        shortcode: "AT", // Австрия
        name: "regions_lib.AT",
        regions: [
            {
                name: "regions_lib.9", // Бургенланд
                shortcode: "9",
            },
            {
                name: "regions_lib.16", // Вена
                shortcode: "16",
            },
            {
                name: "regions_lib.15", // Верхняя Австрия
                shortcode: "15",
            },
            {
                name: "regions_lib.11", // Зальцбург
                shortcode: "11",
            },
            {
                name: "regions_lib.12", // Каринтия
                shortcode: "12",
            },
            {
                name: "regions_lib.17", // Нижняя Австрия
                shortcode: "17",
            },
            {
                name: "regions_lib.13", // Тироль
                shortcode: "13",
            },
            {
                name: "regions_lib.10", // Форарльберг
                shortcode: "10",
            },
            {
                name: "regions_lib.14", // Штирия
                shortcode: "14",
            },
        ]
    },
    {
        shortcode: "AZ", // Азербайджан
        name: "regions_lib.AZ",
        regions: [
            {
                name: "regions_lib.82", // Absheron Rayon
                shortcode: "82",
            },
            {
                name: "regions_lib.80", // Aghdam Rayon
                shortcode: "80",
            },
            {
                name: "regions_lib.57", // Aghdash Rayon
                shortcode: "57",
            },
            {
                name: "regions_lib.67", // Aghjabadi Rayon
                shortcode: "67",
            },
            {
                name: "regions_lib.52", // Aghstafa Rayon
                shortcode: "52",
            },
            {
                name: "regions_lib.40", // Aghsu Rayon
                shortcode: "40",
            },
            {
                name: "regions_lib.53", // Astara District
                shortcode: "53",
            },
            {
                name: "regions_lib.86", // Baku City
                shortcode: "86",
            },
            {
                name: "regions_lib.56", // Balakan Rayon
                shortcode: "56",
            },
            {
                name: "regions_lib.70", // Barda Rayon
                shortcode: "70",
            },
            {
                name: "regions_lib.48", // Beylagan Rayon
                shortcode: "48",
            },
            {
                name: "regions_lib.54", // Bilasuvar Rayon
                shortcode: "54",
            },
            {
                name: "regions_lib.23", // Dashkasan Rayon
                shortcode: "23",
            },
            {
                name: "regions_lib.73", // Fizuli Rayon
                shortcode: "73",
            },
            {
                name: "regions_lib.59", // Gadabay Rayon
                shortcode: "59",
            },
            {
                name: "regions_lib.84", // Ganja City
                shortcode: "84",
            },
            {
                name: "regions_lib.25", // Gobustan Rayon
                shortcode: "25",
            },
            {
                name: "regions_lib.60", // Goranboy Rayon
                shortcode: "60",
            },
            {
                name: "regions_lib.62", // Goychay Rayon
                shortcode: "62",
            },
            {
                name: "regions_lib.38", // Goygol Rayon
                shortcode: "38",
            },
            {
                name: "regions_lib.37", // Hajigabul Rayon
                shortcode: "37",
            },
            {
                name: "regions_lib.65", // Imishli Rayon
                shortcode: "65",
            },
            {
                name: "regions_lib.45", // Ismayilli Rayon
                shortcode: "45",
            },
            {
                name: "regions_lib.46", // Jabrayil District
                shortcode: "46",
            },
            {
                name: "regions_lib.81", // Jalilabad
                shortcode: "81",
            },
            {
                name: "regions_lib.31", // Kalbajar District
                shortcode: "31",
            },
            {
                name: "regions_lib.74", // Khachmaz Rayon
                shortcode: "74",
            },
            {
                name: "regions_lib.19", // Khizi Rayon
                shortcode: "19",
            },
            {
                name: "regions_lib.27", // Khojavend District
                shortcode: "27",
            },
            {
                name: "regions_lib.64", // Kurdamir Rayon
                shortcode: "64",
            },
            {
                name: "regions_lib.79", // Lankaran Rayon
                shortcode: "79",
            },
            {
                name: "regions_lib.41", // Laçın Rayonu
                shortcode: "41",
            },
            {
                name: "regions_lib.42", // Lerik Rayon
                shortcode: "42",
            },
            {
                name: "regions_lib.76", // Masally District
                shortcode: "76",
            },
            {
                name: "regions_lib.58", // Mingacevir City
                shortcode: "58",
            },
            {
                name: "regions_lib.18", // Naftalan City
                shortcode: "18",
            },
            {
                name: "regions_lib.44", // Neftchala Rayon
                shortcode: "44",
            },
            {
                name: "regions_lib.28", // Oghuz Rayon
                shortcode: "28",
            },
            {
                name: "regions_lib.63", // Qabala Rayon
                shortcode: "63",
            },
            {
                name: "regions_lib.34", // Qakh Rayon
                shortcode: "34",
            },
            {
                name: "regions_lib.55", // Qazakh Rayon
                shortcode: "55",
            },
            {
                name: "regions_lib.71", // Quba Rayon
                shortcode: "71",
            },
            {
                name: "regions_lib.24", // Qubadli Rayon
                shortcode: "24",
            },
            {
                name: "regions_lib.49", // Qusar Rayon
                shortcode: "49",
            },
            {
                name: "regions_lib.51", // Saatly Rayon
                shortcode: "51",
            },
            {
                name: "regions_lib.72", // Sabirabad Rayon
                shortcode: "72",
            },
            {
                name: "regions_lib.66", // Salyan Rayon
                shortcode: "66",
            },
            {
                name: "regions_lib.35", // Samukh Rayon
                shortcode: "35",
            },
            {
                name: "regions_lib.30", // Shabran Rayon
                shortcode: "30",
            },
            {
                name: "regions_lib.77", // Shaki City
                shortcode: "77",
            },
            {
                name: "regions_lib.78", // Shaki Rayon
                shortcode: "78",
            },
            {
                name: "regions_lib.50", // Shamakhi Rayon
                shortcode: "50",
            },
            {
                name: "regions_lib.83", // Shamkir Rayon
                shortcode: "83",
            },
            {
                name: "regions_lib.47", // Shirvan
                shortcode: "47",
            },
            {
                name: "regions_lib.26", // Siazan Rayon
                shortcode: "26",
            },
            {
                name: "regions_lib.85", // Sumqayit City
                shortcode: "85",
            },
            {
                name: "regions_lib.61", // Tartar Rayon
                shortcode: "61",
            },
            {
                name: "regions_lib.75", // Tovuz Rayon
                shortcode: "75",
            },
            {
                name: "regions_lib.43", // Ujar Rayon
                shortcode: "43",
            },
            {
                name: "regions_lib.33", // Xankandi Sahari
                shortcode: "33",
            },
            {
                name: "regions_lib.20", // Xocalı Rayonu
                shortcode: "20",
            },
            {
                name: "regions_lib.32", // Yardymli Rayon
                shortcode: "32",
            },
            {
                name: "regions_lib.68", // Yevlakh Rayon
                shortcode: "68",
            },
            {
                name: "regions_lib.36", // Yevlax City
                shortcode: "36",
            },
            {
                name: "regions_lib.21", // Zangilan Rayon
                shortcode: "21",
            },
            {
                name: "regions_lib.69", // Zaqatala Rayon
                shortcode: "69",
            },
            {
                name: "regions_lib.22", // Şuşa Rayonu
                shortcode: "22",
            },
            {
                name: "regions_lib.29", // Зардобский Район
                shortcode: "29",
            },
            {
                name: "regions_lib.39", // Нахичевань
                shortcode: "39",
            },
        ]
    },
    {
        shortcode: "AX", // Аландские острова
        name: "regions_lib.AX",
        regions: [
            {
                name: "regions_lib.88", // Mariehamns stad
                shortcode: "88",
            },
            {
                name: "regions_lib.89", // Ålands landsbygd
                shortcode: "89",
            },
            {
                name: "regions_lib.87", // Ålands skärgård
                shortcode: "87",
            },
        ]
    },
    {
        shortcode: "AL", // Албания
        name: "regions_lib.AL",
        regions: [
            {
                name: "regions_lib.94", // Qarku i Beratit
                shortcode: "94",
            },
            {
                name: "regions_lib.93", // Qarku i Dibrës
                shortcode: "93",
            },
            {
                name: "regions_lib.98", // Qarku i Durrësit
                shortcode: "98",
            },
            {
                name: "regions_lib.99", // Qarku i Elbasanit
                shortcode: "99",
            },
            {
                name: "regions_lib.100", // Qarku i Fierit
                shortcode: "100",
            },
            {
                name: "regions_lib.90", // Qarku i Gjirokastrës
                shortcode: "90",
            },
            {
                name: "regions_lib.97", // Qarku i Korçës
                shortcode: "97",
            },
            {
                name: "regions_lib.91", // Qarku i Kukësit
                shortcode: "91",
            },
            {
                name: "regions_lib.92", // Qarku i Lezhës
                shortcode: "92",
            },
            {
                name: "regions_lib.96", // Qarku i Shkodrës
                shortcode: "96",
            },
            {
                name: "regions_lib.95", // Qarku i Vlorës
                shortcode: "95",
            },
            {
                name: "regions_lib.101", // Tirana
                shortcode: "101",
            },
        ]
    },
    {
        shortcode: "DZ", // Алжир
        name: "regions_lib.DZ",
        regions: [
            {
                name: "regions_lib.123", // Adrar
                shortcode: "123",
            },
            {
                name: "regions_lib.159", // Algiers Province
                shortcode: "159",
            },
            {
                name: "regions_lib.131", // Annaba
                shortcode: "131",
            },
            {
                name: "regions_lib.141", // Aïn Defla Province
                shortcode: "141",
            },
            {
                name: "regions_lib.121", // Aïn Témouchent Province
                shortcode: "121",
            },
            {
                name: "regions_lib.154", // Batna
                shortcode: "154",
            },
            {
                name: "regions_lib.109", // Beni Abbes
                shortcode: "109",
            },
            {
                name: "regions_lib.138", // Biskra Province
                shortcode: "138",
            },
            {
                name: "regions_lib.153", // Blida Province
                shortcode: "153",
            },
            {
                name: "regions_lib.111", // Bordj Badji Mokhtar
                shortcode: "111",
            },
            {
                name: "regions_lib.137", // Bouira Province
                shortcode: "137",
            },
            {
                name: "regions_lib.144", // Boumerdes Province
                shortcode: "144",
            },
            {
                name: "regions_lib.117", // Béchar Province
                shortcode: "117",
            },
            {
                name: "regions_lib.148", // Béjaia Province
                shortcode: "148",
            },
            {
                name: "regions_lib.152", // Chlef Province
                shortcode: "152",
            },
            {
                name: "regions_lib.149", // Constantine Province
                shortcode: "149",
            },
            {
                name: "regions_lib.106", // Djanet
                shortcode: "106",
            },
            {
                name: "regions_lib.156", // Djelfa Province
                shortcode: "156",
            },
            {
                name: "regions_lib.116", // El Bayadh Province
                shortcode: "116",
            },
            {
                name: "regions_lib.105", // El Menia
                shortcode: "105",
            },
            {
                name: "regions_lib.103", // El Mghair
                shortcode: "103",
            },
            {
                name: "regions_lib.135", // El Oued Province
                shortcode: "135",
            },
            {
                name: "regions_lib.124", // El Tarf Province
                shortcode: "124",
            },
            {
                name: "regions_lib.120", // Ghardaia Province
                shortcode: "120",
            },
            {
                name: "regions_lib.127", // Guelma Province
                shortcode: "127",
            },
            {
                name: "regions_lib.113", // Illizi Province
                shortcode: "113",
            },
            {
                name: "regions_lib.108", // In Guezzam
                shortcode: "108",
            },
            {
                name: "regions_lib.107", // In Salah
                shortcode: "107",
            },
            {
                name: "regions_lib.134", // Jijel Province
                shortcode: "134",
            },
            {
                name: "regions_lib.122", // Khenchela Province
                shortcode: "122",
            },
            {
                name: "regions_lib.126", // Laghouat Province
                shortcode: "126",
            },
            {
                name: "regions_lib.151", // M&quot;Sila Province
                shortcode: "151",
            },
            {
                name: "regions_lib.143", // Mascara Province
                shortcode: "143",
            },
            {
                name: "regions_lib.145", // Medea Province
                shortcode: "145",
            },
            {
                name: "regions_lib.142", // Mila Province
                shortcode: "142",
            },
            {
                name: "regions_lib.140", // Mostaganem Province
                shortcode: "140",
            },
            {
                name: "regions_lib.115", // Naama Province
                shortcode: "115",
            },
            {
                name: "regions_lib.157", // Oran Province
                shortcode: "157",
            },
            {
                name: "regions_lib.128", // Ouargla Province
                shortcode: "128",
            },
            {
                name: "regions_lib.102", // Ouled Djellal
                shortcode: "102",
            },
            {
                name: "regions_lib.132", // Oum el Bouaghi Province
                shortcode: "132",
            },
            {
                name: "regions_lib.139", // Relizane Province
                shortcode: "139",
            },
            {
                name: "regions_lib.119", // Saida Province
                shortcode: "119",
            },
            {
                name: "regions_lib.130", // Sidi Bel Abbès Province
                shortcode: "130",
            },
            {
                name: "regions_lib.147", // Skikda Province
                shortcode: "147",
            },
            {
                name: "regions_lib.125", // Souk Ahras Province
                shortcode: "125",
            },
            {
                name: "regions_lib.158", // Sétif Province
                shortcode: "158",
            },
            {
                name: "regions_lib.114", // Tamanrasset Province
                shortcode: "114",
            },
            {
                name: "regions_lib.146", // Tiaret Province
                shortcode: "146",
            },
            {
                name: "regions_lib.110", // Timimoun
                shortcode: "110",
            },
            {
                name: "regions_lib.112", // Tindouf Province
                shortcode: "112",
            },
            {
                name: "regions_lib.129", // Tipaza Province
                shortcode: "129",
            },
            {
                name: "regions_lib.118", // Tissemsilt Province
                shortcode: "118",
            },
            {
                name: "regions_lib.155", // Tizi Ouzou Province
                shortcode: "155",
            },
            {
                name: "regions_lib.150", // Tlemcen Province
                shortcode: "150",
            },
            {
                name: "regions_lib.104", // Touggourt
                shortcode: "104",
            },
            {
                name: "regions_lib.136", // Tébessa Province
                shortcode: "136",
            },
            {
                name: "regions_lib.133", // Wilaya de Bordj Bou Arréridj
                shortcode: "133",
            },
        ]
    },
    {
        shortcode: "AS", // Американское Самоа
        name: "regions_lib.AS",
        regions: [
            {
                name: "regions_lib.163", // Eastern District
                shortcode: "163",
            },
            {
                name: "regions_lib.162", // Manu&quot;a District
                shortcode: "162",
            },
            {
                name: "regions_lib.160", // Rose Atoll
                shortcode: "160",
            },
            {
                name: "regions_lib.161", // Swains Island
                shortcode: "161",
            },
            {
                name: "regions_lib.164", // Western District
                shortcode: "164",
            },
        ]
    },
    {
        shortcode: "AI", // Ангилья
        name: "regions_lib.AI",
        regions: [
            {
                name: "regions_lib.168", // Blowing Point
                shortcode: "168",
            },
            {
                name: "regions_lib.167", // East End
                shortcode: "167",
            },
            {
                name: "regions_lib.173", // George Hill
                shortcode: "173",
            },
            {
                name: "regions_lib.172", // Island Harbour
                shortcode: "172",
            },
            {
                name: "regions_lib.166", // North Hill
                shortcode: "166",
            },
            {
                name: "regions_lib.178", // North Side
                shortcode: "178",
            },
            {
                name: "regions_lib.165", // Sandy Ground
                shortcode: "165",
            },
            {
                name: "regions_lib.170", // Sandy Hill
                shortcode: "170",
            },
            {
                name: "regions_lib.175", // South Hill
                shortcode: "175",
            },
            {
                name: "regions_lib.176", // Stoney Ground
                shortcode: "176",
            },
            {
                name: "regions_lib.174", // The Farrington
                shortcode: "174",
            },
            {
                name: "regions_lib.177", // The Quarter
                shortcode: "177",
            },
            {
                name: "regions_lib.171", // The Valley
                shortcode: "171",
            },
            {
                name: "regions_lib.169", // West End
                shortcode: "169",
            },
        ]
    },
    {
        shortcode: "AO", // Ангола
        name: "regions_lib.AO",
        regions: [
            {
                name: "regions_lib.180", // Bengo Province
                shortcode: "180",
            },
            {
                name: "regions_lib.191", // Bíe Province
                shortcode: "191",
            },
            {
                name: "regions_lib.179", // Cuando Cobango Province
                shortcode: "179",
            },
            {
                name: "regions_lib.181", // Cuanza Norte Province
                shortcode: "181",
            },
            {
                name: "regions_lib.189", // Cunene Province
                shortcode: "189",
            },
            {
                name: "regions_lib.195", // Huíla Province
                shortcode: "195",
            },
            {
                name: "regions_lib.185", // Kwanza Sul Province
                shortcode: "185",
            },
            {
                name: "regions_lib.188", // Luanda Norte Province
                shortcode: "188",
            },
            {
                name: "regions_lib.196", // Luanda Province
                shortcode: "196",
            },
            {
                name: "regions_lib.190", // Malanje Province
                shortcode: "190",
            },
            {
                name: "regions_lib.182", // Namibe Province
                shortcode: "182",
            },
            {
                name: "regions_lib.192", // Uíge Province
                shortcode: "192",
            },
            {
                name: "regions_lib.194", // Бенгела
                shortcode: "194",
            },
            {
                name: "regions_lib.184", // Заире
                shortcode: "184",
            },
            {
                name: "regions_lib.186", // Кабинда
                shortcode: "186",
            },
            {
                name: "regions_lib.187", // Мошико
                shortcode: "187",
            },
            {
                name: "regions_lib.193", // Уамбо
                shortcode: "193",
            },
            {
                name: "regions_lib.183", // Южная Лунда
                shortcode: "183",
            },
        ]
    },
    {
        shortcode: "AD", // Андорра
        name: "regions_lib.AD",
        regions: [
            {
                name: "regions_lib.203", // Andorra la Vella
                shortcode: "203",
            },
            {
                name: "regions_lib.201", // Encamp
                shortcode: "201",
            },
            {
                name: "regions_lib.199", // La Massana
                shortcode: "199",
            },
            {
                name: "regions_lib.198", // Канильо
                shortcode: "198",
            },
            {
                name: "regions_lib.197", // Ордино
                shortcode: "197",
            },
            {
                name: "regions_lib.200", // Сан-Жулиа-де-Лория
                shortcode: "200",
            },
            {
                name: "regions_lib.202", // Эскальдес-Энгордань
                shortcode: "202",
            },
        ]
    },
    {
        shortcode: "AQ", // Антарктида
        name: "regions_lib.AQ",
        regions: [
        ]
    },
    {
        shortcode: "AG", // Антигуа и Барбуда
        name: "regions_lib.AG",
        regions: [
            {
                name: "regions_lib.207", // Parish of Saint George
                shortcode: "207",
            },
            {
                name: "regions_lib.210", // Parish of Saint John
                shortcode: "210",
            },
            {
                name: "regions_lib.208", // Parish of Saint Mary
                shortcode: "208",
            },
            {
                name: "regions_lib.209", // Parish of Saint Paul
                shortcode: "209",
            },
            {
                name: "regions_lib.206", // Parish of Saint Peter
                shortcode: "206",
            },
            {
                name: "regions_lib.205", // Parish of Saint Philip
                shortcode: "205",
            },
            {
                name: "regions_lib.204", // Барбуда
                shortcode: "204",
            },
        ]
    },
    {
        shortcode: "AR", // Аргентина
        name: "regions_lib.AR",
        regions: [
            {
                name: "regions_lib.234", // Buenos Aires
                shortcode: "234",
            },
            {
                name: "regions_lib.231", // Buenos Aires F.D.
                shortcode: "231",
            },
            {
                name: "regions_lib.215", // Catamarca Province
                shortcode: "215",
            },
            {
                name: "regions_lib.226", // Chaco Province
                shortcode: "226",
            },
            {
                name: "regions_lib.217", // Chubut Province
                shortcode: "217",
            },
            {
                name: "regions_lib.233", // Córdoba Province
                shortcode: "233",
            },
            {
                name: "regions_lib.228", // Entre Ríos Province
                shortcode: "228",
            },
            {
                name: "regions_lib.219", // Formosa Province
                shortcode: "219",
            },
            {
                name: "regions_lib.221", // Jujuy Province
                shortcode: "221",
            },
            {
                name: "regions_lib.214", // La Pampa Province
                shortcode: "214",
            },
            {
                name: "regions_lib.213", // La Rioja Province
                shortcode: "213",
            },
            {
                name: "regions_lib.230", // Mendoza Province
                shortcode: "230",
            },
            {
                name: "regions_lib.225", // Misiones Province
                shortcode: "225",
            },
            {
                name: "regions_lib.218", // Neuquén Province
                shortcode: "218",
            },
            {
                name: "regions_lib.220", // Río Negro Province
                shortcode: "220",
            },
            {
                name: "regions_lib.227", // Salta Province
                shortcode: "227",
            },
            {
                name: "regions_lib.222", // San Juan Province
                shortcode: "222",
            },
            {
                name: "regions_lib.216", // San Luis Province
                shortcode: "216",
            },
            {
                name: "regions_lib.212", // Santa Cruz Province
                shortcode: "212",
            },
            {
                name: "regions_lib.232", // Santa Fe Province
                shortcode: "232",
            },
            {
                name: "regions_lib.223", // Santiago del Estero Province
                shortcode: "223",
            },
            {
                name: "regions_lib.211", // Tierra del Fuego Province
                shortcode: "211",
            },
            {
                name: "regions_lib.229", // Tucumán Province
                shortcode: "229",
            },
            {
                name: "regions_lib.224", // Корриентес
                shortcode: "224",
            },
        ]
    },
    {
        shortcode: "AM", // Армения
        name: "regions_lib.AM",
        regions: [
            {
                name: "regions_lib.245", // Yerevan
                shortcode: "245",
            },
            {
                name: "regions_lib.237", // Арагацотнская область
                shortcode: "237",
            },
            {
                name: "regions_lib.243", // Араратская область
                shortcode: "243",
            },
            {
                name: "regions_lib.244", // Армавирская область
                shortcode: "244",
            },
            {
                name: "regions_lib.235", // Вайоцдзорская область
                shortcode: "235",
            },
            {
                name: "regions_lib.239", // Гехаркуникская область
                shortcode: "239",
            },
            {
                name: "regions_lib.242", // Котайкская область
                shortcode: "242",
            },
            {
                name: "regions_lib.240", // Лорийская область
                shortcode: "240",
            },
            {
                name: "regions_lib.238", // Сюникская область
                shortcode: "238",
            },
            {
                name: "regions_lib.236", // Тавушская область
                shortcode: "236",
            },
            {
                name: "regions_lib.241", // Ширакская область
                shortcode: "241",
            },
        ]
    },
    {
        shortcode: "AW", // Аруба
        name: "regions_lib.AW",
        regions: [
        ]
    },
    {
        shortcode: "AF", // Афганистан
        name: "regions_lib.AF",
        regions: [
            {
                name: "regions_lib.273", // Badakhshān
                shortcode: "273",
            },
            {
                name: "regions_lib.269", // Baghlan
                shortcode: "269",
            },
            {
                name: "regions_lib.258", // Bamyan
                shortcode: "258",
            },
            {
                name: "regions_lib.268", // Bādghīs
                shortcode: "268",
            },
            {
                name: "regions_lib.253", // Daykundi Province
                shortcode: "253",
            },
            {
                name: "regions_lib.255", // Farāh
                shortcode: "255",
            },
            {
                name: "regions_lib.274", // Ghazni
                shortcode: "274",
            },
            {
                name: "regions_lib.263", // Ghowr
                shortcode: "263",
            },
            {
                name: "regions_lib.266", // Jowzjan
                shortcode: "266",
            },
            {
                name: "regions_lib.279", // Kabul
                shortcode: "279",
            },
            {
                name: "regions_lib.278", // Kandahar
                shortcode: "278",
            },
            {
                name: "regions_lib.256", // Kapisa
                shortcode: "256",
            },
            {
                name: "regions_lib.260", // Kunar
                shortcode: "260",
            },
            {
                name: "regions_lib.262", // Laghman
                shortcode: "262",
            },
            {
                name: "regions_lib.252", // Logar
                shortcode: "252",
            },
            {
                name: "regions_lib.277", // Nangarhar
                shortcode: "277",
            },
            {
                name: "regions_lib.248", // Nimroz
                shortcode: "248",
            },
            {
                name: "regions_lib.246", // Nuristan
                shortcode: "246",
            },
            {
                name: "regions_lib.257", // Paktika
                shortcode: "257",
            },
            {
                name: "regions_lib.267", // Parwan
                shortcode: "267",
            },
            {
                name: "regions_lib.261", // Samangan
                shortcode: "261",
            },
            {
                name: "regions_lib.265", // Sar-e Pul
                shortcode: "265",
            },
            {
                name: "regions_lib.272", // Takhar
                shortcode: "272",
            },
            {
                name: "regions_lib.249", // Velāyat-e Khowst
                shortcode: "249",
            },
            {
                name: "regions_lib.251", // Wardak
                shortcode: "251",
            },
            {
                name: "regions_lib.254", // Wilāyat-e Zābul
                shortcode: "254",
            },
            {
                name: "regions_lib.275", // Балх
                shortcode: "275",
            },
            {
                name: "regions_lib.276", // Герат
                shortcode: "276",
            },
            {
                name: "regions_lib.271", // Гильменд
                shortcode: "271",
            },
            {
                name: "regions_lib.264", // Кундуз
                shortcode: "264",
            },
            {
                name: "regions_lib.259", // Пактия
                shortcode: "259",
            },
            {
                name: "regions_lib.247", // Панджшер
                shortcode: "247",
            },
            {
                name: "regions_lib.250", // Урузган
                shortcode: "250",
            },
            {
                name: "regions_lib.270", // Фарьяб
                shortcode: "270",
            },
        ]
    },
    {
        shortcode: "BS", // Багамы
        name: "regions_lib.BS",
        regions: [
            {
                name: "regions_lib.290", // Acklins Island District
                shortcode: "290",
            },
            {
                name: "regions_lib.293", // Berry Islands District
                shortcode: "293",
            },
            {
                name: "regions_lib.300", // Bimini District
                shortcode: "300",
            },
            {
                name: "regions_lib.289", // Black Point District
                shortcode: "289",
            },
            {
                name: "regions_lib.299", // Cat Island
                shortcode: "299",
            },
            {
                name: "regions_lib.283", // Central Abaco District
                shortcode: "283",
            },
            {
                name: "regions_lib.292", // Central Andros District
                shortcode: "292",
            },
            {
                name: "regions_lib.282", // Central Eleuthera District
                shortcode: "282",
            },
            {
                name: "regions_lib.310", // City of Freeport District
                shortcode: "310",
            },
            {
                name: "regions_lib.287", // Crooked Island and Long Cay District
                shortcode: "287",
            },
            {
                name: "regions_lib.281", // East Grand Bahama District
                shortcode: "281",
            },
            {
                name: "regions_lib.307", // Exuma District
                shortcode: "307",
            },
            {
                name: "regions_lib.288", // Grand Cay District
                shortcode: "288",
            },
            {
                name: "regions_lib.301", // Harbour Island District
                shortcode: "301",
            },
            {
                name: "regions_lib.291", // Hope Town District
                shortcode: "291",
            },
            {
                name: "regions_lib.295", // Inagua
                shortcode: "295",
            },
            {
                name: "regions_lib.302", // Long Island
                shortcode: "302",
            },
            {
                name: "regions_lib.294", // Mangrove Cay
                shortcode: "294",
            },
            {
                name: "regions_lib.286", // Mayaguana District
                shortcode: "286",
            },
            {
                name: "regions_lib.296", // Moore’s Island District
                shortcode: "296",
            },
            {
                name: "regions_lib.311", // New Providence District
                shortcode: "311",
            },
            {
                name: "regions_lib.309", // North Abaco District
                shortcode: "309",
            },
            {
                name: "regions_lib.305", // North Andros District
                shortcode: "305",
            },
            {
                name: "regions_lib.303", // North Eleuthera District
                shortcode: "303",
            },
            {
                name: "regions_lib.285", // Ragged Island District
                shortcode: "285",
            },
            {
                name: "regions_lib.284", // Rum Cay
                shortcode: "284",
            },
            {
                name: "regions_lib.297", // San Salvador District
                shortcode: "297",
            },
            {
                name: "regions_lib.308", // South Abaco District
                shortcode: "308",
            },
            {
                name: "regions_lib.304", // South Andros
                shortcode: "304",
            },
            {
                name: "regions_lib.306", // South Eleuthera
                shortcode: "306",
            },
            {
                name: "regions_lib.298", // Spanish Wells District
                shortcode: "298",
            },
            {
                name: "regions_lib.280", // West Grand Bahama District
                shortcode: "280",
            },
        ]
    },
    {
        shortcode: "BD", // Бангладеш
        name: "regions_lib.BD",
        regions: [
            {
                name: "regions_lib.312", // Barisal Division
                shortcode: "312",
            },
            {
                name: "regions_lib.319", // Dhaka Division
                shortcode: "319",
            },
            {
                name: "regions_lib.315", // Khulna Division
                shortcode: "315",
            },
            {
                name: "regions_lib.314", // Mymensingh Division
                shortcode: "314",
            },
            {
                name: "regions_lib.316", // Rangpur Division
                shortcode: "316",
            },
            {
                name: "regions_lib.317", // Rājshāhi Division
                shortcode: "317",
            },
            {
                name: "regions_lib.313", // Sylhet Division
                shortcode: "313",
            },
            {
                name: "regions_lib.318", // Читтагонг
                shortcode: "318",
            },
        ]
    },
    {
        shortcode: "BB", // Барбадос
        name: "regions_lib.BB",
        regions: [
            {
                name: "regions_lib.329", // Christ Church
                shortcode: "329",
            },
            {
                name: "regions_lib.320", // Saint Andrew
                shortcode: "320",
            },
            {
                name: "regions_lib.326", // Saint George
                shortcode: "326",
            },
            {
                name: "regions_lib.328", // Saint James
                shortcode: "328",
            },
            {
                name: "regions_lib.323", // Saint John
                shortcode: "323",
            },
            {
                name: "regions_lib.321", // Saint Joseph
                shortcode: "321",
            },
            {
                name: "regions_lib.322", // Saint Lucy
                shortcode: "322",
            },
            {
                name: "regions_lib.330", // Saint Michael
                shortcode: "330",
            },
            {
                name: "regions_lib.324", // Saint Peter
                shortcode: "324",
            },
            {
                name: "regions_lib.327", // Saint Philip
                shortcode: "327",
            },
            {
                name: "regions_lib.325", // Saint Thomas
                shortcode: "325",
            },
        ]
    },
    {
        shortcode: "BH", // Бахрейн
        name: "regions_lib.BH",
        regions: [
            {
                name: "regions_lib.334", // Manama
                shortcode: "334",
            },
            {
                name: "regions_lib.333", // Northern Governorate
                shortcode: "333",
            },
            {
                name: "regions_lib.331", // Southern Governorate
                shortcode: "331",
            },
            {
                name: "regions_lib.332", // Мухаррак
                shortcode: "332",
            },
        ]
    },
    {
        shortcode: "BY", // Беларусь
        name: "regions_lib.BY",
        regions: [
            {
                name: "regions_lib.340", // Gomelskaya Oblast
                shortcode: "340",
            },
            {
                name: "regions_lib.336", // Grodnenskaya Oblast’
                shortcode: "336",
            },
            {
                name: "regions_lib.339", // Minskaya Oblast’
                shortcode: "339",
            },
            {
                name: "regions_lib.338", // Брестская Область
                shortcode: "338",
            },
            {
                name: "regions_lib.337", // Витебская Область
                shortcode: "337",
            },
            {
                name: "regions_lib.341", // Минск
                shortcode: "341",
            },
            {
                name: "regions_lib.335", // Могилевская область
                shortcode: "335",
            },
        ]
    },
    {
        shortcode: "BZ", // Белиз
        name: "regions_lib.BZ",
        regions: [
            {
                name: "regions_lib.347", // Belize District
                shortcode: "347",
            },
            {
                name: "regions_lib.346", // Cayo District
                shortcode: "346",
            },
            {
                name: "regions_lib.344", // Corozal District
                shortcode: "344",
            },
            {
                name: "regions_lib.345", // Orange Walk District
                shortcode: "345",
            },
            {
                name: "regions_lib.343", // Stann Creek District
                shortcode: "343",
            },
            {
                name: "regions_lib.342", // Toledo District
                shortcode: "342",
            },
        ]
    },
    {
        shortcode: "BE", // Бельгия
        name: "regions_lib.BE",
        regions: [
            {
                name: "regions_lib.348", // Brussels Capital
                shortcode: "348",
            },
            {
                name: "regions_lib.350", // Flanders
                shortcode: "350",
            },
            {
                name: "regions_lib.349", // Wallonia
                shortcode: "349",
            },
        ]
    },
    {
        shortcode: "BJ", // Бенин
        name: "regions_lib.BJ",
        regions: [
            {
                name: "regions_lib.356", // Atakora Department
                shortcode: "356",
            },
            {
                name: "regions_lib.362", // Atlantique Department
                shortcode: "362",
            },
            {
                name: "regions_lib.361", // Borgou Department
                shortcode: "361",
            },
            {
                name: "regions_lib.357", // Collines Department
                shortcode: "357",
            },
            {
                name: "regions_lib.354", // Kouffo Department
                shortcode: "354",
            },
            {
                name: "regions_lib.351", // Mono Department
                shortcode: "351",
            },
            {
                name: "regions_lib.360", // Ouémé Department
                shortcode: "360",
            },
            {
                name: "regions_lib.353", // Plateau Department
                shortcode: "353",
            },
            {
                name: "regions_lib.358", // Zou Department
                shortcode: "358",
            },
            {
                name: "regions_lib.355", // Алибори
                shortcode: "355",
            },
            {
                name: "regions_lib.352", // Донга
                shortcode: "352",
            },
            {
                name: "regions_lib.359", // Литораль
                shortcode: "359",
            },
        ]
    },
    {
        shortcode: "BM", // Бермудские о-ва
        name: "regions_lib.BM",
        regions: [
            {
                name: "regions_lib.370", // Devonshire Parish
                shortcode: "370",
            },
            {
                name: "regions_lib.363", // Hamilton City
                shortcode: "363",
            },
            {
                name: "regions_lib.366", // Hamilton Parish
                shortcode: "366",
            },
            {
                name: "regions_lib.365", // Paget Parish
                shortcode: "365",
            },
            {
                name: "regions_lib.373", // Pembroke Parish
                shortcode: "373",
            },
            {
                name: "regions_lib.364", // Saint George
                shortcode: "364",
            },
            {
                name: "regions_lib.367", // Saint George’s Parish
                shortcode: "367",
            },
            {
                name: "regions_lib.371", // Sandys Parish
                shortcode: "371",
            },
            {
                name: "regions_lib.368", // Smith’s Parish
                shortcode: "368",
            },
            {
                name: "regions_lib.369", // Southampton Parish
                shortcode: "369",
            },
            {
                name: "regions_lib.372", // Warwick Parish
                shortcode: "372",
            },
        ]
    },
    {
        shortcode: "BG", // Болгария
        name: "regions_lib.BG",
        regions: [
            {
                name: "regions_lib.396", // Blagoevgrad
                shortcode: "396",
            },
            {
                name: "regions_lib.398", // Burgas
                shortcode: "398",
            },
            {
                name: "regions_lib.391", // Haskovo
                shortcode: "391",
            },
            {
                name: "regions_lib.383", // Lovech
                shortcode: "383",
            },
            {
                name: "regions_lib.388", // Oblast Dobrich
                shortcode: "388",
            },
            {
                name: "regions_lib.385", // Oblast Kardzhali
                shortcode: "385",
            },
            {
                name: "regions_lib.381", // Oblast Kyustendil
                shortcode: "381",
            },
            {
                name: "regions_lib.384", // Oblast Montana
                shortcode: "384",
            },
            {
                name: "regions_lib.394", // Oblast Pleven
                shortcode: "394",
            },
            {
                name: "regions_lib.379", // Oblast Razgrad
                shortcode: "379",
            },
            {
                name: "regions_lib.390", // Oblast Ruse
                shortcode: "390",
            },
            {
                name: "regions_lib.387", // Oblast Shumen
                shortcode: "387",
            },
            {
                name: "regions_lib.377", // Oblast Silistra
                shortcode: "377",
            },
            {
                name: "regions_lib.389", // Oblast Sliven
                shortcode: "389",
            },
            {
                name: "regions_lib.375", // Oblast Smolyan
                shortcode: "375",
            },
            {
                name: "regions_lib.397", // Oblast Stara Zagora
                shortcode: "397",
            },
            {
                name: "regions_lib.378", // Oblast Targovishte
                shortcode: "378",
            },
            {
                name: "regions_lib.393", // Oblast Veliko Tarnovo
                shortcode: "393",
            },
            {
                name: "regions_lib.374", // Oblast Vidin
                shortcode: "374",
            },
            {
                name: "regions_lib.386", // Oblast Vratsa
                shortcode: "386",
            },
            {
                name: "regions_lib.380", // Oblast Yambol
                shortcode: "380",
            },
            {
                name: "regions_lib.395", // Pazardzhik
                shortcode: "395",
            },
            {
                name: "regions_lib.382", // Pernik
                shortcode: "382",
            },
            {
                name: "regions_lib.400", // Plovdiv
                shortcode: "400",
            },
            {
                name: "regions_lib.401", // Sofia-Grad
                shortcode: "401",
            },
            {
                name: "regions_lib.399", // Varna
                shortcode: "399",
            },
            {
                name: "regions_lib.376", // Габровская область
                shortcode: "376",
            },
            {
                name: "regions_lib.392", // Софийская область
                shortcode: "392",
            },
        ]
    },
    {
        shortcode: "BO", // Боливия
        name: "regions_lib.BO",
        regions: [
            {
                name: "regions_lib.406", // Departamento de Chuquisaca
                shortcode: "406",
            },
            {
                name: "regions_lib.408", // Departamento de Cochabamba
                shortcode: "408",
            },
            {
                name: "regions_lib.409", // Departamento de La Paz
                shortcode: "409",
            },
            {
                name: "regions_lib.402", // Departamento de Pando
                shortcode: "402",
            },
            {
                name: "regions_lib.407", // Departamento de Potosí
                shortcode: "407",
            },
            {
                name: "regions_lib.410", // Departamento de Santa Cruz
                shortcode: "410",
            },
            {
                name: "regions_lib.404", // Departamento de Tarija
                shortcode: "404",
            },
            {
                name: "regions_lib.403", // El Beni
                shortcode: "403",
            },
            {
                name: "regions_lib.405", // Oruro
                shortcode: "405",
            },
        ]
    },
    {
        shortcode: "BQ", // Бонэйр, Синт-Эстатиус и Саба
        name: "regions_lib.BQ",
        regions: [
            {
                name: "regions_lib.413", // Bonaire
                shortcode: "413",
            },
            {
                name: "regions_lib.411", // Saba
                shortcode: "411",
            },
            {
                name: "regions_lib.412", // Синт-Эстатиус
                shortcode: "412",
            },
        ]
    },
    {
        shortcode: "BA", // Босния и Герцеговина
        name: "regions_lib.BA",
        regions: [
            {
                name: "regions_lib.416", // Federation of Bosnia and Herzegovina
                shortcode: "416",
            },
            {
                name: "regions_lib.415", // Republika Srpska
                shortcode: "415",
            },
            {
                name: "regions_lib.414", // Округ Брчко
                shortcode: "414",
            },
        ]
    },
    {
        shortcode: "BW", // Ботсвана
        name: "regions_lib.BW",
        regions: [
            {
                name: "regions_lib.429", // Central District
                shortcode: "429",
            },
            {
                name: "regions_lib.419", // Chobe District
                shortcode: "419",
            },
            {
                name: "regions_lib.427", // City of Francistown
                shortcode: "427",
            },
            {
                name: "regions_lib.431", // Gaborone
                shortcode: "431",
            },
            {
                name: "regions_lib.421", // Ghanzi District
                shortcode: "421",
            },
            {
                name: "regions_lib.418", // Jwaneng
                shortcode: "418",
            },
            {
                name: "regions_lib.422", // Kgalagadi District
                shortcode: "422",
            },
            {
                name: "regions_lib.424", // Kgatleng District
                shortcode: "424",
            },
            {
                name: "regions_lib.430", // Kweneng District
                shortcode: "430",
            },
            {
                name: "regions_lib.420", // Lobatse
                shortcode: "420",
            },
            {
                name: "regions_lib.428", // Ngwaketsi District
                shortcode: "428",
            },
            {
                name: "regions_lib.426", // North-East District
                shortcode: "426",
            },
            {
                name: "regions_lib.425", // North-West District
                shortcode: "425",
            },
            {
                name: "regions_lib.423", // Selibe Phikwe
                shortcode: "423",
            },
            {
                name: "regions_lib.432", // South-East District
                shortcode: "432",
            },
            {
                name: "regions_lib.417", // Sowa Town
                shortcode: "417",
            },
        ]
    },
    {
        shortcode: "BR", // Бразилия
        name: "regions_lib.BR",
        regions: [
            {
                name: "regions_lib.435", // Acre
                shortcode: "435",
            },
            {
                name: "regions_lib.443", // Alagoas
                shortcode: "443",
            },
            {
                name: "regions_lib.434", // Amapá
                shortcode: "434",
            },
            {
                name: "regions_lib.452", // Ceará
                shortcode: "452",
            },
            {
                name: "regions_lib.456", // Estado de Bahia
                shortcode: "456",
            },
            {
                name: "regions_lib.454", // Paraná
                shortcode: "454",
            },
            {
                name: "regions_lib.447", // Paraíba
                shortcode: "447",
            },
            {
                name: "regions_lib.444", // Rio Grande do Norte
                shortcode: "444",
            },
            {
                name: "regions_lib.455", // Rio Grande do Sul
                shortcode: "455",
            },
            {
                name: "regions_lib.457", // Rio de Janeiro
                shortcode: "457",
            },
            {
                name: "regions_lib.437", // Rondônia
                shortcode: "437",
            },
            {
                name: "regions_lib.433", // Roraima
                shortcode: "433",
            },
            {
                name: "regions_lib.449", // Santa Catarina
                shortcode: "449",
            },
            {
                name: "regions_lib.438", // Sergipe
                shortcode: "438",
            },
            {
                name: "regions_lib.459", // São Paulo
                shortcode: "459",
            },
            {
                name: "regions_lib.445", // Амазонас
                shortcode: "445",
            },
            {
                name: "regions_lib.448", // Гояс
                shortcode: "448",
            },
            {
                name: "regions_lib.450", // Мараньян
                shortcode: "450",
            },
            {
                name: "regions_lib.441", // Мату-Гросу
                shortcode: "441",
            },
            {
                name: "regions_lib.439", // Мату-Гросу-ду-Сул
                shortcode: "439",
            },
            {
                name: "regions_lib.458", // Минас-Жерайс
                shortcode: "458",
            },
            {
                name: "regions_lib.451", // Пара
                shortcode: "451",
            },
            {
                name: "regions_lib.453", // Пернамбуку
                shortcode: "453",
            },
            {
                name: "regions_lib.442", // Пиауи
                shortcode: "442",
            },
            {
                name: "regions_lib.436", // Токантинс
                shortcode: "436",
            },
            {
                name: "regions_lib.440", // Федеральный округ
                shortcode: "440",
            },
            {
                name: "regions_lib.446", // Эспириту-Санту
                shortcode: "446",
            },
        ]
    },
    {
        shortcode: "GB", // Британия
        name: "regions_lib.GB",
        regions: [
            {
                name: "regions_lib.463", // Англия
                shortcode: "463",
            },
            {
                name: "regions_lib.460", // Северная Ирландия
                shortcode: "460",
            },
            {
                name: "regions_lib.461", // Уэльс
                shortcode: "461",
            },
            {
                name: "regions_lib.462", // Шотландия
                shortcode: "462",
            },
        ]
    },
    {
        shortcode: "BN", // Бруней-Даруссалам
        name: "regions_lib.BN",
        regions: [
            {
                name: "regions_lib.466", // Belait
                shortcode: "466",
            },
            {
                name: "regions_lib.467", // Daerah Brunei dan Muara
                shortcode: "467",
            },
            {
                name: "regions_lib.464", // Temburong
                shortcode: "464",
            },
            {
                name: "regions_lib.465", // Tutong
                shortcode: "465",
            },
        ]
    },
    {
        shortcode: "BF", // Буркина-Фасо
        name: "regions_lib.BF",
        regions: [
            {
                name: "regions_lib.479", // Boucle du Mouhoun
                shortcode: "479",
            },
            {
                name: "regions_lib.468", // Cascades Region
                shortcode: "468",
            },
            {
                name: "regions_lib.473", // Centre-Est Region
                shortcode: "473",
            },
            {
                name: "regions_lib.475", // Centre-Nord Region
                shortcode: "475",
            },
            {
                name: "regions_lib.477", // Centre-Ouest Region
                shortcode: "477",
            },
            {
                name: "regions_lib.471", // Centre-Sud Region
                shortcode: "471",
            },
            {
                name: "regions_lib.480", // Centre Region
                shortcode: "480",
            },
            {
                name: "regions_lib.478", // Hauts-Bassins Region
                shortcode: "478",
            },
            {
                name: "regions_lib.474", // Nord Region
                shortcode: "474",
            },
            {
                name: "regions_lib.470", // Plateau-Central Region
                shortcode: "470",
            },
            {
                name: "regions_lib.472", // Sahel Region
                shortcode: "472",
            },
            {
                name: "regions_lib.469", // Sud-Ouest Region
                shortcode: "469",
            },
            {
                name: "regions_lib.476", // Восточная область
                shortcode: "476",
            },
        ]
    },
    {
        shortcode: "BI", // Бурунди
        name: "regions_lib.BI",
        regions: [
            {
                name: "regions_lib.486", // Bubanza Province
                shortcode: "486",
            },
            {
                name: "regions_lib.498", // Bujumbura Mairie Province
                shortcode: "498",
            },
            {
                name: "regions_lib.493", // Bujumbura Rural Province
                shortcode: "493",
            },
            {
                name: "regions_lib.491", // Bururi Province
                shortcode: "491",
            },
            {
                name: "regions_lib.482", // Cankuzo Province
                shortcode: "482",
            },
            {
                name: "regions_lib.490", // Cibitoke Province
                shortcode: "490",
            },
            {
                name: "regions_lib.496", // Gitega Province
                shortcode: "496",
            },
            {
                name: "regions_lib.488", // Karuzi Province
                shortcode: "488",
            },
            {
                name: "regions_lib.489", // Kayanza Province
                shortcode: "489",
            },
            {
                name: "regions_lib.495", // Kirundo Province
                shortcode: "495",
            },
            {
                name: "regions_lib.492", // Makamba Province
                shortcode: "492",
            },
            {
                name: "regions_lib.485", // Muramvya Province
                shortcode: "485",
            },
            {
                name: "regions_lib.494", // Muyinga Province
                shortcode: "494",
            },
            {
                name: "regions_lib.483", // Mwaro Province
                shortcode: "483",
            },
            {
                name: "regions_lib.497", // Ngozi Province
                shortcode: "497",
            },
            {
                name: "regions_lib.481", // Rumonge Province
                shortcode: "481",
            },
            {
                name: "regions_lib.484", // Rutana Province
                shortcode: "484",
            },
            {
                name: "regions_lib.487", // Ruyigi Province
                shortcode: "487",
            },
        ]
    },
    {
        shortcode: "BT", // Бутан
        name: "regions_lib.BT",
        regions: [
            {
                name: "regions_lib.504", // Bumthang Dzongkhag
                shortcode: "504",
            },
            {
                name: "regions_lib.516", // Chhukha Dzongkhag
                shortcode: "516",
            },
            {
                name: "regions_lib.507", // Dagana Dzongkhag
                shortcode: "507",
            },
            {
                name: "regions_lib.499", // Gasa
                shortcode: "499",
            },
            {
                name: "regions_lib.500", // Haa Dzongkhag
                shortcode: "500",
            },
            {
                name: "regions_lib.501", // Lhuentse Dzongkhag
                shortcode: "501",
            },
            {
                name: "regions_lib.515", // Mongar Dzongkhag
                shortcode: "515",
            },
            {
                name: "regions_lib.506", // Pemagatshel Dzongkhag
                shortcode: "506",
            },
            {
                name: "regions_lib.505", // Punakha Dzongkhag
                shortcode: "505",
            },
            {
                name: "regions_lib.514", // Samdrup Jongkhar Dzongkhag
                shortcode: "514",
            },
            {
                name: "regions_lib.517", // Samtse Dzongkhag
                shortcode: "517",
            },
            {
                name: "regions_lib.513", // Sarpang Dzongkhag
                shortcode: "513",
            },
            {
                name: "regions_lib.518", // Thimphu Dzongkhag
                shortcode: "518",
            },
            {
                name: "regions_lib.502", // Trashi Yangste
                shortcode: "502",
            },
            {
                name: "regions_lib.512", // Trashigang Dzongkhag
                shortcode: "512",
            },
            {
                name: "regions_lib.503", // Trongsa Dzongkhag
                shortcode: "503",
            },
            {
                name: "regions_lib.509", // Tsirang Dzongkhag
                shortcode: "509",
            },
            {
                name: "regions_lib.511", // Wangdue Phodrang Dzongkhag
                shortcode: "511",
            },
            {
                name: "regions_lib.508", // Zhemgang Dzongkhag
                shortcode: "508",
            },
            {
                name: "regions_lib.510", // Паро
                shortcode: "510",
            },
        ]
    },
    {
        shortcode: "VU", // Вануату
        name: "regions_lib.VU",
        regions: [
            {
                name: "regions_lib.522", // Malampa Province
                shortcode: "522",
            },
            {
                name: "regions_lib.520", // Penama Province
                shortcode: "520",
            },
            {
                name: "regions_lib.523", // Sanma Province
                shortcode: "523",
            },
            {
                name: "regions_lib.524", // Shefa Province
                shortcode: "524",
            },
            {
                name: "regions_lib.521", // Tafea Province
                shortcode: "521",
            },
            {
                name: "regions_lib.519", // Torba Province
                shortcode: "519",
            },
        ]
    },
    {
        shortcode: "VA", // Ватикан
        name: "regions_lib.VA",
        regions: [
        ]
    },
    {
        shortcode: "HU", // Венгрия
        name: "regions_lib.HU",
        regions: [
            {
                name: "regions_lib.534", // Baranya
                shortcode: "534",
            },
            {
                name: "regions_lib.532", // Bekes County
                shortcode: "532",
            },
            {
                name: "regions_lib.544", // Budapest
                shortcode: "544",
            },
            {
                name: "regions_lib.537", // Csongrád megye
                shortcode: "537",
            },
            {
                name: "regions_lib.529", // Heves megye
                shortcode: "529",
            },
            {
                name: "regions_lib.525", // Nógrád megye
                shortcode: "525",
            },
            {
                name: "regions_lib.543", // Pest megye
                shortcode: "543",
            },
            {
                name: "regions_lib.531", // Somogy megye
                shortcode: "531",
            },
            {
                name: "regions_lib.526", // Tolna megye
                shortcode: "526",
            },
            {
                name: "regions_lib.533", // Veszprém megye
                shortcode: "533",
            },
            {
                name: "regions_lib.539", // Бач-Кишкун
                shortcode: "539",
            },
            {
                name: "regions_lib.542", // Боршод-Абауй-Земплен
                shortcode: "542",
            },
            {
                name: "regions_lib.527", // Ваш
                shortcode: "527",
            },
            {
                name: "regions_lib.538", // Дьёр-Мошон-Шопрон
                shortcode: "538",
            },
            {
                name: "regions_lib.528", // Зала
                shortcode: "528",
            },
            {
                name: "regions_lib.530", // Комаром-Эстергом
                shortcode: "530",
            },
            {
                name: "regions_lib.541", // Сабольч-Сатмар-Берег
                shortcode: "541",
            },
            {
                name: "regions_lib.536", // Фейер
                shortcode: "536",
            },
            {
                name: "regions_lib.540", // Хайду-Бихар
                shortcode: "540",
            },
            {
                name: "regions_lib.535", // Яс-Надькун-Сольнок
                shortcode: "535",
            },
        ]
    },
    {
        shortcode: "VE", // Венесуэла
        name: "regions_lib.VE",
        regions: [
            {
                name: "regions_lib.546", // Amazonas
                shortcode: "546",
            },
            {
                name: "regions_lib.562", // Anzoátegui
                shortcode: "562",
            },
            {
                name: "regions_lib.550", // Apure
                shortcode: "550",
            },
            {
                name: "regions_lib.564", // Aragua
                shortcode: "564",
            },
            {
                name: "regions_lib.555", // Barinas
                shortcode: "555",
            },
            {
                name: "regions_lib.563", // Bolívar
                shortcode: "563",
            },
            {
                name: "regions_lib.567", // Carabobo
                shortcode: "567",
            },
            {
                name: "regions_lib.548", // Cojedes
                shortcode: "548",
            },
            {
                name: "regions_lib.545", // Dependencias Federales
                shortcode: "545",
            },
            {
                name: "regions_lib.549", // Estado La Guaira
                shortcode: "549",
            },
            {
                name: "regions_lib.553", // Estado Trujillo
                shortcode: "553",
            },
            {
                name: "regions_lib.552", // Estado Yaracuy
                shortcode: "552",
            },
            {
                name: "regions_lib.560", // Falcón
                shortcode: "560",
            },
            {
                name: "regions_lib.554", // Guárico
                shortcode: "554",
            },
            {
                name: "regions_lib.565", // Lara
                shortcode: "565",
            },
            {
                name: "regions_lib.568", // Miranda
                shortcode: "568",
            },
            {
                name: "regions_lib.558", // Monagas
                shortcode: "558",
            },
            {
                name: "regions_lib.556", // Mérida
                shortcode: "556",
            },
            {
                name: "regions_lib.551", // Nueva Esparta
                shortcode: "551",
            },
            {
                name: "regions_lib.557", // Portuguesa
                shortcode: "557",
            },
            {
                name: "regions_lib.559", // Sucre
                shortcode: "559",
            },
            {
                name: "regions_lib.561", // Táchira
                shortcode: "561",
            },
            {
                name: "regions_lib.569", // Zulia
                shortcode: "569",
            },
            {
                name: "regions_lib.547", // Дельта-Амакуро
                shortcode: "547",
            },
            {
                name: "regions_lib.566", // Федеральный округ Венесуэлы
                shortcode: "566",
            },
        ]
    },
    {
        shortcode: "VG", // Виргинские о-ва (Великобритания)
        name: "regions_lib.VG",
        regions: [
        ]
    },
    {
        shortcode: "VI", // Виргинские о-ва (США)
        name: "regions_lib.VI",
        regions: [
            {
                name: "regions_lib.571", // Saint Croix Island
                shortcode: "571",
            },
            {
                name: "regions_lib.570", // Saint John Island
                shortcode: "570",
            },
            {
                name: "regions_lib.572", // Saint Thomas Island
                shortcode: "572",
            },
        ]
    },
    {
        shortcode: "UM", // Внешние малые о-ва (США)
        name: "regions_lib.UM",
        regions: [
            {
                name: "regions_lib.576", // Howland Island
                shortcode: "576",
            },
            {
                name: "regions_lib.575", // Jarvis Island
                shortcode: "575",
            },
            {
                name: "regions_lib.574", // Johnston Atoll
                shortcode: "574",
            },
            {
                name: "regions_lib.573", // Kingman Reef
                shortcode: "573",
            },
            {
                name: "regions_lib.580", // Midway Atoll
                shortcode: "580",
            },
            {
                name: "regions_lib.579", // Palmyra Atoll
                shortcode: "579",
            },
            {
                name: "regions_lib.581", // Атолл Уэйк
                shortcode: "581",
            },
            {
                name: "regions_lib.577", // Бейкер
                shortcode: "577",
            },
            {
                name: "regions_lib.578", // Остров Навасса
                shortcode: "578",
            },
        ]
    },
    {
        shortcode: "VN", // Вьетнам
        name: "regions_lib.VN",
        regions: [
            {
                name: "regions_lib.639", // An Giang
                shortcode: "639",
            },
            {
                name: "regions_lib.619", // Can Tho
                shortcode: "619",
            },
            {
                name: "regions_lib.610", // Da Nang
                shortcode: "610",
            },
            {
                name: "regions_lib.622", // Gia Lai
                shortcode: "622",
            },
            {
                name: "regions_lib.637", // Haiphong
                shortcode: "637",
            },
            {
                name: "regions_lib.643", // Hanoi
                shortcode: "643",
            },
            {
                name: "regions_lib.596", // Hậu Giang
                shortcode: "596",
            },
            {
                name: "regions_lib.585", // Kon Tum
                shortcode: "585",
            },
            {
                name: "regions_lib.626", // Long An
                shortcode: "626",
            },
            {
                name: "regions_lib.620", // Quảng Ngãi Province
                shortcode: "620",
            },
            {
                name: "regions_lib.631", // Tiền Giang
                shortcode: "631",
            },
            {
                name: "regions_lib.607", // Tây Ninh Province
                shortcode: "607",
            },
            {
                name: "regions_lib.604", // Tỉnh Bà Rịa-Vũng Tàu
                shortcode: "604",
            },
            {
                name: "regions_lib.633", // Tỉnh Bình Dương
                shortcode: "633",
            },
            {
                name: "regions_lib.600", // Tỉnh Bình Phước
                shortcode: "600",
            },
            {
                name: "regions_lib.613", // Tỉnh Bình Thuận
                shortcode: "613",
            },
            {
                name: "regions_lib.628", // Tỉnh Bình Định
                shortcode: "628",
            },
            {
                name: "regions_lib.597", // Tỉnh Bạc Liêu
                shortcode: "597",
            },
            {
                name: "regions_lib.629", // Tỉnh Bắc Giang
                shortcode: "629",
            },
            {
                name: "regions_lib.582", // Tỉnh Bắc Kạn
                shortcode: "582",
            },
            {
                name: "regions_lib.606", // Tỉnh Bắc Ninh
                shortcode: "606",
            },
            {
                name: "regions_lib.624", // Tỉnh Bến Tre
                shortcode: "624",
            },
            {
                name: "regions_lib.587", // Tỉnh Cao Bằng
                shortcode: "587",
            },
            {
                name: "regions_lib.621", // Tỉnh Cà Mau
                shortcode: "621",
            },
            {
                name: "regions_lib.592", // Tỉnh Hà Giang
                shortcode: "592",
            },
            {
                name: "regions_lib.599", // Tỉnh Hà Nam
                shortcode: "599",
            },
            {
                name: "regions_lib.623", // Tỉnh Hà Tĩnh
                shortcode: "623",
            },
            {
                name: "regions_lib.598", // Tỉnh Hòa Bình
                shortcode: "598",
            },
            {
                name: "regions_lib.611", // Tỉnh Hưng Yên
                shortcode: "611",
            },
            {
                name: "regions_lib.634", // Tỉnh Hải Dương
                shortcode: "634",
            },
            {
                name: "regions_lib.615", // Tỉnh Khánh Hòa
                shortcode: "615",
            },
            {
                name: "regions_lib.630", // Tỉnh Kiến Giang
                shortcode: "630",
            },
            {
                name: "regions_lib.583", // Tỉnh Lai Châu
                shortcode: "583",
            },
            {
                name: "regions_lib.590", // Tỉnh Lào Cai
                shortcode: "590",
            },
            {
                name: "regions_lib.617", // Tỉnh Lâm Đồng
                shortcode: "617",
            },
            {
                name: "regions_lib.595", // Tỉnh Lạng Sơn
                shortcode: "595",
            },
            {
                name: "regions_lib.638", // Tỉnh Nam Định
                shortcode: "638",
            },
            {
                name: "regions_lib.641", // Tỉnh Nghệ An
                shortcode: "641",
            },
            {
                name: "regions_lib.588", // Tỉnh Ninh Bình
                shortcode: "588",
            },
            {
                name: "regions_lib.589", // Tỉnh Ninh Thuận
                shortcode: "589",
            },
            {
                name: "regions_lib.625", // Tỉnh Phú Thọ
                shortcode: "625",
            },
            {
                name: "regions_lib.602", // Tỉnh Phú Yên
                shortcode: "602",
            },
            {
                name: "regions_lib.601", // Tỉnh Quảng Bình
                shortcode: "601",
            },
            {
                name: "regions_lib.627", // Tỉnh Quảng Nam
                shortcode: "627",
            },
            {
                name: "regions_lib.616", // Tỉnh Quảng Ninh
                shortcode: "616",
            },
            {
                name: "regions_lib.591", // Tỉnh Quảng Trị
                shortcode: "591",
            },
            {
                name: "regions_lib.618", // Tỉnh Sóc Trăng
                shortcode: "618",
            },
            {
                name: "regions_lib.603", // Tỉnh Sơn La
                shortcode: "603",
            },
            {
                name: "regions_lib.642", // Tỉnh Thanh Hóa
                shortcode: "642",
            },
            {
                name: "regions_lib.636", // Tỉnh Thái Bình
                shortcode: "636",
            },
            {
                name: "regions_lib.612", // Tỉnh Thái Nguyên
                shortcode: "612",
            },
            {
                name: "regions_lib.609", // Tỉnh Thừa Thiên-Huế
                shortcode: "609",
            },
            {
                name: "regions_lib.605", // Tỉnh Trà Vinh
                shortcode: "605",
            },
            {
                name: "regions_lib.593", // Tỉnh Tuyên Quang
                shortcode: "593",
            },
            {
                name: "regions_lib.608", // Tỉnh Vĩnh Long
                shortcode: "608",
            },
            {
                name: "regions_lib.614", // Tỉnh Vĩnh Phúc
                shortcode: "614",
            },
            {
                name: "regions_lib.594", // Tỉnh Yên Bái
                shortcode: "594",
            },
            {
                name: "regions_lib.586", // Tỉnh Ðiện Biên
                shortcode: "586",
            },
            {
                name: "regions_lib.640", // Tỉnh Đồng Nai
                shortcode: "640",
            },
            {
                name: "regions_lib.632", // Tỉnh Đồng Tháp
                shortcode: "632",
            },
            {
                name: "regions_lib.584", // Ðắk Nông
                shortcode: "584",
            },
            {
                name: "regions_lib.635", // Đắk Lắk
                shortcode: "635",
            },
            {
                name: "regions_lib.644", // Хошимин
                shortcode: "644",
            },
        ]
    },
    {
        shortcode: "GA", // Габон
        name: "regions_lib.GA",
        regions: [
            {
                name: "regions_lib.652", // Haut-Ogooué Province
                shortcode: "652",
            },
            {
                name: "regions_lib.646", // Moyen-Ogooué Province
                shortcode: "646",
            },
            {
                name: "regions_lib.649", // Ngouni Province
                shortcode: "649",
            },
            {
                name: "regions_lib.645", // Nyanga Province
                shortcode: "645",
            },
            {
                name: "regions_lib.648", // Ogooué-Ivindo Province
                shortcode: "648",
            },
            {
                name: "regions_lib.647", // Ogooué-Lol Province
                shortcode: "647",
            },
            {
                name: "regions_lib.651", // Ogooué-Maritime Province
                shortcode: "651",
            },
            {
                name: "regions_lib.650", // Woleu-Ntem Province
                shortcode: "650",
            },
            {
                name: "regions_lib.653", // Эстуэр
                shortcode: "653",
            },
        ]
    },
    {
        shortcode: "HT", // Гаити
        name: "regions_lib.HT",
        regions: [
            {
                name: "regions_lib.658", // Centre
                shortcode: "658",
            },
            {
                name: "regions_lib.654", // Département de Nippes
                shortcode: "654",
            },
            {
                name: "regions_lib.662", // Département de l&quot;Artibonite
                shortcode: "662",
            },
            {
                name: "regions_lib.663", // Département de l&quot;Ouest
                shortcode: "663",
            },
            {
                name: "regions_lib.655", // Département du Nord-Est
                shortcode: "655",
            },
            {
                name: "regions_lib.656", // Grand&quot;Anse
                shortcode: "656",
            },
            {
                name: "regions_lib.661", // Nord
                shortcode: "661",
            },
            {
                name: "regions_lib.660", // Nord-Ouest
                shortcode: "660",
            },
            {
                name: "regions_lib.659", // Sud
                shortcode: "659",
            },
            {
                name: "regions_lib.657", // Sud-Est
                shortcode: "657",
            },
        ]
    },
    {
        shortcode: "GY", // Гайана
        name: "regions_lib.GY",
        regions: [
            {
                name: "regions_lib.667", // Barima-Waini Region
                shortcode: "667",
            },
            {
                name: "regions_lib.666", // Cuyuni-Mazaruni Region
                shortcode: "666",
            },
            {
                name: "regions_lib.673", // Demerara-Mahaica Region
                shortcode: "673",
            },
            {
                name: "regions_lib.672", // East Berbice-Corentyne Region
                shortcode: "672",
            },
            {
                name: "regions_lib.671", // Essequibo Islands-West Demerara Region
                shortcode: "671",
            },
            {
                name: "regions_lib.670", // Mahaica-Berbice Region
                shortcode: "670",
            },
            {
                name: "regions_lib.669", // Pomeroon-Supenaam Region
                shortcode: "669",
            },
            {
                name: "regions_lib.664", // Potaro-Siparuni Region
                shortcode: "664",
            },
            {
                name: "regions_lib.668", // Upper Demerara-Berbice Region
                shortcode: "668",
            },
            {
                name: "regions_lib.665", // Upper Takutu-Upper Essequibo Region
                shortcode: "665",
            },
        ]
    },
    {
        shortcode: "GM", // Гамбия
        name: "regions_lib.GM",
        regions: [
            {
                name: "regions_lib.674", // Central River Division
                shortcode: "674",
            },
            {
                name: "regions_lib.675", // City of Banjul
                shortcode: "675",
            },
            {
                name: "regions_lib.676", // Lower River Division
                shortcode: "676",
            },
            {
                name: "regions_lib.678", // North Bank Division
                shortcode: "678",
            },
            {
                name: "regions_lib.677", // Upper River Division
                shortcode: "677",
            },
            {
                name: "regions_lib.679", // West Coast
                shortcode: "679",
            },
        ]
    },
    {
        shortcode: "GH", // Гана
        name: "regions_lib.GH",
        regions: [
            {
                name: "regions_lib.681", // Ahafo
                shortcode: "681",
            },
            {
                name: "regions_lib.694", // Ashanti Region
                shortcode: "694",
            },
            {
                name: "regions_lib.688", // Bono
                shortcode: "688",
            },
            {
                name: "regions_lib.687", // Bono East
                shortcode: "687",
            },
            {
                name: "regions_lib.689", // Central Region
                shortcode: "689",
            },
            {
                name: "regions_lib.693", // Eastern Region
                shortcode: "693",
            },
            {
                name: "regions_lib.695", // Greater Accra Region
                shortcode: "695",
            },
            {
                name: "regions_lib.680", // North East
                shortcode: "680",
            },
            {
                name: "regions_lib.691", // Northern Region
                shortcode: "691",
            },
            {
                name: "regions_lib.683", // Oti
                shortcode: "683",
            },
            {
                name: "regions_lib.682", // Savannah
                shortcode: "682",
            },
            {
                name: "regions_lib.686", // Upper East Region
                shortcode: "686",
            },
            {
                name: "regions_lib.684", // Upper West Region
                shortcode: "684",
            },
            {
                name: "regions_lib.690", // Volta Region
                shortcode: "690",
            },
            {
                name: "regions_lib.685", // Western North
                shortcode: "685",
            },
            {
                name: "regions_lib.692", // Western Region
                shortcode: "692",
            },
        ]
    },
    {
        shortcode: "GP", // Гваделупа
        name: "regions_lib.GP",
        regions: [
            {
                name: "regions_lib.696", // Guadeloupe
                shortcode: "696",
            },
        ]
    },
    {
        shortcode: "GT", // Гватемала
        name: "regions_lib.GT",
        regions: [
            {
                name: "regions_lib.717", // Departamento de Alta Verapaz
                shortcode: "717",
            },
            {
                name: "regions_lib.701", // Departamento de Baja Verapaz
                shortcode: "701",
            },
            {
                name: "regions_lib.711", // Departamento de Chimaltenango
                shortcode: "711",
            },
            {
                name: "regions_lib.706", // Departamento de Chiquimula
                shortcode: "706",
            },
            {
                name: "regions_lib.699", // Departamento de El Progreso
                shortcode: "699",
            },
            {
                name: "regions_lib.712", // Departamento de Escuintla
                shortcode: "712",
            },
            {
                name: "regions_lib.718", // Departamento de Guatemala
                shortcode: "718",
            },
            {
                name: "regions_lib.716", // Departamento de Huehuetenango
                shortcode: "716",
            },
            {
                name: "regions_lib.703", // Departamento de Izabal
                shortcode: "703",
            },
            {
                name: "regions_lib.705", // Departamento de Jalapa
                shortcode: "705",
            },
            {
                name: "regions_lib.697", // Departamento de Jutiapa
                shortcode: "697",
            },
            {
                name: "regions_lib.713", // Departamento de Quetzaltenango
                shortcode: "713",
            },
            {
                name: "regions_lib.698", // Departamento de Retalhuleu
                shortcode: "698",
            },
            {
                name: "regions_lib.704", // Departamento de Sacatepéquez
                shortcode: "704",
            },
            {
                name: "regions_lib.715", // Departamento de San Marcos
                shortcode: "715",
            },
            {
                name: "regions_lib.702", // Departamento de Santa Rosa
                shortcode: "702",
            },
            {
                name: "regions_lib.708", // Departamento de Sololá
                shortcode: "708",
            },
            {
                name: "regions_lib.709", // Departamento de Suchitepéquez
                shortcode: "709",
            },
            {
                name: "regions_lib.707", // Departamento de Totonicapán
                shortcode: "707",
            },
            {
                name: "regions_lib.700", // Departamento de Zacapa
                shortcode: "700",
            },
            {
                name: "regions_lib.710", // Departamento del Petén
                shortcode: "710",
            },
            {
                name: "regions_lib.714", // Departamento del Quiché
                shortcode: "714",
            },
        ]
    },
    {
        shortcode: "GN", // Гвинея
        name: "regions_lib.GN",
        regions: [
            {
                name: "regions_lib.722", // Boke Region
                shortcode: "722",
            },
            {
                name: "regions_lib.725", // Conakry Region
                shortcode: "725",
            },
            {
                name: "regions_lib.720", // Faranah Region
                shortcode: "720",
            },
            {
                name: "regions_lib.726", // Kankan Region
                shortcode: "726",
            },
            {
                name: "regions_lib.723", // Kindia
                shortcode: "723",
            },
            {
                name: "regions_lib.721", // Labé Region
                shortcode: "721",
            },
            {
                name: "regions_lib.719", // Mamou Region
                shortcode: "719",
            },
            {
                name: "regions_lib.724", // Nzerekore Region
                shortcode: "724",
            },
        ]
    },
    {
        shortcode: "GW", // Гвинея-Бисау
        name: "regions_lib.GW",
        regions: [
            {
                name: "regions_lib.733", // Bafatá
                shortcode: "733",
            },
            {
                name: "regions_lib.729", // Biombo
                shortcode: "729",
            },
            {
                name: "regions_lib.735", // Bissau Region
                shortcode: "735",
            },
            {
                name: "regions_lib.727", // Bolama
                shortcode: "727",
            },
            {
                name: "regions_lib.731", // Cacheu Region
                shortcode: "731",
            },
            {
                name: "regions_lib.732", // Gabú
                shortcode: "732",
            },
            {
                name: "regions_lib.734", // Oio Region
                shortcode: "734",
            },
            {
                name: "regions_lib.728", // Quinara
                shortcode: "728",
            },
            {
                name: "regions_lib.730", // Tombali
                shortcode: "730",
            },
        ]
    },
    {
        shortcode: "DE", // Германия
        name: "regions_lib.DE",
        regions: [
            {
                name: "regions_lib.740", // Free State of Thuringia
                shortcode: "740",
            },
            {
                name: "regions_lib.739", // Free and Hanseatic City of Hamburg
                shortcode: "739",
            },
            {
                name: "regions_lib.738", // Mecklenburg-Vorpommern
                shortcode: "738",
            },
            {
                name: "regions_lib.745", // Saxony
                shortcode: "745",
            },
            {
                name: "regions_lib.750", // Бавария
                shortcode: "750",
            },
            {
                name: "regions_lib.749", // Баден-Вюртемберг
                shortcode: "749",
            },
            {
                name: "regions_lib.744", // Берлин
                shortcode: "744",
            },
            {
                name: "regions_lib.742", // Бранденбург
                shortcode: "742",
            },
            {
                name: "regions_lib.736", // Бремен
                shortcode: "736",
            },
            {
                name: "regions_lib.747", // Гессен
                shortcode: "747",
            },
            {
                name: "regions_lib.748", // Нижняя Саксония
                shortcode: "748",
            },
            {
                name: "regions_lib.746", // Рейнланд-Пфальц
                shortcode: "746",
            },
            {
                name: "regions_lib.737", // Саар
                shortcode: "737",
            },
            {
                name: "regions_lib.741", // Саксония-Анхальт
                shortcode: "741",
            },
            {
                name: "regions_lib.751", // Северный Рейн-Вестфалия
                shortcode: "751",
            },
            {
                name: "regions_lib.743", // Шлезвиг-Гольштейн
                shortcode: "743",
            },
        ]
    },
    {
        shortcode: "GG", // Гернси
        name: "regions_lib.GG",
        regions: [
            {
                name: "regions_lib.754", // Alderney
                shortcode: "754",
            },
            {
                name: "regions_lib.753", // Forest
                shortcode: "753",
            },
            {
                name: "regions_lib.757", // Saint Saviour
                shortcode: "757",
            },
            {
                name: "regions_lib.758", // St Martin
                shortcode: "758",
            },
            {
                name: "regions_lib.762", // St Peter Port
                shortcode: "762",
            },
            {
                name: "regions_lib.755", // St Pierre du Bois
                shortcode: "755",
            },
            {
                name: "regions_lib.759", // St Sampson
                shortcode: "759",
            },
            {
                name: "regions_lib.761", // Vale
                shortcode: "761",
            },
            {
                name: "regions_lib.760", // Кастел
                shortcode: "760",
            },
            {
                name: "regions_lib.756", // Сент-Андру
                shortcode: "756",
            },
            {
                name: "regions_lib.752", // Тортевал
                shortcode: "752",
            },
        ]
    },
    {
        shortcode: "GI", // Гибралтар
        name: "regions_lib.GI",
        regions: [
        ]
    },
    {
        shortcode: "HN", // Гондурас
        name: "regions_lib.HN",
        regions: [
            {
                name: "regions_lib.763", // Departamento de Atlántida
                shortcode: "763",
            },
            {
                name: "regions_lib.776", // Departamento de Choluteca
                shortcode: "776",
            },
            {
                name: "regions_lib.771", // Departamento de Colón
                shortcode: "771",
            },
            {
                name: "regions_lib.775", // Departamento de Comayagua
                shortcode: "775",
            },
            {
                name: "regions_lib.773", // Departamento de Copán
                shortcode: "773",
            },
            {
                name: "regions_lib.779", // Departamento de Cortés
                shortcode: "779",
            },
            {
                name: "regions_lib.774", // Departamento de El Paraíso
                shortcode: "774",
            },
            {
                name: "regions_lib.780", // Departamento de Francisco Morazán
                shortcode: "780",
            },
            {
                name: "regions_lib.769", // Departamento de Intibucá
                shortcode: "769",
            },
            {
                name: "regions_lib.765", // Departamento de Islas de la Bahía
                shortcode: "765",
            },
            {
                name: "regions_lib.768", // Departamento de La Paz
                shortcode: "768",
            },
            {
                name: "regions_lib.770", // Departamento de Lempira
                shortcode: "770",
            },
            {
                name: "regions_lib.766", // Departamento de Ocotepeque
                shortcode: "766",
            },
            {
                name: "regions_lib.777", // Departamento de Olancho
                shortcode: "777",
            },
            {
                name: "regions_lib.772", // Departamento de Santa Bárbara
                shortcode: "772",
            },
            {
                name: "regions_lib.767", // Departamento de Valle
                shortcode: "767",
            },
            {
                name: "regions_lib.778", // Departamento de Yoro
                shortcode: "778",
            },
            {
                name: "regions_lib.764", // Грасьяс-а-Дьос
                shortcode: "764",
            },
        ]
    },
    {
        shortcode: "HK", // Гонконг
        name: "regions_lib.HK",
        regions: [
            {
                name: "regions_lib.783", // Central and Western District
                shortcode: "783",
            },
            {
                name: "regions_lib.796", // Eastern
                shortcode: "796",
            },
            {
                name: "regions_lib.781", // Islands District
                shortcode: "781",
            },
            {
                name: "regions_lib.793", // Kwai Tsing
                shortcode: "793",
            },
            {
                name: "regions_lib.795", // Kwun Tong
                shortcode: "795",
            },
            {
                name: "regions_lib.785", // North
                shortcode: "785",
            },
            {
                name: "regions_lib.790", // Sai Kung District
                shortcode: "790",
            },
            {
                name: "regions_lib.789", // Sham Shui Po
                shortcode: "789",
            },
            {
                name: "regions_lib.784", // Southern
                shortcode: "784",
            },
            {
                name: "regions_lib.787", // Tai Po District
                shortcode: "787",
            },
            {
                name: "regions_lib.786", // Tsuen Wan District
                shortcode: "786",
            },
            {
                name: "regions_lib.782", // Wan Chai
                shortcode: "782",
            },
            {
                name: "regions_lib.791", // Wong Tai Sin
                shortcode: "791",
            },
            {
                name: "regions_lib.794", // Yuen Long District
                shortcode: "794",
            },
            {
                name: "regions_lib.788", // Коулун-Сити
                shortcode: "788",
            },
            {
                name: "regions_lib.797", // Сатхинь
                shortcode: "797",
            },
            {
                name: "regions_lib.792", // Тхюньмунь
                shortcode: "792",
            },
        ]
    },
    {
        shortcode: "GD", // Гренада
        name: "regions_lib.GD",
        regions: [
            {
                name: "regions_lib.799", // Carriacou and Petite Martinique
                shortcode: "799",
            },
            {
                name: "regions_lib.803", // Saint Andrew
                shortcode: "803",
            },
            {
                name: "regions_lib.802", // Saint David
                shortcode: "802",
            },
            {
                name: "regions_lib.804", // Saint George
                shortcode: "804",
            },
            {
                name: "regions_lib.800", // Saint John
                shortcode: "800",
            },
            {
                name: "regions_lib.798", // Saint Mark
                shortcode: "798",
            },
            {
                name: "regions_lib.801", // Saint Patrick
                shortcode: "801",
            },
        ]
    },
    {
        shortcode: "GL", // Гренландия
        name: "regions_lib.GL",
        regions: [
            {
                name: "regions_lib.808", // Avannaata
                shortcode: "808",
            },
            {
                name: "regions_lib.805", // Qeqertalik
                shortcode: "805",
            },
            {
                name: "regions_lib.807", // Кекката
                shortcode: "807",
            },
            {
                name: "regions_lib.806", // Куяллек
                shortcode: "806",
            },
            {
                name: "regions_lib.809", // Сермерсоок
                shortcode: "809",
            },
        ]
    },
    {
        shortcode: "GR", // Греция
        name: "regions_lib.GR",
        regions: [
            {
                name: "regions_lib.823", // Attica
                shortcode: "823",
            },
            {
                name: "regions_lib.810", // Autonomous Monastic State of the Holy Mountain
                shortcode: "810",
            },
            {
                name: "regions_lib.818", // Central Greece
                shortcode: "818",
            },
            {
                name: "regions_lib.822", // Central Macedonia
                shortcode: "822",
            },
            {
                name: "regions_lib.819", // Crete
                shortcode: "819",
            },
            {
                name: "regions_lib.817", // East Macedonia and Thrace
                shortcode: "817",
            },
            {
                name: "regions_lib.814", // Epirus
                shortcode: "814",
            },
            {
                name: "regions_lib.812", // Ionian Islands
                shortcode: "812",
            },
            {
                name: "regions_lib.811", // North Aegean
                shortcode: "811",
            },
            {
                name: "regions_lib.816", // Peloponnese
                shortcode: "816",
            },
            {
                name: "regions_lib.815", // South Aegean
                shortcode: "815",
            },
            {
                name: "regions_lib.821", // Thessaly
                shortcode: "821",
            },
            {
                name: "regions_lib.820", // West Greece
                shortcode: "820",
            },
            {
                name: "regions_lib.813", // West Macedonia
                shortcode: "813",
            },
        ]
    },
    {
        shortcode: "GE", // Грузия
        name: "regions_lib.GE",
        regions: [
            {
                name: "regions_lib.835", // K&quot;alak&quot;i T&quot;bilisi
                shortcode: "835",
            },
            {
                name: "regions_lib.830", // Samegrelo-Zemo Svanetis Mkhare
                shortcode: "830",
            },
            {
                name: "regions_lib.828", // Абхазия
                shortcode: "828",
            },
            {
                name: "regions_lib.832", // Автономная Республика Аджария
                shortcode: "832",
            },
            {
                name: "regions_lib.826", // Гурия
                shortcode: "826",
            },
            {
                name: "regions_lib.834", // Имеретия
                shortcode: "834",
            },
            {
                name: "regions_lib.829", // Кахетия
                shortcode: "829",
            },
            {
                name: "regions_lib.833", // Квемо-Картли
                shortcode: "833",
            },
            {
                name: "regions_lib.825", // Мцхета-Мтианети
                shortcode: "825",
            },
            {
                name: "regions_lib.824", // Рача-Лечхуми и Нижняя Сванетия
                shortcode: "824",
            },
            {
                name: "regions_lib.827", // Самцхе-Джавахети
                shortcode: "827",
            },
            {
                name: "regions_lib.831", // Шида-Картли
                shortcode: "831",
            },
        ]
    },
    {
        shortcode: "GU", // Гуам
        name: "regions_lib.GU",
        regions: [
            {
                name: "regions_lib.844", // Agana Heights
                shortcode: "844",
            },
            {
                name: "regions_lib.845", // Agat
                shortcode: "845",
            },
            {
                name: "regions_lib.840", // Asan
                shortcode: "840",
            },
            {
                name: "regions_lib.850", // Barrigada
                shortcode: "850",
            },
            {
                name: "regions_lib.849", // Chalan Pago-Ordot Municipality
                shortcode: "849",
            },
            {
                name: "regions_lib.854", // Dededo
                shortcode: "854",
            },
            {
                name: "regions_lib.837", // Hagatna
                shortcode: "837",
            },
            {
                name: "regions_lib.841", // Inarajan
                shortcode: "841",
            },
            {
                name: "regions_lib.851", // Mangilao
                shortcode: "851",
            },
            {
                name: "regions_lib.838", // Merizo Municipality
                shortcode: "838",
            },
            {
                name: "regions_lib.847", // Mongmong-Toto-Maite Municipality
                shortcode: "847",
            },
            {
                name: "regions_lib.839", // Piti Municipality
                shortcode: "839",
            },
            {
                name: "regions_lib.848", // Santa Rita Municipality
                shortcode: "848",
            },
            {
                name: "regions_lib.842", // Sinajana Municipality
                shortcode: "842",
            },
            {
                name: "regions_lib.843", // Talofofo Municipality
                shortcode: "843",
            },
            {
                name: "regions_lib.852", // Tamuning
                shortcode: "852",
            },
            {
                name: "regions_lib.836", // Umatac
                shortcode: "836",
            },
            {
                name: "regions_lib.853", // Yigo Municipality
                shortcode: "853",
            },
            {
                name: "regions_lib.846", // Yona
                shortcode: "846",
            },
        ]
    },
    {
        shortcode: "DK", // Дания
        name: "regions_lib.DK",
        regions: [
            {
                name: "regions_lib.859", // Capital Region
                shortcode: "859",
            },
            {
                name: "regions_lib.855", // North Denmark
                shortcode: "855",
            },
            {
                name: "regions_lib.856", // Зеландия
                shortcode: "856",
            },
            {
                name: "regions_lib.858", // Центральная Ютландия
                shortcode: "858",
            },
            {
                name: "regions_lib.857", // Южная Дания
                shortcode: "857",
            },
        ]
    },
    {
        shortcode: "JE", // Джерси
        name: "regions_lib.JE",
        regions: [
            {
                name: "regions_lib.862", // Saint Ouen
                shortcode: "862",
            },
            {
                name: "regions_lib.865", // St. Brelade
                shortcode: "865",
            },
            {
                name: "regions_lib.864", // St Clement
                shortcode: "864",
            },
            {
                name: "regions_lib.866", // St Helier
                shortcode: "866",
            },
            {
                name: "regions_lib.860", // St John
                shortcode: "860",
            },
            {
                name: "regions_lib.861", // St Martîn
                shortcode: "861",
            },
            {
                name: "regions_lib.863", // St Peter
                shortcode: "863",
            },
        ]
    },
    {
        shortcode: "DJ", // Джибути
        name: "regions_lib.DJ",
        regions: [
            {
                name: "regions_lib.869", // Ali Sabieh Region
                shortcode: "869",
            },
            {
                name: "regions_lib.868", // Arta Region
                shortcode: "868",
            },
            {
                name: "regions_lib.872", // Djibouti City
                shortcode: "872",
            },
            {
                name: "regions_lib.870", // Дикиль
                shortcode: "870",
            },
            {
                name: "regions_lib.867", // Обок
                shortcode: "867",
            },
            {
                name: "regions_lib.871", // Таджура
                shortcode: "871",
            },
        ]
    },
    {
        shortcode: "DM", // Доминика
        name: "regions_lib.DM",
        regions: [
            {
                name: "regions_lib.881", // Saint Andrew
                shortcode: "881",
            },
            {
                name: "regions_lib.878", // Saint David
                shortcode: "878",
            },
            {
                name: "regions_lib.882", // Saint George
                shortcode: "882",
            },
            {
                name: "regions_lib.876", // Saint John
                shortcode: "876",
            },
            {
                name: "regions_lib.877", // Saint Joseph
                shortcode: "877",
            },
            {
                name: "regions_lib.873", // Saint Luke
                shortcode: "873",
            },
            {
                name: "regions_lib.875", // Saint Mark
                shortcode: "875",
            },
            {
                name: "regions_lib.880", // Saint Patrick
                shortcode: "880",
            },
            {
                name: "regions_lib.879", // Saint Paul
                shortcode: "879",
            },
            {
                name: "regions_lib.874", // Saint Peter
                shortcode: "874",
            },
        ]
    },
    {
        shortcode: "DO", // Доминиканская Республика
        name: "regions_lib.DO",
        regions: [
            {
                name: "regions_lib.913", // Distrito Nacional
                shortcode: "913",
            },
            {
                name: "regions_lib.907", // Provincia Duarte
                shortcode: "907",
            },
            {
                name: "regions_lib.905", // Provincia Espaillat
                shortcode: "905",
            },
            {
                name: "regions_lib.895", // Provincia María Trinidad Sánchez
                shortcode: "895",
            },
            {
                name: "regions_lib.896", // Provincia Sánchez Ramírez
                shortcode: "896",
            },
            {
                name: "regions_lib.903", // Provincia de Azua
                shortcode: "903",
            },
            {
                name: "regions_lib.891", // Provincia de Baoruco
                shortcode: "891",
            },
            {
                name: "regions_lib.900", // Provincia de Barahona
                shortcode: "900",
            },
            {
                name: "regions_lib.886", // Provincia de Dajabón
                shortcode: "886",
            },
            {
                name: "regions_lib.890", // Provincia de El Seibo
                shortcode: "890",
            },
            {
                name: "regions_lib.888", // Provincia de Elías Piña
                shortcode: "888",
            },
            {
                name: "regions_lib.889", // Provincia de Hato Mayor
                shortcode: "889",
            },
            {
                name: "regions_lib.893", // Provincia de Hermanas Mirabal
                shortcode: "893",
            },
            {
                name: "regions_lib.884", // Provincia de Independencia
                shortcode: "884",
            },
            {
                name: "regions_lib.902", // Provincia de La Altagracia
                shortcode: "902",
            },
            {
                name: "regions_lib.904", // Provincia de La Romana
                shortcode: "904",
            },
            {
                name: "regions_lib.910", // Provincia de La Vega
                shortcode: "910",
            },
            {
                name: "regions_lib.898", // Provincia de Monseñor Nouel
                shortcode: "898",
            },
            {
                name: "regions_lib.894", // Provincia de Monte Cristi
                shortcode: "894",
            },
            {
                name: "regions_lib.901", // Provincia de Monte Plata
                shortcode: "901",
            },
            {
                name: "regions_lib.883", // Provincia de Pedernales
                shortcode: "883",
            },
            {
                name: "regions_lib.899", // Provincia de Peravia
                shortcode: "899",
            },
            {
                name: "regions_lib.911", // Provincia de San Cristóbal
                shortcode: "911",
            },
            {
                name: "regions_lib.887", // Provincia de San José de Ocoa
                shortcode: "887",
            },
            {
                name: "regions_lib.906", // Provincia de San Juan
                shortcode: "906",
            },
            {
                name: "regions_lib.908", // Provincia de San Pedro de Macorís
                shortcode: "908",
            },
            {
                name: "regions_lib.912", // Provincia de Santiago
                shortcode: "912",
            },
            {
                name: "regions_lib.885", // Provincia de Santiago Rodríguez
                shortcode: "885",
            },
            {
                name: "regions_lib.914", // Provincia de Santo Domingo
                shortcode: "914",
            },
            {
                name: "regions_lib.897", // Provincia de Valverde
                shortcode: "897",
            },
            {
                name: "regions_lib.909", // Puerto Plata
                shortcode: "909",
            },
            {
                name: "regions_lib.892", // Samaná
                shortcode: "892",
            },
        ]
    },
    {
        shortcode: "EG", // Египет
        name: "regions_lib.EG",
        regions: [
            {
                name: "regions_lib.934", // Alexandria Governorate
                shortcode: "934",
            },
            {
                name: "regions_lib.924", // Aswan Governorate
                shortcode: "924",
            },
            {
                name: "regions_lib.931", // Asyut Governorate
                shortcode: "931",
            },
            {
                name: "regions_lib.937", // Beheira Governorate
                shortcode: "937",
            },
            {
                name: "regions_lib.926", // Beni Suweif Governorate
                shortcode: "926",
            },
            {
                name: "regions_lib.941", // Cairo Governorate
                shortcode: "941",
            },
            {
                name: "regions_lib.938", // Dakahlia Governorate
                shortcode: "938",
            },
            {
                name: "regions_lib.925", // Damietta Governorate
                shortcode: "925",
            },
            {
                name: "regions_lib.929", // Faiyum Governorate
                shortcode: "929",
            },
            {
                name: "regions_lib.933", // Gharbia Governorate
                shortcode: "933",
            },
            {
                name: "regions_lib.940", // Giza Governorate
                shortcode: "940",
            },
            {
                name: "regions_lib.923", // Ismailia Governorate
                shortcode: "923",
            },
            {
                name: "regions_lib.928", // Kafr el-Sheikh Governorate
                shortcode: "928",
            },
            {
                name: "regions_lib.922", // Luxor Governorate
                shortcode: "922",
            },
            {
                name: "regions_lib.918", // Matruh Governorate
                shortcode: "918",
            },
            {
                name: "regions_lib.936", // Minya Governorate
                shortcode: "936",
            },
            {
                name: "regions_lib.930", // Monufia Governorate
                shortcode: "930",
            },
            {
                name: "regions_lib.916", // New Valley Governorate
                shortcode: "916",
            },
            {
                name: "regions_lib.919", // North Sinai Governorate
                shortcode: "919",
            },
            {
                name: "regions_lib.921", // Port Said Governorate
                shortcode: "921",
            },
            {
                name: "regions_lib.935", // Qalyubia Governorate
                shortcode: "935",
            },
            {
                name: "regions_lib.927", // Qena Governorate
                shortcode: "927",
            },
            {
                name: "regions_lib.917", // Red Sea Governorate
                shortcode: "917",
            },
            {
                name: "regions_lib.939", // Sharqia Province
                shortcode: "939",
            },
            {
                name: "regions_lib.932", // Sohag Governorate
                shortcode: "932",
            },
            {
                name: "regions_lib.915", // South Sinai Governorate
                shortcode: "915",
            },
            {
                name: "regions_lib.920", // Suez Governorate
                shortcode: "920",
            },
        ]
    },
    {
        shortcode: "CD", // Заир
        name: "regions_lib.CD",
        regions: [
            {
                name: "regions_lib.960", // Bas-Congo Province
                shortcode: "960",
            },
            {
                name: "regions_lib.943", // Bas Uele
                shortcode: "943",
            },
            {
                name: "regions_lib.954", // Haut-Lomani
                shortcode: "954",
            },
            {
                name: "regions_lib.951", // Haut Uele
                shortcode: "951",
            },
            {
                name: "regions_lib.963", // Ituri
                shortcode: "963",
            },
            {
                name: "regions_lib.959", // Kasai Province
                shortcode: "959",
            },
            {
                name: "regions_lib.957", // Kasaï-Central
                shortcode: "957",
            },
            {
                name: "regions_lib.966", // Kasaï-Oriental Province
                shortcode: "966",
            },
            {
                name: "regions_lib.942", // Kinshasa City
                shortcode: "942",
            },
            {
                name: "regions_lib.952", // Kwango
                shortcode: "952",
            },
            {
                name: "regions_lib.953", // Lomami
                shortcode: "953",
            },
            {
                name: "regions_lib.947", // Lualaba
                shortcode: "947",
            },
            {
                name: "regions_lib.949", // Mai Ndombe
                shortcode: "949",
            },
            {
                name: "regions_lib.948", // Maniema Province
                shortcode: "948",
            },
            {
                name: "regions_lib.962", // Province du Haut-Katanga
                shortcode: "962",
            },
            {
                name: "regions_lib.964", // Province du Nord-Kivu
                shortcode: "964",
            },
            {
                name: "regions_lib.946", // Province du Nord-Ubangi
                shortcode: "946",
            },
            {
                name: "regions_lib.956", // Province du Sud-Ubangi
                shortcode: "956",
            },
            {
                name: "regions_lib.945", // Sankuru
                shortcode: "945",
            },
            {
                name: "regions_lib.961", // South Kivu Province
                shortcode: "961",
            },
            {
                name: "regions_lib.958", // Tanganika
                shortcode: "958",
            },
            {
                name: "regions_lib.967", // Équateur Province
                shortcode: "967",
            },
            {
                name: "regions_lib.965", // Квилу
                shortcode: "965",
            },
            {
                name: "regions_lib.950", // Монгала
                shortcode: "950",
            },
            {
                name: "regions_lib.955", // Чопо
                shortcode: "955",
            },
            {
                name: "regions_lib.944", // Чуапа
                shortcode: "944",
            },
        ]
    },
    {
        shortcode: "ZM", // Замбия
        name: "regions_lib.ZM",
        regions: [
            {
                name: "regions_lib.973", // Central Province
                shortcode: "973",
            },
            {
                name: "regions_lib.976", // Copperbelt Province
                shortcode: "976",
            },
            {
                name: "regions_lib.975", // Eastern Province
                shortcode: "975",
            },
            {
                name: "regions_lib.971", // Luapula Province
                shortcode: "971",
            },
            {
                name: "regions_lib.977", // Lusaka Province
                shortcode: "977",
            },
            {
                name: "regions_lib.969", // Muchinga Province
                shortcode: "969",
            },
            {
                name: "regions_lib.968", // North-Western Province
                shortcode: "968",
            },
            {
                name: "regions_lib.972", // Northern Province
                shortcode: "972",
            },
            {
                name: "regions_lib.974", // Southern Province
                shortcode: "974",
            },
            {
                name: "regions_lib.970", // Western Province
                shortcode: "970",
            },
        ]
    },
    {
        shortcode: "EH", // Западная Сахара
        name: "regions_lib.EH",
        regions: [
        ]
    },
    {
        shortcode: "ZW", // Зимбабве
        name: "regions_lib.ZW",
        regions: [
            {
                name: "regions_lib.978", // Bulawayo Province
                shortcode: "978",
            },
            {
                name: "regions_lib.987", // Harare
                shortcode: "987",
            },
            {
                name: "regions_lib.986", // Manicaland Province
                shortcode: "986",
            },
            {
                name: "regions_lib.981", // Mashonaland Central
                shortcode: "981",
            },
            {
                name: "regions_lib.982", // Mashonaland East Province
                shortcode: "982",
            },
            {
                name: "regions_lib.983", // Mashonaland West
                shortcode: "983",
            },
            {
                name: "regions_lib.984", // Masvingo Province
                shortcode: "984",
            },
            {
                name: "regions_lib.980", // Matabeleland North Province
                shortcode: "980",
            },
            {
                name: "regions_lib.979", // Matabeleland South Province
                shortcode: "979",
            },
            {
                name: "regions_lib.985", // Midlands Province
                shortcode: "985",
            },
        ]
    },
    {
        shortcode: "IL", // Израиль
        name: "regions_lib.IL",
        regions: [
            {
                name: "regions_lib.991", // Jerusalem
                shortcode: "991",
            },
            {
                name: "regions_lib.988", // Judea and Samaria Area
                shortcode: "988",
            },
            {
                name: "regions_lib.992", // Northern District
                shortcode: "992",
            },
            {
                name: "regions_lib.993", // Tel Aviv
                shortcode: "993",
            },
            {
                name: "regions_lib.989", // Хайфский округ
                shortcode: "989",
            },
            {
                name: "regions_lib.994", // Центральный район
                shortcode: "994",
            },
            {
                name: "regions_lib.990", // Южный район
                shortcode: "990",
            },
        ]
    },
    {
        shortcode: "IN", // Индия
        name: "regions_lib.IN",
        regions: [
            {
                name: "regions_lib.998", // Dadra and Nagar Haveli and Daman and Diu
                shortcode: "998",
            },
            {
                name: "regions_lib.1011", // Jammu and Kashmir
                shortcode: "1011",
            },
            {
                name: "regions_lib.995", // Lakshadweep
                shortcode: "995",
            },
            {
                name: "regions_lib.1012", // National Capital Territory of Delhi
                shortcode: "1012",
            },
            {
                name: "regions_lib.1020", // State of Odisha
                shortcode: "1020",
            },
            {
                name: "regions_lib.1010", // State of Uttarākhand
                shortcode: "1010",
            },
            {
                name: "regions_lib.997", // Андаманские и Никобарские острова
                shortcode: "997",
            },
            {
                name: "regions_lib.1021", // Андхра-Прадеш
                shortcode: "1021",
            },
            {
                name: "regions_lib.1003", // Аруначал-Прадеш
                shortcode: "1003",
            },
            {
                name: "regions_lib.1016", // Ассам
                shortcode: "1016",
            },
            {
                name: "regions_lib.1028", // Бихар
                shortcode: "1028",
            },
            {
                name: "regions_lib.1004", // Гоа
                shortcode: "1004",
            },
            {
                name: "regions_lib.1022", // Гуджарат
                shortcode: "1022",
            },
            {
                name: "regions_lib.1017", // Джаркханд
                shortcode: "1017",
            },
            {
                name: "regions_lib.1027", // Западная Бенгалия
                shortcode: "1027",
            },
            {
                name: "regions_lib.1023", // Карнатака
                shortcode: "1023",
            },
            {
                name: "regions_lib.1018", // Керала
                shortcode: "1018",
            },
            {
                name: "regions_lib.996", // Ладакх
                shortcode: "996",
            },
            {
                name: "regions_lib.1026", // Мадхья-Прадеш
                shortcode: "1026",
            },
            {
                name: "regions_lib.1006", // Манипур
                shortcode: "1006",
            },
            {
                name: "regions_lib.1029", // Махараштра
                shortcode: "1029",
            },
            {
                name: "regions_lib.1007", // Мегхалая
                shortcode: "1007",
            },
            {
                name: "regions_lib.1001", // Мизорам
                shortcode: "1001",
            },
            {
                name: "regions_lib.1005", // Нагаленд
                shortcode: "1005",
            },
            {
                name: "regions_lib.1015", // Пенджаб
                shortcode: "1015",
            },
            {
                name: "regions_lib.1002", // Пудучерри
                shortcode: "1002",
            },
            {
                name: "regions_lib.1024", // Раджастхан
                shortcode: "1024",
            },
            {
                name: "regions_lib.999", // Сикким
                shortcode: "999",
            },
            {
                name: "regions_lib.1025", // Тамилнад
                shortcode: "1025",
            },
            {
                name: "regions_lib.1019", // Телангана
                shortcode: "1019",
            },
            {
                name: "regions_lib.1008", // Трипура
                shortcode: "1008",
            },
            {
                name: "regions_lib.1030", // Уттар-Прадеш
                shortcode: "1030",
            },
            {
                name: "regions_lib.1013", // Харьяна
                shortcode: "1013",
            },
            {
                name: "regions_lib.1009", // Химачал-Прадеш
                shortcode: "1009",
            },
            {
                name: "regions_lib.1000", // Чандигарх
                shortcode: "1000",
            },
            {
                name: "regions_lib.1014", // Чхаттисгарх
                shortcode: "1014",
            },
        ]
    },
    {
        shortcode: "ID", // Индонезия
        name: "regions_lib.ID",
        regions: [
            {
                name: "regions_lib.1044", // Bengkulu
                shortcode: "1044",
            },
            {
                name: "regions_lib.1040", // Central Papua
                shortcode: "1040",
            },
            {
                name: "regions_lib.1055", // East Nusa Tenggara
                shortcode: "1055",
            },
            {
                name: "regions_lib.1037", // Gorontalo
                shortcode: "1037",
            },
            {
                name: "regions_lib.1039", // Highland Papua
                shortcode: "1039",
            },
            {
                name: "regions_lib.1063", // Jakarta Special Capital Region
                shortcode: "1063",
            },
            {
                name: "regions_lib.1050", // Jambi
                shortcode: "1050",
            },
            {
                name: "regions_lib.1042", // Kepulauan Bangka Belitung
                shortcode: "1042",
            },
            {
                name: "regions_lib.1061", // Lampung
                shortcode: "1061",
            },
            {
                name: "regions_lib.1043", // Maluku
                shortcode: "1043",
            },
            {
                name: "regions_lib.1035", // North Kalimantan
                shortcode: "1035",
            },
            {
                name: "regions_lib.1038", // North Maluku
                shortcode: "1038",
            },
            {
                name: "regions_lib.1066", // Provinsi Jawa Tengah
                shortcode: "1066",
            },
            {
                name: "regions_lib.1057", // Provinsi Kalimantan Barat
                shortcode: "1057",
            },
            {
                name: "regions_lib.1052", // Provinsi Kalimantan Selatan
                shortcode: "1052",
            },
            {
                name: "regions_lib.1048", // Provinsi Kalimantan Tengah
                shortcode: "1048",
            },
            {
                name: "regions_lib.1051", // Provinsi Kalimantan Timur
                shortcode: "1051",
            },
            {
                name: "regions_lib.1045", // Provinsi Kepulauan Riau
                shortcode: "1045",
            },
            {
                name: "regions_lib.1033", // Provinsi Papua Barat
                shortcode: "1033",
            },
            {
                name: "regions_lib.1062", // Provinsi Sulawesi Selatan
                shortcode: "1062",
            },
            {
                name: "regions_lib.1058", // Provinsi Sumatera Barat
                shortcode: "1058",
            },
            {
                name: "regions_lib.1059", // Riau
                shortcode: "1059",
            },
            {
                name: "regions_lib.1032", // South Papua
                shortcode: "1032",
            },
            {
                name: "regions_lib.1047", // Southeast Sulawesi
                shortcode: "1047",
            },
            {
                name: "regions_lib.1034", // Southwest Papua
                shortcode: "1034",
            },
            {
                name: "regions_lib.1054", // Special Region of Aceh
                shortcode: "1054",
            },
            {
                name: "regions_lib.1036", // Special Region of Papua
                shortcode: "1036",
            },
            {
                name: "regions_lib.1031", // Special Region of Yogyakarta
                shortcode: "1031",
            },
            {
                name: "regions_lib.1056", // West Nusa Tenggara
                shortcode: "1056",
            },
            {
                name: "regions_lib.1041", // West Sulawesi
                shortcode: "1041",
            },
            {
                name: "regions_lib.1053", // Бали
                shortcode: "1053",
            },
            {
                name: "regions_lib.1064", // Бантен
                shortcode: "1064",
            },
            {
                name: "regions_lib.1067", // Восточная Ява
                shortcode: "1067",
            },
            {
                name: "regions_lib.1068", // Западная Ява
                shortcode: "1068",
            },
            {
                name: "regions_lib.1065", // Северная Суматра
                shortcode: "1065",
            },
            {
                name: "regions_lib.1046", // Северный Сулавеси
                shortcode: "1046",
            },
            {
                name: "regions_lib.1049", // Центральный Сулавеси
                shortcode: "1049",
            },
            {
                name: "regions_lib.1060", // Южная Суматра
                shortcode: "1060",
            },
        ]
    },
    {
        shortcode: "JO", // Иордания
        name: "regions_lib.JO",
        regions: [
            {
                name: "regions_lib.1069", // Ajloun
                shortcode: "1069",
            },
            {
                name: "regions_lib.1080", // Amman Governorate
                shortcode: "1080",
            },
            {
                name: "regions_lib.1077", // Balqa Governorate
                shortcode: "1077",
            },
            {
                name: "regions_lib.1075", // Jerash Governorate
                shortcode: "1075",
            },
            {
                name: "regions_lib.1074", // Karak
                shortcode: "1074",
            },
            {
                name: "regions_lib.1071", // Ma&quot;an Governorate
                shortcode: "1071",
            },
            {
                name: "regions_lib.1072", // Madaba Governorate
                shortcode: "1072",
            },
            {
                name: "regions_lib.1076", // Mafraq Governorate
                shortcode: "1076",
            },
            {
                name: "regions_lib.1073", // Muḩāfaz̧at al ‘Aqabah
                shortcode: "1073",
            },
            {
                name: "regions_lib.1070", // Tafilah Governorate
                shortcode: "1070",
            },
            {
                name: "regions_lib.1078", // Zarqa Governorate
                shortcode: "1078",
            },
            {
                name: "regions_lib.1079", // Ирбид
                shortcode: "1079",
            },
        ]
    },
    {
        shortcode: "IQ", // Ирак
        name: "regions_lib.IQ",
        regions: [
            {
                name: "regions_lib.1094", // Al Anbar
                shortcode: "1094",
            },
            {
                name: "regions_lib.1090", // An Najaf
                shortcode: "1090",
            },
            {
                name: "regions_lib.1098", // Baghdad Governorate
                shortcode: "1098",
            },
            {
                name: "regions_lib.1086", // Diyālá
                shortcode: "1086",
            },
            {
                name: "regions_lib.1087", // Duhok
                shortcode: "1087",
            },
            {
                name: "regions_lib.1095", // Erbil
                shortcode: "1095",
            },
            {
                name: "regions_lib.1089", // Kirkuk
                shortcode: "1089",
            },
            {
                name: "regions_lib.1084", // Muhafazat Salah ad Din
                shortcode: "1084",
            },
            {
                name: "regions_lib.1091", // Muhafazat Wasit
                shortcode: "1091",
            },
            {
                name: "regions_lib.1093", // Muḩāfaz̧at Bābil
                shortcode: "1093",
            },
            {
                name: "regions_lib.1088", // Muḩāfaz̧at Dhī Qār
                shortcode: "1088",
            },
            {
                name: "regions_lib.1085", // Muḩāfaz̧at Karbalā’
                shortcode: "1085",
            },
            {
                name: "regions_lib.1083", // Muḩāfaz̧at Maysān
                shortcode: "1083",
            },
            {
                name: "regions_lib.1096", // Muḩāfaz̧at Nīnawá
                shortcode: "1096",
            },
            {
                name: "regions_lib.1097", // Muḩāfaz̧at al Başrah
                shortcode: "1097",
            },
            {
                name: "regions_lib.1082", // Muḩāfaz̧at al Muthanná
                shortcode: "1082",
            },
            {
                name: "regions_lib.1081", // Muḩāfaz̧at al Qādisīyah
                shortcode: "1081",
            },
            {
                name: "regions_lib.1092", // Muḩāfaz̧at as Sulaymānīyah
                shortcode: "1092",
            },
        ]
    },
    {
        shortcode: "IR", // Иран
        name: "regions_lib.IR",
        regions: [
            {
                name: "regions_lib.1119", // Alborz Province
                shortcode: "1119",
            },
            {
                name: "regions_lib.1108", // Ardabil Province
                shortcode: "1108",
            },
            {
                name: "regions_lib.1104", // Chaharmahal and Bakhtiari Province
                shortcode: "1104",
            },
            {
                name: "regions_lib.1124", // East Azerbaijan Province
                shortcode: "1124",
            },
            {
                name: "regions_lib.1118", // Gilan Province
                shortcode: "1118",
            },
            {
                name: "regions_lib.1116", // Golestan Province
                shortcode: "1116",
            },
            {
                name: "regions_lib.1113", // Hamadan Province
                shortcode: "1113",
            },
            {
                name: "regions_lib.1099", // Ilam Province
                shortcode: "1099",
            },
            {
                name: "regions_lib.1127", // Isfahan
                shortcode: "1127",
            },
            {
                name: "regions_lib.1121", // Kerman
                shortcode: "1121",
            },
            {
                name: "regions_lib.1117", // Kermanshah Province
                shortcode: "1117",
            },
            {
                name: "regions_lib.1101", // Kohgiluyeh and Boyer-Ahmad Province
                shortcode: "1101",
            },
            {
                name: "regions_lib.1112", // Kurdistan Province
                shortcode: "1112",
            },
            {
                name: "regions_lib.1114", // Lorestan Province
                shortcode: "1114",
            },
            {
                name: "regions_lib.1103", // North Khorasan Province
                shortcode: "1103",
            },
            {
                name: "regions_lib.1109", // Qazvin Province
                shortcode: "1109",
            },
            {
                name: "regions_lib.1110", // Qom Province
                shortcode: "1110",
            },
            {
                name: "regions_lib.1100", // Semnan Province
                shortcode: "1100",
            },
            {
                name: "regions_lib.1120", // Sistan and Baluchestan
                shortcode: "1120",
            },
            {
                name: "regions_lib.1102", // South Khorasan Province
                shortcode: "1102",
            },
            {
                name: "regions_lib.1129", // Tehran Province
                shortcode: "1129",
            },
            {
                name: "regions_lib.1122", // West Azerbaijan Province
                shortcode: "1122",
            },
            {
                name: "regions_lib.1106", // Yazd Province
                shortcode: "1106",
            },
            {
                name: "regions_lib.1105", // Zanjan Province
                shortcode: "1105",
            },
            {
                name: "regions_lib.1107", // Бушир
                shortcode: "1107",
            },
            {
                name: "regions_lib.1123", // Мазендеран
                shortcode: "1123",
            },
            {
                name: "regions_lib.1126", // Фарс
                shortcode: "1126",
            },
            {
                name: "regions_lib.1128", // Хорасан-Резави
                shortcode: "1128",
            },
            {
                name: "regions_lib.1115", // Хормозган
                shortcode: "1115",
            },
            {
                name: "regions_lib.1125", // Хузестан
                shortcode: "1125",
            },
            {
                name: "regions_lib.1111", // Центральный остан
                shortcode: "1111",
            },
        ]
    },
    {
        shortcode: "IE", // Ирландия
        name: "regions_lib.IE",
        regions: [
            {
                name: "regions_lib.1130", // Connacht
                shortcode: "1130",
            },
            {
                name: "regions_lib.1133", // Ленстер
                shortcode: "1133",
            },
            {
                name: "regions_lib.1131", // Манстер
                shortcode: "1131",
            },
            {
                name: "regions_lib.1132", // Ольстер
                shortcode: "1132",
            },
        ]
    },
    {
        shortcode: "IS", // Исландия
        name: "regions_lib.IS",
        regions: [
            {
                name: "regions_lib.1141", // Capital Region
                shortcode: "1141",
            },
            {
                name: "regions_lib.1136", // East
                shortcode: "1136",
            },
            {
                name: "regions_lib.1140", // Northeast
                shortcode: "1140",
            },
            {
                name: "regions_lib.1135", // Northwest
                shortcode: "1135",
            },
            {
                name: "regions_lib.1139", // South
                shortcode: "1139",
            },
            {
                name: "regions_lib.1138", // Southern Peninsula
                shortcode: "1138",
            },
            {
                name: "regions_lib.1137", // West
                shortcode: "1137",
            },
            {
                name: "regions_lib.1134", // Westfjords
                shortcode: "1134",
            },
        ]
    },
    {
        shortcode: "ES", // Испания
        name: "regions_lib.ES",
        regions: [
            {
                name: "regions_lib.1160", // Андалусия
                shortcode: "1160",
            },
            {
                name: "regions_lib.1150", // Арагон
                shortcode: "1150",
            },
            {
                name: "regions_lib.1147", // Астурия
                shortcode: "1147",
            },
            {
                name: "regions_lib.1149", // Балеарские Острова
                shortcode: "1149",
            },
            {
                name: "regions_lib.1156", // Галисия
                shortcode: "1156",
            },
            {
                name: "regions_lib.1153", // Канарские Острова
                shortcode: "1153",
            },
            {
                name: "regions_lib.1145", // Кантабрия
                shortcode: "1145",
            },
            {
                name: "regions_lib.1155", // Кастилия и Леон
                shortcode: "1155",
            },
            {
                name: "regions_lib.1152", // Кастилия — Ла-Манча
                shortcode: "1152",
            },
            {
                name: "regions_lib.1159", // Каталония
                shortcode: "1159",
            },
            {
                name: "regions_lib.1143", // Мелилья
                shortcode: "1143",
            },
            {
                name: "regions_lib.1151", // Мурсия
                shortcode: "1151",
            },
            {
                name: "regions_lib.1146", // Наварра
                shortcode: "1146",
            },
            {
                name: "regions_lib.1157", // Область Валенсия
                shortcode: "1157",
            },
            {
                name: "regions_lib.1158", // Область Мадрид
                shortcode: "1158",
            },
            {
                name: "regions_lib.1144", // Риоха
                shortcode: "1144",
            },
            {
                name: "regions_lib.1142", // Сеута
                shortcode: "1142",
            },
            {
                name: "regions_lib.1154", // Страна Басков
                shortcode: "1154",
            },
            {
                name: "regions_lib.1148", // Эстремадура
                shortcode: "1148",
            },
        ]
    },
    {
        shortcode: "IT", // Италия
        name: "regions_lib.IT",
        regions: [
            {
                name: "regions_lib.1161", // Aosta Valley
                shortcode: "1161",
            },
            {
                name: "regions_lib.1175", // Emilia-Romagna
                shortcode: "1175",
            },
            {
                name: "regions_lib.1166", // Friuli Venezia Giulia
                shortcode: "1166",
            },
            {
                name: "regions_lib.1180", // Lombardy
                shortcode: "1180",
            },
            {
                name: "regions_lib.1170", // Sardinia
                shortcode: "1170",
            },
            {
                name: "regions_lib.1167", // Абруццо
                shortcode: "1167",
            },
            {
                name: "regions_lib.1173", // Апулия
                shortcode: "1173",
            },
            {
                name: "regions_lib.1163", // Базиликата
                shortcode: "1163",
            },
            {
                name: "regions_lib.1165", // Больцано
                shortcode: "1165",
            },
            {
                name: "regions_lib.1176", // Венеция
                shortcode: "1176",
            },
            {
                name: "regions_lib.1171", // Калабрия
                shortcode: "1171",
            },
            {
                name: "regions_lib.1179", // Кампания
                shortcode: "1179",
            },
            {
                name: "regions_lib.1178", // Лацио
                shortcode: "1178",
            },
            {
                name: "regions_lib.1169", // Лигурия
                shortcode: "1169",
            },
            {
                name: "regions_lib.1168", // Марке
                shortcode: "1168",
            },
            {
                name: "regions_lib.1162", // Молизе
                shortcode: "1162",
            },
            {
                name: "regions_lib.1174", // Пьемонт
                shortcode: "1174",
            },
            {
                name: "regions_lib.1177", // Сицилия
                shortcode: "1177",
            },
            {
                name: "regions_lib.1172", // Тоскана
                shortcode: "1172",
            },
            {
                name: "regions_lib.1164", // Умбрия
                shortcode: "1164",
            },
        ]
    },
    {
        shortcode: "YE", // Йемен
        name: "regions_lib.YE",
        regions: [
            {
                name: "regions_lib.1190", // Al Bayda Governorate
                shortcode: "1190",
            },
            {
                name: "regions_lib.1202", // Al Hudaydah Governorate
                shortcode: "1202",
            },
            {
                name: "regions_lib.1182", // Al Mahrah
                shortcode: "1182",
            },
            {
                name: "regions_lib.1189", // Al Maḩwīt
                shortcode: "1189",
            },
            {
                name: "regions_lib.1199", // Amanat alasimah
                shortcode: "1199",
            },
            {
                name: "regions_lib.1191", // Governorate Number One
                shortcode: "1191",
            },
            {
                name: "regions_lib.1196", // Hadhramaut Governorate
                shortcode: "1196",
            },
            {
                name: "regions_lib.1200", // Ibb
                shortcode: "1200",
            },
            {
                name: "regions_lib.1186", // Muḩāfaz̧at Abyan
                shortcode: "1186",
            },
            {
                name: "regions_lib.1197", // Muḩāfaz̧at Dhamār
                shortcode: "1197",
            },
            {
                name: "regions_lib.1192", // Muḩāfaz̧at Laḩij
                shortcode: "1192",
            },
            {
                name: "regions_lib.1183", // Muḩāfaz̧at Ma’rib
                shortcode: "1183",
            },
            {
                name: "regions_lib.1184", // Muḩāfaz̧at Raymah
                shortcode: "1184",
            },
            {
                name: "regions_lib.1201", // Muḩāfaz̧at Ta‘izz
                shortcode: "1201",
            },
            {
                name: "regions_lib.1187", // Muḩāfaz̧at al Jawf
                shortcode: "1187",
            },
            {
                name: "regions_lib.1188", // Muḩāfaz̧at aḑ Ḑāli‘
                shortcode: "1188",
            },
            {
                name: "regions_lib.1193", // Muḩāfaz̧at Şa‘dah
                shortcode: "1193",
            },
            {
                name: "regions_lib.1198", // Muḩāfaz̧at Ḩajjah
                shortcode: "1198",
            },
            {
                name: "regions_lib.1194", // Muḩāfaz̧at ‘Amrān
                shortcode: "1194",
            },
            {
                name: "regions_lib.1195", // Sanaa
                shortcode: "1195",
            },
            {
                name: "regions_lib.1181", // Socotra
                shortcode: "1181",
            },
            {
                name: "regions_lib.1185", // Шабва
                shortcode: "1185",
            },
        ]
    },
    {
        shortcode: "KP", // КНДР
        name: "regions_lib.KP",
        regions: [
            {
                name: "regions_lib.1205", // Chagang-do
                shortcode: "1205",
            },
            {
                name: "regions_lib.1209", // Hambuk
                shortcode: "1209",
            },
            {
                name: "regions_lib.1212", // Hamnam
                shortcode: "1212",
            },
            {
                name: "regions_lib.1207", // Hwanghae-bukto
                shortcode: "1207",
            },
            {
                name: "regions_lib.1208", // Hwanghae-namdo
                shortcode: "1208",
            },
            {
                name: "regions_lib.1206", // Kangwŏn-do
                shortcode: "1206",
            },
            {
                name: "regions_lib.1211", // North Pyongan
                shortcode: "1211",
            },
            {
                name: "regions_lib.1210", // Pyongyang
                shortcode: "1210",
            },
            {
                name: "regions_lib.1203", // Rason
                shortcode: "1203",
            },
            {
                name: "regions_lib.1204", // Ryanggang
                shortcode: "1204",
            },
            {
                name: "regions_lib.1213", // South Pyongan
                shortcode: "1213",
            },
        ]
    },
    {
        shortcode: "CV", // Кабо-Верде
        name: "regions_lib.CV",
        regions: [
            {
                name: "regions_lib.1222", // Boa Vista Municipality
                shortcode: "1222",
            },
            {
                name: "regions_lib.1216", // Brava Municipality
                shortcode: "1216",
            },
            {
                name: "regions_lib.1219", // Maio Municipality
                shortcode: "1219",
            },
            {
                name: "regions_lib.1224", // Mosteiros Municipality
                shortcode: "1224",
            },
            {
                name: "regions_lib.1217", // Paul Municipality
                shortcode: "1217",
            },
            {
                name: "regions_lib.1227", // Porto Novo Municipality
                shortcode: "1227",
            },
            {
                name: "regions_lib.1235", // Praia Municipality
                shortcode: "1235",
            },
            {
                name: "regions_lib.1218", // Ribeira Brava Municipality
                shortcode: "1218",
            },
            {
                name: "regions_lib.1229", // Ribeira Grande Municipality
                shortcode: "1229",
            },
            {
                name: "regions_lib.1220", // Ribeira Grande de Santiago Municipality
                shortcode: "1220",
            },
            {
                name: "regions_lib.1232", // Sal
                shortcode: "1232",
            },
            {
                name: "regions_lib.1233", // Santa Catarina Municipality
                shortcode: "1233",
            },
            {
                name: "regions_lib.1215", // Santa Catarina do Fogo Municipality
                shortcode: "1215",
            },
            {
                name: "regions_lib.1231", // Santa Cruz Municipality
                shortcode: "1231",
            },
            {
                name: "regions_lib.1225", // São Domingos Municipality
                shortcode: "1225",
            },
            {
                name: "regions_lib.1230", // São Filipe Municipality
                shortcode: "1230",
            },
            {
                name: "regions_lib.1221", // São Lourenço dos Órgãos
                shortcode: "1221",
            },
            {
                name: "regions_lib.1226", // São Miguel Municipality
                shortcode: "1226",
            },
            {
                name: "regions_lib.1223", // São Salvador do Mundo Municipality
                shortcode: "1223",
            },
            {
                name: "regions_lib.1234", // São Vicente Municipality
                shortcode: "1234",
            },
            {
                name: "regions_lib.1228", // Tarrafal Municipality
                shortcode: "1228",
            },
            {
                name: "regions_lib.1214", // Tarrafal de São Nicolau Municipality
                shortcode: "1214",
            },
        ]
    },
    {
        shortcode: "KZ", // Казахстан
        name: "regions_lib.KZ",
        regions: [
            {
                name: "regions_lib.1249", // Kostanayskaya Oblast’
                shortcode: "1249",
            },
            {
                name: "regions_lib.1243", // Абайская область
                shortcode: "1243",
            },
            {
                name: "regions_lib.1245", // Акмолинская область
                shortcode: "1245",
            },
            {
                name: "regions_lib.1247", // Актюбинская область
                shortcode: "1247",
            },
            {
                name: "regions_lib.1255", // Алматинская Область
                shortcode: "1255",
            },
            {
                name: "regions_lib.1254", // Алматы
                shortcode: "1254",
            },
            {
                name: "regions_lib.1239", // Атырауская область
                shortcode: "1239",
            },
            {
                name: "regions_lib.1253", // Восточно-Казахстанская область
                shortcode: "1253",
            },
            {
                name: "regions_lib.1248", // Город Астана
                shortcode: "1248",
            },
            {
                name: "regions_lib.1237", // Город Байконыр
                shortcode: "1237",
            },
            {
                name: "regions_lib.1251", // Жамбылская область
                shortcode: "1251",
            },
            {
                name: "regions_lib.1236", // Жетысуская область
                shortcode: "1236",
            },
            {
                name: "regions_lib.1242", // Западно-Казахстанская область
                shortcode: "1242",
            },
            {
                name: "regions_lib.1252", // Карагандинская область
                shortcode: "1252",
            },
            {
                name: "regions_lib.1244", // Кызылординская Область
                shortcode: "1244",
            },
            {
                name: "regions_lib.1241", // Мангистауская Область
                shortcode: "1241",
            },
            {
                name: "regions_lib.1246", // Павлодарская Область
                shortcode: "1246",
            },
            {
                name: "regions_lib.1240", // Северо-Казахстанская область
                shortcode: "1240",
            },
            {
                name: "regions_lib.1256", // Туркестанская область
                shortcode: "1256",
            },
            {
                name: "regions_lib.1238", // Улытауская область
                shortcode: "1238",
            },
            {
                name: "regions_lib.1250", // Шымкент
                shortcode: "1250",
            },
        ]
    },
    {
        shortcode: "KY", // Каймановы Острова
        name: "regions_lib.KY",
        regions: [
            {
                name: "regions_lib.1260", // Bodden Town
                shortcode: "1260",
            },
            {
                name: "regions_lib.1257", // East End
                shortcode: "1257",
            },
            {
                name: "regions_lib.1262", // George Town
                shortcode: "1262",
            },
            {
                name: "regions_lib.1258", // North Side
                shortcode: "1258",
            },
            {
                name: "regions_lib.1259", // Sister Island
                shortcode: "1259",
            },
            {
                name: "regions_lib.1261", // West Bay
                shortcode: "1261",
            },
        ]
    },
    {
        shortcode: "KH", // Камбоджа
        name: "regions_lib.KH",
        regions: [
            {
                name: "regions_lib.1280", // Kampong Speu
                shortcode: "1280",
            },
            {
                name: "regions_lib.1278", // Kampong Thom
                shortcode: "1278",
            },
            {
                name: "regions_lib.1272", // Kratie
                shortcode: "1272",
            },
            {
                name: "regions_lib.1265", // Mondolkiri
                shortcode: "1265",
            },
            {
                name: "regions_lib.1266", // Pailin
                shortcode: "1266",
            },
            {
                name: "regions_lib.1287", // Phnom Penh
                shortcode: "1287",
            },
            {
                name: "regions_lib.1282", // Prey Veng
                shortcode: "1282",
            },
            {
                name: "regions_lib.1281", // Siem Reap
                shortcode: "1281",
            },
            {
                name: "regions_lib.1263", // Stung Treng
                shortcode: "1263",
            },
            {
                name: "regions_lib.1283", // Takeo
                shortcode: "1283",
            },
            {
                name: "regions_lib.1279", // Tboung Khmum
                shortcode: "1279",
            },
            {
                name: "regions_lib.1270", // Ŏtâr Méanchey
                shortcode: "1270",
            },
            {
                name: "regions_lib.1277", // Бантеаймеантьей
                shortcode: "1277",
            },
            {
                name: "regions_lib.1284", // Баттамбанг
                shortcode: "1284",
            },
            {
                name: "regions_lib.1264", // Каеп
                shortcode: "1264",
            },
            {
                name: "regions_lib.1286", // Кампонгтям
                shortcode: "1286",
            },
            {
                name: "regions_lib.1275", // Кампонгчнанг
                shortcode: "1275",
            },
            {
                name: "regions_lib.1276", // Кампот
                shortcode: "1276",
            },
            {
                name: "regions_lib.1285", // Кандаль
                shortcode: "1285",
            },
            {
                name: "regions_lib.1268", // Кохконг
                shortcode: "1268",
            },
            {
                name: "regions_lib.1274", // Поусат
                shortcode: "1274",
            },
            {
                name: "regions_lib.1269", // Прэахвихеа
                shortcode: "1269",
            },
            {
                name: "regions_lib.1267", // Ратанакири
                shortcode: "1267",
            },
            {
                name: "regions_lib.1273", // Свайриенг
                shortcode: "1273",
            },
            {
                name: "regions_lib.1271", // Сиануквиль
                shortcode: "1271",
            },
        ]
    },
    {
        shortcode: "CM", // Камерун
        name: "regions_lib.CM",
        regions: [
            {
                name: "regions_lib.1288", // Adamaoua Region
                shortcode: "1288",
            },
            {
                name: "regions_lib.1297", // Centre Region
                shortcode: "1297",
            },
            {
                name: "regions_lib.1290", // East Region
                shortcode: "1290",
            },
            {
                name: "regions_lib.1294", // Far North
                shortcode: "1294",
            },
            {
                name: "regions_lib.1295", // Littoral Region
                shortcode: "1295",
            },
            {
                name: "regions_lib.1293", // North-West Region
                shortcode: "1293",
            },
            {
                name: "regions_lib.1296", // North Region
                shortcode: "1296",
            },
            {
                name: "regions_lib.1291", // South-West Region
                shortcode: "1291",
            },
            {
                name: "regions_lib.1289", // South Region
                shortcode: "1289",
            },
            {
                name: "regions_lib.1292", // West Region
                shortcode: "1292",
            },
        ]
    },
    {
        shortcode: "CA", // Канада
        name: "regions_lib.CA",
        regions: [
            {
                name: "regions_lib.1302", // Newfoundland and Labrador
                shortcode: "1302",
            },
            {
                name: "regions_lib.1299", // Northwest Territories
                shortcode: "1299",
            },
            {
                name: "regions_lib.1309", // Quebec
                shortcode: "1309",
            },
            {
                name: "regions_lib.1307", // Альберта
                shortcode: "1307",
            },
            {
                name: "regions_lib.1308", // Британская Колумбия
                shortcode: "1308",
            },
            {
                name: "regions_lib.1306", // Манитоба
                shortcode: "1306",
            },
            {
                name: "regions_lib.1304", // Новая Шотландия
                shortcode: "1304",
            },
            {
                name: "regions_lib.1298", // Нунавут
                shortcode: "1298",
            },
            {
                name: "regions_lib.1303", // Нью-Брансуик
                shortcode: "1303",
            },
            {
                name: "regions_lib.1310", // Онтарио
                shortcode: "1310",
            },
            {
                name: "regions_lib.1301", // Остров Принца Эдуарда
                shortcode: "1301",
            },
            {
                name: "regions_lib.1305", // Саскачеван
                shortcode: "1305",
            },
            {
                name: "regions_lib.1300", // Юкон
                shortcode: "1300",
            },
        ]
    },
    {
        shortcode: "QA", // Катар
        name: "regions_lib.QA",
        regions: [
            {
                name: "regions_lib.1316", // Al-Shahaniya
                shortcode: "1316",
            },
            {
                name: "regions_lib.1312", // Al Wakrah
                shortcode: "1312",
            },
            {
                name: "regions_lib.1314", // Baladīyat Umm Şalāl
                shortcode: "1314",
            },
            {
                name: "regions_lib.1318", // Baladīyat ad Dawḩah
                shortcode: "1318",
            },
            {
                name: "regions_lib.1313", // Baladīyat al Khawr wa adh Dhakhīrah
                shortcode: "1313",
            },
            {
                name: "regions_lib.1317", // Baladīyat ar Rayyān
                shortcode: "1317",
            },
            {
                name: "regions_lib.1311", // Baladīyat ash Shamāl
                shortcode: "1311",
            },
            {
                name: "regions_lib.1315", // Baladīyat az̧ Z̧a‘āyin
                shortcode: "1315",
            },
        ]
    },
    {
        shortcode: "KE", // Кения
        name: "regions_lib.KE",
        regions: [
            {
                name: "regions_lib.1334", // Baringo
                shortcode: "1334",
            },
            {
                name: "regions_lib.1339", // Bomet
                shortcode: "1339",
            },
            {
                name: "regions_lib.1361", // Bungoma
                shortcode: "1361",
            },
            {
                name: "regions_lib.1341", // Busia
                shortcode: "1341",
            },
            {
                name: "regions_lib.1324", // Elegeyo-Marakwet
                shortcode: "1324",
            },
            {
                name: "regions_lib.1331", // Embu
                shortcode: "1331",
            },
            {
                name: "regions_lib.1337", // Garissa
                shortcode: "1337",
            },
            {
                name: "regions_lib.1351", // Homa Bay
                shortcode: "1351",
            },
            {
                name: "regions_lib.1321", // Isiolo
                shortcode: "1321",
            },
            {
                name: "regions_lib.1350", // Kajiado
                shortcode: "1350",
            },
            {
                name: "regions_lib.1362", // Kakamega
                shortcode: "1362",
            },
            {
                name: "regions_lib.1342", // Kericho
                shortcode: "1342",
            },
            {
                name: "regions_lib.1364", // Kiambu
                shortcode: "1364",
            },
            {
                name: "regions_lib.1359", // Kilifi
                shortcode: "1359",
            },
            {
                name: "regions_lib.1332", // Kirinyaga
                shortcode: "1332",
            },
            {
                name: "regions_lib.1357", // Kisii
                shortcode: "1357",
            },
            {
                name: "regions_lib.1353", // Kisumu
                shortcode: "1353",
            },
            {
                name: "regions_lib.1352", // Kitui
                shortcode: "1352",
            },
            {
                name: "regions_lib.1338", // Kwale
                shortcode: "1338",
            },
            {
                name: "regions_lib.1328", // Laikipia
                shortcode: "1328",
            },
            {
                name: "regions_lib.1319", // Lamu
                shortcode: "1319",
            },
            {
                name: "regions_lib.1358", // Machakos
                shortcode: "1358",
            },
            {
                name: "regions_lib.1344", // Makueni
                shortcode: "1344",
            },
            {
                name: "regions_lib.1326", // Marsabit
                shortcode: "1326",
            },
            {
                name: "regions_lib.1360", // Meru
                shortcode: "1360",
            },
            {
                name: "regions_lib.1349", // Migori
                shortcode: "1349",
            },
            {
                name: "regions_lib.1356", // Mombasa
                shortcode: "1356",
            },
            {
                name: "regions_lib.1348", // Murang&quot;A
                shortcode: "1348",
            },
            {
                name: "regions_lib.1365", // Nairobi
                shortcode: "1365",
            },
            {
                name: "regions_lib.1363", // Nakuru
                shortcode: "1363",
            },
            {
                name: "regions_lib.1340", // Nandi
                shortcode: "1340",
            },
            {
                name: "regions_lib.1354", // Narok
                shortcode: "1354",
            },
            {
                name: "regions_lib.1330", // Nyamira
                shortcode: "1330",
            },
            {
                name: "regions_lib.1333", // Nyandarua
                shortcode: "1333",
            },
            {
                name: "regions_lib.1335", // Nyeri
                shortcode: "1335",
            },
            {
                name: "regions_lib.1322", // Samburu
                shortcode: "1322",
            },
            {
                name: "regions_lib.1346", // Siaya
                shortcode: "1346",
            },
            {
                name: "regions_lib.1323", // Taita Taveta
                shortcode: "1323",
            },
            {
                name: "regions_lib.1320", // Tana River
                shortcode: "1320",
            },
            {
                name: "regions_lib.1325", // Tharaka - Nithi
                shortcode: "1325",
            },
            {
                name: "regions_lib.1345", // Trans Nzoia
                shortcode: "1345",
            },
            {
                name: "regions_lib.1343", // Turkana
                shortcode: "1343",
            },
            {
                name: "regions_lib.1355", // Uasin Gishu
                shortcode: "1355",
            },
            {
                name: "regions_lib.1329", // Vihiga
                shortcode: "1329",
            },
            {
                name: "regions_lib.1336", // Wajir
                shortcode: "1336",
            },
            {
                name: "regions_lib.1327", // West Pokot
                shortcode: "1327",
            },
            {
                name: "regions_lib.1347", // Мандера
                shortcode: "1347",
            },
        ]
    },
    {
        shortcode: "CY", // Кипр
        name: "regions_lib.CY",
        regions: [
            {
                name: "regions_lib.1366", // Ammochostos
                shortcode: "1366",
            },
            {
                name: "regions_lib.1367", // Keryneia
                shortcode: "1367",
            },
            {
                name: "regions_lib.1369", // Larnaka
                shortcode: "1369",
            },
            {
                name: "regions_lib.1370", // Limassol District
                shortcode: "1370",
            },
            {
                name: "regions_lib.1371", // Nicosia
                shortcode: "1371",
            },
            {
                name: "regions_lib.1368", // Pafos
                shortcode: "1368",
            },
        ]
    },
    {
        shortcode: "KI", // Кирибати
        name: "regions_lib.KI",
        regions: [
            {
                name: "regions_lib.1374", // Gilbert Islands
                shortcode: "1374",
            },
            {
                name: "regions_lib.1373", // Line Islands
                shortcode: "1373",
            },
            {
                name: "regions_lib.1372", // Phoenix Islands
                shortcode: "1372",
            },
        ]
    },
    {
        shortcode: "CN", // Китай
        name: "regions_lib.CN",
        regions: [
            {
                name: "regions_lib.1398", // Anhui
                shortcode: "1398",
            },
            {
                name: "regions_lib.1380", // Beijing Municipality
                shortcode: "1380",
            },
            {
                name: "regions_lib.1386", // Chongqing Municipality
                shortcode: "1386",
            },
            {
                name: "regions_lib.1388", // Fujian Sheng
                shortcode: "1388",
            },
            {
                name: "regions_lib.1384", // Gansu
                shortcode: "1384",
            },
            {
                name: "regions_lib.1405", // Guangdong
                shortcode: "1405",
            },
            {
                name: "regions_lib.1395", // Guangxi Zhuang Autonomous Region
                shortcode: "1395",
            },
            {
                name: "regions_lib.1390", // Guizhou
                shortcode: "1390",
            },
            {
                name: "regions_lib.1378", // Hainan Sheng
                shortcode: "1378",
            },
            {
                name: "regions_lib.1400", // Hebei
                shortcode: "1400",
            },
            {
                name: "regions_lib.1391", // Heilongjiang
                shortcode: "1391",
            },
            {
                name: "regions_lib.1404", // Henan
                shortcode: "1404",
            },
            {
                name: "regions_lib.1399", // Hunan Sheng
                shortcode: "1399",
            },
            {
                name: "regions_lib.1383", // Inner Mongolia Autonomous Region
                shortcode: "1383",
            },
            {
                name: "regions_lib.1401", // Jiangsu
                shortcode: "1401",
            },
            {
                name: "regions_lib.1393", // Jiangxi
                shortcode: "1393",
            },
            {
                name: "regions_lib.1385", // Jilin
                shortcode: "1385",
            },
            {
                name: "regions_lib.1392", // Liaoning Sheng
                shortcode: "1392",
            },
            {
                name: "regions_lib.1377", // Ningxia Hui Autonomous Region
                shortcode: "1377",
            },
            {
                name: "regions_lib.1376", // Qinghai
                shortcode: "1376",
            },
            {
                name: "regions_lib.1403", // Shandong
                shortcode: "1403",
            },
            {
                name: "regions_lib.1381", // Shanghai Municipality
                shortcode: "1381",
            },
            {
                name: "regions_lib.1387", // Shanxi
                shortcode: "1387",
            },
            {
                name: "regions_lib.1402", // Sichuan
                shortcode: "1402",
            },
            {
                name: "regions_lib.1379", // Tianjin Municipality
                shortcode: "1379",
            },
            {
                name: "regions_lib.1375", // Tibet Autonomous Region
                shortcode: "1375",
            },
            {
                name: "regions_lib.1382", // Xinjiang Uyghur Autonomous Region
                shortcode: "1382",
            },
            {
                name: "regions_lib.1394", // Yunnan Sheng
                shortcode: "1394",
            },
            {
                name: "regions_lib.1396", // Zhejiang
                shortcode: "1396",
            },
            {
                name: "regions_lib.1397", // Хубэй
                shortcode: "1397",
            },
            {
                name: "regions_lib.1389", // Шэньси
                shortcode: "1389",
            },
        ]
    },
    {
        shortcode: "CC", // Кокосовые о-ва
        name: "regions_lib.CC",
        regions: [
        ]
    },
    {
        shortcode: "CO", // Колумбия
        name: "regions_lib.CO",
        regions: [
            {
                name: "regions_lib.1409", // Amazonas
                shortcode: "1409",
            },
            {
                name: "regions_lib.1437", // Antioquia
                shortcode: "1437",
            },
            {
                name: "regions_lib.1434", // Atlántico
                shortcode: "1434",
            },
            {
                name: "regions_lib.1438", // Bogota D.C.
                shortcode: "1438",
            },
            {
                name: "regions_lib.1435", // Cundinamarca
                shortcode: "1435",
            },
            {
                name: "regions_lib.1412", // Departamento de Arauca
                shortcode: "1412",
            },
            {
                name: "regions_lib.1432", // Departamento de Bolívar
                shortcode: "1432",
            },
            {
                name: "regions_lib.1425", // Departamento de Boyacá
                shortcode: "1425",
            },
            {
                name: "regions_lib.1421", // Departamento de Caldas
                shortcode: "1421",
            },
            {
                name: "regions_lib.1415", // Departamento de Casanare
                shortcode: "1415",
            },
            {
                name: "regions_lib.1431", // Departamento de Córdoba
                shortcode: "1431",
            },
            {
                name: "regions_lib.1418", // Departamento de La Guajira
                shortcode: "1418",
            },
            {
                name: "regions_lib.1430", // Departamento de Nariño
                shortcode: "1430",
            },
            {
                name: "regions_lib.1429", // Departamento de Norte de Santander
                shortcode: "1429",
            },
            {
                name: "regions_lib.1420", // Departamento de Risaralda
                shortcode: "1420",
            },
            {
                name: "regions_lib.1433", // Departamento de Santander
                shortcode: "1433",
            },
            {
                name: "regions_lib.1419", // Departamento de Sucre
                shortcode: "1419",
            },
            {
                name: "regions_lib.1426", // Departamento de Tolima
                shortcode: "1426",
            },
            {
                name: "regions_lib.1414", // Departamento del Caquetá
                shortcode: "1414",
            },
            {
                name: "regions_lib.1428", // Departamento del Cauca
                shortcode: "1428",
            },
            {
                name: "regions_lib.1424", // Departamento del Cesar
                shortcode: "1424",
            },
            {
                name: "regions_lib.1416", // Departamento del Chocó
                shortcode: "1416",
            },
            {
                name: "regions_lib.1406", // Departamento del Guainía
                shortcode: "1406",
            },
            {
                name: "regions_lib.1410", // Departamento del Guaviare
                shortcode: "1410",
            },
            {
                name: "regions_lib.1423", // Departamento del Huila
                shortcode: "1423",
            },
            {
                name: "regions_lib.1427", // Departamento del Magdalena
                shortcode: "1427",
            },
            {
                name: "regions_lib.1422", // Departamento del Meta
                shortcode: "1422",
            },
            {
                name: "regions_lib.1413", // Departamento del Putumayo
                shortcode: "1413",
            },
            {
                name: "regions_lib.1436", // Departamento del Valle del Cauca
                shortcode: "1436",
            },
            {
                name: "regions_lib.1407", // Departamento del Vaupés
                shortcode: "1407",
            },
            {
                name: "regions_lib.1411", // Departamento del Vichada
                shortcode: "1411",
            },
            {
                name: "regions_lib.1408", // Providencia y Santa Catalina, Departamento de Archipiélago de San Andrés
                shortcode: "1408",
            },
            {
                name: "regions_lib.1417", // Quindío Department
                shortcode: "1417",
            },
        ]
    },
    {
        shortcode: "KM", // Коморские о-ва
        name: "regions_lib.KM",
        regions: [
            {
                name: "regions_lib.1439", // Mohéli
                shortcode: "1439",
            },
            {
                name: "regions_lib.1440", // Ndzuwani
                shortcode: "1440",
            },
            {
                name: "regions_lib.1441", // Нгазиджа
                shortcode: "1441",
            },
        ]
    },
    {
        shortcode: "XK", // Косово
        name: "regions_lib.XK",
        regions: [
            {
                name: "regions_lib.1444", // Ferizaj District
                shortcode: "1444",
            },
            {
                name: "regions_lib.1445", // Gjakova District
                shortcode: "1445",
            },
            {
                name: "regions_lib.1443", // Gjilan District
                shortcode: "1443",
            },
            {
                name: "regions_lib.1446", // Mitrovica District
                shortcode: "1446",
            },
            {
                name: "regions_lib.1442", // Pec District
                shortcode: "1442",
            },
            {
                name: "regions_lib.1448", // Pristina District
                shortcode: "1448",
            },
            {
                name: "regions_lib.1447", // Prizren District
                shortcode: "1447",
            },
        ]
    },
    {
        shortcode: "CR", // Коста-Рика
        name: "regions_lib.CR",
        regions: [
            {
                name: "regions_lib.1454", // Provincia de Alajuela
                shortcode: "1454",
            },
            {
                name: "regions_lib.1453", // Provincia de Cartago
                shortcode: "1453",
            },
            {
                name: "regions_lib.1449", // Provincia de Guanacaste
                shortcode: "1449",
            },
            {
                name: "regions_lib.1452", // Provincia de Heredia
                shortcode: "1452",
            },
            {
                name: "regions_lib.1450", // Provincia de Limón
                shortcode: "1450",
            },
            {
                name: "regions_lib.1451", // Provincia de Puntarenas
                shortcode: "1451",
            },
            {
                name: "regions_lib.1455", // Provincia de San José
                shortcode: "1455",
            },
        ]
    },
    {
        shortcode: "CI", // Кот-д’Ивуар
        name: "regions_lib.CI",
        regions: [
            {
                name: "regions_lib.1469", // Abidjan
                shortcode: "1469",
            },
            {
                name: "regions_lib.1466", // Bas-Sassandra
                shortcode: "1466",
            },
            {
                name: "regions_lib.1461", // Comoé
                shortcode: "1461",
            },
            {
                name: "regions_lib.1457", // Denguélé
                shortcode: "1457",
            },
            {
                name: "regions_lib.1464", // Gôh-Djiboua
                shortcode: "1464",
            },
            {
                name: "regions_lib.1460", // Lacs
                shortcode: "1460",
            },
            {
                name: "regions_lib.1463", // Lagunes
                shortcode: "1463",
            },
            {
                name: "regions_lib.1468", // Montagnes
                shortcode: "1468",
            },
            {
                name: "regions_lib.1467", // Sassandra-Marahoué
                shortcode: "1467",
            },
            {
                name: "regions_lib.1465", // Savanes
                shortcode: "1465",
            },
            {
                name: "regions_lib.1462", // Vallée du Bandama
                shortcode: "1462",
            },
            {
                name: "regions_lib.1458", // Woroba
                shortcode: "1458",
            },
            {
                name: "regions_lib.1456", // Yamoussoukro Autonomous District
                shortcode: "1456",
            },
            {
                name: "regions_lib.1459", // Zanzan
                shortcode: "1459",
            },
        ]
    },
    {
        shortcode: "CU", // Куба
        name: "regions_lib.CU",
        regions: [
            {
                name: "regions_lib.1476", // Artemisa
                shortcode: "1476",
            },
            {
                name: "regions_lib.1480", // La Habana
                shortcode: "1480",
            },
            {
                name: "regions_lib.1478", // Las Tunas
                shortcode: "1478",
            },
            {
                name: "regions_lib.1472", // Mayabeque
                shortcode: "1472",
            },
            {
                name: "regions_lib.1470", // Municipio Especial Isla de la Juventud
                shortcode: "1470",
            },
            {
                name: "regions_lib.1483", // Provincia Granma
                shortcode: "1483",
            },
            {
                name: "regions_lib.1471", // Provincia de Camagüey
                shortcode: "1471",
            },
            {
                name: "regions_lib.1474", // Provincia de Ciego de Ávila
                shortcode: "1474",
            },
            {
                name: "regions_lib.1473", // Provincia de Cienfuegos
                shortcode: "1473",
            },
            {
                name: "regions_lib.1477", // Provincia de Guantánamo
                shortcode: "1477",
            },
            {
                name: "regions_lib.1484", // Provincia de Holguín
                shortcode: "1484",
            },
            {
                name: "regions_lib.1479", // Provincia de Matanzas
                shortcode: "1479",
            },
            {
                name: "regions_lib.1482", // Provincia de Pinar del Río
                shortcode: "1482",
            },
            {
                name: "regions_lib.1475", // Provincia de Sancti Spíritus
                shortcode: "1475",
            },
            {
                name: "regions_lib.1485", // Provincia de Santiago de Cuba
                shortcode: "1485",
            },
            {
                name: "regions_lib.1481", // Provincia de Villa Clara
                shortcode: "1481",
            },
        ]
    },
    {
        shortcode: "KW", // Кувейт
        name: "regions_lib.KW",
        regions: [
            {
                name: "regions_lib.1489", // Al Asimah
                shortcode: "1489",
            },
            {
                name: "regions_lib.1491", // Al Farwaniyah
                shortcode: "1491",
            },
            {
                name: "regions_lib.1486", // Muḩāfaz̧at Mubārak al Kabīr
                shortcode: "1486",
            },
            {
                name: "regions_lib.1488", // Muḩāfaz̧at al Aḩmadī
                shortcode: "1488",
            },
            {
                name: "regions_lib.1487", // Muḩāfaz̧at al Jahrā’
                shortcode: "1487",
            },
            {
                name: "regions_lib.1490", // Muḩāfaz̧at Ḩawallī
                shortcode: "1490",
            },
        ]
    },
    {
        shortcode: "KG", // Кыргызстан
        name: "regions_lib.KG",
        regions: [
            {
                name: "regions_lib.1496", // Batkenskaya Oblast’
                shortcode: "1496",
            },
            {
                name: "regions_lib.1499", // Dzhalal-Abadskaya Oblast’
                shortcode: "1499",
            },
            {
                name: "regions_lib.1495", // Issyk-Kul’skaya Oblast’
                shortcode: "1495",
            },
            {
                name: "regions_lib.1494", // Osh City
                shortcode: "1494",
            },
            {
                name: "regions_lib.1498", // Город Бишкек
                shortcode: "1498",
            },
            {
                name: "regions_lib.1493", // Нарынская Область
                shortcode: "1493",
            },
            {
                name: "regions_lib.1500", // Ошская Область
                shortcode: "1500",
            },
            {
                name: "regions_lib.1492", // Таласская Область
                shortcode: "1492",
            },
            {
                name: "regions_lib.1497", // Чуйская Область
                shortcode: "1497",
            },
        ]
    },
    {
        shortcode: "CW", // Кюрасао
        name: "regions_lib.CW",
        regions: [
        ]
    },
    {
        shortcode: "LA", // Лаос
        name: "regions_lib.LA",
        regions: [
            {
                name: "regions_lib.1504", // Attapu
                shortcode: "1504",
            },
            {
                name: "regions_lib.1509", // Bolikhamxai
                shortcode: "1509",
            },
            {
                name: "regions_lib.1517", // Champasak
                shortcode: "1517",
            },
            {
                name: "regions_lib.1508", // Houaphan
                shortcode: "1508",
            },
            {
                name: "regions_lib.1512", // Khammouan
                shortcode: "1512",
            },
            {
                name: "regions_lib.1506", // Khouèng Bokèo
                shortcode: "1506",
            },
            {
                name: "regions_lib.1510", // Khouèng Oudômxai
                shortcode: "1510",
            },
            {
                name: "regions_lib.1505", // Khouèng Phôngsali
                shortcode: "1505",
            },
            {
                name: "regions_lib.1518", // Khouèng Savannakhét
                shortcode: "1518",
            },
            {
                name: "regions_lib.1502", // Khouèng Xékong
                shortcode: "1502",
            },
            {
                name: "regions_lib.1503", // Louangnamtha
                shortcode: "1503",
            },
            {
                name: "regions_lib.1515", // Luang Prabang Province
                shortcode: "1515",
            },
            {
                name: "regions_lib.1513", // Salavan
                shortcode: "1513",
            },
            {
                name: "regions_lib.1516", // Vientiane Prefecture
                shortcode: "1516",
            },
            {
                name: "regions_lib.1514", // Vientiane Province
                shortcode: "1514",
            },
            {
                name: "regions_lib.1511", // Xaignabouli
                shortcode: "1511",
            },
            {
                name: "regions_lib.1501", // Xaisomboun Province
                shortcode: "1501",
            },
            {
                name: "regions_lib.1507", // Xiangkhouang
                shortcode: "1507",
            },
        ]
    },
    {
        shortcode: "LV", // Латвия
        name: "regions_lib.LV",
        regions: [
            {
                name: "regions_lib.1542", // Aizkraukles novads
                shortcode: "1542",
            },
            {
                name: "regions_lib.1525", // Alūksnes Novads
                shortcode: "1525",
            },
            {
                name: "regions_lib.1536", // Augšdaugava Municipality
                shortcode: "1536",
            },
            {
                name: "regions_lib.1528", // Balvu Novads
                shortcode: "1528",
            },
            {
                name: "regions_lib.1552", // Bauskas Novads
                shortcode: "1552",
            },
            {
                name: "regions_lib.1551", // Cēsu Novads
                shortcode: "1551",
            },
            {
                name: "regions_lib.1539", // Dobeles novads
                shortcode: "1539",
            },
            {
                name: "regions_lib.1529", // Gulbenes novads
                shortcode: "1529",
            },
            {
                name: "regions_lib.1546", // Jelgavas novads
                shortcode: "1546",
            },
            {
                name: "regions_lib.1554", // Jūrmala
                shortcode: "1554",
            },
            {
                name: "regions_lib.1532", // Krāslavas novads
                shortcode: "1532",
            },
            {
                name: "regions_lib.1538", // Kuldīgas novads
                shortcode: "1538",
            },
            {
                name: "regions_lib.1540", // Limbažu novads
                shortcode: "1540",
            },
            {
                name: "regions_lib.1534", // Ludzas novads
                shortcode: "1534",
            },
            {
                name: "regions_lib.1523", // Līvāni
                shortcode: "1523",
            },
            {
                name: "regions_lib.1541", // Madona Municipality
                shortcode: "1541",
            },
            {
                name: "regions_lib.1533", // Mārupe
                shortcode: "1533",
            },
            {
                name: "regions_lib.1558", // Ogre
                shortcode: "1558",
            },
            {
                name: "regions_lib.1530", // Olaine
                shortcode: "1530",
            },
            {
                name: "regions_lib.1526", // Preiļi Municipality
                shortcode: "1526",
            },
            {
                name: "regions_lib.1520", // Ropažu Novads
                shortcode: "1520",
            },
            {
                name: "regions_lib.1537", // Rēzekne
                shortcode: "1537",
            },
            {
                name: "regions_lib.1543", // Rēzeknes Novads
                shortcode: "1543",
            },
            {
                name: "regions_lib.1535", // Salaspils Novads
                shortcode: "1535",
            },
            {
                name: "regions_lib.1549", // Saldus Municipality
                shortcode: "1549",
            },
            {
                name: "regions_lib.1522", // Saulkrastu Novads
                shortcode: "1522",
            },
            {
                name: "regions_lib.1545", // Siguldas Novads
                shortcode: "1545",
            },
            {
                name: "regions_lib.1527", // Smiltenes Novads
                shortcode: "1527",
            },
            {
                name: "regions_lib.1547", // South Kurzeme Municipality
                shortcode: "1547",
            },
            {
                name: "regions_lib.1553", // Talsi Municipality
                shortcode: "1553",
            },
            {
                name: "regions_lib.1556", // Tukuma novads
                shortcode: "1556",
            },
            {
                name: "regions_lib.1521", // Valka Municipality
                shortcode: "1521",
            },
            {
                name: "regions_lib.1555", // Valmiera
                shortcode: "1555",
            },
            {
                name: "regions_lib.1519", // Varakļānu Novads
                shortcode: "1519",
            },
            {
                name: "regions_lib.1524", // Ventspils Municipality
                shortcode: "1524",
            },
            {
                name: "regions_lib.1531", // Ādaži
                shortcode: "1531",
            },
            {
                name: "regions_lib.1544", // Ķekava
                shortcode: "1544",
            },
            {
                name: "regions_lib.1548", // Вентспилс
                shortcode: "1548",
            },
            {
                name: "regions_lib.1560", // Даугавпилс
                shortcode: "1560",
            },
            {
                name: "regions_lib.1550", // Екабпилсский край
                shortcode: "1550",
            },
            {
                name: "regions_lib.1557", // Елгава
                shortcode: "1557",
            },
            {
                name: "regions_lib.1559", // Лиепая
                shortcode: "1559",
            },
            {
                name: "regions_lib.1561", // Рига
                shortcode: "1561",
            },
        ]
    },
    {
        shortcode: "LS", // Лесото
        name: "regions_lib.LS",
        regions: [
            {
                name: "regions_lib.1570", // Leribe District
                shortcode: "1570",
            },
            {
                name: "regions_lib.1568", // Mafeteng District
                shortcode: "1568",
            },
            {
                name: "regions_lib.1567", // Mohale’s Hoek District
                shortcode: "1567",
            },
            {
                name: "regions_lib.1563", // Qacha’s Nek
                shortcode: "1563",
            },
            {
                name: "regions_lib.1562", // Берья
                shortcode: "1562",
            },
            {
                name: "regions_lib.1569", // Бута-Буте
                shortcode: "1569",
            },
            {
                name: "regions_lib.1571", // Масеру
                shortcode: "1571",
            },
            {
                name: "regions_lib.1564", // Мокотлонг
                shortcode: "1564",
            },
            {
                name: "regions_lib.1566", // Таба-Цека
                shortcode: "1566",
            },
            {
                name: "regions_lib.1565", // Цгутинг
                shortcode: "1565",
            },
        ]
    },
    {
        shortcode: "LR", // Либерия
        name: "regions_lib.LR",
        regions: [
            {
                name: "regions_lib.1576", // Bomi County
                shortcode: "1576",
            },
            {
                name: "regions_lib.1583", // Bong County
                shortcode: "1583",
            },
            {
                name: "regions_lib.1575", // Gbarpolu County
                shortcode: "1575",
            },
            {
                name: "regions_lib.1582", // Grand Bassa County
                shortcode: "1582",
            },
            {
                name: "regions_lib.1578", // Grand Cape Mount County
                shortcode: "1578",
            },
            {
                name: "regions_lib.1580", // Grand Gedeh County
                shortcode: "1580",
            },
            {
                name: "regions_lib.1572", // Grand Kru County
                shortcode: "1572",
            },
            {
                name: "regions_lib.1584", // Lofa County
                shortcode: "1584",
            },
            {
                name: "regions_lib.1581", // Margibi County
                shortcode: "1581",
            },
            {
                name: "regions_lib.1579", // Maryland County
                shortcode: "1579",
            },
            {
                name: "regions_lib.1586", // Montserrado County
                shortcode: "1586",
            },
            {
                name: "regions_lib.1585", // Nimba County
                shortcode: "1585",
            },
            {
                name: "regions_lib.1574", // River Cess County
                shortcode: "1574",
            },
            {
                name: "regions_lib.1573", // River Gee County
                shortcode: "1573",
            },
            {
                name: "regions_lib.1577", // Sinoe County
                shortcode: "1577",
            },
        ]
    },
    {
        shortcode: "LB", // Ливан
        name: "regions_lib.LB",
        regions: [
            {
                name: "regions_lib.1594", // Beyrouth
                shortcode: "1594",
            },
            {
                name: "regions_lib.1589", // Mohafazat Aakkâr
                shortcode: "1589",
            },
            {
                name: "regions_lib.1588", // Mohafazat Baalbek-Hermel
                shortcode: "1588",
            },
            {
                name: "regions_lib.1591", // Mohafazat Béqaa
                shortcode: "1591",
            },
            {
                name: "regions_lib.1592", // Mohafazat Liban-Nord
                shortcode: "1592",
            },
            {
                name: "regions_lib.1593", // Mohafazat Mont-Liban
                shortcode: "1593",
            },
            {
                name: "regions_lib.1587", // Mohafazat Nabatîyé
                shortcode: "1587",
            },
            {
                name: "regions_lib.1590", // Южный Ливан
                shortcode: "1590",
            },
        ]
    },
    {
        shortcode: "LY", // Ливия
        name: "regions_lib.LY",
        regions: [
            {
                name: "regions_lib.1604", // Al Buţnān
                shortcode: "1604",
            },
            {
                name: "regions_lib.1608", // Al Jabal al Akhḑar
                shortcode: "1608",
            },
            {
                name: "regions_lib.1613", // Al Jafārah
                shortcode: "1613",
            },
            {
                name: "regions_lib.1601", // Al Marj
                shortcode: "1601",
            },
            {
                name: "regions_lib.1612", // Al Marqab
                shortcode: "1612",
            },
            {
                name: "regions_lib.1607", // Al Wāḩāt
                shortcode: "1607",
            },
            {
                name: "regions_lib.1609", // An Nuqāţ al Khams
                shortcode: "1609",
            },
            {
                name: "regions_lib.1610", // Az Zāwiyah
                shortcode: "1610",
            },
            {
                name: "regions_lib.1605", // Darnah
                shortcode: "1605",
            },
            {
                name: "regions_lib.1595", // Ghāt
                shortcode: "1595",
            },
            {
                name: "regions_lib.1611", // Jabal al Gharbi District
                shortcode: "1611",
            },
            {
                name: "regions_lib.1600", // Murzuq
                shortcode: "1600",
            },
            {
                name: "regions_lib.1602", // Nālūt
                shortcode: "1602",
            },
            {
                name: "regions_lib.1603", // Sabhā
                shortcode: "1603",
            },
            {
                name: "regions_lib.1616", // Sha‘bīyat Banghāzī
                shortcode: "1616",
            },
            {
                name: "regions_lib.1614", // Sha‘bīyat Mişrātah
                shortcode: "1614",
            },
            {
                name: "regions_lib.1606", // Surt
                shortcode: "1606",
            },
            {
                name: "regions_lib.1615", // Tripoli
                shortcode: "1615",
            },
            {
                name: "regions_lib.1598", // Wādī al Ḩayāt
                shortcode: "1598",
            },
            {
                name: "regions_lib.1599", // Wādī ash Shāţi’
                shortcode: "1599",
            },
            {
                name: "regions_lib.1596", // Эль-Джуфра
                shortcode: "1596",
            },
            {
                name: "regions_lib.1597", // Эль-Куфра
                shortcode: "1597",
            },
        ]
    },
    {
        shortcode: "LT", // Литва
        name: "regions_lib.LT",
        regions: [
            {
                name: "regions_lib.1620", // Alytus County
                shortcode: "1620",
            },
            {
                name: "regions_lib.1625", // Kaunas County
                shortcode: "1625",
            },
            {
                name: "regions_lib.1624", // Klaipėda County
                shortcode: "1624",
            },
            {
                name: "regions_lib.1621", // Marijampolė County
                shortcode: "1621",
            },
            {
                name: "regions_lib.1622", // Panevėžys
                shortcode: "1622",
            },
            {
                name: "regions_lib.1617", // Tauragė County
                shortcode: "1617",
            },
            {
                name: "regions_lib.1619", // Telšių apskritis
                shortcode: "1619",
            },
            {
                name: "regions_lib.1618", // Utena County
                shortcode: "1618",
            },
            {
                name: "regions_lib.1626", // Vilnius County
                shortcode: "1626",
            },
            {
                name: "regions_lib.1623", // Šiauliai County
                shortcode: "1623",
            },
        ]
    },
    {
        shortcode: "LI", // Лихтенштейн
        name: "regions_lib.LI",
        regions: [
            {
                name: "regions_lib.1634", // Balzers
                shortcode: "1634",
            },
            {
                name: "regions_lib.1629", // Gamprin
                shortcode: "1629",
            },
            {
                name: "regions_lib.1627", // Planken
                shortcode: "1627",
            },
            {
                name: "regions_lib.1630", // Ruggell
                shortcode: "1630",
            },
            {
                name: "regions_lib.1637", // Schaan
                shortcode: "1637",
            },
            {
                name: "regions_lib.1628", // Schellenberg
                shortcode: "1628",
            },
            {
                name: "regions_lib.1635", // Triesen
                shortcode: "1635",
            },
            {
                name: "regions_lib.1631", // Triesenberg
                shortcode: "1631",
            },
            {
                name: "regions_lib.1636", // Vaduz
                shortcode: "1636",
            },
            {
                name: "regions_lib.1633", // Маурен
                shortcode: "1633",
            },
            {
                name: "regions_lib.1632", // Эшен
                shortcode: "1632",
            },
        ]
    },
    {
        shortcode: "LU", // Люксембург
        name: "regions_lib.LU",
        regions: [
            {
                name: "regions_lib.1642", // Canton d&quot;Echternach
                shortcode: "1642",
            },
            {
                name: "regions_lib.1648", // Canton d&quot;Esch-sur-Alzette
                shortcode: "1648",
            },
            {
                name: "regions_lib.1647", // Capellen
                shortcode: "1647",
            },
            {
                name: "regions_lib.1641", // Clervaux
                shortcode: "1641",
            },
            {
                name: "regions_lib.1645", // Diekirch
                shortcode: "1645",
            },
            {
                name: "regions_lib.1638", // Grevenmacher
                shortcode: "1638",
            },
            {
                name: "regions_lib.1649", // Luxembourg
                shortcode: "1649",
            },
            {
                name: "regions_lib.1646", // Mersch
                shortcode: "1646",
            },
            {
                name: "regions_lib.1643", // Redange
                shortcode: "1643",
            },
            {
                name: "regions_lib.1644", // Remich
                shortcode: "1644",
            },
            {
                name: "regions_lib.1639", // Vianden
                shortcode: "1639",
            },
            {
                name: "regions_lib.1640", // Wiltz
                shortcode: "1640",
            },
        ]
    },
    {
        shortcode: "MU", // Маврикий
        name: "regions_lib.MU",
        regions: [
            {
                name: "regions_lib.1651", // Agalega Islands
                shortcode: "1651",
            },
            {
                name: "regions_lib.1653", // Black River District
                shortcode: "1653",
            },
            {
                name: "regions_lib.1660", // Flacq District
                shortcode: "1660",
            },
            {
                name: "regions_lib.1657", // Grand Port District
                shortcode: "1657",
            },
            {
                name: "regions_lib.1655", // Moka District
                shortcode: "1655",
            },
            {
                name: "regions_lib.1659", // Pamplemousses District
                shortcode: "1659",
            },
            {
                name: "regions_lib.1661", // Plaines Wilhems District
                shortcode: "1661",
            },
            {
                name: "regions_lib.1658", // Port Louis District
                shortcode: "1658",
            },
            {
                name: "regions_lib.1656", // Rivière du Rempart District
                shortcode: "1656",
            },
            {
                name: "regions_lib.1652", // Rodrigues
                shortcode: "1652",
            },
            {
                name: "regions_lib.1654", // Savanne District
                shortcode: "1654",
            },
            {
                name: "regions_lib.1650", // Каргадос-Карахос
                shortcode: "1650",
            },
        ]
    },
    {
        shortcode: "MR", // Мавритания
        name: "regions_lib.MR",
        regions: [
            {
                name: "regions_lib.1669", // Hodh El Gharbi
                shortcode: "1669",
            },
            {
                name: "regions_lib.1674", // Hodh ech Chargui
                shortcode: "1674",
            },
            {
                name: "regions_lib.1675", // Nouakchott Nord
                shortcode: "1675",
            },
            {
                name: "regions_lib.1667", // Nouakchott Ouest
                shortcode: "1667",
            },
            {
                name: "regions_lib.1676", // Nouakchott Sud
                shortcode: "1676",
            },
            {
                name: "regions_lib.1671", // Wilaya du Trarza
                shortcode: "1671",
            },
            {
                name: "regions_lib.1663", // Адрар
                shortcode: "1663",
            },
            {
                name: "regions_lib.1673", // Ассаба
                shortcode: "1673",
            },
            {
                name: "regions_lib.1670", // Бракна
                shortcode: "1670",
            },
            {
                name: "regions_lib.1672", // Горголь
                shortcode: "1672",
            },
            {
                name: "regions_lib.1666", // Дахлет-Нуадибу
                shortcode: "1666",
            },
            {
                name: "regions_lib.1662", // Иншири
                shortcode: "1662",
            },
            {
                name: "regions_lib.1668", // Кудимага
                shortcode: "1668",
            },
            {
                name: "regions_lib.1665", // Тагант
                shortcode: "1665",
            },
            {
                name: "regions_lib.1664", // Тирис-Земмур
                shortcode: "1664",
            },
        ]
    },
    {
        shortcode: "MG", // Мадагаскар
        name: "regions_lib.MG",
        regions: [
            {
                name: "regions_lib.1693", // Alaotra Mangoro Region
                shortcode: "1693",
            },
            {
                name: "regions_lib.1685", // Amoron&quot;i Mania Region
                shortcode: "1685",
            },
            {
                name: "regions_lib.1699", // Analamanga Region
                shortcode: "1699",
            },
            {
                name: "regions_lib.1692", // Analanjirofo Region
                shortcode: "1692",
            },
            {
                name: "regions_lib.1688", // Androy Region
                shortcode: "1688",
            },
            {
                name: "regions_lib.1684", // Anosy Region
                shortcode: "1684",
            },
            {
                name: "regions_lib.1697", // Atsimo-Andrefana Region
                shortcode: "1697",
            },
            {
                name: "regions_lib.1690", // Atsimo-Atsinanana
                shortcode: "1690",
            },
            {
                name: "regions_lib.1695", // Atsinanana Region
                shortcode: "1695",
            },
            {
                name: "regions_lib.1678", // Betsiboka Region
                shortcode: "1678",
            },
            {
                name: "regions_lib.1689", // Boeny Region
                shortcode: "1689",
            },
            {
                name: "regions_lib.1680", // Bongolava Region
                shortcode: "1680",
            },
            {
                name: "regions_lib.1686", // Diana Region
                shortcode: "1686",
            },
            {
                name: "regions_lib.1683", // Fitovinany Region
                shortcode: "1683",
            },
            {
                name: "regions_lib.1694", // Haute Matsiatra Region
                shortcode: "1694",
            },
            {
                name: "regions_lib.1679", // Ihorombe Region
                shortcode: "1679",
            },
            {
                name: "regions_lib.1687", // Itasy Region
                shortcode: "1687",
            },
            {
                name: "regions_lib.1677", // Melaky Region
                shortcode: "1677",
            },
            {
                name: "regions_lib.1681", // Menabe Region
                shortcode: "1681",
            },
            {
                name: "regions_lib.1691", // Sava Region
                shortcode: "1691",
            },
            {
                name: "regions_lib.1696", // Sofia Region
                shortcode: "1696",
            },
            {
                name: "regions_lib.1698", // Vakinankaratra Region
                shortcode: "1698",
            },
            {
                name: "regions_lib.1682", // Vatovavy Region
                shortcode: "1682",
            },
        ]
    },
    {
        shortcode: "YT", // Майотта
        name: "regions_lib.YT",
        regions: [
            {
                name: "regions_lib.1700", // Acoua
                shortcode: "1700",
            },
            {
                name: "regions_lib.1712", // Bandraboua
                shortcode: "1712",
            },
            {
                name: "regions_lib.1708", // Bandrele
                shortcode: "1708",
            },
            {
                name: "regions_lib.1702", // Bouéni
                shortcode: "1702",
            },
            {
                name: "regions_lib.1705", // Chiconi
                shortcode: "1705",
            },
            {
                name: "regions_lib.1706", // Chirongui
                shortcode: "1706",
            },
            {
                name: "regions_lib.1713", // Dembeni
                shortcode: "1713",
            },
            {
                name: "regions_lib.1714", // Dzaoudzi
                shortcode: "1714",
            },
            {
                name: "regions_lib.1701", // Kani-Kéli
                shortcode: "1701",
            },
            {
                name: "regions_lib.1715", // Koungou
                shortcode: "1715",
            },
            {
                name: "regions_lib.1703", // M&quot;Tsangamouji
                shortcode: "1703",
            },
            {
                name: "regions_lib.1716", // Mamoudzou
                shortcode: "1716",
            },
            {
                name: "regions_lib.1704", // Mtsamboro
                shortcode: "1704",
            },
            {
                name: "regions_lib.1707", // Ouangani
                shortcode: "1707",
            },
            {
                name: "regions_lib.1710", // Pamandzi
                shortcode: "1710",
            },
            {
                name: "regions_lib.1709", // Sada
                shortcode: "1709",
            },
            {
                name: "regions_lib.1711", // Tsingoni
                shortcode: "1711",
            },
        ]
    },
    {
        shortcode: "MO", // Макао
        name: "regions_lib.MO",
        regions: [
            {
                name: "regions_lib.1718", // Nossa Senhora do Carmo
                shortcode: "1718",
            },
            {
                name: "regions_lib.1717", // São Francisco Xavier
                shortcode: "1717",
            },
        ]
    },
    {
        shortcode: "MW", // Малави
        name: "regions_lib.MW",
        regions: [
            {
                name: "regions_lib.1720", // Central Region
                shortcode: "1720",
            },
            {
                name: "regions_lib.1719", // Northern Region
                shortcode: "1719",
            },
            {
                name: "regions_lib.1721", // Southern Region
                shortcode: "1721",
            },
        ]
    },
    {
        shortcode: "MY", // Малайзия
        name: "regions_lib.MY",
        regions: [
            {
                name: "regions_lib.1730", // Kelantan
                shortcode: "1730",
            },
            {
                name: "regions_lib.1732", // Kuala Lumpur
                shortcode: "1732",
            },
            {
                name: "regions_lib.1722", // Labuan
                shortcode: "1722",
            },
            {
                name: "regions_lib.1725", // Melaka
                shortcode: "1725",
            },
            {
                name: "regions_lib.1726", // Negeri Sembilan
                shortcode: "1726",
            },
            {
                name: "regions_lib.1728", // Pahang
                shortcode: "1728",
            },
            {
                name: "regions_lib.1734", // Perak
                shortcode: "1734",
            },
            {
                name: "regions_lib.1723", // Perlis
                shortcode: "1723",
            },
            {
                name: "regions_lib.1729", // Pulau Pinang
                shortcode: "1729",
            },
            {
                name: "regions_lib.1724", // Sabah
                shortcode: "1724",
            },
            {
                name: "regions_lib.1727", // Terengganu
                shortcode: "1727",
            },
            {
                name: "regions_lib.1735", // Джохор
                shortcode: "1735",
            },
            {
                name: "regions_lib.1731", // Кедах
                shortcode: "1731",
            },
            {
                name: "regions_lib.1733", // Саравак
                shortcode: "1733",
            },
            {
                name: "regions_lib.1736", // Селангор
                shortcode: "1736",
            },
        ]
    },
    {
        shortcode: "ML", // Мали
        name: "regions_lib.ML",
        regions: [
            {
                name: "regions_lib.1742", // Bamako Region
                shortcode: "1742",
            },
            {
                name: "regions_lib.1740", // Gao
                shortcode: "1740",
            },
            {
                name: "regions_lib.1743", // Kayes
                shortcode: "1743",
            },
            {
                name: "regions_lib.1739", // Kidal
                shortcode: "1739",
            },
            {
                name: "regions_lib.1746", // Koulikoro
                shortcode: "1746",
            },
            {
                name: "regions_lib.1744", // Mopti
                shortcode: "1744",
            },
            {
                name: "regions_lib.1738", // Ménaka Region
                shortcode: "1738",
            },
            {
                name: "regions_lib.1747", // Sikasso
                shortcode: "1747",
            },
            {
                name: "regions_lib.1745", // Ségou
                shortcode: "1745",
            },
            {
                name: "regions_lib.1737", // Taoudénit Region
                shortcode: "1737",
            },
            {
                name: "regions_lib.1741", // Tombouctou
                shortcode: "1741",
            },
        ]
    },
    {
        shortcode: "MV", // Мальдивы
        name: "regions_lib.MV",
        regions: [
            {
                name: "regions_lib.1764", // Addu Atoll
                shortcode: "1764",
            },
            {
                name: "regions_lib.1756", // Baa Atholhu
                shortcode: "1756",
            },
            {
                name: "regions_lib.1752", // Dhaalu Atholhu
                shortcode: "1752",
            },
            {
                name: "regions_lib.1749", // Faafu Atholhu
                shortcode: "1749",
            },
            {
                name: "regions_lib.1757", // Gaafu Alif Atoll
                shortcode: "1757",
            },
            {
                name: "regions_lib.1763", // Gaafu Dhaalu Atoll
                shortcode: "1763",
            },
            {
                name: "regions_lib.1755", // Gnaviyani Atoll
                shortcode: "1755",
            },
            {
                name: "regions_lib.1765", // Haa Alifu Atholhu
                shortcode: "1765",
            },
            {
                name: "regions_lib.1766", // Haa Dhaalu Atholhu
                shortcode: "1766",
            },
            {
                name: "regions_lib.1761", // Kaafu Atoll
                shortcode: "1761",
            },
            {
                name: "regions_lib.1758", // Laamu Atholhu
                shortcode: "1758",
            },
            {
                name: "regions_lib.1754", // Lhaviyani Atholhu
                shortcode: "1754",
            },
            {
                name: "regions_lib.1751", // Meemu Atholhu
                shortcode: "1751",
            },
            {
                name: "regions_lib.1760", // Noonu Atoll
                shortcode: "1760",
            },
            {
                name: "regions_lib.1750", // Northern Ari Atoll
                shortcode: "1750",
            },
            {
                name: "regions_lib.1767", // Raa Atoll
                shortcode: "1767",
            },
            {
                name: "regions_lib.1762", // Shaviyani Atholhu
                shortcode: "1762",
            },
            {
                name: "regions_lib.1753", // Southern Ari Atoll
                shortcode: "1753",
            },
            {
                name: "regions_lib.1759", // Thaa Atholhu
                shortcode: "1759",
            },
            {
                name: "regions_lib.1748", // Vaavu Atholhu
                shortcode: "1748",
            },
        ]
    },
    {
        shortcode: "MT", // Мальта
        name: "regions_lib.MT",
        regions: [
            {
                name: "regions_lib.1817", // Attard
                shortcode: "1817",
            },
            {
                name: "regions_lib.1834", // Birkirkara
                shortcode: "1834",
            },
            {
                name: "regions_lib.1823", // Birżebbuġa
                shortcode: "1823",
            },
            {
                name: "regions_lib.1805", // Bormla
                shortcode: "1805",
            },
            {
                name: "regions_lib.1797", // Dingli
                shortcode: "1797",
            },
            {
                name: "regions_lib.1783", // Il-Birgu
                shortcode: "1783",
            },
            {
                name: "regions_lib.1822", // Il-Fgura
                shortcode: "1822",
            },
            {
                name: "regions_lib.1771", // Il-Fontana
                shortcode: "1771",
            },
            {
                name: "regions_lib.1779", // Il-Furjana
                shortcode: "1779",
            },
            {
                name: "regions_lib.1791", // Il-Gudja
                shortcode: "1791",
            },
            {
                name: "regions_lib.1821", // Il-Gżira
                shortcode: "1821",
            },
            {
                name: "regions_lib.1790", // Il-Kalkara
                shortcode: "1790",
            },
            {
                name: "regions_lib.1806", // Il-Marsa
                shortcode: "1806",
            },
            {
                name: "regions_lib.1819", // Il-Mellieħa
                shortcode: "1819",
            },
            {
                name: "regions_lib.1832", // Il-Mosta
                shortcode: "1832",
            },
            {
                name: "regions_lib.1773", // Il-Munxar
                shortcode: "1773",
            },
            {
                name: "regions_lib.1777", // Il-Qala
                shortcode: "1777",
            },
            {
                name: "regions_lib.1787", // Il-Qrendi
                shortcode: "1787",
            },
            {
                name: "regions_lib.1814", // Il-Ħamrun
                shortcode: "1814",
            },
            {
                name: "regions_lib.1800", // In-Nadur
                shortcode: "1800",
            },
            {
                name: "regions_lib.1829", // In-Naxxar
                shortcode: "1829",
            },
            {
                name: "regions_lib.1820", // Ir-Rabat
                shortcode: "1820",
            },
            {
                name: "regions_lib.1813", // Is-Siġġiewi
                shortcode: "1813",
            },
            {
                name: "regions_lib.1826", // Is-Swieqi
                shortcode: "1826",
            },
            {
                name: "regions_lib.1803", // Ix-Xagħra
                shortcode: "1803",
            },
            {
                name: "regions_lib.1793", // Ix-Xewkija
                shortcode: "1793",
            },
            {
                name: "regions_lib.1775", // Ix-Xgħajra
                shortcode: "1775",
            },
            {
                name: "regions_lib.1774", // Iż-Żebbuġ
                shortcode: "1774",
            },
            {
                name: "regions_lib.1818", // Iż-Żejtun
                shortcode: "1818",
            },
            {
                name: "regions_lib.1815", // Iż-Żurrieq
                shortcode: "1815",
            },
            {
                name: "regions_lib.1782", // Kirkop
                shortcode: "1782",
            },
            {
                name: "regions_lib.1772", // L-Għarb
                shortcode: "1772",
            },
            {
                name: "regions_lib.1769", // L-Għasri
                shortcode: "1769",
            },
            {
                name: "regions_lib.1795", // L-Iklin
                shortcode: "1795",
            },
            {
                name: "regions_lib.1768", // L-Imdina
                shortcode: "1768",
            },
            {
                name: "regions_lib.1794", // L-Imqabba
                shortcode: "1794",
            },
            {
                name: "regions_lib.1824", // L-Imsida
                shortcode: "1824",
            },
            {
                name: "regions_lib.1784", // L-Imtarfa
                shortcode: "1784",
            },
            {
                name: "regions_lib.1798", // L-Imġarr
                shortcode: "1798",
            },
            {
                name: "regions_lib.1786", // L-Isla
                shortcode: "1786",
            },
            {
                name: "regions_lib.1792", // Lija
                shortcode: "1792",
            },
            {
                name: "regions_lib.1808", // Luqa
                shortcode: "1808",
            },
            {
                name: "regions_lib.1827", // Marsaskala
                shortcode: "1827",
            },
            {
                name: "regions_lib.1796", // Marsaxlokk
                shortcode: "1796",
            },
            {
                name: "regions_lib.1812", // Paola
                shortcode: "1812",
            },
            {
                name: "regions_lib.1799", // Pembroke
                shortcode: "1799",
            },
            {
                name: "regions_lib.1831", // Qormi
                shortcode: "1831",
            },
            {
                name: "regions_lib.1781", // Safi
                shortcode: "1781",
            },
            {
                name: "regions_lib.1828", // Saint John
                shortcode: "1828",
            },
            {
                name: "regions_lib.1825", // Saint Julian&quot;s
                shortcode: "1825",
            },
            {
                name: "regions_lib.1770", // Saint Lawrence
                shortcode: "1770",
            },
            {
                name: "regions_lib.1789", // Saint Lucia
                shortcode: "1789",
            },
            {
                name: "regions_lib.1835", // Saint Paul’s Bay
                shortcode: "1835",
            },
            {
                name: "regions_lib.1810", // Saint Venera
                shortcode: "1810",
            },
            {
                name: "regions_lib.1780", // Sannat
                shortcode: "1780",
            },
            {
                name: "regions_lib.1804", // Tal-Pietà
                shortcode: "1804",
            },
            {
                name: "regions_lib.1811", // Tarxien
                shortcode: "1811",
            },
            {
                name: "regions_lib.1833", // Tas-Sliema
                shortcode: "1833",
            },
            {
                name: "regions_lib.1776", // Ta’ Kerċem
                shortcode: "1776",
            },
            {
                name: "regions_lib.1778", // Ta’ Xbiex
                shortcode: "1778",
            },
            {
                name: "regions_lib.1807", // Valletta
                shortcode: "1807",
            },
            {
                name: "regions_lib.1809", // Victoria
                shortcode: "1809",
            },
            {
                name: "regions_lib.1785", // Ħal Għargħur
                shortcode: "1785",
            },
            {
                name: "regions_lib.1802", // Ħal Għaxaq
                shortcode: "1802",
            },
            {
                name: "regions_lib.1830", // Ħaż-Żabbar
                shortcode: "1830",
            },
            {
                name: "regions_lib.1816", // Ħaż-Żebbuġ
                shortcode: "1816",
            },
            {
                name: "regions_lib.1788", // Айнсилем
                shortcode: "1788",
            },
            {
                name: "regions_lib.1801", // Бальцан
                shortcode: "1801",
            },
        ]
    },
    {
        shortcode: "MA", // Марокко
        name: "regions_lib.MA",
        regions: [
            {
                name: "regions_lib.1841", // Béni Mellal-Khénifra
                shortcode: "1841",
            },
            {
                name: "regions_lib.1847", // Casablanca-Settat
                shortcode: "1847",
            },
            {
                name: "regions_lib.1836", // Dakhla-Oued Ed-Dahab
                shortcode: "1836",
            },
            {
                name: "regions_lib.1839", // Drâa-Tafilalet
                shortcode: "1839",
            },
            {
                name: "regions_lib.1844", // Fès-Meknès
                shortcode: "1844",
            },
            {
                name: "regions_lib.1838", // Guelmim-Oued Noun
                shortcode: "1838",
            },
            {
                name: "regions_lib.1837", // Laâyoune-Sakia El Hamra
                shortcode: "1837",
            },
            {
                name: "regions_lib.1845", // Marrakesh-Safi
                shortcode: "1845",
            },
            {
                name: "regions_lib.1840", // Oriental
                shortcode: "1840",
            },
            {
                name: "regions_lib.1846", // Rabat-Salé-Kénitra
                shortcode: "1846",
            },
            {
                name: "regions_lib.1842", // Souss-Massa
                shortcode: "1842",
            },
            {
                name: "regions_lib.1843", // Tanger-Tetouan-Al Hoceima
                shortcode: "1843",
            },
        ]
    },
    {
        shortcode: "MQ", // Мартиника
        name: "regions_lib.MQ",
        regions: [
            {
                name: "regions_lib.1848", // Martinique
                shortcode: "1848",
            },
        ]
    },
    {
        shortcode: "MH", // Маршалловы Острова
        name: "regions_lib.MH",
        regions: [
            {
                name: "regions_lib.1869", // Ailinglaplap Atoll
                shortcode: "1869",
            },
            {
                name: "regions_lib.1857", // Ailuk Atoll
                shortcode: "1857",
            },
            {
                name: "regions_lib.1870", // Arno Atoll
                shortcode: "1870",
            },
            {
                name: "regions_lib.1859", // Aur Atoll
                shortcode: "1859",
            },
            {
                name: "regions_lib.1865", // Ebon Atoll
                shortcode: "1865",
            },
            {
                name: "regions_lib.1862", // Enewetak Atoll
                shortcode: "1862",
            },
            {
                name: "regions_lib.1850", // Jabat Island
                shortcode: "1850",
            },
            {
                name: "regions_lib.1868", // Jaluit Atoll
                shortcode: "1868",
            },
            {
                name: "regions_lib.1861", // Kili Island
                shortcode: "1861",
            },
            {
                name: "regions_lib.1871", // Kwajalein Atoll
                shortcode: "1871",
            },
            {
                name: "regions_lib.1853", // Lae Atoll
                shortcode: "1853",
            },
            {
                name: "regions_lib.1852", // Lib Island
                shortcode: "1852",
            },
            {
                name: "regions_lib.1858", // Likiep Atoll
                shortcode: "1858",
            },
            {
                name: "regions_lib.1872", // Majuro Atoll
                shortcode: "1872",
            },
            {
                name: "regions_lib.1863", // Maloelap Atoll
                shortcode: "1863",
            },
            {
                name: "regions_lib.1854", // Mejit Island
                shortcode: "1854",
            },
            {
                name: "regions_lib.1867", // Mili Atoll
                shortcode: "1867",
            },
            {
                name: "regions_lib.1860", // Namdrik Atoll
                shortcode: "1860",
            },
            {
                name: "regions_lib.1866", // Namu Atoll
                shortcode: "1866",
            },
            {
                name: "regions_lib.1849", // Rongelap Atoll
                shortcode: "1849",
            },
            {
                name: "regions_lib.1856", // Ujae Atoll
                shortcode: "1856",
            },
            {
                name: "regions_lib.1855", // Utrik Atoll
                shortcode: "1855",
            },
            {
                name: "regions_lib.1851", // Wotho Atoll
                shortcode: "1851",
            },
            {
                name: "regions_lib.1864", // Wotje Atoll
                shortcode: "1864",
            },
        ]
    },
    {
        shortcode: "MX", // Мексика
        name: "regions_lib.MX",
        regions: [
            {
                name: "regions_lib.1903", // Ciudad de México
                shortcode: "1903",
            },
            {
                name: "regions_lib.1894", // Estado de Baja California
                shortcode: "1894",
            },
            {
                name: "regions_lib.1874", // Estado de Baja California Sur
                shortcode: "1874",
            },
            {
                name: "regions_lib.1875", // Estado de Campeche
                shortcode: "1875",
            },
            {
                name: "regions_lib.1890", // Estado de Coahuila de Zaragoza
                shortcode: "1890",
            },
            {
                name: "regions_lib.1873", // Estado de Colima
                shortcode: "1873",
            },
            {
                name: "regions_lib.1880", // Estado de Durango
                shortcode: "1880",
            },
            {
                name: "regions_lib.1899", // Estado de Guanajuato
                shortcode: "1899",
            },
            {
                name: "regions_lib.1892", // Estado de Guerrero
                shortcode: "1892",
            },
            {
                name: "regions_lib.1889", // Estado de Hidalgo
                shortcode: "1889",
            },
            {
                name: "regions_lib.1902", // Estado de Jalisco
                shortcode: "1902",
            },
            {
                name: "regions_lib.1896", // Estado de Michoacán de Ocampo
                shortcode: "1896",
            },
            {
                name: "regions_lib.1882", // Estado de Morelos
                shortcode: "1882",
            },
            {
                name: "regions_lib.1877", // Estado de Nayarit
                shortcode: "1877",
            },
            {
                name: "regions_lib.1898", // Estado de Nuevo León
                shortcode: "1898",
            },
            {
                name: "regions_lib.1895", // Estado de Oaxaca
                shortcode: "1895",
            },
            {
                name: "regions_lib.1900", // Estado de Puebla
                shortcode: "1900",
            },
            {
                name: "regions_lib.1881", // Estado de Quintana Roo
                shortcode: "1881",
            },
            {
                name: "regions_lib.1886", // Estado de San Luis Potosí
                shortcode: "1886",
            },
            {
                name: "regions_lib.1888", // Estado de Sinaloa
                shortcode: "1888",
            },
            {
                name: "regions_lib.1885", // Estado de Tabasco
                shortcode: "1885",
            },
            {
                name: "regions_lib.1891", // Estado de Tamaulipas
                shortcode: "1891",
            },
            {
                name: "regions_lib.1878", // Estado de Tlaxcala
                shortcode: "1878",
            },
            {
                name: "regions_lib.1901", // Estado de Veracruz-Llave
                shortcode: "1901",
            },
            {
                name: "regions_lib.1883", // Estado de Yucatán
                shortcode: "1883",
            },
            {
                name: "regions_lib.1879", // Estado de Zacatecas
                shortcode: "1879",
            },
            {
                name: "regions_lib.1904", // México
                shortcode: "1904",
            },
            {
                name: "regions_lib.1876", // Агуаскальентес
                shortcode: "1876",
            },
            {
                name: "regions_lib.1884", // Керетаро
                shortcode: "1884",
            },
            {
                name: "regions_lib.1887", // Сонора
                shortcode: "1887",
            },
            {
                name: "regions_lib.1893", // Чиуауа
                shortcode: "1893",
            },
            {
                name: "regions_lib.1897", // Чьяпас
                shortcode: "1897",
            },
        ]
    },
    {
        shortcode: "MZ", // Мозамбик
        name: "regions_lib.MZ",
        regions: [
            {
                name: "regions_lib.1912", // Cabo Delgado Province
                shortcode: "1912",
            },
            {
                name: "regions_lib.1908", // Cidade de Maputo
                shortcode: "1908",
            },
            {
                name: "regions_lib.1907", // Gaza Province
                shortcode: "1907",
            },
            {
                name: "regions_lib.1906", // Inhambane Province
                shortcode: "1906",
            },
            {
                name: "regions_lib.1905", // Manica Province
                shortcode: "1905",
            },
            {
                name: "regions_lib.1910", // Maputo Province
                shortcode: "1910",
            },
            {
                name: "regions_lib.1909", // Niassa Province
                shortcode: "1909",
            },
            {
                name: "regions_lib.1914", // Província de Zambézia
                shortcode: "1914",
            },
            {
                name: "regions_lib.1911", // Sofala Province
                shortcode: "1911",
            },
            {
                name: "regions_lib.1915", // Нампула
                shortcode: "1915",
            },
            {
                name: "regions_lib.1913", // Тете
                shortcode: "1913",
            },
        ]
    },
    {
        shortcode: "MD", // Молдова
        name: "regions_lib.MD",
        regions: [
            {
                name: "regions_lib.1950", // Găgăuzia
                shortcode: "1950",
            },
            {
                name: "regions_lib.1949", // Municipiul Bălţi
                shortcode: "1949",
            },
            {
                name: "regions_lib.1932", // Raionul Călăraşi
                shortcode: "1932",
            },
            {
                name: "regions_lib.1940", // Raionul Căuşeni
                shortcode: "1940",
            },
            {
                name: "regions_lib.1917", // Raionul Dubăsari
                shortcode: "1917",
            },
            {
                name: "regions_lib.1951", // Unitatea Teritorială din Stînga Nistrului
                shortcode: "1951",
            },
            {
                name: "regions_lib.1942", // Бендеры
                shortcode: "1942",
            },
            {
                name: "regions_lib.1916", // Бессарабский район
                shortcode: "1916",
            },
            {
                name: "regions_lib.1933", // Бричанский район
                shortcode: "1933",
            },
            {
                name: "regions_lib.1925", // Глодянский район
                shortcode: "1925",
            },
            {
                name: "regions_lib.1920", // Дондюшанский район
                shortcode: "1920",
            },
            {
                name: "regions_lib.1939", // Дрокиевский район
                shortcode: "1939",
            },
            {
                name: "regions_lib.1935", // Единецкий район
                shortcode: "1935",
            },
            {
                name: "regions_lib.1947", // Кагульский район
                shortcode: "1947",
            },
            {
                name: "regions_lib.1924", // Кантемирский район
                shortcode: "1924",
            },
            {
                name: "regions_lib.1952", // Кишинёв
                shortcode: "1952",
            },
            {
                name: "regions_lib.1931", // Криулянский район
                shortcode: "1931",
            },
            {
                name: "regions_lib.1922", // Леовский район
                shortcode: "1922",
            },
            {
                name: "regions_lib.1927", // Ниспоренский район
                shortcode: "1927",
            },
            {
                name: "regions_lib.1934", // Новоаненский район
                shortcode: "1934",
            },
            {
                name: "regions_lib.1923", // Окницкий район
                shortcode: "1923",
            },
            {
                name: "regions_lib.1948", // Оргеевский район
                shortcode: "1948",
            },
            {
                name: "regions_lib.1921", // Резинский район
                shortcode: "1921",
            },
            {
                name: "regions_lib.1928", // Рышканский район
                shortcode: "1928",
            },
            {
                name: "regions_lib.1944", // Сорокский район
                shortcode: "1944",
            },
            {
                name: "regions_lib.1941", // Страшенский район
                shortcode: "1941",
            },
            {
                name: "regions_lib.1930", // Суворовский Район
                shortcode: "1930",
            },
            {
                name: "regions_lib.1936", // Сынжерейский район
                shortcode: "1936",
            },
            {
                name: "regions_lib.1919", // Тараклийский район
                shortcode: "1919",
            },
            {
                name: "regions_lib.1929", // Теленештский район
                shortcode: "1929",
            },
            {
                name: "regions_lib.1945", // Унгенский район
                shortcode: "1945",
            },
            {
                name: "regions_lib.1938", // Фалештский район
                shortcode: "1938",
            },
            {
                name: "regions_lib.1937", // Флорештский район
                shortcode: "1937",
            },
            {
                name: "regions_lib.1946", // Хынчештский район
                shortcode: "1946",
            },
            {
                name: "regions_lib.1926", // Чимишлийский район
                shortcode: "1926",
            },
            {
                name: "regions_lib.1918", // Шолданештский район
                shortcode: "1918",
            },
            {
                name: "regions_lib.1943", // Яловенский район
                shortcode: "1943",
            },
        ]
    },
    {
        shortcode: "MC", // Монако
        name: "regions_lib.MC",
        regions: [
            {
                name: "regions_lib.1953", // Commune de Monaco
                shortcode: "1953",
            },
        ]
    },
    {
        shortcode: "MN", // Монголия
        name: "regions_lib.MN",
        regions: [
            {
                name: "regions_lib.1968", // Arhangay Aymag
                shortcode: "1968",
            },
            {
                name: "regions_lib.1971", // Bayan-Ölgiy Aymag
                shortcode: "1971",
            },
            {
                name: "regions_lib.1958", // Bulgan
                shortcode: "1958",
            },
            {
                name: "regions_lib.1969", // Darhan-Uul Aymag
                shortcode: "1969",
            },
            {
                name: "regions_lib.1963", // Dornod Aymag
                shortcode: "1963",
            },
            {
                name: "regions_lib.1955", // Dundgovĭ Aymag
                shortcode: "1955",
            },
            {
                name: "regions_lib.1961", // Dzavhan Aymag
                shortcode: "1961",
            },
            {
                name: "regions_lib.1960", // East Gobi Aymag
                shortcode: "1960",
            },
            {
                name: "regions_lib.1956", // Govĭ-Altay Aymag
                shortcode: "1956",
            },
            {
                name: "regions_lib.1954", // Govĭ-Sumber
                shortcode: "1954",
            },
            {
                name: "regions_lib.1962", // Hentiy Aymag
                shortcode: "1962",
            },
            {
                name: "regions_lib.1966", // Hovd
                shortcode: "1966",
            },
            {
                name: "regions_lib.1974", // Kosogol’skiy Aymak
                shortcode: "1974",
            },
            {
                name: "regions_lib.1970", // Orhon Aymag
                shortcode: "1970",
            },
            {
                name: "regions_lib.1972", // Selenge Aymag
                shortcode: "1972",
            },
            {
                name: "regions_lib.1957", // Sühbaatar Aymag
                shortcode: "1957",
            },
            {
                name: "regions_lib.1967", // Tsentral’nyy Aymag
                shortcode: "1967",
            },
            {
                name: "regions_lib.1975", // Ulaanbaatar Hot
                shortcode: "1975",
            },
            {
                name: "regions_lib.1964", // Uvs Aymag
                shortcode: "1964",
            },
            {
                name: "regions_lib.1959", // Ömnögovĭ
                shortcode: "1959",
            },
            {
                name: "regions_lib.1973", // Övörhangay
                shortcode: "1973",
            },
            {
                name: "regions_lib.1965", // Баян-хонгор
                shortcode: "1965",
            },
        ]
    },
    {
        shortcode: "MS", // Монсеррат
        name: "regions_lib.MS",
        regions: [
            {
                name: "regions_lib.1976", // Parish of Saint Anthony
                shortcode: "1976",
            },
            {
                name: "regions_lib.1977", // Parish of Saint Georges
                shortcode: "1977",
            },
            {
                name: "regions_lib.1978", // Parish of Saint Peter
                shortcode: "1978",
            },
        ]
    },
    {
        shortcode: "MM", // Мьянма (Бирма)
        name: "regions_lib.MM",
        regions: [
            {
                name: "regions_lib.1992", // Ayeyarwady Region
                shortcode: "1992",
            },
            {
                name: "regions_lib.1989", // Bago Region
                shortcode: "1989",
            },
            {
                name: "regions_lib.1980", // Chin State
                shortcode: "1980",
            },
            {
                name: "regions_lib.1984", // Kachin State
                shortcode: "1984",
            },
            {
                name: "regions_lib.1979", // Kayah State
                shortcode: "1979",
            },
            {
                name: "regions_lib.1983", // Kayin State
                shortcode: "1983",
            },
            {
                name: "regions_lib.1987", // Magway Region
                shortcode: "1987",
            },
            {
                name: "regions_lib.1991", // Mandalay Region
                shortcode: "1991",
            },
            {
                name: "regions_lib.1985", // Mon State
                shortcode: "1985",
            },
            {
                name: "regions_lib.1981", // Nay Pyi Taw
                shortcode: "1981",
            },
            {
                name: "regions_lib.1986", // Rakhine State
                shortcode: "1986",
            },
            {
                name: "regions_lib.1988", // Sagaing Region
                shortcode: "1988",
            },
            {
                name: "regions_lib.1990", // Shan State
                shortcode: "1990",
            },
            {
                name: "regions_lib.1982", // Tanintharyi Region
                shortcode: "1982",
            },
            {
                name: "regions_lib.1993", // Yangon
                shortcode: "1993",
            },
        ]
    },
    {
        shortcode: "NA", // Намибия
        name: "regions_lib.NA",
        regions: [
            {
                name: "regions_lib.2000", // Erongo
                shortcode: "2000",
            },
            {
                name: "regions_lib.1994", // Hardap
                shortcode: "1994",
            },
            {
                name: "regions_lib.1995", // Karas
                shortcode: "1995",
            },
            {
                name: "regions_lib.2002", // Kavango East Region
                shortcode: "2002",
            },
            {
                name: "regions_lib.1998", // Kavango West Region
                shortcode: "1998",
            },
            {
                name: "regions_lib.1997", // Kunene
                shortcode: "1997",
            },
            {
                name: "regions_lib.2001", // Otjozondjupa
                shortcode: "2001",
            },
            {
                name: "regions_lib.1999", // Zambezi Region
                shortcode: "1999",
            },
            {
                name: "regions_lib.2007", // Кхомас
                shortcode: "2007",
            },
            {
                name: "regions_lib.1996", // Омахеке
                shortcode: "1996",
            },
            {
                name: "regions_lib.2006", // Омусати
                shortcode: "2006",
            },
            {
                name: "regions_lib.2005", // Охангвена
                shortcode: "2005",
            },
            {
                name: "regions_lib.2004", // Ошана
                shortcode: "2004",
            },
            {
                name: "regions_lib.2003", // Ошикото
                shortcode: "2003",
            },
        ]
    },
    {
        shortcode: "NR", // Науру
        name: "regions_lib.NR",
        regions: [
            {
                name: "regions_lib.2019", // Aiwo District
                shortcode: "2019",
            },
            {
                name: "regions_lib.2010", // Anabar District
                shortcode: "2010",
            },
            {
                name: "regions_lib.2015", // Anetan District
                shortcode: "2015",
            },
            {
                name: "regions_lib.2009", // Anibare District
                shortcode: "2009",
            },
            {
                name: "regions_lib.2014", // Baiti District
                shortcode: "2014",
            },
            {
                name: "regions_lib.2016", // Boe District
                shortcode: "2016",
            },
            {
                name: "regions_lib.2017", // Buada District
                shortcode: "2017",
            },
            {
                name: "regions_lib.2021", // Denigomodu District
                shortcode: "2021",
            },
            {
                name: "regions_lib.2012", // Ewa District
                shortcode: "2012",
            },
            {
                name: "regions_lib.2008", // Ijuw District
                shortcode: "2008",
            },
            {
                name: "regions_lib.2020", // Meneng District
                shortcode: "2020",
            },
            {
                name: "regions_lib.2013", // Nibok District
                shortcode: "2013",
            },
            {
                name: "regions_lib.2011", // Uaboe District
                shortcode: "2011",
            },
            {
                name: "regions_lib.2018", // Ярен
                shortcode: "2018",
            },
        ]
    },
    {
        shortcode: "NP", // Непал
        name: "regions_lib.NP",
        regions: [
            {
                name: "regions_lib.2027", // Bagmati Province
                shortcode: "2027",
            },
            {
                name: "regions_lib.2023", // Gandaki Pradesh
                shortcode: "2023",
            },
            {
                name: "regions_lib.2022", // Karnali Pradesh
                shortcode: "2022",
            },
            {
                name: "regions_lib.2026", // Lumbini Province
                shortcode: "2026",
            },
            {
                name: "regions_lib.2025", // Province 1
                shortcode: "2025",
            },
            {
                name: "regions_lib.2028", // Province 2
                shortcode: "2028",
            },
            {
                name: "regions_lib.2024", // Sudurpashchim Pradesh
                shortcode: "2024",
            },
        ]
    },
    {
        shortcode: "NE", // Нигер
        name: "regions_lib.NE",
        regions: [
            {
                name: "regions_lib.2032", // Dosso Region
                shortcode: "2032",
            },
            {
                name: "regions_lib.2036", // Maradi
                shortcode: "2036",
            },
            {
                name: "regions_lib.2031", // Niamey
                shortcode: "2031",
            },
            {
                name: "regions_lib.2034", // Tahoua
                shortcode: "2034",
            },
            {
                name: "regions_lib.2033", // Tillaberi Region
                shortcode: "2033",
            },
            {
                name: "regions_lib.2035", // Zinder
                shortcode: "2035",
            },
            {
                name: "regions_lib.2030", // Агадес
                shortcode: "2030",
            },
            {
                name: "regions_lib.2029", // Диффа
                shortcode: "2029",
            },
        ]
    },
    {
        shortcode: "NG", // Нигерия
        name: "regions_lib.NG",
        regions: [
            {
                name: "regions_lib.2059", // Abia State
                shortcode: "2059",
            },
            {
                name: "regions_lib.2052", // Adamawa
                shortcode: "2052",
            },
            {
                name: "regions_lib.2064", // Akwa Ibom State
                shortcode: "2064",
            },
            {
                name: "regions_lib.2061", // Anambra
                shortcode: "2061",
            },
            {
                name: "regions_lib.2067", // Bauchi
                shortcode: "2067",
            },
            {
                name: "regions_lib.2039", // Bayelsa State
                shortcode: "2039",
            },
            {
                name: "regions_lib.2063", // Benue State
                shortcode: "2063",
            },
            {
                name: "regions_lib.2065", // Borno State
                shortcode: "2065",
            },
            {
                name: "regions_lib.2053", // Cross River State
                shortcode: "2053",
            },
            {
                name: "regions_lib.2058", // Delta
                shortcode: "2058",
            },
            {
                name: "regions_lib.2047", // Ebonyi State
                shortcode: "2047",
            },
            {
                name: "regions_lib.2048", // Edo
                shortcode: "2048",
            },
            {
                name: "regions_lib.2044", // Ekiti State
                shortcode: "2044",
            },
            {
                name: "regions_lib.2046", // Enugu State
                shortcode: "2046",
            },
            {
                name: "regions_lib.2041", // Gombe State
                shortcode: "2041",
            },
            {
                name: "regions_lib.2062", // Imo State
                shortcode: "2062",
            },
            {
                name: "regions_lib.2045", // Jigawa State
                shortcode: "2045",
            },
            {
                name: "regions_lib.2070", // Kaduna State
                shortcode: "2070",
            },
            {
                name: "regions_lib.2072", // Kano State
                shortcode: "2072",
            },
            {
                name: "regions_lib.2066", // Katsina State
                shortcode: "2066",
            },
            {
                name: "regions_lib.2051", // Kebbi State
                shortcode: "2051",
            },
            {
                name: "regions_lib.2050", // Kogi State
                shortcode: "2050",
            },
            {
                name: "regions_lib.2043", // Kwara State
                shortcode: "2043",
            },
            {
                name: "regions_lib.2073", // Lagos State
                shortcode: "2073",
            },
            {
                name: "regions_lib.2040", // Nasarawa State
                shortcode: "2040",
            },
            {
                name: "regions_lib.2056", // Niger State
                shortcode: "2056",
            },
            {
                name: "regions_lib.2055", // Ogun State
                shortcode: "2055",
            },
            {
                name: "regions_lib.2054", // Ondo State
                shortcode: "2054",
            },
            {
                name: "regions_lib.2057", // Osun State
                shortcode: "2057",
            },
            {
                name: "regions_lib.2068", // Oyo State
                shortcode: "2068",
            },
            {
                name: "regions_lib.2049", // Plateau State
                shortcode: "2049",
            },
            {
                name: "regions_lib.2069", // Rivers State
                shortcode: "2069",
            },
            {
                name: "regions_lib.2060", // Sokoto State
                shortcode: "2060",
            },
            {
                name: "regions_lib.2038", // Taraba State
                shortcode: "2038",
            },
            {
                name: "regions_lib.2042", // Yobe State
                shortcode: "2042",
            },
            {
                name: "regions_lib.2071", // Zamfara State
                shortcode: "2071",
            },
            {
                name: "regions_lib.2037", // Федеральная столичная территория
                shortcode: "2037",
            },
        ]
    },
    {
        shortcode: "NL", // Нидерланды
        name: "regions_lib.NL",
        regions: [
            {
                name: "regions_lib.2082", // Гелдерланд
                shortcode: "2082",
            },
            {
                name: "regions_lib.2077", // Гронинген
                shortcode: "2077",
            },
            {
                name: "regions_lib.2076", // Дренте
                shortcode: "2076",
            },
            {
                name: "regions_lib.2074", // Зеландия
                shortcode: "2074",
            },
            {
                name: "regions_lib.2079", // Лимбург
                shortcode: "2079",
            },
            {
                name: "regions_lib.2080", // Оверайссел
                shortcode: "2080",
            },
            {
                name: "regions_lib.2084", // Северная Голландия
                shortcode: "2084",
            },
            {
                name: "regions_lib.2083", // Северный Брабант
                shortcode: "2083",
            },
            {
                name: "regions_lib.2081", // Утрехт
                shortcode: "2081",
            },
            {
                name: "regions_lib.2075", // Флеволанд
                shortcode: "2075",
            },
            {
                name: "regions_lib.2078", // Фрисланд
                shortcode: "2078",
            },
            {
                name: "regions_lib.2085", // Южная Голландия
                shortcode: "2085",
            },
        ]
    },
    {
        shortcode: "NI", // Никарагуа
        name: "regions_lib.NI",
        regions: [
            {
                name: "regions_lib.2100", // Costa Caribe Norte
                shortcode: "2100",
            },
            {
                name: "regions_lib.2096", // Costa Caribe Sur
                shortcode: "2096",
            },
            {
                name: "regions_lib.2089", // Departamento de Boaco
                shortcode: "2089",
            },
            {
                name: "regions_lib.2091", // Departamento de Carazo
                shortcode: "2091",
            },
            {
                name: "regions_lib.2098", // Departamento de Chinandega
                shortcode: "2098",
            },
            {
                name: "regions_lib.2090", // Departamento de Chontales
                shortcode: "2090",
            },
            {
                name: "regions_lib.2093", // Departamento de Estelí
                shortcode: "2093",
            },
            {
                name: "regions_lib.2092", // Departamento de Granada
                shortcode: "2092",
            },
            {
                name: "regions_lib.2099", // Departamento de Jinotega
                shortcode: "2099",
            },
            {
                name: "regions_lib.2097", // Departamento de León
                shortcode: "2097",
            },
            {
                name: "regions_lib.2087", // Departamento de Madriz
                shortcode: "2087",
            },
            {
                name: "regions_lib.2102", // Departamento de Managua
                shortcode: "2102",
            },
            {
                name: "regions_lib.2095", // Departamento de Masaya
                shortcode: "2095",
            },
            {
                name: "regions_lib.2101", // Departamento de Matagalpa
                shortcode: "2101",
            },
            {
                name: "regions_lib.2094", // Departamento de Nueva Segovia
                shortcode: "2094",
            },
            {
                name: "regions_lib.2088", // Departamento de Rivas
                shortcode: "2088",
            },
            {
                name: "regions_lib.2086", // Departamento de Río San Juan
                shortcode: "2086",
            },
        ]
    },
    {
        shortcode: "NU", // Ниуе
        name: "regions_lib.NU",
        regions: [
        ]
    },
    {
        shortcode: "NZ", // Новая Зеландия
        name: "regions_lib.NZ",
        regions: [
            {
                name: "regions_lib.2103", // Chatham Islands
                shortcode: "2103",
            },
            {
                name: "regions_lib.2111", // Hawke&quot;s Bay
                shortcode: "2111",
            },
            {
                name: "regions_lib.2114", // Manawatu-Wanganui
                shortcode: "2114",
            },
            {
                name: "regions_lib.2107", // Nelson
                shortcode: "2107",
            },
            {
                name: "regions_lib.2110", // Taranaki
                shortcode: "2110",
            },
            {
                name: "regions_lib.2108", // Tasman
                shortcode: "2108",
            },
            {
                name: "regions_lib.2116", // Waikato
                shortcode: "2116",
            },
            {
                name: "regions_lib.2115", // Бей-оф-Пленти
                shortcode: "2115",
            },
            {
                name: "regions_lib.2117", // Веллингтон
                shortcode: "2117",
            },
            {
                name: "regions_lib.2106", // Гисборн
                shortcode: "2106",
            },
            {
                name: "regions_lib.2118", // Кентербери
                shortcode: "2118",
            },
            {
                name: "regions_lib.2105", // Марлборо
                shortcode: "2105",
            },
            {
                name: "regions_lib.2112", // Нортленд
                shortcode: "2112",
            },
            {
                name: "regions_lib.2119", // Окленд
                shortcode: "2119",
            },
            {
                name: "regions_lib.2113", // Отаго
                shortcode: "2113",
            },
            {
                name: "regions_lib.2109", // Саутленд
                shortcode: "2109",
            },
            {
                name: "regions_lib.2104", // Уэст-Кост
                shortcode: "2104",
            },
        ]
    },
    {
        shortcode: "NC", // Новая Каледония
        name: "regions_lib.NC",
        regions: [
            {
                name: "regions_lib.2120", // Loyalty Islands
                shortcode: "2120",
            },
            {
                name: "regions_lib.2121", // Province Nord
                shortcode: "2121",
            },
            {
                name: "regions_lib.2122", // Province Sud
                shortcode: "2122",
            },
        ]
    },
    {
        shortcode: "NO", // Норвегия
        name: "regions_lib.NO",
        regions: [
            {
                name: "regions_lib.2126", // Agder
                shortcode: "2126",
            },
            {
                name: "regions_lib.2127", // Innlandet
                shortcode: "2127",
            },
            {
                name: "regions_lib.2125", // Møre og Romsdal fylke
                shortcode: "2125",
            },
            {
                name: "regions_lib.2123", // Nordland Fylke
                shortcode: "2123",
            },
            {
                name: "regions_lib.2132", // Oslo County
                shortcode: "2132",
            },
            {
                name: "regions_lib.2130", // Rogaland Fylke
                shortcode: "2130",
            },
            {
                name: "regions_lib.2124", // Troms og Finnmark
                shortcode: "2124",
            },
            {
                name: "regions_lib.2129", // Trøndelag
                shortcode: "2129",
            },
            {
                name: "regions_lib.2128", // Vestfold og Telemark
                shortcode: "2128",
            },
            {
                name: "regions_lib.2131", // Vestland
                shortcode: "2131",
            },
            {
                name: "regions_lib.2133", // Viken
                shortcode: "2133",
            },
        ]
    },
    {
        shortcode: "AE", // ОАЭ
        name: "regions_lib.AE",
        regions: [
            {
                name: "regions_lib.2137", // Ajman
                shortcode: "2137",
            },
            {
                name: "regions_lib.2139", // Ash Shāriqah
                shortcode: "2139",
            },
            {
                name: "regions_lib.2140", // Dubai
                shortcode: "2140",
            },
            {
                name: "regions_lib.2135", // Fujairah Emirate
                shortcode: "2135",
            },
            {
                name: "regions_lib.2136", // Imārat Ra’s al Khaymah
                shortcode: "2136",
            },
            {
                name: "regions_lib.2134", // Imārat Umm al Qaywayn
                shortcode: "2134",
            },
            {
                name: "regions_lib.2138", // Абу-Даби
                shortcode: "2138",
            },
        ]
    },
    {
        shortcode: "OM", // Оман
        name: "regions_lib.OM",
        regions: [
            {
                name: "regions_lib.2150", // Al Batinah North Governorate
                shortcode: "2150",
            },
            {
                name: "regions_lib.2148", // Al Batinah South Governorate
                shortcode: "2148",
            },
            {
                name: "regions_lib.2145", // Ash Sharqiyah North Governorate
                shortcode: "2145",
            },
            {
                name: "regions_lib.2146", // Ash Sharqiyah South Governorate
                shortcode: "2146",
            },
            {
                name: "regions_lib.2144", // Az̧ Z̧āhirah
                shortcode: "2144",
            },
            {
                name: "regions_lib.2147", // Dhofar Governorate
                shortcode: "2147",
            },
            {
                name: "regions_lib.2141", // Musandam
                shortcode: "2141",
            },
            {
                name: "regions_lib.2151", // Muḩāfaz̧at Masqaţ
                shortcode: "2151",
            },
            {
                name: "regions_lib.2149", // Muḩāfaz̧at ad Dākhilīyah
                shortcode: "2149",
            },
            {
                name: "regions_lib.2143", // Muḩāfaz̧at al Buraymī
                shortcode: "2143",
            },
            {
                name: "regions_lib.2142", // Muḩāfaz̧at al Wusţá
                shortcode: "2142",
            },
        ]
    },
    {
        shortcode: "BL", // Остров Святого Бартоломея
        name: "regions_lib.BL",
        regions: [
        ]
    },
    {
        shortcode: "MF", // Остров Святого Мартина
        name: "regions_lib.MF",
        regions: [
        ]
    },
    {
        shortcode: "CK", // Острова Кука
        name: "regions_lib.CK",
        regions: [
            {
                name: "regions_lib.2161", // Aitutaki
                shortcode: "2161",
            },
            {
                name: "regions_lib.2156", // Atiu
                shortcode: "2156",
            },
            {
                name: "regions_lib.2155", // Ma&quot;uke
                shortcode: "2155",
            },
            {
                name: "regions_lib.2160", // Mangaia
                shortcode: "2160",
            },
            {
                name: "regions_lib.2158", // Manihiki
                shortcode: "2158",
            },
            {
                name: "regions_lib.2154", // Mitiaro
                shortcode: "2154",
            },
            {
                name: "regions_lib.2152", // Palmerston
                shortcode: "2152",
            },
            {
                name: "regions_lib.2157", // Penrhyn
                shortcode: "2157",
            },
            {
                name: "regions_lib.2159", // Pukapuka
                shortcode: "2159",
            },
            {
                name: "regions_lib.2153", // Rakahanga
                shortcode: "2153",
            },
            {
                name: "regions_lib.2162", // Rarotonga
                shortcode: "2162",
            },
        ]
    },
    {
        shortcode: "PK", // Пакистан
        name: "regions_lib.PK",
        regions: [
            {
                name: "regions_lib.2166", // Balochistan
                shortcode: "2166",
            },
            {
                name: "regions_lib.2163", // Gilgit-Baltistan
                shortcode: "2163",
            },
            {
                name: "regions_lib.2164", // Islamabad
                shortcode: "2164",
            },
            {
                name: "regions_lib.2167", // Khyber Pakhtunkhwa
                shortcode: "2167",
            },
            {
                name: "regions_lib.2165", // Азад Кашмир
                shortcode: "2165",
            },
            {
                name: "regions_lib.2169", // Пенджаб
                shortcode: "2169",
            },
            {
                name: "regions_lib.2168", // Синд
                shortcode: "2168",
            },
        ]
    },
    {
        shortcode: "PW", // Палау
        name: "regions_lib.PW",
        regions: [
            {
                name: "regions_lib.2170", // Hatohobei
                shortcode: "2170",
            },
            {
                name: "regions_lib.2175", // State of Aimeliik
                shortcode: "2175",
            },
            {
                name: "regions_lib.2184", // State of Airai
                shortcode: "2184",
            },
            {
                name: "regions_lib.2178", // State of Angaur
                shortcode: "2178",
            },
            {
                name: "regions_lib.2173", // State of Kayangel
                shortcode: "2173",
            },
            {
                name: "regions_lib.2185", // State of Koror
                shortcode: "2185",
            },
            {
                name: "regions_lib.2179", // State of Melekeok
                shortcode: "2179",
            },
            {
                name: "regions_lib.2182", // State of Ngaraard
                shortcode: "2182",
            },
            {
                name: "regions_lib.2181", // State of Ngarchelong
                shortcode: "2181",
            },
            {
                name: "regions_lib.2172", // State of Ngardmau
                shortcode: "2172",
            },
            {
                name: "regions_lib.2180", // State of Ngatpang
                shortcode: "2180",
            },
            {
                name: "regions_lib.2176", // State of Ngchesar
                shortcode: "2176",
            },
            {
                name: "regions_lib.2177", // State of Ngeremlengui
                shortcode: "2177",
            },
            {
                name: "regions_lib.2174", // State of Ngiwal
                shortcode: "2174",
            },
            {
                name: "regions_lib.2183", // State of Peleliu
                shortcode: "2183",
            },
            {
                name: "regions_lib.2171", // State of Sonsorol
                shortcode: "2171",
            },
        ]
    },
    {
        shortcode: "PS", // Палестина
        name: "regions_lib.PS",
        regions: [
            {
                name: "regions_lib.2187", // West Bank
                shortcode: "2187",
            },
            {
                name: "regions_lib.2186", // Сектор Газа
                shortcode: "2186",
            },
        ]
    },
    {
        shortcode: "PA", // Панама
        name: "regions_lib.PA",
        regions: [
            {
                name: "regions_lib.2188", // Naso Tjër Di
                shortcode: "2188",
            },
            {
                name: "regions_lib.2195", // Ngöbe-Buglé
                shortcode: "2195",
            },
            {
                name: "regions_lib.2200", // Panamá Oeste
                shortcode: "2200",
            },
            {
                name: "regions_lib.2194", // Provincia de Bocas del Toro
                shortcode: "2194",
            },
            {
                name: "regions_lib.2199", // Provincia de Chiriquí
                shortcode: "2199",
            },
            {
                name: "regions_lib.2197", // Provincia de Coclé
                shortcode: "2197",
            },
            {
                name: "regions_lib.2198", // Provincia de Colón
                shortcode: "2198",
            },
            {
                name: "regions_lib.2193", // Provincia de Herrera
                shortcode: "2193",
            },
            {
                name: "regions_lib.2192", // Provincia de Los Santos
                shortcode: "2192",
            },
            {
                name: "regions_lib.2201", // Provincia de Panamá
                shortcode: "2201",
            },
            {
                name: "regions_lib.2196", // Provincia de Veraguas
                shortcode: "2196",
            },
            {
                name: "regions_lib.2191", // Provincia del Darién
                shortcode: "2191",
            },
            {
                name: "regions_lib.2190", // Гуна-Яла
                shortcode: "2190",
            },
            {
                name: "regions_lib.2189", // Эмбера-Воунаан
                shortcode: "2189",
            },
        ]
    },
    {
        shortcode: "PG", // Папуа — Новая Гвинея
        name: "regions_lib.PG",
        regions: [
            {
                name: "regions_lib.2203", // Bougainville
                shortcode: "2203",
            },
            {
                name: "regions_lib.2205", // Central Province
                shortcode: "2205",
            },
            {
                name: "regions_lib.2213", // Chimbu Province
                shortcode: "2213",
            },
            {
                name: "regions_lib.2219", // East Sepik Province
                shortcode: "2219",
            },
            {
                name: "regions_lib.2222", // Eastern Highlands Province
                shortcode: "2222",
            },
            {
                name: "regions_lib.2218", // Enga Province
                shortcode: "2218",
            },
            {
                name: "regions_lib.2204", // Gulf Province
                shortcode: "2204",
            },
            {
                name: "regions_lib.2216", // Hela
                shortcode: "2216",
            },
            {
                name: "regions_lib.2206", // Jiwaka
                shortcode: "2206",
            },
            {
                name: "regions_lib.2220", // Madang Province
                shortcode: "2220",
            },
            {
                name: "regions_lib.2202", // Manus Province
                shortcode: "2202",
            },
            {
                name: "regions_lib.2212", // Milne Bay Province
                shortcode: "2212",
            },
            {
                name: "regions_lib.2223", // Morobe Province
                shortcode: "2223",
            },
            {
                name: "regions_lib.2214", // National Capital District
                shortcode: "2214",
            },
            {
                name: "regions_lib.2209", // New Ireland Province
                shortcode: "2209",
            },
            {
                name: "regions_lib.2208", // Northern Province
                shortcode: "2208",
            },
            {
                name: "regions_lib.2221", // Southern Highlands Province
                shortcode: "2221",
            },
            {
                name: "regions_lib.2207", // West Sepik Province
                shortcode: "2207",
            },
            {
                name: "regions_lib.2217", // Western Highlands Province
                shortcode: "2217",
            },
            {
                name: "regions_lib.2210", // Western Province
                shortcode: "2210",
            },
            {
                name: "regions_lib.2215", // Восточная Новая Британия
                shortcode: "2215",
            },
            {
                name: "regions_lib.2211", // Западная Новая Британия
                shortcode: "2211",
            },
        ]
    },
    {
        shortcode: "PY", // Парагвай
        name: "regions_lib.PY",
        regions: [
            {
                name: "regions_lib.2239", // Asunción
                shortcode: "2239",
            },
            {
                name: "regions_lib.2241", // Departamento Central
                shortcode: "2241",
            },
            {
                name: "regions_lib.2224", // Departamento de Alto Paraguay
                shortcode: "2224",
            },
            {
                name: "regions_lib.2225", // Departamento de Boquerón
                shortcode: "2225",
            },
            {
                name: "regions_lib.2237", // Departamento de Caaguazú
                shortcode: "2237",
            },
            {
                name: "regions_lib.2230", // Departamento de Caazapá
                shortcode: "2230",
            },
            {
                name: "regions_lib.2231", // Departamento de Canindeyú
                shortcode: "2231",
            },
            {
                name: "regions_lib.2233", // Departamento de Concepción
                shortcode: "2233",
            },
            {
                name: "regions_lib.2238", // Departamento de Itapúa
                shortcode: "2238",
            },
            {
                name: "regions_lib.2228", // Departamento de Misiones
                shortcode: "2228",
            },
            {
                name: "regions_lib.2234", // Departamento de Paraguarí
                shortcode: "2234",
            },
            {
                name: "regions_lib.2227", // Departamento de Presidente Hayes
                shortcode: "2227",
            },
            {
                name: "regions_lib.2236", // Departamento de San Pedro
                shortcode: "2236",
            },
            {
                name: "regions_lib.2240", // Departamento del Alto Paraná
                shortcode: "2240",
            },
            {
                name: "regions_lib.2229", // Departamento del Amambay
                shortcode: "2229",
            },
            {
                name: "regions_lib.2232", // Departamento del Guairá
                shortcode: "2232",
            },
            {
                name: "regions_lib.2235", // Departamento de la Cordillera
                shortcode: "2235",
            },
            {
                name: "regions_lib.2226", // Departamento de Ñeembucú
                shortcode: "2226",
            },
        ]
    },
    {
        shortcode: "PE", // Перу
        name: "regions_lib.PE",
        regions: [
            {
                name: "regions_lib.2262", // Cajamarca
                shortcode: "2262",
            },
            {
                name: "regions_lib.2255", // Callao
                shortcode: "2255",
            },
            {
                name: "regions_lib.2259", // Cusco
                shortcode: "2259",
            },
            {
                name: "regions_lib.2243", // Departamento de Moquegua
                shortcode: "2243",
            },
            {
                name: "regions_lib.2266", // Provincia de Lima
                shortcode: "2266",
            },
            {
                name: "regions_lib.2247", // Región de Apurímac
                shortcode: "2247",
            },
            {
                name: "regions_lib.2253", // Región de Huánuco
                shortcode: "2253",
            },
            {
                name: "regions_lib.2254", // Región de San Martín
                shortcode: "2254",
            },
            {
                name: "regions_lib.2249", // Амасонас
                shortcode: "2249",
            },
            {
                name: "regions_lib.2257", // Анкаш
                shortcode: "2257",
            },
            {
                name: "regions_lib.2260", // Арекипа
                shortcode: "2260",
            },
            {
                name: "regions_lib.2251", // Аякучо
                shortcode: "2251",
            },
            {
                name: "regions_lib.2252", // Ика
                shortcode: "2252",
            },
            {
                name: "regions_lib.2264", // Ла-Либертад
                shortcode: "2264",
            },
            {
                name: "regions_lib.2258", // Ламбаеке
                shortcode: "2258",
            },
            {
                name: "regions_lib.2267", // Лима
                shortcode: "2267",
            },
            {
                name: "regions_lib.2256", // Лорето
                shortcode: "2256",
            },
            {
                name: "regions_lib.2242", // Мадре-де-Дьос
                shortcode: "2242",
            },
            {
                name: "regions_lib.2246", // Паско
                shortcode: "2246",
            },
            {
                name: "regions_lib.2263", // Пуно
                shortcode: "2263",
            },
            {
                name: "regions_lib.2265", // Пьюра
                shortcode: "2265",
            },
            {
                name: "regions_lib.2245", // Такна
                shortcode: "2245",
            },
            {
                name: "regions_lib.2244", // Тумбес
                shortcode: "2244",
            },
            {
                name: "regions_lib.2250", // Уанкавелика
                shortcode: "2250",
            },
            {
                name: "regions_lib.2248", // Укаяли
                shortcode: "2248",
            },
            {
                name: "regions_lib.2261", // Хунин
                shortcode: "2261",
            },
        ]
    },
    {
        shortcode: "PL", // Польша
        name: "regions_lib.PL",
        regions: [
            {
                name: "regions_lib.2272", // Варминско-Мазурское воеводство
                shortcode: "2272",
            },
            {
                name: "regions_lib.2281", // Великопольское воеводство
                shortcode: "2281",
            },
            {
                name: "regions_lib.2273", // Западнопоморское воеводство
                shortcode: "2273",
            },
            {
                name: "regions_lib.2274", // Куявско-Поморское воеводство
                shortcode: "2274",
            },
            {
                name: "regions_lib.2278", // Лодзинское воеводство
                shortcode: "2278",
            },
            {
                name: "regions_lib.2275", // Люблинское воеводство
                shortcode: "2275",
            },
            {
                name: "regions_lib.2269", // Любуское воеводство
                shortcode: "2269",
            },
            {
                name: "regions_lib.2283", // Мазовецкое воеводство
                shortcode: "2283",
            },
            {
                name: "regions_lib.2280", // Малопольское воеводство
                shortcode: "2280",
            },
            {
                name: "regions_lib.2279", // Нижнесилезское воеводство
                shortcode: "2279",
            },
            {
                name: "regions_lib.2268", // Опольское воеводство
                shortcode: "2268",
            },
            {
                name: "regions_lib.2276", // Подкарпатское воеводство
                shortcode: "2276",
            },
            {
                name: "regions_lib.2270", // Подляское воеводство
                shortcode: "2270",
            },
            {
                name: "regions_lib.2277", // Поморское воеводство
                shortcode: "2277",
            },
            {
                name: "regions_lib.2271", // Свентокшишское воеводство
                shortcode: "2271",
            },
            {
                name: "regions_lib.2282", // Силезское воеводство
                shortcode: "2282",
            },
        ]
    },
    {
        shortcode: "PT", // Португалия
        name: "regions_lib.PT",
        regions: [
            {
                name: "regions_lib.2299", // Aveiro District
                shortcode: "2299",
            },
            {
                name: "regions_lib.2291", // Azores
                shortcode: "2291",
            },
            {
                name: "regions_lib.2286", // Beja
                shortcode: "2286",
            },
            {
                name: "regions_lib.2301", // Braga
                shortcode: "2301",
            },
            {
                name: "regions_lib.2285", // Braganza District
                shortcode: "2285",
            },
            {
                name: "regions_lib.2289", // Castelo Branco
                shortcode: "2289",
            },
            {
                name: "regions_lib.2295", // Coimbra
                shortcode: "2295",
            },
            {
                name: "regions_lib.2300", // District of Setúbal
                shortcode: "2300",
            },
            {
                name: "regions_lib.2296", // Faro
                shortcode: "2296",
            },
            {
                name: "regions_lib.2297", // Leiria
                shortcode: "2297",
            },
            {
                name: "regions_lib.2292", // Madeira
                shortcode: "2292",
            },
            {
                name: "regions_lib.2284", // Portalegre
                shortcode: "2284",
            },
            {
                name: "regions_lib.2302", // Porto
                shortcode: "2302",
            },
            {
                name: "regions_lib.2298", // Santarém
                shortcode: "2298",
            },
            {
                name: "regions_lib.2293", // Viana do Castelo
                shortcode: "2293",
            },
            {
                name: "regions_lib.2290", // Vila Real
                shortcode: "2290",
            },
            {
                name: "regions_lib.2294", // Viseu
                shortcode: "2294",
            },
            {
                name: "regions_lib.2287", // Évora
                shortcode: "2287",
            },
            {
                name: "regions_lib.2288", // Гуарда
                shortcode: "2288",
            },
            {
                name: "regions_lib.2303", // Лиссабон
                shortcode: "2303",
            },
        ]
    },
    {
        shortcode: "PR", // Пуэрто-Рико
        name: "regions_lib.PR",
        regions: [
            {
                name: "regions_lib.2317", // Adjuntas
                shortcode: "2317",
            },
            {
                name: "regions_lib.2355", // Aguada
                shortcode: "2355",
            },
            {
                name: "regions_lib.2370", // Aguadilla
                shortcode: "2370",
            },
            {
                name: "regions_lib.2333", // Aguas Buenas
                shortcode: "2333",
            },
            {
                name: "regions_lib.2330", // Aibonito
                shortcode: "2330",
            },
            {
                name: "regions_lib.2375", // Arecibo
                shortcode: "2375",
            },
            {
                name: "regions_lib.2318", // Arroyo
                shortcode: "2318",
            },
            {
                name: "regions_lib.2334", // Añasco
                shortcode: "2334",
            },
            {
                name: "regions_lib.2325", // Barceloneta
                shortcode: "2325",
            },
            {
                name: "regions_lib.2336", // Barranquitas
                shortcode: "2336",
            },
            {
                name: "regions_lib.2380", // Bayamón Municipio
                shortcode: "2380",
            },
            {
                name: "regions_lib.2366", // Cabo Rojo Municipio
                shortcode: "2366",
            },
            {
                name: "regions_lib.2377", // Caguas Municipio
                shortcode: "2377",
            },
            {
                name: "regions_lib.2342", // Camuy Municipio
                shortcode: "2342",
            },
            {
                name: "regions_lib.2363", // Canóvanas Municipio
                shortcode: "2363",
            },
            {
                name: "regions_lib.2379", // Carolina Municipio
                shortcode: "2379",
            },
            {
                name: "regions_lib.2332", // Cataño Municipio
                shortcode: "2332",
            },
            {
                name: "regions_lib.2364", // Cayey Municipio
                shortcode: "2364",
            },
            {
                name: "regions_lib.2310", // Ceiba Municipio
                shortcode: "2310",
            },
            {
                name: "regions_lib.2314", // Ciales Municipio
                shortcode: "2314",
            },
            {
                name: "regions_lib.2358", // Cidra Municipio
                shortcode: "2358",
            },
            {
                name: "regions_lib.2352", // Coamo Municipio
                shortcode: "2352",
            },
            {
                name: "regions_lib.2320", // Comerío Municipio
                shortcode: "2320",
            },
            {
                name: "regions_lib.2345", // Corozal Municipio
                shortcode: "2345",
            },
            {
                name: "regions_lib.2304", // Culebra Municipio
                shortcode: "2304",
            },
            {
                name: "regions_lib.2347", // Dorado Municipio
                shortcode: "2347",
            },
            {
                name: "regions_lib.2344", // Fajardo Municipio
                shortcode: "2344",
            },
            {
                name: "regions_lib.2309", // Florida Municipio
                shortcode: "2309",
            },
            {
                name: "regions_lib.2360", // Guayama Municipio
                shortcode: "2360",
            },
            {
                name: "regions_lib.2321", // Guayanilla Municipio
                shortcode: "2321",
            },
            {
                name: "regions_lib.2376", // Guaynabo Municipio
                shortcode: "2376",
            },
            {
                name: "regions_lib.2361", // Gurabo Municipio
                shortcode: "2361",
            },
            {
                name: "regions_lib.2316", // Guánica Municipio
                shortcode: "2316",
            },
            {
                name: "regions_lib.2354", // Hatillo Municipio
                shortcode: "2354",
            },
            {
                name: "regions_lib.2313", // Hormigueros Municipio
                shortcode: "2313",
            },
            {
                name: "regions_lib.2368", // Humacao Municipio
                shortcode: "2368",
            },
            {
                name: "regions_lib.2365", // Juana Díaz Municipio
                shortcode: "2365",
            },
            {
                name: "regions_lib.2327", // Lajas Municipio
                shortcode: "2327",
            },
            {
                name: "regions_lib.2338", // Lares Municipio
                shortcode: "2338",
            },
            {
                name: "regions_lib.2307", // Las Marías Municipio
                shortcode: "2307",
            },
            {
                name: "regions_lib.2348", // Las Piedras Municipio
                shortcode: "2348",
            },
            {
                name: "regions_lib.2335", // Loíza Municipio
                shortcode: "2335",
            },
            {
                name: "regions_lib.2319", // Luquillo Municipio
                shortcode: "2319",
            },
            {
                name: "regions_lib.2359", // Manatí Municipio
                shortcode: "2359",
            },
            {
                name: "regions_lib.2305", // Maricao Municipio
                shortcode: "2305",
            },
            {
                name: "regions_lib.2308", // Maunabo Municipio
                shortcode: "2308",
            },
            {
                name: "regions_lib.2373", // Mayagüez Municipio
                shortcode: "2373",
            },
            {
                name: "regions_lib.2350", // Moca Municipio
                shortcode: "2350",
            },
            {
                name: "regions_lib.2340", // Morovis Municipio
                shortcode: "2340",
            },
            {
                name: "regions_lib.2362", // Municipio de Isabela
                shortcode: "2362",
            },
            {
                name: "regions_lib.2312", // Municipio de Jayuya
                shortcode: "2312",
            },
            {
                name: "regions_lib.2351", // Municipio de Juncos
                shortcode: "2351",
            },
            {
                name: "regions_lib.2331", // Naguabo Municipio
                shortcode: "2331",
            },
            {
                name: "regions_lib.2337", // Naranjito Municipio
                shortcode: "2337",
            },
            {
                name: "regions_lib.2323", // Orocovis Municipio
                shortcode: "2323",
            },
            {
                name: "regions_lib.2315", // Patillas Municipio
                shortcode: "2315",
            },
            {
                name: "regions_lib.2324", // Peñuelas Municipio
                shortcode: "2324",
            },
            {
                name: "regions_lib.2378", // Ponce Municipio
                shortcode: "2378",
            },
            {
                name: "regions_lib.2328", // Quebradillas Municipio
                shortcode: "2328",
            },
            {
                name: "regions_lib.2311", // Rincón Municipio
                shortcode: "2311",
            },
            {
                name: "regions_lib.2367", // Río Grande Municipio
                shortcode: "2367",
            },
            {
                name: "regions_lib.2326", // Sabana Grande Municipio
                shortcode: "2326",
            },
            {
                name: "regions_lib.2339", // Salinas Municipio
                shortcode: "2339",
            },
            {
                name: "regions_lib.2343", // San Germán Municipio
                shortcode: "2343",
            },
            {
                name: "regions_lib.2381", // San Juan
                shortcode: "2381",
            },
            {
                name: "regions_lib.2353", // San Lorenzo Municipio
                shortcode: "2353",
            },
            {
                name: "regions_lib.2357", // San Sebastián Municipio
                shortcode: "2357",
            },
            {
                name: "regions_lib.2322", // Santa Isabel Municipio
                shortcode: "2322",
            },
            {
                name: "regions_lib.2371", // Toa Alta Municipio
                shortcode: "2371",
            },
            {
                name: "regions_lib.2374", // Toa Baja Municipio
                shortcode: "2374",
            },
            {
                name: "regions_lib.2372", // Trujillo Alto Municipio
                shortcode: "2372",
            },
            {
                name: "regions_lib.2341", // Utuado Municipio
                shortcode: "2341",
            },
            {
                name: "regions_lib.2349", // Vega Alta Municipio
                shortcode: "2349",
            },
            {
                name: "regions_lib.2369", // Vega Baja Municipio
                shortcode: "2369",
            },
            {
                name: "regions_lib.2306", // Vieques Municipality
                shortcode: "2306",
            },
            {
                name: "regions_lib.2329", // Villalba Municipio
                shortcode: "2329",
            },
            {
                name: "regions_lib.2346", // Yabucoa Municipio
                shortcode: "2346",
            },
            {
                name: "regions_lib.2356", // Yauco Municipio
                shortcode: "2356",
            },
        ]
    },
    {
        shortcode: "CG", // Республика Конго
        name: "regions_lib.CG",
        regions: [
            {
                name: "regions_lib.2390", // Bouenza Department
                shortcode: "2390",
            },
            {
                name: "regions_lib.2393", // Brazzavile Department
                shortcode: "2393",
            },
            {
                name: "regions_lib.2382", // Cuvette-Ouest Department
                shortcode: "2382",
            },
            {
                name: "regions_lib.2392", // Kouilou Department
                shortcode: "2392",
            },
            {
                name: "regions_lib.2383", // Lékoumou Department
                shortcode: "2383",
            },
            {
                name: "regions_lib.2388", // Niari Region
                shortcode: "2388",
            },
            {
                name: "regions_lib.2387", // Plateaux Department
                shortcode: "2387",
            },
            {
                name: "regions_lib.2391", // Pointe-Noire Department
                shortcode: "2391",
            },
            {
                name: "regions_lib.2389", // Pool Department
                shortcode: "2389",
            },
            {
                name: "regions_lib.2384", // Sangha Department
                shortcode: "2384",
            },
            {
                name: "regions_lib.2386", // Кювет
                shortcode: "2386",
            },
            {
                name: "regions_lib.2385", // Ликуала
                shortcode: "2385",
            },
        ]
    },
    {
        shortcode: "KR", // Республика Корея
        name: "regions_lib.KR",
        regions: [
            {
                name: "regions_lib.2407", // Busan
                shortcode: "2407",
            },
            {
                name: "regions_lib.2403", // Chungcheongnam-do
                shortcode: "2403",
            },
            {
                name: "regions_lib.2404", // Daegu
                shortcode: "2404",
            },
            {
                name: "regions_lib.2398", // Daejeon
                shortcode: "2398",
            },
            {
                name: "regions_lib.2399", // Gangwon-do
                shortcode: "2399",
            },
            {
                name: "regions_lib.2397", // Gwangju
                shortcode: "2397",
            },
            {
                name: "regions_lib.2410", // Gyeonggi-do
                shortcode: "2410",
            },
            {
                name: "regions_lib.2405", // Gyeongsangbuk-do
                shortcode: "2405",
            },
            {
                name: "regions_lib.2408", // Gyeongsangnam-do
                shortcode: "2408",
            },
            {
                name: "regions_lib.2406", // Incheon
                shortcode: "2406",
            },
            {
                name: "regions_lib.2395", // Jeju-do
                shortcode: "2395",
            },
            {
                name: "regions_lib.2401", // Jeollabuk-do
                shortcode: "2401",
            },
            {
                name: "regions_lib.2402", // Jeollanam-do
                shortcode: "2402",
            },
            {
                name: "regions_lib.2400", // North Chungcheong
                shortcode: "2400",
            },
            {
                name: "regions_lib.2394", // Sejong-si
                shortcode: "2394",
            },
            {
                name: "regions_lib.2409", // Seoul
                shortcode: "2409",
            },
            {
                name: "regions_lib.2396", // Ulsan
                shortcode: "2396",
            },
        ]
    },
    {
        shortcode: "RE", // Реюньон
        name: "regions_lib.RE",
        regions: [
            {
                name: "regions_lib.2411", // Réunion
                shortcode: "2411",
            },
        ]
    },
    {
        shortcode: "RU", // Россия
        name: "regions_lib.RU",
        regions: [
            {
                name: "regions_lib.2422", // Адыгея
                shortcode: "2422",
            },
            {
                name: "regions_lib.2416", // Алтай
                shortcode: "2416",
            },
            {
                name: "regions_lib.2473", // Алтайский край
                shortcode: "2473",
            },
            {
                name: "regions_lib.2434", // Амурская область
                shortcode: "2434",
            },
            {
                name: "regions_lib.2452", // Архангельская область
                shortcode: "2452",
            },
            {
                name: "regions_lib.2440", // Астраханская область
                shortcode: "2440",
            },
            {
                name: "regions_lib.2467", // Белгородская область
                shortcode: "2467",
            },
            {
                name: "regions_lib.2454", // Брянская область
                shortcode: "2454",
            },
            {
                name: "regions_lib.2461", // Владимирская область
                shortcode: "2461",
            },
            {
                name: "regions_lib.2476", // Волгоградская область
                shortcode: "2476",
            },
            {
                name: "regions_lib.2453", // Вологодская область
                shortcode: "2453",
            },
            {
                name: "regions_lib.2472", // Воронежская область
                shortcode: "2472",
            },
            {
                name: "regions_lib.2415", // Еврейская автономная область
                shortcode: "2415",
            },
            {
                name: "regions_lib.2447", // Забайкальский Край
                shortcode: "2447",
            },
            {
                name: "regions_lib.2444", // Ивановская область
                shortcode: "2444",
            },
            {
                name: "regions_lib.2423", // Ингушетия
                shortcode: "2423",
            },
            {
                name: "regions_lib.2474", // Иркутская область
                shortcode: "2474",
            },
            {
                name: "regions_lib.2438", // Кабардино-Балкария
                shortcode: "2438",
            },
            {
                name: "regions_lib.2445", // Калининградская область
                shortcode: "2445",
            },
            {
                name: "regions_lib.2417", // Калмыкия
                shortcode: "2417",
            },
            {
                name: "regions_lib.2443", // Калужская область
                shortcode: "2443",
            },
            {
                name: "regions_lib.2418", // Камчатский край
                shortcode: "2418",
            },
            {
                name: "regions_lib.2420", // Карачаево-Черкессия
                shortcode: "2420",
            },
            {
                name: "regions_lib.2424", // Карельская республика
                shortcode: "2424",
            },
            {
                name: "regions_lib.2478", // Кемеровская область
                shortcode: "2478",
            },
            {
                name: "regions_lib.2458", // Кировская область
                shortcode: "2458",
            },
            {
                name: "regions_lib.2436", // Коми
                shortcode: "2436",
            },
            {
                name: "regions_lib.2429", // Костромская область
                shortcode: "2429",
            },
            {
                name: "regions_lib.2492", // Краснодарский край
                shortcode: "2492",
            },
            {
                name: "regions_lib.2481", // Красноярский край
                shortcode: "2481",
            },
            {
                name: "regions_lib.2437", // Курганская область
                shortcode: "2437",
            },
            {
                name: "regions_lib.2449", // Курская область
                shortcode: "2449",
            },
            {
                name: "regions_lib.2468", // Ленинградская область
                shortcode: "2468",
            },
            {
                name: "regions_lib.2451", // Липецкая область
                shortcode: "2451",
            },
            {
                name: "regions_lib.2414", // Магаданская область
                shortcode: "2414",
            },
            {
                name: "regions_lib.2494", // Москва
                shortcode: "2494",
            },
            {
                name: "regions_lib.2493", // Московская область
                shortcode: "2493",
            },
            {
                name: "regions_lib.2433", // Мурманская область
                shortcode: "2433",
            },
            {
                name: "regions_lib.2412", // Ненецкий АО
                shortcode: "2412",
            },
            {
                name: "regions_lib.2484", // Нижегородская область
                shortcode: "2484",
            },
            {
                name: "regions_lib.2427", // Новгородская Область
                shortcode: "2427",
            },
            {
                name: "regions_lib.2479", // Новосибирская область
                shortcode: "2479",
            },
            {
                name: "regions_lib.2470", // Омская область
                shortcode: "2470",
            },
            {
                name: "regions_lib.2471", // Оренбургская область
                shortcode: "2471",
            },
            {
                name: "regions_lib.2432", // Орловская область
                shortcode: "2432",
            },
            {
                name: "regions_lib.2460", // Пензенская область
                shortcode: "2460",
            },
            {
                name: "regions_lib.2477", // Пермский край
                shortcode: "2477",
            },
            {
                name: "regions_lib.2469", // Приморский край
                shortcode: "2469",
            },
            {
                name: "regions_lib.2428", // Псковская область
                shortcode: "2428",
            },
            {
                name: "regions_lib.2488", // Республика Башкортостан
                shortcode: "2488",
            },
            {
                name: "regions_lib.2442", // Республика Бурятия
                shortcode: "2442",
            },
            {
                name: "regions_lib.2482", // Республика Дагестан
                shortcode: "2482",
            },
            {
                name: "regions_lib.3288", // Республика Крым
                shortcode: "3288",
            },
            {
                name: "regions_lib.2430", // Республика Марий Эл
                shortcode: "2430",
            },
            {
                name: "regions_lib.2435", // Республика Мордовия
                shortcode: "2435",
            },
            {
                name: "regions_lib.2419", // Республика Тыва
                shortcode: "2419",
            },
            {
                name: "regions_lib.2489", // Ростовская область
                shortcode: "2489",
            },
            {
                name: "regions_lib.2450", // Рязанская область
                shortcode: "2450",
            },
            {
                name: "regions_lib.2483", // Самарская область
                shortcode: "2483",
            },
            {
                name: "regions_lib.2491", // Санкт-Петербург
                shortcode: "2491",
            },
            {
                name: "regions_lib.2475", // Саратовская область
                shortcode: "2475",
            },
            {
                name: "regions_lib.2441", // Саха (Якутия)
                shortcode: "2441",
            },
            {
                name: "regions_lib.2421", // Сахалинская область
                shortcode: "2421",
            },
            {
                name: "regions_lib.2490", // Свердловская область
                shortcode: "2490",
            },
            {
                name: "regions_lib.2431", // Северная Осетия
                shortcode: "2431",
            },
            {
                name: "regions_lib.2439", // Смоленская область
                shortcode: "2439",
            },
            {
                name: "regions_lib.2480", // Ставрополье
                shortcode: "2480",
            },
            {
                name: "regions_lib.2446", // Тамбовская область
                shortcode: "2446",
            },
            {
                name: "regions_lib.2487", // Татарстан
                shortcode: "2487",
            },
            {
                name: "regions_lib.2459", // Тверская область
                shortcode: "2459",
            },
            {
                name: "regions_lib.2448", // Томская область
                shortcode: "2448",
            },
            {
                name: "regions_lib.2464", // Тульская область
                shortcode: "2464",
            },
            {
                name: "regions_lib.2486", // Тюменская область
                shortcode: "2486",
            },
            {
                name: "regions_lib.2466", // Удмуртия
                shortcode: "2466",
            },
            {
                name: "regions_lib.2456", // Ульяновская область
                shortcode: "2456",
            },
            {
                name: "regions_lib.2462", // Хабаровский край
                shortcode: "2462",
            },
            {
                name: "regions_lib.2425", // Хакасия
                shortcode: "2425",
            },
            {
                name: "regions_lib.2463", // Ханты-Мансийский автономный округ
                shortcode: "2463",
            },
            {
                name: "regions_lib.2485", // Челябинская область
                shortcode: "2485",
            },
            {
                name: "regions_lib.2465", // Чеченская Республика
                shortcode: "2465",
            },
            {
                name: "regions_lib.2455", // Чувашия
                shortcode: "2455",
            },
            {
                name: "regions_lib.2413", // Чукотский автономный округ
                shortcode: "2413",
            },
            {
                name: "regions_lib.2426", // Ямало-Ненецкий АО
                shortcode: "2426",
            },
            {
                name: "regions_lib.2457", // Ярославская область
                shortcode: "2457",
            },
        ]
    },
    {
        shortcode: "RW", // Руанда
        name: "regions_lib.RW",
        regions: [
            {
                name: "regions_lib.2499", // Eastern Province
                shortcode: "2499",
            },
            {
                name: "regions_lib.2495", // Kigali
                shortcode: "2495",
            },
            {
                name: "regions_lib.2496", // Northern Province
                shortcode: "2496",
            },
            {
                name: "regions_lib.2498", // Southern Province
                shortcode: "2498",
            },
            {
                name: "regions_lib.2497", // Western Province
                shortcode: "2497",
            },
        ]
    },
    {
        shortcode: "RO", // Румыния
        name: "regions_lib.RO",
        regions: [
            {
                name: "regions_lib.2511", // Alba
                shortcode: "2511",
            },
            {
                name: "regions_lib.2522", // Arad
                shortcode: "2522",
            },
            {
                name: "regions_lib.2533", // Argeş
                shortcode: "2533",
            },
            {
                name: "regions_lib.2537", // Bacău
                shortcode: "2537",
            },
            {
                name: "regions_lib.2530", // Bihor
                shortcode: "2530",
            },
            {
                name: "regions_lib.2509", // Bistriţa-Năsăud
                shortcode: "2509",
            },
            {
                name: "regions_lib.2517", // Botoşani
                shortcode: "2517",
            },
            {
                name: "regions_lib.2529", // Braşov
                shortcode: "2529",
            },
            {
                name: "regions_lib.2513", // Brăila
                shortcode: "2513",
            },
            {
                name: "regions_lib.2541", // București
                shortcode: "2541",
            },
            {
                name: "regions_lib.2520", // Buzău
                shortcode: "2520",
            },
            {
                name: "regions_lib.2504", // Caraş-Severin
                shortcode: "2504",
            },
            {
                name: "regions_lib.2536", // Cluj
                shortcode: "2536",
            },
            {
                name: "regions_lib.2538", // Constanța
                shortcode: "2538",
            },
            {
                name: "regions_lib.2500", // Covasna
                shortcode: "2500",
            },
            {
                name: "regions_lib.2510", // Călăraşi
                shortcode: "2510",
            },
            {
                name: "regions_lib.2539", // Dolj
                shortcode: "2539",
            },
            {
                name: "regions_lib.2527", // Dâmboviţa
                shortcode: "2527",
            },
            {
                name: "regions_lib.2531", // Galaţi
                shortcode: "2531",
            },
            {
                name: "regions_lib.2505", // Giurgiu
                shortcode: "2505",
            },
            {
                name: "regions_lib.2514", // Gorj
                shortcode: "2514",
            },
            {
                name: "regions_lib.2508", // Harghita
                shortcode: "2508",
            },
            {
                name: "regions_lib.2526", // Hunedoara
                shortcode: "2526",
            },
            {
                name: "regions_lib.2506", // Ialomiţa
                shortcode: "2506",
            },
            {
                name: "regions_lib.2532", // Iaşi
                shortcode: "2532",
            },
            {
                name: "regions_lib.2525", // Maramureş
                shortcode: "2525",
            },
            {
                name: "regions_lib.2503", // Mehedinţi
                shortcode: "2503",
            },
            {
                name: "regions_lib.2528", // Mureş
                shortcode: "2528",
            },
            {
                name: "regions_lib.2523", // Neamţ
                shortcode: "2523",
            },
            {
                name: "regions_lib.2512", // Satu Mare
                shortcode: "2512",
            },
            {
                name: "regions_lib.2518", // Sibiu
                shortcode: "2518",
            },
            {
                name: "regions_lib.2535", // Suceava
                shortcode: "2535",
            },
            {
                name: "regions_lib.2501", // Sălaj
                shortcode: "2501",
            },
            {
                name: "regions_lib.2519", // Teleorman
                shortcode: "2519",
            },
            {
                name: "regions_lib.2534", // Timiş
                shortcode: "2534",
            },
            {
                name: "regions_lib.2502", // Tulcea
                shortcode: "2502",
            },
            {
                name: "regions_lib.2521", // Vaslui
                shortcode: "2521",
            },
            {
                name: "regions_lib.2516", // Vâlcea
                shortcode: "2516",
            },
            {
                name: "regions_lib.2515", // Вранча
                shortcode: "2515",
            },
            {
                name: "regions_lib.2507", // Илфов
                shortcode: "2507",
            },
            {
                name: "regions_lib.2524", // Олт
                shortcode: "2524",
            },
            {
                name: "regions_lib.2540", // Прахова
                shortcode: "2540",
            },
        ]
    },
    {
        shortcode: "US", // США
        name: "regions_lib.US",
        regions: [
            {
                name: "regions_lib.2554", // Айдахо
                shortcode: "2554",
            },
            {
                name: "regions_lib.2563", // Айова
                shortcode: "2563",
            },
            {
                name: "regions_lib.2570", // Алабама
                shortcode: "2570",
            },
            {
                name: "regions_lib.2546", // Аляска
                shortcode: "2546",
            },
            {
                name: "regions_lib.2576", // Аризона
                shortcode: "2576",
            },
            {
                name: "regions_lib.2561", // Арканзас
                shortcode: "2561",
            },
            {
                name: "regions_lib.2542", // Вайоминг
                shortcode: "2542",
            },
            {
                name: "regions_lib.2579", // Вашингтон
                shortcode: "2579",
            },
            {
                name: "regions_lib.2544", // Вермонт
                shortcode: "2544",
            },
            {
                name: "regions_lib.2581", // Виргиния
                shortcode: "2581",
            },
            {
                name: "regions_lib.2573", // Висконсин
                shortcode: "2573",
            },
            {
                name: "regions_lib.2551", // Гавайи
                shortcode: "2551",
            },
            {
                name: "regions_lib.2548", // Делавэр
                shortcode: "2548",
            },
            {
                name: "regions_lib.2583", // Джорджия
                shortcode: "2583",
            },
            {
                name: "regions_lib.2556", // Западная Виргиния
                shortcode: "2556",
            },
            {
                name: "regions_lib.2588", // Иллинойс
                shortcode: "2588",
            },
            {
                name: "regions_lib.2578", // Индиана
                shortcode: "2578",
            },
            {
                name: "regions_lib.2592", // Калифорния
                shortcode: "2592",
            },
            {
                name: "regions_lib.2560", // Канзас
                shortcode: "2560",
            },
            {
                name: "regions_lib.2567", // Кентукки
                shortcode: "2567",
            },
            {
                name: "regions_lib.2571", // Колорадо
                shortcode: "2571",
            },
            {
                name: "regions_lib.2564", // Коннектикут
                shortcode: "2564",
            },
            {
                name: "regions_lib.2569", // Луизиана
                shortcode: "2569",
            },
            {
                name: "regions_lib.2580", // Массачусетс
                shortcode: "2580",
            },
            {
                name: "regions_lib.2572", // Миннесота
                shortcode: "2572",
            },
            {
                name: "regions_lib.2562", // Миссисипи
                shortcode: "2562",
            },
            {
                name: "regions_lib.2575", // Миссури
                shortcode: "2575",
            },
            {
                name: "regions_lib.2584", // Мичиган
                shortcode: "2584",
            },
            {
                name: "regions_lib.2549", // Монтана
                shortcode: "2549",
            },
            {
                name: "regions_lib.2553", // Мэн
                shortcode: "2553",
            },
            {
                name: "regions_lib.2574", // Мэриленд
                shortcode: "2574",
            },
            {
                name: "regions_lib.2555", // Небраска
                shortcode: "2555",
            },
            {
                name: "regions_lib.2558", // Невада
                shortcode: "2558",
            },
            {
                name: "regions_lib.2552", // Нью-Гэмпшир
                shortcode: "2552",
            },
            {
                name: "regions_lib.2582", // Нью-Джерси
                shortcode: "2582",
            },
            {
                name: "regions_lib.2590", // Нью-Йорк
                shortcode: "2590",
            },
            {
                name: "regions_lib.2557", // Нью-Мехико
                shortcode: "2557",
            },
            {
                name: "regions_lib.2586", // Огайо
                shortcode: "2586",
            },
            {
                name: "regions_lib.2565", // Оклахома
                shortcode: "2565",
            },
            {
                name: "regions_lib.2543", // Округ Колумбия
                shortcode: "2543",
            },
            {
                name: "regions_lib.2566", // Орегон
                shortcode: "2566",
            },
            {
                name: "regions_lib.2587", // Пенсильвания
                shortcode: "2587",
            },
            {
                name: "regions_lib.2550", // Род-Айленд
                shortcode: "2550",
            },
            {
                name: "regions_lib.2545", // Северная Дакота
                shortcode: "2545",
            },
            {
                name: "regions_lib.2585", // Северная Каролина
                shortcode: "2585",
            },
            {
                name: "regions_lib.2577", // Теннесси
                shortcode: "2577",
            },
            {
                name: "regions_lib.2591", // Техас
                shortcode: "2591",
            },
            {
                name: "regions_lib.2589", // Флорида
                shortcode: "2589",
            },
            {
                name: "regions_lib.2547", // Южная Дакота
                shortcode: "2547",
            },
            {
                name: "regions_lib.2568", // Южная Каролина
                shortcode: "2568",
            },
            {
                name: "regions_lib.2559", // Юта
                shortcode: "2559",
            },
        ]
    },
    {
        shortcode: "SV", // Сальвадор
        name: "regions_lib.SV",
        regions: [
            {
                name: "regions_lib.2600", // Departamento de Ahuachapán
                shortcode: "2600",
            },
            {
                name: "regions_lib.2593", // Departamento de Cabañas
                shortcode: "2593",
            },
            {
                name: "regions_lib.2596", // Departamento de Chalatenango
                shortcode: "2596",
            },
            {
                name: "regions_lib.2597", // Departamento de Cuscatlán
                shortcode: "2597",
            },
            {
                name: "regions_lib.2605", // Departamento de La Libertad
                shortcode: "2605",
            },
            {
                name: "regions_lib.2599", // Departamento de La Paz
                shortcode: "2599",
            },
            {
                name: "regions_lib.2598", // Departamento de La Unión
                shortcode: "2598",
            },
            {
                name: "regions_lib.2595", // Departamento de Morazán
                shortcode: "2595",
            },
            {
                name: "regions_lib.2603", // Departamento de San Miguel
                shortcode: "2603",
            },
            {
                name: "regions_lib.2606", // Departamento de San Salvador
                shortcode: "2606",
            },
            {
                name: "regions_lib.2594", // Departamento de San Vicente
                shortcode: "2594",
            },
            {
                name: "regions_lib.2604", // Departamento de Santa Ana
                shortcode: "2604",
            },
            {
                name: "regions_lib.2602", // Departamento de Sonsonate
                shortcode: "2602",
            },
            {
                name: "regions_lib.2601", // Departamento de Usulután
                shortcode: "2601",
            },
        ]
    },
    {
        shortcode: "WS", // Самоа
        name: "regions_lib.WS",
        regions: [
            {
                name: "regions_lib.2615", // A&quot;ana
                shortcode: "2615",
            },
            {
                name: "regions_lib.2614", // Fa‘asaleleaga
                shortcode: "2614",
            },
            {
                name: "regions_lib.2610", // Gagaifomauga
                shortcode: "2610",
            },
            {
                name: "regions_lib.2612", // Gaga‘emauga
                shortcode: "2612",
            },
            {
                name: "regions_lib.2608", // Satupa‘itea
                shortcode: "2608",
            },
            {
                name: "regions_lib.2607", // Va‘a-o-Fonoti
                shortcode: "2607",
            },
            {
                name: "regions_lib.2609", // Аига-и-ле-Таи
                shortcode: "2609",
            },
            {
                name: "regions_lib.2616", // Атуа
                shortcode: "2616",
            },
            {
                name: "regions_lib.2611", // Ваисигано
                shortcode: "2611",
            },
            {
                name: "regions_lib.2613", // Палаули
                shortcode: "2613",
            },
            {
                name: "regions_lib.2617", // Туамасага
                shortcode: "2617",
            },
        ]
    },
    {
        shortcode: "SM", // Сан-Марино
        name: "regions_lib.SM",
        regions: [
            {
                name: "regions_lib.2621", // Castello di Acquaviva
                shortcode: "2621",
            },
            {
                name: "regions_lib.2625", // Castello di Borgo Maggiore
                shortcode: "2625",
            },
            {
                name: "regions_lib.2623", // Castello di Domagnano
                shortcode: "2623",
            },
            {
                name: "regions_lib.2620", // Castello di Faetano
                shortcode: "2620",
            },
            {
                name: "regions_lib.2622", // Castello di Fiorentino
                shortcode: "2622",
            },
            {
                name: "regions_lib.2618", // Castello di Montegiardino
                shortcode: "2618",
            },
            {
                name: "regions_lib.2624", // Castello di San Marino Città
                shortcode: "2624",
            },
            {
                name: "regions_lib.2626", // Serravalle
                shortcode: "2626",
            },
            {
                name: "regions_lib.2619", // Кьезануова
                shortcode: "2619",
            },
        ]
    },
    {
        shortcode: "ST", // Сан-Томе и Принсипи
        name: "regions_lib.ST",
        regions: [
            {
                name: "regions_lib.2627", // Принсипи
                shortcode: "2627",
            },
            {
                name: "regions_lib.2628", // Сан-Томе
                shortcode: "2628",
            },
        ]
    },
    {
        shortcode: "SA", // Саудовская Аравия
        name: "regions_lib.SA",
        regions: [
            {
                name: "regions_lib.2635", // Al-Qassim Region
                shortcode: "2635",
            },
            {
                name: "regions_lib.2631", // Al Bahah Region
                shortcode: "2631",
            },
            {
                name: "regions_lib.2632", // Al Jawf Region
                shortcode: "2632",
            },
            {
                name: "regions_lib.2639", // Eastern Province
                shortcode: "2639",
            },
            {
                name: "regions_lib.2633", // Ha&quot;il Region
                shortcode: "2633",
            },
            {
                name: "regions_lib.2636", // Jazan Region
                shortcode: "2636",
            },
            {
                name: "regions_lib.2641", // Mecca Region
                shortcode: "2641",
            },
            {
                name: "regions_lib.2637", // Medina Region
                shortcode: "2637",
            },
            {
                name: "regions_lib.2630", // Najran Region
                shortcode: "2630",
            },
            {
                name: "regions_lib.2629", // Northern Borders Region
                shortcode: "2629",
            },
            {
                name: "regions_lib.2640", // Riyadh Region
                shortcode: "2640",
            },
            {
                name: "regions_lib.2634", // Tabuk Region
                shortcode: "2634",
            },
            {
                name: "regions_lib.2638", // Административный округ Асир
                shortcode: "2638",
            },
        ]
    },
    {
        shortcode: "MK", // Северная Македония
        name: "regions_lib.MK",
        regions: [
            {
                name: "regions_lib.2676", // Berovo
                shortcode: "2676",
            },
            {
                name: "regions_lib.2710", // Bitola
                shortcode: "2710",
            },
            {
                name: "regions_lib.2690", // Debar
                shortcode: "2690",
            },
            {
                name: "regions_lib.2666", // Demir Hisar
                shortcode: "2666",
            },
            {
                name: "regions_lib.2653", // Demir Kapija
                shortcode: "2653",
            },
            {
                name: "regions_lib.2692", // Gevgelija
                shortcode: "2692",
            },
            {
                name: "regions_lib.2712", // Grad Skopje
                shortcode: "2712",
            },
            {
                name: "regions_lib.2700", // Kavadarci
                shortcode: "2700",
            },
            {
                name: "regions_lib.2670", // Kratovo
                shortcode: "2670",
            },
            {
                name: "regions_lib.2689", // Kriva Palanka
                shortcode: "2689",
            },
            {
                name: "regions_lib.2711", // Kumanovo
                shortcode: "2711",
            },
            {
                name: "regions_lib.2660", // Makedonski Brod
                shortcode: "2660",
            },
            {
                name: "regions_lib.2661", // Mogila
                shortcode: "2661",
            },
            {
                name: "regions_lib.2687", // Negotino
                shortcode: "2687",
            },
            {
                name: "regions_lib.2645", // Novaci
                shortcode: "2645",
            },
            {
                name: "regions_lib.2674", // Novo Selo
                shortcode: "2674",
            },
            {
                name: "regions_lib.2702", // Ohrid
                shortcode: "2702",
            },
            {
                name: "regions_lib.2678", // Opština Aračinovo
                shortcode: "2678",
            },
            {
                name: "regions_lib.2663", // Opština Centar Župa
                shortcode: "2663",
            },
            {
                name: "regions_lib.2682", // Opština Delčevo
                shortcode: "2682",
            },
            {
                name: "regions_lib.2646", // Opština Dojran
                shortcode: "2646",
            },
            {
                name: "regions_lib.2704", // Opština Kičevo
                shortcode: "2704",
            },
            {
                name: "regions_lib.2648", // Opština Konče
                shortcode: "2648",
            },
            {
                name: "regions_lib.2699", // Opština Kočani
                shortcode: "2699",
            },
            {
                name: "regions_lib.2658", // Opština Krivogaštani
                shortcode: "2658",
            },
            {
                name: "regions_lib.2671", // Opština Kruševo
                shortcode: "2671",
            },
            {
                name: "regions_lib.2697", // Opština Lipkovo
                shortcode: "2697",
            },
            {
                name: "regions_lib.2656", // Opština Pehčevo
                shortcode: "2656",
            },
            {
                name: "regions_lib.2681", // Opština Probištip
                shortcode: "2681",
            },
            {
                name: "regions_lib.2696", // Opština Radoviš
                shortcode: "2696",
            },
            {
                name: "regions_lib.2649", // Opština Rankovce
                shortcode: "2649",
            },
            {
                name: "regions_lib.2659", // Opština Sopište
                shortcode: "2659",
            },
            {
                name: "regions_lib.2651", // Opština Staro Nagoričane
                shortcode: "2651",
            },
            {
                name: "regions_lib.2691", // Opština Studeničani
                shortcode: "2691",
            },
            {
                name: "regions_lib.2642", // Opština Vevčani
                shortcode: "2642",
            },
            {
                name: "regions_lib.2694", // Opština Vrapčište
                shortcode: "2694",
            },
            {
                name: "regions_lib.2665", // Opština Čaška
                shortcode: "2665",
            },
            {
                name: "regions_lib.2672", // Opština Čučer-Sandevo
                shortcode: "2672",
            },
            {
                name: "regions_lib.2701", // Opština Štip
                shortcode: "2701",
            },
            {
                name: "regions_lib.2695", // Opština Želino
                shortcode: "2695",
            },
            {
                name: "regions_lib.2669", // Petrovec
                shortcode: "2669",
            },
            {
                name: "regions_lib.2657", // Plasnica
                shortcode: "2657",
            },
            {
                name: "regions_lib.2707", // Prilep
                shortcode: "2707",
            },
            {
                name: "regions_lib.2683", // Resen
                shortcode: "2683",
            },
            {
                name: "regions_lib.2654", // Rosoman
                shortcode: "2654",
            },
            {
                name: "regions_lib.2706", // Struga
                shortcode: "2706",
            },
            {
                name: "regions_lib.2705", // Strumica
                shortcode: "2705",
            },
            {
                name: "regions_lib.2686", // Sveti Nikole
                shortcode: "2686",
            },
            {
                name: "regions_lib.2693", // Tearce
                shortcode: "2693",
            },
            {
                name: "regions_lib.2709", // Tetovo
                shortcode: "2709",
            },
            {
                name: "regions_lib.2677", // Vasilevo
                shortcode: "2677",
            },
            {
                name: "regions_lib.2703", // Veles
                shortcode: "2703",
            },
            {
                name: "regions_lib.2688", // Vinica
                shortcode: "2688",
            },
            {
                name: "regions_lib.2655", // Zelenikovo
                shortcode: "2655",
            },
            {
                name: "regions_lib.2662", // Češinovo-Obleševo
                shortcode: "2662",
            },
            {
                name: "regions_lib.2667", // Богданци
                shortcode: "2667",
            },
            {
                name: "regions_lib.2698", // Боговинье
                shortcode: "2698",
            },
            {
                name: "regions_lib.2679", // Босилово
                shortcode: "2679",
            },
            {
                name: "regions_lib.2684", // Брвеница
                shortcode: "2684",
            },
            {
                name: "regions_lib.2675", // Валандово
                shortcode: "2675",
            },
            {
                name: "regions_lib.2708", // Гостивар
                shortcode: "2708",
            },
            {
                name: "regions_lib.2647", // Градско
                shortcode: "2647",
            },
            {
                name: "regions_lib.2650", // Дебарца
                shortcode: "2650",
            },
            {
                name: "regions_lib.2680", // Долнени
                shortcode: "2680",
            },
            {
                name: "regions_lib.2673", // Егуновце
                shortcode: "2673",
            },
            {
                name: "regions_lib.2644", // Зрновци
                shortcode: "2644",
            },
            {
                name: "regions_lib.2685", // Илинден
                shortcode: "2685",
            },
            {
                name: "regions_lib.2652", // Карбинци
                shortcode: "2652",
            },
            {
                name: "regions_lib.2643", // Лозово
                shortcode: "2643",
            },
            {
                name: "regions_lib.2668", // Маврово и Ростуша
                shortcode: "2668",
            },
            {
                name: "regions_lib.2664", // Македонска-Каменица
                shortcode: "2664",
            },
        ]
    },
    {
        shortcode: "MP", // Северные Марианские о-ва
        name: "regions_lib.MP",
        regions: [
            {
                name: "regions_lib.2713", // Northern Islands
                shortcode: "2713",
            },
            {
                name: "regions_lib.2714", // Rota
                shortcode: "2714",
            },
            {
                name: "regions_lib.2716", // Saipan
                shortcode: "2716",
            },
            {
                name: "regions_lib.2715", // Tinian Municipality
                shortcode: "2715",
            },
        ]
    },
    {
        shortcode: "SC", // Сейшельские Острова
        name: "regions_lib.SC",
        regions: [
            {
                name: "regions_lib.2741", // Anse Boileau
                shortcode: "2741",
            },
            {
                name: "regions_lib.2742", // Anse Etoile
                shortcode: "2742",
            },
            {
                name: "regions_lib.2736", // Anse Royale
                shortcode: "2736",
            },
            {
                name: "regions_lib.2732", // Anse aux Pins
                shortcode: "2732",
            },
            {
                name: "regions_lib.2727", // Au Cap
                shortcode: "2727",
            },
            {
                name: "regions_lib.2725", // Baie Lazare
                shortcode: "2725",
            },
            {
                name: "regions_lib.2739", // Baie Sainte Anne
                shortcode: "2739",
            },
            {
                name: "regions_lib.2738", // Beau Vallon
                shortcode: "2738",
            },
            {
                name: "regions_lib.2723", // Bel Air
                shortcode: "2723",
            },
            {
                name: "regions_lib.2735", // Bel Ombre
                shortcode: "2735",
            },
            {
                name: "regions_lib.2734", // Cascade
                shortcode: "2734",
            },
            {
                name: "regions_lib.2740", // English River
                shortcode: "2740",
            },
            {
                name: "regions_lib.2733", // Glacis
                shortcode: "2733",
            },
            {
                name: "regions_lib.2737", // Grand Anse Mahe
                shortcode: "2737",
            },
            {
                name: "regions_lib.2730", // Grand Anse Praslin
                shortcode: "2730",
            },
            {
                name: "regions_lib.2718", // La Digue
                shortcode: "2718",
            },
            {
                name: "regions_lib.2719", // Les Mamelles
                shortcode: "2719",
            },
            {
                name: "regions_lib.2724", // Mont Buxton
                shortcode: "2724",
            },
            {
                name: "regions_lib.2731", // Mont Fleuri
                shortcode: "2731",
            },
            {
                name: "regions_lib.2717", // Outer Islands
                shortcode: "2717",
            },
            {
                name: "regions_lib.2728", // Plaisance
                shortcode: "2728",
            },
            {
                name: "regions_lib.2722", // Pointe Larue
                shortcode: "2722",
            },
            {
                name: "regions_lib.2720", // Port Glaud
                shortcode: "2720",
            },
            {
                name: "regions_lib.2726", // Roche Caiman
                shortcode: "2726",
            },
            {
                name: "regions_lib.2729", // Saint Louis
                shortcode: "2729",
            },
            {
                name: "regions_lib.2721", // Takamaka
                shortcode: "2721",
            },
        ]
    },
    {
        shortcode: "PM", // Сен-Пьер и Микелон
        name: "regions_lib.PM",
        regions: [
            {
                name: "regions_lib.2744", // Commune de Saint-Pierre
                shortcode: "2744",
            },
            {
                name: "regions_lib.2743", // Miquelon-Langlade
                shortcode: "2743",
            },
        ]
    },
    {
        shortcode: "SN", // Сенегал
        name: "regions_lib.SN",
        regions: [
            {
                name: "regions_lib.2745", // Fatick
                shortcode: "2745",
            },
            {
                name: "regions_lib.2746", // Région de Kaffrine
                shortcode: "2746",
            },
            {
                name: "regions_lib.2747", // Région de Kédougou
                shortcode: "2747",
            },
            {
                name: "regions_lib.2748", // Région de Sédhiou
                shortcode: "2748",
            },
            {
                name: "regions_lib.2757", // Région de Thiès
                shortcode: "2757",
            },
            {
                name: "regions_lib.2758", // Дакар
                shortcode: "2758",
            },
            {
                name: "regions_lib.2756", // Диурбель
                shortcode: "2756",
            },
            {
                name: "regions_lib.2750", // Зигиншор
                shortcode: "2750",
            },
            {
                name: "regions_lib.2755", // Каолак
                shortcode: "2755",
            },
            {
                name: "regions_lib.2751", // Колда
                shortcode: "2751",
            },
            {
                name: "regions_lib.2754", // Луга
                shortcode: "2754",
            },
            {
                name: "regions_lib.2749", // Матам
                shortcode: "2749",
            },
            {
                name: "regions_lib.2753", // Сен-Луи
                shortcode: "2753",
            },
            {
                name: "regions_lib.2752", // Тамбакунда
                shortcode: "2752",
            },
        ]
    },
    {
        shortcode: "VC", // Сент-Винсент и Гренадины
        name: "regions_lib.VC",
        regions: [
            {
                name: "regions_lib.2762", // Grenadines
                shortcode: "2762",
            },
            {
                name: "regions_lib.2763", // Parish of Charlotte
                shortcode: "2763",
            },
            {
                name: "regions_lib.2760", // Parish of Saint Andrew
                shortcode: "2760",
            },
            {
                name: "regions_lib.2761", // Parish of Saint David
                shortcode: "2761",
            },
            {
                name: "regions_lib.2764", // Parish of Saint George
                shortcode: "2764",
            },
            {
                name: "regions_lib.2759", // Parish of Saint Patrick
                shortcode: "2759",
            },
        ]
    },
    {
        shortcode: "KN", // Сент-Китс и Невис
        name: "regions_lib.KN",
        regions: [
            {
                name: "regions_lib.2769", // Christ Church Nichola Town
                shortcode: "2769",
            },
            {
                name: "regions_lib.2775", // Saint Anne Sandy Point
                shortcode: "2775",
            },
            {
                name: "regions_lib.2778", // Saint George Basseterre
                shortcode: "2778",
            },
            {
                name: "regions_lib.2774", // Saint George Gingerland
                shortcode: "2774",
            },
            {
                name: "regions_lib.2765", // Saint James Windward
                shortcode: "2765",
            },
            {
                name: "regions_lib.2776", // Saint John Capesterre
                shortcode: "2776",
            },
            {
                name: "regions_lib.2771", // Saint John Figtree
                shortcode: "2771",
            },
            {
                name: "regions_lib.2777", // Saint Mary Cayon
                shortcode: "2777",
            },
            {
                name: "regions_lib.2770", // Saint Paul Capesterre
                shortcode: "2770",
            },
            {
                name: "regions_lib.2766", // Saint Paul Charlestown
                shortcode: "2766",
            },
            {
                name: "regions_lib.2773", // Saint Peter Basseterre
                shortcode: "2773",
            },
            {
                name: "regions_lib.2768", // Saint Thomas Lowland
                shortcode: "2768",
            },
            {
                name: "regions_lib.2772", // Saint Thomas Middle Island
                shortcode: "2772",
            },
            {
                name: "regions_lib.2767", // Trinity Palmetto Point
                shortcode: "2767",
            },
        ]
    },
    {
        shortcode: "LC", // Сент-Люсия
        name: "regions_lib.LC",
        regions: [
            {
                name: "regions_lib.2781", // Anse-la-Raye
                shortcode: "2781",
            },
            {
                name: "regions_lib.2779", // Canaries
                shortcode: "2779",
            },
            {
                name: "regions_lib.2784", // Dennery
                shortcode: "2784",
            },
            {
                name: "regions_lib.2787", // Gros-Islet
                shortcode: "2787",
            },
            {
                name: "regions_lib.2782", // Laborie
                shortcode: "2782",
            },
            {
                name: "regions_lib.2783", // Soufrière
                shortcode: "2783",
            },
            {
                name: "regions_lib.2785", // Vieux-Fort
                shortcode: "2785",
            },
            {
                name: "regions_lib.2788", // Кастри
                shortcode: "2788",
            },
            {
                name: "regions_lib.2786", // Микуд
                shortcode: "2786",
            },
            {
                name: "regions_lib.2780", // Шуазёль
                shortcode: "2780",
            },
        ]
    },
    {
        shortcode: "RS", // Сербия
        name: "regions_lib.RS",
        regions: [
            {
                name: "regions_lib.2789", // Vojvodina
                shortcode: "2789",
            },
            {
                name: "regions_lib.2790", // Центральная Сербия
                shortcode: "2790",
            },
        ]
    },
    {
        shortcode: "SG", // Сингапур
        name: "regions_lib.SG",
        regions: [
        ]
    },
    {
        shortcode: "SX", // Синт-Мартен
        name: "regions_lib.SX",
        regions: [
        ]
    },
    {
        shortcode: "SY", // Сирия
        name: "regions_lib.SY",
        regions: [
            {
                name: "regions_lib.2800", // Damascus Governorate
                shortcode: "2800",
            },
            {
                name: "regions_lib.2803", // Мухафаза Дамаск
                shortcode: "2803",
            },
            {
                name: "regions_lib.2795", // Мухафаза Дейр-эз-Зор
                shortcode: "2795",
            },
            {
                name: "regions_lib.2794", // Мухафаза Деръа
                shortcode: "2794",
            },
            {
                name: "regions_lib.2796", // Мухафаза Идлиб
                shortcode: "2796",
            },
            {
                name: "regions_lib.2802", // Мухафаза Латакия
                shortcode: "2802",
            },
            {
                name: "regions_lib.2793", // Мухафаза Ракка
                shortcode: "2793",
            },
            {
                name: "regions_lib.2798", // Мухафаза Тартус
                shortcode: "2798",
            },
            {
                name: "regions_lib.2804", // Мухафаза Халеб
                shortcode: "2804",
            },
            {
                name: "regions_lib.2799", // Мухафаза Хама
                shortcode: "2799",
            },
            {
                name: "regions_lib.2797", // Мухафаза Хасеке
                shortcode: "2797",
            },
            {
                name: "regions_lib.2801", // Мухафаза Хомс
                shortcode: "2801",
            },
            {
                name: "regions_lib.2791", // Мухафаза Эль-Кунейтра
                shortcode: "2791",
            },
            {
                name: "regions_lib.2792", // Мухафаза Эс-Сувейда
                shortcode: "2792",
            },
        ]
    },
    {
        shortcode: "SK", // Словакия
        name: "regions_lib.SK",
        regions: [
            {
                name: "regions_lib.2808", // Banska Bystrica
                shortcode: "2808",
            },
            {
                name: "regions_lib.2806", // Bratislava
                shortcode: "2806",
            },
            {
                name: "regions_lib.2811", // Kosice
                shortcode: "2811",
            },
            {
                name: "regions_lib.2810", // Nitra
                shortcode: "2810",
            },
            {
                name: "regions_lib.2812", // Presov
                shortcode: "2812",
            },
            {
                name: "regions_lib.2807", // Trencin
                shortcode: "2807",
            },
            {
                name: "regions_lib.2805", // Trnava
                shortcode: "2805",
            },
            {
                name: "regions_lib.2809", // Zilina
                shortcode: "2809",
            },
        ]
    },
    {
        shortcode: "SI", // Словения
        name: "regions_lib.SI",
        regions: [
            {
                name: "regions_lib.2878", // Ankaran
                shortcode: "2878",
            },
            {
                name: "regions_lib.2857", // Benedikt
                shortcode: "2857",
            },
            {
                name: "regions_lib.2829", // Bistrica ob Sotli
                shortcode: "2829",
            },
            {
                name: "regions_lib.2834", // Bloke
                shortcode: "2834",
            },
            {
                name: "regions_lib.2900", // Borovnica
                shortcode: "2900",
            },
            {
                name: "regions_lib.2838", // Cankova
                shortcode: "2838",
            },
            {
                name: "regions_lib.2841", // Cerkvenjak
                shortcode: "2841",
            },
            {
                name: "regions_lib.2853", // Cirkulane
                shortcode: "2853",
            },
            {
                name: "regions_lib.2861", // Destrnik
                shortcode: "2861",
            },
            {
                name: "regions_lib.2820", // Dobje
                shortcode: "2820",
            },
            {
                name: "regions_lib.2897", // Dobrepolje
                shortcode: "2897",
            },
            {
                name: "regions_lib.2849", // Dobrna
                shortcode: "2849",
            },
            {
                name: "regions_lib.2825", // Dobrovnik
                shortcode: "2825",
            },
            {
                name: "regions_lib.2883", // Dolenjske Toplice
                shortcode: "2883",
            },
            {
                name: "regions_lib.2870", // Dornava
                shortcode: "2870",
            },
            {
                name: "regions_lib.2871", // Gorje
                shortcode: "2871",
            },
            {
                name: "regions_lib.2863", // Gornji Grad
                shortcode: "2863",
            },
            {
                name: "regions_lib.2848", // Gornji Petrovci
                shortcode: "2848",
            },
            {
                name: "regions_lib.2850", // Grad
                shortcode: "2850",
            },
            {
                name: "regions_lib.2892", // Hajdina
                shortcode: "2892",
            },
            {
                name: "regions_lib.2813", // Hodos
                shortcode: "2813",
            },
            {
                name: "regions_lib.2867", // Horjul
                shortcode: "2867",
            },
            {
                name: "regions_lib.2909", // Hrpelje-Kozina
                shortcode: "2909",
            },
            {
                name: "regions_lib.2818", // Jezersko
                shortcode: "2818",
            },
            {
                name: "regions_lib.2816", // Kobilje
                shortcode: "2816",
            },
            {
                name: "regions_lib.2885", // Komen
                shortcode: "2885",
            },
            {
                name: "regions_lib.2858", // Kostanjevica na Krki
                shortcode: "2858",
            },
            {
                name: "regions_lib.2817", // Kostel
                shortcode: "2817",
            },
            {
                name: "regions_lib.2879", // Kozje
                shortcode: "2879",
            },
            {
                name: "regions_lib.2832", // Kuzma
                shortcode: "2832",
            },
            {
                name: "regions_lib.2864", // Ljubno
                shortcode: "2864",
            },
            {
                name: "regions_lib.2887", // Log-Dragomer
                shortcode: "2887",
            },
            {
                name: "regions_lib.2872", // Lovrenc na Pohorju
                shortcode: "2872",
            },
            {
                name: "regions_lib.2842", // Makole
                shortcode: "2842",
            },
            {
                name: "regions_lib.2901", // Markovci
                shortcode: "2901",
            },
            {
                name: "regions_lib.2828", // Mirna
                shortcode: "2828",
            },
            {
                name: "regions_lib.2869", // Mokronog-Trebelno
                shortcode: "2869",
            },
            {
                name: "regions_lib.2904", // Mozirje
                shortcode: "2904",
            },
            {
                name: "regions_lib.2884", // Muta
                shortcode: "2884",
            },
            {
                name: "regions_lib.2860", // Nazarje
                shortcode: "2860",
            },
            {
                name: "regions_lib.2888", // Občina Apače
                shortcode: "2888",
            },
            {
                name: "regions_lib.2880", // Občina Bovec
                shortcode: "2880",
            },
            {
                name: "regions_lib.2896", // Občina Divača
                shortcode: "2896",
            },
            {
                name: "regions_lib.2902", // Občina Gorišnica
                shortcode: "2902",
            },
            {
                name: "regions_lib.2856", // Občina Juršinci
                shortcode: "2856",
            },
            {
                name: "regions_lib.2910", // Občina Kobarid
                shortcode: "2910",
            },
            {
                name: "regions_lib.2891", // Občina Križevci
                shortcode: "2891",
            },
            {
                name: "regions_lib.2898", // Občina Loška Dolina
                shortcode: "2898",
            },
            {
                name: "regions_lib.2840", // Občina Loški Potok
                shortcode: "2840",
            },
            {
                name: "regions_lib.2831", // Občina Luče
                shortcode: "2831",
            },
            {
                name: "regions_lib.2903", // Občina Majšperk
                shortcode: "2903",
            },
            {
                name: "regions_lib.2890", // Občina Mežica
                shortcode: "2890",
            },
            {
                name: "regions_lib.2865", // Občina Mirna Peč
                shortcode: "2865",
            },
            {
                name: "regions_lib.2881", // Občina Podčetrtek
                shortcode: "2881",
            },
            {
                name: "regions_lib.2827", // Občina Razkrižje
                shortcode: "2827",
            },
            {
                name: "regions_lib.2912", // Občina Renče-Vogrsko
                shortcode: "2912",
            },
            {
                name: "regions_lib.2852", // Občina Rečica ob Savinji
                shortcode: "2852",
            },
            {
                name: "regions_lib.2877", // Občina Rogašovci
                shortcode: "2877",
            },
            {
                name: "regions_lib.2895", // Občina Semič
                shortcode: "2895",
            },
            {
                name: "regions_lib.2847", // Občina Sodražica
                shortcode: "2847",
            },
            {
                name: "regions_lib.2815", // Občina Solčava
                shortcode: "2815",
            },
            {
                name: "regions_lib.2846", // Občina Središče ob Dravi
                shortcode: "2846",
            },
            {
                name: "regions_lib.2908", // Občina Starše
                shortcode: "2908",
            },
            {
                name: "regions_lib.2894", // Občina Straža
                shortcode: "2894",
            },
            {
                name: "regions_lib.2821", // Občina Sveti Andraž v Slovenskih Goricah
                shortcode: "2821",
            },
            {
                name: "regions_lib.2868", // Občina Sveti Jurij ob Ščavnici
                shortcode: "2868",
            },
            {
                name: "regions_lib.2844", // Občina Sveti Tomaž
                shortcode: "2844",
            },
            {
                name: "regions_lib.2905", // Občina Tišina
                shortcode: "2905",
            },
            {
                name: "regions_lib.2826", // Občina Trnovska vas
                shortcode: "2826",
            },
            {
                name: "regions_lib.2882", // Občina Turnišče
                shortcode: "2882",
            },
            {
                name: "regions_lib.2907", // Občina Velike Lašče
                shortcode: "2907",
            },
            {
                name: "regions_lib.2823", // Občina Veržej
                shortcode: "2823",
            },
            {
                name: "regions_lib.2836", // Občina Zavrč
                shortcode: "2836",
            },
            {
                name: "regions_lib.2906", // Občina Črenšovci
                shortcode: "2906",
            },
            {
                name: "regions_lib.2886", // Občina Črna na Koroškem
                shortcode: "2886",
            },
            {
                name: "regions_lib.2833", // Občina Šalovci
                shortcode: "2833",
            },
            {
                name: "regions_lib.2866", // Občina Šentrupert
                shortcode: "2866",
            },
            {
                name: "regions_lib.2876", // Občina Škocjan
                shortcode: "2876",
            },
            {
                name: "regions_lib.2875", // Občina Šmarješke Toplice
                shortcode: "2875",
            },
            {
                name: "regions_lib.2874", // Občina Šmartno ob Paki
                shortcode: "2874",
            },
            {
                name: "regions_lib.2911", // Občina Štore
                shortcode: "2911",
            },
            {
                name: "regions_lib.2824", // Občina Žetale
                shortcode: "2824",
            },
            {
                name: "regions_lib.2837", // Odranci
                shortcode: "2837",
            },
            {
                name: "regions_lib.2899", // Oplotnica
                shortcode: "2899",
            },
            {
                name: "regions_lib.2814", // Osilnica
                shortcode: "2814",
            },
            {
                name: "regions_lib.2839", // Podlehnik
                shortcode: "2839",
            },
            {
                name: "regions_lib.2855", // Polzela
                shortcode: "2855",
            },
            {
                name: "regions_lib.2889", // Preddvor
                shortcode: "2889",
            },
            {
                name: "regions_lib.2822", // Ribnica na Pohorju
                shortcode: "2822",
            },
            {
                name: "regions_lib.2873", // Rogatec
                shortcode: "2873",
            },
            {
                name: "regions_lib.2854", // Sveta Ana
                shortcode: "2854",
            },
            {
                name: "regions_lib.2845", // Sveta Trojica v Slovenskih Goricah
                shortcode: "2845",
            },
            {
                name: "regions_lib.2843", // Sveti Jurij v Slovenskih Goricah
                shortcode: "2843",
            },
            {
                name: "regions_lib.2835", // Tabor
                shortcode: "2835",
            },
            {
                name: "regions_lib.2893", // Trzin
                shortcode: "2893",
            },
            {
                name: "regions_lib.2830", // Velika Polana
                shortcode: "2830",
            },
            {
                name: "regions_lib.2851", // Vitanje
                shortcode: "2851",
            },
            {
                name: "regions_lib.2819", // Vransko
                shortcode: "2819",
            },
            {
                name: "regions_lib.2862", // Vuzenica
                shortcode: "2862",
            },
            {
                name: "regions_lib.2859", // Подвелка
                shortcode: "2859",
            },
        ]
    },
    {
        shortcode: "SB", // Соломоновы Острова
        name: "regions_lib.SB",
        regions: [
            {
                name: "regions_lib.2915", // Central Province
                shortcode: "2915",
            },
            {
                name: "regions_lib.2917", // Choiseul
                shortcode: "2917",
            },
            {
                name: "regions_lib.2920", // Guadalcanal Province
                shortcode: "2920",
            },
            {
                name: "regions_lib.2919", // Honiara
                shortcode: "2919",
            },
            {
                name: "regions_lib.2916", // Isabel Province
                shortcode: "2916",
            },
            {
                name: "regions_lib.2918", // Makira-Ulawa Province
                shortcode: "2918",
            },
            {
                name: "regions_lib.2922", // Malaita Province
                shortcode: "2922",
            },
            {
                name: "regions_lib.2913", // Rennell and Bellona
                shortcode: "2913",
            },
            {
                name: "regions_lib.2914", // Temotu Province
                shortcode: "2914",
            },
            {
                name: "regions_lib.2921", // Western Province
                shortcode: "2921",
            },
        ]
    },
    {
        shortcode: "SO", // Сомали
        name: "regions_lib.SO",
        regions: [
            {
                name: "regions_lib.2932", // Awdal
                shortcode: "2932",
            },
            {
                name: "regions_lib.2927", // Bakool
                shortcode: "2927",
            },
            {
                name: "regions_lib.2939", // Banaadir
                shortcode: "2939",
            },
            {
                name: "regions_lib.2933", // Bari
                shortcode: "2933",
            },
            {
                name: "regions_lib.2938", // Bay
                shortcode: "2938",
            },
            {
                name: "regions_lib.2935", // Galguduud
                shortcode: "2935",
            },
            {
                name: "regions_lib.2928", // Gedo
                shortcode: "2928",
            },
            {
                name: "regions_lib.2926", // Gobolka Jubbada Dhexe
                shortcode: "2926",
            },
            {
                name: "regions_lib.2923", // Gobolka Jubbada Hoose
                shortcode: "2923",
            },
            {
                name: "regions_lib.2930", // Gobolka Shabeellaha Dhexe
                shortcode: "2930",
            },
            {
                name: "regions_lib.2940", // Hiiraan
                shortcode: "2940",
            },
            {
                name: "regions_lib.2936", // Lower Shabeelle
                shortcode: "2936",
            },
            {
                name: "regions_lib.2934", // Mudug
                shortcode: "2934",
            },
            {
                name: "regions_lib.2929", // Nugaal
                shortcode: "2929",
            },
            {
                name: "regions_lib.2931", // Sanaag
                shortcode: "2931",
            },
            {
                name: "regions_lib.2924", // Sool
                shortcode: "2924",
            },
            {
                name: "regions_lib.2925", // Togdheer
                shortcode: "2925",
            },
            {
                name: "regions_lib.2937", // Woqooyi Galbeed
                shortcode: "2937",
            },
        ]
    },
    {
        shortcode: "SD", // Судан
        name: "regions_lib.SD",
        regions: [
            {
                name: "regions_lib.2955", // Al Jazīrah
                shortcode: "2955",
            },
            {
                name: "regions_lib.2949", // Al Qaḑārif
                shortcode: "2949",
            },
            {
                name: "regions_lib.2941", // Blue Nile
                shortcode: "2941",
            },
            {
                name: "regions_lib.2954", // Central Darfur
                shortcode: "2954",
            },
            {
                name: "regions_lib.2951", // Eastern Darfur
                shortcode: "2951",
            },
            {
                name: "regions_lib.2946", // Kassala
                shortcode: "2946",
            },
            {
                name: "regions_lib.2958", // Khartoum State
                shortcode: "2958",
            },
            {
                name: "regions_lib.2957", // North Kordofan
                shortcode: "2957",
            },
            {
                name: "regions_lib.2942", // Northern
                shortcode: "2942",
            },
            {
                name: "regions_lib.2953", // Northern Darfur
                shortcode: "2953",
            },
            {
                name: "regions_lib.2944", // River Nile
                shortcode: "2944",
            },
            {
                name: "regions_lib.2952", // Sinnār
                shortcode: "2952",
            },
            {
                name: "regions_lib.2956", // Southern Darfur
                shortcode: "2956",
            },
            {
                name: "regions_lib.2945", // Southern Kordofan
                shortcode: "2945",
            },
            {
                name: "regions_lib.2948", // West Kordofan State
                shortcode: "2948",
            },
            {
                name: "regions_lib.2943", // Western Darfur
                shortcode: "2943",
            },
            {
                name: "regions_lib.2947", // White Nile
                shortcode: "2947",
            },
            {
                name: "regions_lib.2950", // провинция Красное Море
                shortcode: "2950",
            },
        ]
    },
    {
        shortcode: "SR", // Суринам
        name: "regions_lib.SR",
        regions: [
            {
                name: "regions_lib.2960", // Distrikt Brokopondo
                shortcode: "2960",
            },
            {
                name: "regions_lib.2964", // Distrikt Commewijne
                shortcode: "2964",
            },
            {
                name: "regions_lib.2959", // Distrikt Coronie
                shortcode: "2959",
            },
            {
                name: "regions_lib.2962", // Distrikt Marowijne
                shortcode: "2962",
            },
            {
                name: "regions_lib.2966", // Distrikt Nickerie
                shortcode: "2966",
            },
            {
                name: "regions_lib.2963", // Distrikt Para
                shortcode: "2963",
            },
            {
                name: "regions_lib.2968", // Distrikt Paramaribo
                shortcode: "2968",
            },
            {
                name: "regions_lib.2961", // Distrikt Saramacca
                shortcode: "2961",
            },
            {
                name: "regions_lib.2965", // Distrikt Sipaliwini
                shortcode: "2965",
            },
            {
                name: "regions_lib.2967", // Distrikt Wanica
                shortcode: "2967",
            },
        ]
    },
    {
        shortcode: "SL", // Сьерра-Леоне
        name: "regions_lib.SL",
        regions: [
            {
                name: "regions_lib.2970", // Eastern Province
                shortcode: "2970",
            },
            {
                name: "regions_lib.2969", // North West Province
                shortcode: "2969",
            },
            {
                name: "regions_lib.2973", // Northern Province
                shortcode: "2973",
            },
            {
                name: "regions_lib.2971", // Southern Province
                shortcode: "2971",
            },
            {
                name: "regions_lib.2972", // Western Area
                shortcode: "2972",
            },
        ]
    },
    {
        shortcode: "TJ", // Таджикистан
        name: "regions_lib.TJ",
        regions: [
            {
                name: "regions_lib.2975", // Dushanbe
                shortcode: "2975",
            },
            {
                name: "regions_lib.2974", // Gorno-Badakhshan
                shortcode: "2974",
            },
            {
                name: "regions_lib.2978", // Viloyati Khatlon
                shortcode: "2978",
            },
            {
                name: "regions_lib.2976", // Районы республиканского подчинения
                shortcode: "2976",
            },
            {
                name: "regions_lib.2977", // Согдийская область
                shortcode: "2977",
            },
        ]
    },
    {
        shortcode: "TH", // Таиланд
        name: "regions_lib.TH",
        regions: [
            {
                name: "regions_lib.3055", // Bangkok
                shortcode: "3055",
            },
            {
                name: "regions_lib.2991", // Changwat Amnat Charoen
                shortcode: "2991",
            },
            {
                name: "regions_lib.2985", // Changwat Ang Thong
                shortcode: "2985",
            },
            {
                name: "regions_lib.2994", // Changwat Bueng Kan
                shortcode: "2994",
            },
            {
                name: "regions_lib.3050", // Changwat Buri Ram
                shortcode: "3050",
            },
            {
                name: "regions_lib.3017", // Changwat Chachoengsao
                shortcode: "3017",
            },
            {
                name: "regions_lib.2988", // Changwat Chai Nat
                shortcode: "2988",
            },
            {
                name: "regions_lib.3036", // Changwat Chaiyaphum
                shortcode: "3036",
            },
            {
                name: "regions_lib.3046", // Changwat Chanthaburi
                shortcode: "3046",
            },
            {
                name: "regions_lib.3040", // Changwat Chiang Rai
                shortcode: "3040",
            },
            {
                name: "regions_lib.3047", // Changwat Chon Buri
                shortcode: "3047",
            },
            {
                name: "regions_lib.3002", // Changwat Chumphon
                shortcode: "3002",
            },
            {
                name: "regions_lib.3032", // Changwat Kalasin
                shortcode: "3032",
            },
            {
                name: "regions_lib.3021", // Changwat Kamphaeng Phet
                shortcode: "3021",
            },
            {
                name: "regions_lib.3029", // Changwat Kanchanaburi
                shortcode: "3029",
            },
            {
                name: "regions_lib.3052", // Changwat Khon Kaen
                shortcode: "3052",
            },
            {
                name: "regions_lib.2998", // Changwat Krabi
                shortcode: "2998",
            },
            {
                name: "regions_lib.3022", // Changwat Lampang
                shortcode: "3022",
            },
            {
                name: "regions_lib.2992", // Changwat Lamphun
                shortcode: "2992",
            },
            {
                name: "regions_lib.3013", // Changwat Loei
                shortcode: "3013",
            },
            {
                name: "regions_lib.3023", // Changwat Lop Buri
                shortcode: "3023",
            },
            {
                name: "regions_lib.2986", // Changwat Mae Hong Son
                shortcode: "2986",
            },
            {
                name: "regions_lib.3031", // Changwat Maha Sarakham
                shortcode: "3031",
            },
            {
                name: "regions_lib.2990", // Changwat Mukdahan
                shortcode: "2990",
            },
            {
                name: "regions_lib.2983", // Changwat Nakhon Nayok
                shortcode: "2983",
            },
            {
                name: "regions_lib.3030", // Changwat Nakhon Pathom
                shortcode: "3030",
            },
            {
                name: "regions_lib.3019", // Changwat Nakhon Phanom
                shortcode: "3019",
            },
            {
                name: "regions_lib.3054", // Changwat Nakhon Ratchasima
                shortcode: "3054",
            },
            {
                name: "regions_lib.3034", // Changwat Nakhon Sawan
                shortcode: "3034",
            },
            {
                name: "regions_lib.3048", // Changwat Nakhon Si Thammarat
                shortcode: "3048",
            },
            {
                name: "regions_lib.2999", // Changwat Nan
                shortcode: "2999",
            },
            {
                name: "regions_lib.3024", // Changwat Narathiwat
                shortcode: "3024",
            },
            {
                name: "regions_lib.3003", // Changwat Nong Bua Lamphu
                shortcode: "3003",
            },
            {
                name: "regions_lib.3004", // Changwat Nong Khai
                shortcode: "3004",
            },
            {
                name: "regions_lib.3039", // Changwat Nonthaburi
                shortcode: "3039",
            },
            {
                name: "regions_lib.3038", // Changwat Pathum Thani
                shortcode: "3038",
            },
            {
                name: "regions_lib.3018", // Changwat Pattani
                shortcode: "3018",
            },
            {
                name: "regions_lib.2984", // Changwat Phangnga
                shortcode: "2984",
            },
            {
                name: "regions_lib.3005", // Changwat Phatthalung
                shortcode: "3005",
            },
            {
                name: "regions_lib.2997", // Changwat Phayao
                shortcode: "2997",
            },
            {
                name: "regions_lib.3000", // Changwat Phetchaburi
                shortcode: "3000",
            },
            {
                name: "regions_lib.3006", // Changwat Phichit
                shortcode: "3006",
            },
            {
                name: "regions_lib.3027", // Changwat Phitsanulok
                shortcode: "3027",
            },
            {
                name: "regions_lib.2995", // Changwat Phrae
                shortcode: "2995",
            },
            {
                name: "regions_lib.3001", // Changwat Prachin Buri
                shortcode: "3001",
            },
            {
                name: "regions_lib.3009", // Changwat Prachuap Khiri Khan
                shortcode: "3009",
            },
            {
                name: "regions_lib.2979", // Changwat Ranong
                shortcode: "2979",
            },
            {
                name: "regions_lib.3028", // Changwat Ratchaburi
                shortcode: "3028",
            },
            {
                name: "regions_lib.3020", // Changwat Rayong
                shortcode: "3020",
            },
            {
                name: "regions_lib.3041", // Changwat Roi Et
                shortcode: "3041",
            },
            {
                name: "regions_lib.3010", // Changwat Sa Kaeo
                shortcode: "3010",
            },
            {
                name: "regions_lib.3037", // Changwat Sakon Nakhon
                shortcode: "3037",
            },
            {
                name: "regions_lib.3042", // Changwat Samut Prakan
                shortcode: "3042",
            },
            {
                name: "regions_lib.3011", // Changwat Samut Sakhon
                shortcode: "3011",
            },
            {
                name: "regions_lib.2980", // Changwat Samut Songkhram
                shortcode: "2980",
            },
            {
                name: "regions_lib.3015", // Changwat Saraburi
                shortcode: "3015",
            },
            {
                name: "regions_lib.2987", // Changwat Satun
                shortcode: "2987",
            },
            {
                name: "regions_lib.3045", // Changwat Si Sa Ket
                shortcode: "3045",
            },
            {
                name: "regions_lib.2981", // Changwat Sing Buri
                shortcode: "2981",
            },
            {
                name: "regions_lib.3044", // Changwat Songkhla
                shortcode: "3044",
            },
            {
                name: "regions_lib.3012", // Changwat Sukhothai
                shortcode: "3012",
            },
            {
                name: "regions_lib.3026", // Changwat Suphan Buri
                shortcode: "3026",
            },
            {
                name: "regions_lib.3035", // Changwat Surat Thani
                shortcode: "3035",
            },
            {
                name: "regions_lib.3043", // Changwat Surin
                shortcode: "3043",
            },
            {
                name: "regions_lib.3016", // Changwat Tak
                shortcode: "3016",
            },
            {
                name: "regions_lib.3014", // Changwat Trang
                shortcode: "3014",
            },
            {
                name: "regions_lib.2982", // Changwat Trat
                shortcode: "2982",
            },
            {
                name: "regions_lib.3053", // Changwat Ubon Ratchathani
                shortcode: "3053",
            },
            {
                name: "regions_lib.3049", // Changwat Udon Thani
                shortcode: "3049",
            },
            {
                name: "regions_lib.2989", // Changwat Uthai Thani
                shortcode: "2989",
            },
            {
                name: "regions_lib.3007", // Changwat Yala
                shortcode: "3007",
            },
            {
                name: "regions_lib.3008", // Changwat Yasothon
                shortcode: "3008",
            },
            {
                name: "regions_lib.3051", // Chiang Mai Province
                shortcode: "3051",
            },
            {
                name: "regions_lib.3033", // Phetchabun
                shortcode: "3033",
            },
            {
                name: "regions_lib.3025", // Phra Nakhon Si Ayutthaya
                shortcode: "3025",
            },
            {
                name: "regions_lib.2993", // Phuket
                shortcode: "2993",
            },
            {
                name: "regions_lib.2996", // Uttaradit
                shortcode: "2996",
            },
        ]
    },
    {
        shortcode: "TW", // Тайвань
        name: "regions_lib.TW",
        regions: [
            {
                name: "regions_lib.3056", // Fukien
                shortcode: "3056",
            },
            {
                name: "regions_lib.3059", // Taiwan
                shortcode: "3059",
            },
            {
                name: "regions_lib.3058", // Гаосюн
                shortcode: "3058",
            },
            {
                name: "regions_lib.3057", // Тайбэй
                shortcode: "3057",
            },
        ]
    },
    {
        shortcode: "TZ", // Танзания
        name: "regions_lib.TZ",
        regions: [
            {
                name: "regions_lib.3079", // Arusha Region
                shortcode: "3079",
            },
            {
                name: "regions_lib.3070", // Coast Region
                shortcode: "3070",
            },
            {
                name: "regions_lib.3090", // Dar es Salaam Region
                shortcode: "3090",
            },
            {
                name: "regions_lib.3083", // Dodoma Region
                shortcode: "3083",
            },
            {
                name: "regions_lib.3080", // Geita
                shortcode: "3080",
            },
            {
                name: "regions_lib.3068", // Iringa Region
                shortcode: "3068",
            },
            {
                name: "regions_lib.3085", // Kagera Region
                shortcode: "3085",
            },
            {
                name: "regions_lib.3064", // Katavi
                shortcode: "3064",
            },
            {
                name: "regions_lib.3084", // Kigoma Region
                shortcode: "3084",
            },
            {
                name: "regions_lib.3078", // Kilimanjaro Region
                shortcode: "3078",
            },
            {
                name: "regions_lib.3067", // Lindi Region
                shortcode: "3067",
            },
            {
                name: "regions_lib.3071", // Manyara Region
                shortcode: "3071",
            },
            {
                name: "regions_lib.3081", // Mara Region
                shortcode: "3081",
            },
            {
                name: "regions_lib.3088", // Mbeya Region
                shortcode: "3088",
            },
            {
                name: "regions_lib.3086", // Morogoro
                shortcode: "3086",
            },
            {
                name: "regions_lib.3074", // Mtwara Region
                shortcode: "3074",
            },
            {
                name: "regions_lib.3089", // Mwanza Region
                shortcode: "3089",
            },
            {
                name: "regions_lib.3066", // Njombe
                shortcode: "3066",
            },
            {
                name: "regions_lib.3063", // Pemba North Region
                shortcode: "3063",
            },
            {
                name: "regions_lib.3062", // Pemba South Region
                shortcode: "3062",
            },
            {
                name: "regions_lib.3069", // Rukwa
                shortcode: "3069",
            },
            {
                name: "regions_lib.3073", // Ruvuma Region
                shortcode: "3073",
            },
            {
                name: "regions_lib.3076", // Shinyanga Region
                shortcode: "3076",
            },
            {
                name: "regions_lib.3077", // Simiyu
                shortcode: "3077",
            },
            {
                name: "regions_lib.3075", // Singida Region
                shortcode: "3075",
            },
            {
                name: "regions_lib.3072", // Songwe
                shortcode: "3072",
            },
            {
                name: "regions_lib.3087", // Tabora Region
                shortcode: "3087",
            },
            {
                name: "regions_lib.3082", // Tanga Region
                shortcode: "3082",
            },
            {
                name: "regions_lib.3060", // Zanzibar Central/South Region
                shortcode: "3060",
            },
            {
                name: "regions_lib.3061", // Zanzibar North Region
                shortcode: "3061",
            },
            {
                name: "regions_lib.3065", // Zanzibar Urban/West Region
                shortcode: "3065",
            },
        ]
    },
    {
        shortcode: "TL", // Тимор-Лесте
        name: "regions_lib.TL",
        regions: [
            {
                name: "regions_lib.3099", // Bobonaro
                shortcode: "3099",
            },
            {
                name: "regions_lib.3103", // Díli
                shortcode: "3103",
            },
            {
                name: "regions_lib.3100", // Ermera
                shortcode: "3100",
            },
            {
                name: "regions_lib.3095", // Oecusse
                shortcode: "3095",
            },
            {
                name: "regions_lib.3093", // Айлеу
                shortcode: "3093",
            },
            {
                name: "regions_lib.3091", // Айнару
                shortcode: "3091",
            },
            {
                name: "regions_lib.3101", // Баукау
                shortcode: "3101",
            },
            {
                name: "regions_lib.3098", // Викеке
                shortcode: "3098",
            },
            {
                name: "regions_lib.3102", // Кова-Лима
                shortcode: "3102",
            },
            {
                name: "regions_lib.3096", // Лаутен
                shortcode: "3096",
            },
            {
                name: "regions_lib.3097", // Ликиса
                shortcode: "3097",
            },
            {
                name: "regions_lib.3092", // Манатуту
                shortcode: "3092",
            },
            {
                name: "regions_lib.3094", // Мануфахи
                shortcode: "3094",
            },
        ]
    },
    {
        shortcode: "TG", // Того
        name: "regions_lib.TG",
        regions: [
            {
                name: "regions_lib.3105", // Кара
                shortcode: "3105",
            },
            {
                name: "regions_lib.3106", // Область Саванн
                shortcode: "3106",
            },
            {
                name: "regions_lib.3107", // Плато
                shortcode: "3107",
            },
            {
                name: "regions_lib.3108", // Приморская область
                shortcode: "3108",
            },
            {
                name: "regions_lib.3104", // Центральная область
                shortcode: "3104",
            },
        ]
    },
    {
        shortcode: "TK", // Токелау
        name: "regions_lib.TK",
        regions: [
            {
                name: "regions_lib.3111", // Atafu
                shortcode: "3111",
            },
            {
                name: "regions_lib.3110", // Fakaofo
                shortcode: "3110",
            },
            {
                name: "regions_lib.3109", // Нукунону
                shortcode: "3109",
            },
        ]
    },
    {
        shortcode: "TO", // Тонга
        name: "regions_lib.TO",
        regions: [
            {
                name: "regions_lib.3113", // Eua
                shortcode: "3113",
            },
            {
                name: "regions_lib.3114", // Ha‘apai
                shortcode: "3114",
            },
            {
                name: "regions_lib.3116", // Tongatapu
                shortcode: "3116",
            },
            {
                name: "regions_lib.3115", // Vava‘u
                shortcode: "3115",
            },
            {
                name: "regions_lib.3112", // Острова Ниуас
                shortcode: "3112",
            },
        ]
    },
    {
        shortcode: "TT", // Тринидад и Тобаго
        name: "regions_lib.TT",
        regions: [
            {
                name: "regions_lib.3118", // Arima
                shortcode: "3118",
            },
            {
                name: "regions_lib.3124", // Chaguanas
                shortcode: "3124",
            },
            {
                name: "regions_lib.3120", // City of Port of Spain
                shortcode: "3120",
            },
            {
                name: "regions_lib.3130", // Couva-Tabaquite-Talparo
                shortcode: "3130",
            },
            {
                name: "regions_lib.3128", // Diego Martin
                shortcode: "3128",
            },
            {
                name: "regions_lib.3119", // Mayaro
                shortcode: "3119",
            },
            {
                name: "regions_lib.3126", // Penal/Debe
                shortcode: "3126",
            },
            {
                name: "regions_lib.3117", // Point Fortin
                shortcode: "3117",
            },
            {
                name: "regions_lib.3127", // Princes Town
                shortcode: "3127",
            },
            {
                name: "regions_lib.3121", // San Fernando
                shortcode: "3121",
            },
            {
                name: "regions_lib.3129", // San Juan/Laventille
                shortcode: "3129",
            },
            {
                name: "regions_lib.3123", // Sangre Grande
                shortcode: "3123",
            },
            {
                name: "regions_lib.3125", // Siparia
                shortcode: "3125",
            },
            {
                name: "regions_lib.3122", // Tobago
                shortcode: "3122",
            },
            {
                name: "regions_lib.3131", // Tunapuna/Piarco
                shortcode: "3131",
            },
        ]
    },
    {
        shortcode: "TV", // Тувалу
        name: "regions_lib.TV",
        regions: [
            {
                name: "regions_lib.3139", // Funafuti
                shortcode: "3139",
            },
            {
                name: "regions_lib.3135", // Nanumanga
                shortcode: "3135",
            },
            {
                name: "regions_lib.3137", // Nanumea
                shortcode: "3137",
            },
            {
                name: "regions_lib.3136", // Niutao
                shortcode: "3136",
            },
            {
                name: "regions_lib.3133", // Nui
                shortcode: "3133",
            },
            {
                name: "regions_lib.3134", // Nukufetau
                shortcode: "3134",
            },
            {
                name: "regions_lib.3132", // Nukulaelae
                shortcode: "3132",
            },
            {
                name: "regions_lib.3138", // Vaitupu
                shortcode: "3138",
            },
        ]
    },
    {
        shortcode: "TN", // Тунис
        name: "regions_lib.TN",
        regions: [
            {
                name: "regions_lib.3147", // Gafsa Governorate
                shortcode: "3147",
            },
            {
                name: "regions_lib.3156", // Gouvernorat de Ben Arous
                shortcode: "3156",
            },
            {
                name: "regions_lib.3158", // Gouvernorat de Bizerte
                shortcode: "3158",
            },
            {
                name: "regions_lib.3146", // Gouvernorat de Béja
                shortcode: "3146",
            },
            {
                name: "regions_lib.3148", // Gouvernorat de Gabès
                shortcode: "3148",
            },
            {
                name: "regions_lib.3150", // Gouvernorat de Jendouba
                shortcode: "3150",
            },
            {
                name: "regions_lib.3159", // Gouvernorat de Kairouan
                shortcode: "3159",
            },
            {
                name: "regions_lib.3154", // Gouvernorat de Kasserine
                shortcode: "3154",
            },
            {
                name: "regions_lib.3145", // Gouvernorat de Kef
                shortcode: "3145",
            },
            {
                name: "regions_lib.3142", // Gouvernorat de Kébili
                shortcode: "3142",
            },
            {
                name: "regions_lib.3151", // Gouvernorat de Mahdia
                shortcode: "3151",
            },
            {
                name: "regions_lib.3157", // Gouvernorat de Monastir
                shortcode: "3157",
            },
            {
                name: "regions_lib.3155", // Gouvernorat de Médenine
                shortcode: "3155",
            },
            {
                name: "regions_lib.3161", // Gouvernorat de Nabeul
                shortcode: "3161",
            },
            {
                name: "regions_lib.3162", // Gouvernorat de Sfax
                shortcode: "3162",
            },
            {
                name: "regions_lib.3153", // Gouvernorat de Sidi Bouzid
                shortcode: "3153",
            },
            {
                name: "regions_lib.3144", // Gouvernorat de Siliana
                shortcode: "3144",
            },
            {
                name: "regions_lib.3160", // Gouvernorat de Sousse
                shortcode: "3160",
            },
            {
                name: "regions_lib.3140", // Gouvernorat de Tozeur
                shortcode: "3140",
            },
            {
                name: "regions_lib.3163", // Gouvernorat de Tunis
                shortcode: "3163",
            },
            {
                name: "regions_lib.3143", // Gouvernorat de Zaghouan
                shortcode: "3143",
            },
            {
                name: "regions_lib.3152", // Gouvernorat de l’Ariana
                shortcode: "3152",
            },
            {
                name: "regions_lib.3149", // Мануба
                shortcode: "3149",
            },
            {
                name: "regions_lib.3141", // Татавин
                shortcode: "3141",
            },
        ]
    },
    {
        shortcode: "TM", // Туркменистан
        name: "regions_lib.TM",
        regions: [
            {
                name: "regions_lib.3167", // Daşoguz Welaýaty
                shortcode: "3167",
            },
            {
                name: "regions_lib.3166", // Ахалский велаят
                shortcode: "3166",
            },
            {
                name: "regions_lib.3165", // Ашхабад
                shortcode: "3165",
            },
            {
                name: "regions_lib.3164", // Балканский велаят
                shortcode: "3164",
            },
            {
                name: "regions_lib.3168", // Лебапский велаят
                shortcode: "3168",
            },
            {
                name: "regions_lib.3169", // Марыйский велаят
                shortcode: "3169",
            },
        ]
    },
    {
        shortcode: "TR", // Турция
        name: "regions_lib.TR",
        regions: [
            {
                name: "regions_lib.3218", // Adıyaman
                shortcode: "3218",
            },
            {
                name: "regions_lib.3220", // Afyonkarahisar
                shortcode: "3220",
            },
            {
                name: "regions_lib.3249", // Ankara
                shortcode: "3249",
            },
            {
                name: "regions_lib.3209", // Ağrı
                shortcode: "3209",
            },
            {
                name: "regions_lib.3170", // Bayburt
                shortcode: "3170",
            },
            {
                name: "regions_lib.3247", // Bursa
                shortcode: "3247",
            },
            {
                name: "regions_lib.3239", // Diyarbakır
                shortcode: "3239",
            },
            {
                name: "regions_lib.3197", // Düzce
                shortcode: "3197",
            },
            {
                name: "regions_lib.3215", // Elâzığ
                shortcode: "3215",
            },
            {
                name: "regions_lib.3174", // Gümüşhane
                shortcode: "3174",
            },
            {
                name: "regions_lib.3187", // Hakkari
                shortcode: "3187",
            },
            {
                name: "regions_lib.3183", // Karabük
                shortcode: "3183",
            },
            {
                name: "regions_lib.3190", // Kars
                shortcode: "3190",
            },
            {
                name: "regions_lib.3191", // Nevşehir
                shortcode: "3191",
            },
            {
                name: "regions_lib.3199", // Niğde
                shortcode: "3199",
            },
            {
                name: "regions_lib.3195", // Rize
                shortcode: "3195",
            },
            {
                name: "regions_lib.3217", // Tokat
                shortcode: "3217",
            },
            {
                name: "regions_lib.3216", // Zonguldak
                shortcode: "3216",
            },
            {
                name: "regions_lib.3248", // İzmir
                shortcode: "3248",
            },
            {
                name: "regions_lib.3242", // Şanlıurfa
                shortcode: "3242",
            },
            {
                name: "regions_lib.3244", // Адана
                shortcode: "3244",
            },
            {
                name: "regions_lib.3231", // Айдын
                shortcode: "3231",
            },
            {
                name: "regions_lib.3201", // Аксарай
                shortcode: "3201",
            },
            {
                name: "regions_lib.3194", // Амасья
                shortcode: "3194",
            },
            {
                name: "regions_lib.3246", // Анталья
                shortcode: "3246",
            },
            {
                name: "regions_lib.3172", // Ардахан
                shortcode: "3172",
            },
            {
                name: "regions_lib.3175", // Артвин
                shortcode: "3175",
            },
            {
                name: "regions_lib.3234", // Балыкесир
                shortcode: "3234",
            },
            {
                name: "regions_lib.3176", // Бартын
                shortcode: "3176",
            },
            {
                name: "regions_lib.3213", // Батман
                shortcode: "3213",
            },
            {
                name: "regions_lib.3180", // Биледжик
                shortcode: "3180",
            },
            {
                name: "regions_lib.3188", // Бингёль
                shortcode: "3188",
            },
            {
                name: "regions_lib.3196", // Битлис
                shortcode: "3196",
            },
            {
                name: "regions_lib.3192", // Болу
                shortcode: "3192",
            },
            {
                name: "regions_lib.3186", // Бурдур
                shortcode: "3186",
            },
            {
                name: "regions_lib.3232", // Ван
                shortcode: "3232",
            },
            {
                name: "regions_lib.3243", // Газиантеп
                shortcode: "3243",
            },
            {
                name: "regions_lib.3207", // Гиресун
                shortcode: "3207",
            },
            {
                name: "regions_lib.3228", // Денизли
                shortcode: "3228",
            },
            {
                name: "regions_lib.3205", // Йозгат
                shortcode: "3205",
            },
            {
                name: "regions_lib.3237", // Кайсери
                shortcode: "3237",
            },
            {
                name: "regions_lib.3184", // Караман
                shortcode: "3184",
            },
            {
                name: "regions_lib.3202", // Кастамону
                shortcode: "3202",
            },
            {
                name: "regions_lib.3233", // Кахраманмараш
                shortcode: "3233",
            },
            {
                name: "regions_lib.3173", // Килис
                shortcode: "3173",
            },
            {
                name: "regions_lib.3241", // Коджаэли
                shortcode: "3241",
            },
            {
                name: "regions_lib.3245", // Конья
                shortcode: "3245",
            },
            {
                name: "regions_lib.3198", // Кыркларели
                shortcode: "3198",
            },
            {
                name: "regions_lib.3182", // Кыршехир
                shortcode: "3182",
            },
            {
                name: "regions_lib.3189", // Кырыккале
                shortcode: "3189",
            },
            {
                name: "regions_lib.3214", // Кютахья
                shortcode: "3214",
            },
            {
                name: "regions_lib.3223", // Малатья
                shortcode: "3223",
            },
            {
                name: "regions_lib.3236", // Маниса
                shortcode: "3236",
            },
            {
                name: "regions_lib.3225", // Мардин
                shortcode: "3225",
            },
            {
                name: "regions_lib.3240", // Мерсин
                shortcode: "3240",
            },
            {
                name: "regions_lib.3227", // Мугла
                shortcode: "3227",
            },
            {
                name: "regions_lib.3204", // Муш
                shortcode: "3204",
            },
            {
                name: "regions_lib.3221", // Орду
                shortcode: "3221",
            },
            {
                name: "regions_lib.3210", // Османие
                shortcode: "3210",
            },
            {
                name: "regions_lib.3229", // Сакарья
                shortcode: "3229",
            },
            {
                name: "regions_lib.3235", // Самсун
                shortcode: "3235",
            },
            {
                name: "regions_lib.3219", // Сивас
                shortcode: "3219",
            },
            {
                name: "regions_lib.3193", // Сиирт
                shortcode: "3193",
            },
            {
                name: "regions_lib.3179", // Синоп
                shortcode: "3179",
            },
            {
                name: "regions_lib.3250", // Стамбул
                shortcode: "3250",
            },
            {
                name: "regions_lib.3230", // Текирдаг
                shortcode: "3230",
            },
            {
                name: "regions_lib.3224", // Трабзон
                shortcode: "3224",
            },
            {
                name: "regions_lib.3171", // Тунджели
                shortcode: "3171",
            },
            {
                name: "regions_lib.3200", // Ушак
                shortcode: "3200",
            },
            {
                name: "regions_lib.3238", // Хатай
                shortcode: "3238",
            },
            {
                name: "regions_lib.3212", // Чанаккале
                shortcode: "3212",
            },
            {
                name: "regions_lib.3178", // Чанкыры
                shortcode: "3178",
            },
            {
                name: "regions_lib.3211", // Чорум
                shortcode: "3211",
            },
            {
                name: "regions_lib.3208", // Ширнак
                shortcode: "3208",
            },
            {
                name: "regions_lib.3177", // Ыгдыр
                shortcode: "3177",
            },
            {
                name: "regions_lib.3206", // Ыспарта
                shortcode: "3206",
            },
            {
                name: "regions_lib.3203", // Эдирне
                shortcode: "3203",
            },
            {
                name: "regions_lib.3181", // Эрзинджан
                shortcode: "3181",
            },
            {
                name: "regions_lib.3222", // Эрзурум
                shortcode: "3222",
            },
            {
                name: "regions_lib.3226", // Эскишехир
                shortcode: "3226",
            },
            {
                name: "regions_lib.3185", // Ялова
                shortcode: "3185",
            },
        ]
    },
    {
        shortcode: "UG", // Уганда
        name: "regions_lib.UG",
        regions: [
            {
                name: "regions_lib.3254", // Central Region
                shortcode: "3254",
            },
            {
                name: "regions_lib.3252", // Eastern Region
                shortcode: "3252",
            },
            {
                name: "regions_lib.3251", // Northern Region
                shortcode: "3251",
            },
            {
                name: "regions_lib.3253", // Western Region
                shortcode: "3253",
            },
        ]
    },
    {
        shortcode: "UZ", // Узбекистан
        name: "regions_lib.UZ",
        regions: [
            {
                name: "regions_lib.3267", // Fergana
                shortcode: "3267",
            },
            {
                name: "regions_lib.3259", // Karakalpakstan
                shortcode: "3259",
            },
            {
                name: "regions_lib.3256", // Navoiy Region
                shortcode: "3256",
            },
            {
                name: "regions_lib.3268", // Samarqand Region
                shortcode: "3268",
            },
            {
                name: "regions_lib.3255", // Sirdaryo Region
                shortcode: "3255",
            },
            {
                name: "regions_lib.3261", // Surxondaryo Region
                shortcode: "3261",
            },
            {
                name: "regions_lib.3258", // Xorazm Region
                shortcode: "3258",
            },
            {
                name: "regions_lib.3265", // Андижанская область
                shortcode: "3265",
            },
            {
                name: "regions_lib.3260", // Бухарская область
                shortcode: "3260",
            },
            {
                name: "regions_lib.3257", // Жиззахская область
                shortcode: "3257",
            },
            {
                name: "regions_lib.3266", // Кашкадаринская область
                shortcode: "3266",
            },
            {
                name: "regions_lib.3262", // Наманганская область
                shortcode: "3262",
            },
            {
                name: "regions_lib.3263", // Ташкент
                shortcode: "3263",
            },
            {
                name: "regions_lib.3264", // Ташкентская область
                shortcode: "3264",
            },
        ]
    },
    {
        shortcode: "UA", // Украина
        name: "regions_lib.UA",
        regions: [
            {
                name: "regions_lib.3290", // Luganskaya Oblast’
                shortcode: "3290",
            },
            {
                name: "regions_lib.3285", // Винницкая область
                shortcode: "3285",
            },
            {
                name: "regions_lib.3272", // Волынская область
                shortcode: "3272",
            },
            {
                name: "regions_lib.3269", // Город Севастополь
                shortcode: "3269",
            },
            {
                name: "regions_lib.3294", // Днепропетровская область
                shortcode: "3294",
            },
            {
                name: "regions_lib.3295", // Донецкая область
                shortcode: "3295",
            },
            {
                name: "regions_lib.3281", // Житомирская область
                shortcode: "3281",
            },
            {
                name: "regions_lib.3279", // Закарпатская область
                shortcode: "3279",
            },
            {
                name: "regions_lib.3286", // Запорожская область
                shortcode: "3286",
            },
            {
                name: "regions_lib.3283", // Ивано-Франковская область
                shortcode: "3283",
            },
            {
                name: "regions_lib.3293", // Киев
                shortcode: "3293",
            },
            {
                name: "regions_lib.3287", // Киевская область
                shortcode: "3287",
            },
            {
                name: "regions_lib.3271", // Кировоградская область
                shortcode: "3271",
            },
            {
                name: "regions_lib.3291", // Львовская область
                shortcode: "3291",
            },
            {
                name: "regions_lib.3275", // Николаевская область
                shortcode: "3275",
            },
            {
                name: "regions_lib.3289", // Одесская область
                shortcode: "3289",
            },
            {
                name: "regions_lib.3284", // Полтавская область
                shortcode: "3284",
            },
            {
                name: "regions_lib.3276", // Ровненская область
                shortcode: "3276",
            },
            {
                name: "regions_lib.3278", // Сумская область
                shortcode: "3278",
            },
            {
                name: "regions_lib.3274", // Тернопольская область
                shortcode: "3274",
            },
            {
                name: "regions_lib.3292", // Харьковская область
                shortcode: "3292",
            },
            {
                name: "regions_lib.3273", // Херсонская область
                shortcode: "3273",
            },
            {
                name: "regions_lib.3280", // Хмельницкая область
                shortcode: "3280",
            },
            {
                name: "regions_lib.3282", // Черкасская область
                shortcode: "3282",
            },
            {
                name: "regions_lib.3277", // Черниговская область
                shortcode: "3277",
            },
            {
                name: "regions_lib.3270", // Черновицкая область
                shortcode: "3270",
            },
        ]
    },
    {
        shortcode: "WF", // Уоллис и Футуна
        name: "regions_lib.WF",
        regions: [
            {
                name: "regions_lib.3297", // Alo
                shortcode: "3297",
            },
            {
                name: "regions_lib.3296", // Sigave
                shortcode: "3296",
            },
            {
                name: "regions_lib.3298", // Uvéa
                shortcode: "3298",
            },
        ]
    },
    {
        shortcode: "UY", // Уругвай
        name: "regions_lib.UY",
        regions: [
            {
                name: "regions_lib.3306", // Departamento de Artigas
                shortcode: "3306",
            },
            {
                name: "regions_lib.3316", // Departamento de Canelones
                shortcode: "3316",
            },
            {
                name: "regions_lib.3308", // Departamento de Cerro Largo
                shortcode: "3308",
            },
            {
                name: "regions_lib.3313", // Departamento de Colonia
                shortcode: "3313",
            },
            {
                name: "regions_lib.3302", // Departamento de Durazno
                shortcode: "3302",
            },
            {
                name: "regions_lib.3299", // Departamento de Flores
                shortcode: "3299",
            },
            {
                name: "regions_lib.3304", // Departamento de Florida
                shortcode: "3304",
            },
            {
                name: "regions_lib.3315", // Departamento de Maldonado
                shortcode: "3315",
            },
            {
                name: "regions_lib.3317", // Departamento de Montevideo
                shortcode: "3317",
            },
            {
                name: "regions_lib.3312", // Departamento de Paysandú
                shortcode: "3312",
            },
            {
                name: "regions_lib.3311", // Departamento de Rivera
                shortcode: "3311",
            },
            {
                name: "regions_lib.3305", // Departamento de Rocha
                shortcode: "3305",
            },
            {
                name: "regions_lib.3301", // Departamento de Río Negro
                shortcode: "3301",
            },
            {
                name: "regions_lib.3314", // Departamento de Salto
                shortcode: "3314",
            },
            {
                name: "regions_lib.3310", // Departamento de San José
                shortcode: "3310",
            },
            {
                name: "regions_lib.3307", // Departamento de Soriano
                shortcode: "3307",
            },
            {
                name: "regions_lib.3309", // Departamento de Tacuarembó
                shortcode: "3309",
            },
            {
                name: "regions_lib.3300", // Departamento de Treinta y Tres
                shortcode: "3300",
            },
            {
                name: "regions_lib.3303", // Lavalleja Department
                shortcode: "3303",
            },
        ]
    },
    {
        shortcode: "FO", // Фарерские о-ва
        name: "regions_lib.FO",
        regions: [
            {
                name: "regions_lib.3323", // Eysturoyar sýsla
                shortcode: "3323",
            },
            {
                name: "regions_lib.3322", // Norðoyar sýsla
                shortcode: "3322",
            },
            {
                name: "regions_lib.3318", // Sandoyar Sýsla
                shortcode: "3318",
            },
            {
                name: "regions_lib.3320", // Streymoyar Sýsla
                shortcode: "3320",
            },
            {
                name: "regions_lib.3321", // Suðuroyar sýsla
                shortcode: "3321",
            },
            {
                name: "regions_lib.3319", // Vága Sýsla
                shortcode: "3319",
            },
        ]
    },
    {
        shortcode: "FM", // Федеративные Штаты Микронезии
        name: "regions_lib.FM",
        regions: [
            {
                name: "regions_lib.3327", // State of Chuuk
                shortcode: "3327",
            },
            {
                name: "regions_lib.3324", // State of Kosrae
                shortcode: "3324",
            },
            {
                name: "regions_lib.3326", // State of Pohnpei
                shortcode: "3326",
            },
            {
                name: "regions_lib.3325", // State of Yap
                shortcode: "3325",
            },
        ]
    },
    {
        shortcode: "FJ", // Фиджи
        name: "regions_lib.FJ",
        regions: [
            {
                name: "regions_lib.3328", // Rotuma
                shortcode: "3328",
            },
            {
                name: "regions_lib.3329", // Восточный округ
                shortcode: "3329",
            },
            {
                name: "regions_lib.3331", // Западный округ
                shortcode: "3331",
            },
            {
                name: "regions_lib.3330", // Северный округ
                shortcode: "3330",
            },
            {
                name: "regions_lib.3332", // Центральный округ
                shortcode: "3332",
            },
        ]
    },
    {
        shortcode: "PH", // Филиппины
        name: "regions_lib.PH",
        regions: [
            {
                name: "regions_lib.3344", // Bicol
                shortcode: "3344",
            },
            {
                name: "regions_lib.3348", // Calabarzon
                shortcode: "3348",
            },
            {
                name: "regions_lib.3334", // Caraga
                shortcode: "3334",
            },
            {
                name: "regions_lib.3342", // Davao
                shortcode: "3342",
            },
            {
                name: "regions_lib.3335", // Mimaropa
                shortcode: "3335",
            },
            {
                name: "regions_lib.3349", // National Capital Region
                shortcode: "3349",
            },
            {
                name: "regions_lib.3339", // Soccsksargen
                shortcode: "3339",
            },
            {
                name: "regions_lib.3337", // Zamboanga Peninsula
                shortcode: "3337",
            },
            {
                name: "regions_lib.3338", // Автономный регион в Мусульманском Минданао
                shortcode: "3338",
            },
            {
                name: "regions_lib.3340", // Восточные Висайи
                shortcode: "3340",
            },
            {
                name: "regions_lib.3336", // Долина Кагаян
                shortcode: "3336",
            },
            {
                name: "regions_lib.3346", // Западные Висайи
                shortcode: "3346",
            },
            {
                name: "regions_lib.3343", // Илокос
                shortcode: "3343",
            },
            {
                name: "regions_lib.3333", // Кордильерский административный регион
                shortcode: "3333",
            },
            {
                name: "regions_lib.3341", // Северный Минданао
                shortcode: "3341",
            },
            {
                name: "regions_lib.3345", // Центральные Висайи
                shortcode: "3345",
            },
            {
                name: "regions_lib.3347", // Центральный Лусон
                shortcode: "3347",
            },
        ]
    },
    {
        shortcode: "FI", // Финляндия
        name: "regions_lib.FI",
        regions: [
            {
                name: "regions_lib.3352", // South Karelia Region
                shortcode: "3352",
            },
            {
                name: "regions_lib.3365", // Варсинайс-Суоми
                shortcode: "3365",
            },
            {
                name: "regions_lib.3351", // Кайнуу
                shortcode: "3351",
            },
            {
                name: "regions_lib.3355", // Канта-Хяме
                shortcode: "3355",
            },
            {
                name: "regions_lib.3356", // Кюменлааксо
                shortcode: "3356",
            },
            {
                name: "regions_lib.3357", // Лапландия
                shortcode: "3357",
            },
            {
                name: "regions_lib.3358", // Остроботния
                shortcode: "3358",
            },
            {
                name: "regions_lib.3366", // Пирканмаа
                shortcode: "3366",
            },
            {
                name: "regions_lib.3360", // Пяйят-Хяме
                shortcode: "3360",
            },
            {
                name: "regions_lib.3361", // Сатакунта
                shortcode: "3361",
            },
            {
                name: "regions_lib.3354", // Северная Карелия
                shortcode: "3354",
            },
            {
                name: "regions_lib.3364", // Северная Остроботния
                shortcode: "3364",
            },
            {
                name: "regions_lib.3362", // Северное Саво
                shortcode: "3362",
            },
            {
                name: "regions_lib.3367", // Уусимаа
                shortcode: "3367",
            },
            {
                name: "regions_lib.3350", // Центральная Остроботния
                shortcode: "3350",
            },
            {
                name: "regions_lib.3363", // Центральная Финляндия
                shortcode: "3363",
            },
            {
                name: "regions_lib.3359", // Южная Остроботния
                shortcode: "3359",
            },
            {
                name: "regions_lib.3353", // Южное Саво
                shortcode: "3353",
            },
        ]
    },
    {
        shortcode: "FK", // Фолклендские о-ва
        name: "regions_lib.FK",
        regions: [
        ]
    },
    {
        shortcode: "FR", // Франция
        name: "regions_lib.FR",
        regions: [
            {
                name: "regions_lib.3379", // Auvergne-Rhône-Alpes
                shortcode: "3379",
            },
            {
                name: "regions_lib.3375", // Bourgogne-Franche-Comté
                shortcode: "3375",
            },
            {
                name: "regions_lib.3369", // Centre-Val de Loire
                shortcode: "3369",
            },
            {
                name: "regions_lib.3368", // Corsica
                shortcode: "3368",
            },
            {
                name: "regions_lib.3374", // Grand Est
                shortcode: "3374",
            },
            {
                name: "regions_lib.3377", // Hauts-de-France
                shortcode: "3377",
            },
            {
                name: "regions_lib.3371", // Normandy
                shortcode: "3371",
            },
            {
                name: "regions_lib.3378", // Nouvelle-Aquitaine
                shortcode: "3378",
            },
            {
                name: "regions_lib.3376", // Occitanie
                shortcode: "3376",
            },
            {
                name: "regions_lib.3380", // Île-de-France Region
                shortcode: "3380",
            },
            {
                name: "regions_lib.3370", // Бретань
                shortcode: "3370",
            },
            {
                name: "regions_lib.3372", // Земли Луары
                shortcode: "3372",
            },
            {
                name: "regions_lib.3373", // Прованс — Альпы — Лазурный Берег
                shortcode: "3373",
            },
        ]
    },
    {
        shortcode: "GF", // Французская Гвиана
        name: "regions_lib.GF",
        regions: [
            {
                name: "regions_lib.3381", // Guyane
                shortcode: "3381",
            },
        ]
    },
    {
        shortcode: "PF", // Французская Полинезия
        name: "regions_lib.PF",
        regions: [
            {
                name: "regions_lib.3382", // Îles Australes
                shortcode: "3382",
            },
            {
                name: "regions_lib.3383", // Îles Marquises
                shortcode: "3383",
            },
            {
                name: "regions_lib.3385", // Îles Sous-le-Vent
                shortcode: "3385",
            },
            {
                name: "regions_lib.3384", // Îles Tuamotu-Gambier
                shortcode: "3384",
            },
            {
                name: "regions_lib.3386", // Îles du Vent
                shortcode: "3386",
            },
        ]
    },
    {
        shortcode: "TF", // Французские Южные территории
        name: "regions_lib.TF",
        regions: [
            {
                name: "regions_lib.3388", // Archipel des Kerguelen
                shortcode: "3388",
            },
            {
                name: "regions_lib.3387", // Îles Saint-Paul et Nouvelle-Amsterdam
                shortcode: "3387",
            },
        ]
    },
    {
        shortcode: "HR", // Хорватия
        name: "regions_lib.HR",
        regions: [
            {
                name: "regions_lib.3395", // Bjelovar-Bilogora
                shortcode: "3395",
            },
            {
                name: "regions_lib.3399", // Brod-Posavina
                shortcode: "3399",
            },
            {
                name: "regions_lib.3396", // Dubrovačko-Neretvanska Županija
                shortcode: "3396",
            },
            {
                name: "regions_lib.3409", // Grad Zagreb
                shortcode: "3409",
            },
            {
                name: "regions_lib.3404", // Istria
                shortcode: "3404",
            },
            {
                name: "regions_lib.3397", // Karlovac
                shortcode: "3397",
            },
            {
                name: "regions_lib.3394", // Koprivinica-Krizevci
                shortcode: "3394",
            },
            {
                name: "regions_lib.3398", // Krapina-Zagorje
                shortcode: "3398",
            },
            {
                name: "regions_lib.3389", // Lika-Senj
                shortcode: "3389",
            },
            {
                name: "regions_lib.3393", // Medimurje
                shortcode: "3393",
            },
            {
                name: "regions_lib.3406", // Osječko-Baranjska Županija
                shortcode: "3406",
            },
            {
                name: "regions_lib.3390", // Pozega-Slavonia
                shortcode: "3390",
            },
            {
                name: "regions_lib.3405", // Primorsko-Goranska Županija
                shortcode: "3405",
            },
            {
                name: "regions_lib.3392", // Sibenik-Knin
                shortcode: "3392",
            },
            {
                name: "regions_lib.3401", // Sisak-Moslavina
                shortcode: "3401",
            },
            {
                name: "regions_lib.3408", // Split-Dalmatia
                shortcode: "3408",
            },
            {
                name: "regions_lib.3402", // Varazdin
                shortcode: "3402",
            },
            {
                name: "regions_lib.3391", // Virovitica-Podravina
                shortcode: "3391",
            },
            {
                name: "regions_lib.3403", // Vukovar-Srijem
                shortcode: "3403",
            },
            {
                name: "regions_lib.3400", // Zadar
                shortcode: "3400",
            },
            {
                name: "regions_lib.3407", // Zagreb County
                shortcode: "3407",
            },
        ]
    },
    {
        shortcode: "CF", // Центрально-Африканская Республика
        name: "regions_lib.CF",
        regions: [
            {
                name: "regions_lib.3426", // Bangui Prefecture
                shortcode: "3426",
            },
            {
                name: "regions_lib.3415", // Kémo Prefecture
                shortcode: "3415",
            },
            {
                name: "regions_lib.3416", // Nana-Grébizi Prefecture
                shortcode: "3416",
            },
            {
                name: "regions_lib.3418", // Nana-Mambéré Prefecture
                shortcode: "3418",
            },
            {
                name: "regions_lib.3423", // Ombella-M&quot;Poko Prefecture
                shortcode: "3423",
            },
            {
                name: "regions_lib.3424", // Ouham-Pendé Prefecture
                shortcode: "3424",
            },
            {
                name: "regions_lib.3414", // Sangha-Mbaéré Prefecture
                shortcode: "3414",
            },
            {
                name: "regions_lib.3412", // Баминги-Бангоран
                shortcode: "3412",
            },
            {
                name: "regions_lib.3410", // Вакага
                shortcode: "3410",
            },
            {
                name: "regions_lib.3413", // Верхнее Котто
                shortcode: "3413",
            },
            {
                name: "regions_lib.3411", // Верхнее Мбому
                shortcode: "3411",
            },
            {
                name: "regions_lib.3420", // Лобае
                shortcode: "3420",
            },
            {
                name: "regions_lib.3422", // Мамбере-Кадеи
                shortcode: "3422",
            },
            {
                name: "regions_lib.3417", // Мбому
                shortcode: "3417",
            },
            {
                name: "regions_lib.3419", // Нижнее Котто
                shortcode: "3419",
            },
            {
                name: "regions_lib.3421", // Уака
                shortcode: "3421",
            },
            {
                name: "regions_lib.3425", // Уам
                shortcode: "3425",
            },
        ]
    },
    {
        shortcode: "TD", // Чад
        name: "regions_lib.TD",
        regions: [
            {
                name: "regions_lib.3433", // Barh el Gazel Region
                shortcode: "3433",
            },
            {
                name: "regions_lib.3437", // Batha Region
                shortcode: "3437",
            },
            {
                name: "regions_lib.3429", // Borkou Region
                shortcode: "3429",
            },
            {
                name: "regions_lib.3449", // Chari-Baguirmi Region
                shortcode: "3449",
            },
            {
                name: "regions_lib.3430", // Ennedi-Est Region
                shortcode: "3430",
            },
            {
                name: "regions_lib.3428", // Ennedi-Ouest Region
                shortcode: "3428",
            },
            {
                name: "regions_lib.3438", // Guéra Region
                shortcode: "3438",
            },
            {
                name: "regions_lib.3436", // Kanem Region
                shortcode: "3436",
            },
            {
                name: "regions_lib.3435", // Lac Region
                shortcode: "3435",
            },
            {
                name: "regions_lib.3443", // Logone Occidental Region
                shortcode: "3443",
            },
            {
                name: "regions_lib.3441", // Logone Oriental Region
                shortcode: "3441",
            },
            {
                name: "regions_lib.3448", // Mayo-Kebbi Est Region
                shortcode: "3448",
            },
            {
                name: "regions_lib.3440", // Mayo-Kebbi Ouest Region
                shortcode: "3440",
            },
            {
                name: "regions_lib.3446", // Moyen-Chari Region
                shortcode: "3446",
            },
            {
                name: "regions_lib.3447", // N’Djaména Region
                shortcode: "3447",
            },
            {
                name: "regions_lib.3445", // Ouadaï Region
                shortcode: "3445",
            },
            {
                name: "regions_lib.3431", // Salamat Region
                shortcode: "3431",
            },
            {
                name: "regions_lib.3442", // Tandjilé Region
                shortcode: "3442",
            },
            {
                name: "regions_lib.3427", // Tibesti Region
                shortcode: "3427",
            },
            {
                name: "regions_lib.3432", // Wadi Fira Region
                shortcode: "3432",
            },
            {
                name: "regions_lib.3444", // Мандуль
                shortcode: "3444",
            },
            {
                name: "regions_lib.3434", // Сила
                shortcode: "3434",
            },
            {
                name: "regions_lib.3439", // Хаджер-Ламис
                shortcode: "3439",
            },
        ]
    },
    {
        shortcode: "ME", // Черногория
        name: "regions_lib.ME",
        regions: [
            {
                name: "regions_lib.3456", // Andrijevica
                shortcode: "3456",
            },
            {
                name: "regions_lib.3470", // Bar
                shortcode: "3470",
            },
            {
                name: "regions_lib.3469", // Berane
                shortcode: "3469",
            },
            {
                name: "regions_lib.3472", // Budva
                shortcode: "3472",
            },
            {
                name: "regions_lib.3463", // Cetinje
                shortcode: "3463",
            },
            {
                name: "regions_lib.3457", // Danilovgrad
                shortcode: "3457",
            },
            {
                name: "regions_lib.3453", // Gusinje
                shortcode: "3453",
            },
            {
                name: "regions_lib.3451", // Opština Kolašin
                shortcode: "3451",
            },
            {
                name: "regions_lib.3471", // Opština Nikšić
                shortcode: "3471",
            },
            {
                name: "regions_lib.3461", // Opština Plav
                shortcode: "3461",
            },
            {
                name: "regions_lib.3455", // Opština Plužine
                shortcode: "3455",
            },
            {
                name: "regions_lib.3467", // Opština Rožaje
                shortcode: "3467",
            },
            {
                name: "regions_lib.3450", // Opština Šavnik
                shortcode: "3450",
            },
            {
                name: "regions_lib.3452", // Opština Žabljak
                shortcode: "3452",
            },
            {
                name: "regions_lib.3458", // Petnjica
                shortcode: "3458",
            },
            {
                name: "regions_lib.3464", // Pljevlja
                shortcode: "3464",
            },
            {
                name: "regions_lib.3473", // Podgorica
                shortcode: "3473",
            },
            {
                name: "regions_lib.3459", // Tivat
                shortcode: "3459",
            },
            {
                name: "regions_lib.3460", // Tuzi
                shortcode: "3460",
            },
            {
                name: "regions_lib.3465", // Ulcinj
                shortcode: "3465",
            },
            {
                name: "regions_lib.3462", // Биело-Поле
                shortcode: "3462",
            },
            {
                name: "regions_lib.3468", // Герцег-Нови
                shortcode: "3468",
            },
            {
                name: "regions_lib.3466", // Котор
                shortcode: "3466",
            },
            {
                name: "regions_lib.3454", // Мойковац
                shortcode: "3454",
            },
        ]
    },
    {
        shortcode: "CZ", // Чехия
        name: "regions_lib.CZ",
        regions: [
            {
                name: "regions_lib.3486", // Hlavní město Praha
                shortcode: "3486",
            },
            {
                name: "regions_lib.3474", // Karlovarský kraj
                shortcode: "3474",
            },
            {
                name: "regions_lib.3476", // Kraj Vysočina
                shortcode: "3476",
            },
            {
                name: "regions_lib.3478", // Královéhradecký kraj
                shortcode: "3478",
            },
            {
                name: "regions_lib.3475", // Liberecký kraj
                shortcode: "3475",
            },
            {
                name: "regions_lib.3485", // Moravskoslezský kraj
                shortcode: "3485",
            },
            {
                name: "regions_lib.3481", // Olomoucký kraj
                shortcode: "3481",
            },
            {
                name: "regions_lib.3477", // Pardubický kraj
                shortcode: "3477",
            },
            {
                name: "regions_lib.3480", // Plzeňský kraj
                shortcode: "3480",
            },
            {
                name: "regions_lib.3479", // Zlínský kraj
                shortcode: "3479",
            },
            {
                name: "regions_lib.3483", // Ústecký kraj
                shortcode: "3483",
            },
            {
                name: "regions_lib.3487", // Среднечешский край
                shortcode: "3487",
            },
            {
                name: "regions_lib.3484", // Южноморавский край
                shortcode: "3484",
            },
            {
                name: "regions_lib.3482", // Южночешский край
                shortcode: "3482",
            },
        ]
    },
    {
        shortcode: "CL", // Чили
        name: "regions_lib.CL",
        regions: [
            {
                name: "regions_lib.3488", // Región Aysén
                shortcode: "3488",
            },
            {
                name: "regions_lib.3495", // Región de Antofagasta
                shortcode: "3495",
            },
            {
                name: "regions_lib.3490", // Región de Arica y Parinacota
                shortcode: "3490",
            },
            {
                name: "regions_lib.3492", // Región de Atacama
                shortcode: "3492",
            },
            {
                name: "regions_lib.3496", // Región de Coquimbo
                shortcode: "3496",
            },
            {
                name: "regions_lib.3489", // Región de Magallanes y Antártica Chilena
                shortcode: "3489",
            },
            {
                name: "regions_lib.3501", // Región de Valparaíso
                shortcode: "3501",
            },
            {
                name: "regions_lib.3502", // Región del Biobío
                shortcode: "3502",
            },
            {
                name: "regions_lib.3498", // Región del Libertador General Bernardo O’Higgins
                shortcode: "3498",
            },
            {
                name: "regions_lib.3500", // Región del Maule
                shortcode: "3500",
            },
            {
                name: "regions_lib.3499", // Región de la Araucanía
                shortcode: "3499",
            },
            {
                name: "regions_lib.3497", // Región de los Lagos
                shortcode: "3497",
            },
            {
                name: "regions_lib.3493", // Región de los Ríos
                shortcode: "3493",
            },
            {
                name: "regions_lib.3494", // Región de Ñuble
                shortcode: "3494",
            },
            {
                name: "regions_lib.3503", // Столичная область
                shortcode: "3503",
            },
            {
                name: "regions_lib.3491", // Тарапака
                shortcode: "3491",
            },
        ]
    },
    {
        shortcode: "CH", // Швейцария
        name: "regions_lib.CH",
        regions: [
            {
                name: "regions_lib.3504", // Appenzell Innerrhoden
                shortcode: "3504",
            },
            {
                name: "regions_lib.3519", // Basel-Landschaft
                shortcode: "3519",
            },
            {
                name: "regions_lib.3521", // Canton du Valais
                shortcode: "3521",
            },
            {
                name: "regions_lib.3509", // Canton of Appenzell Ausserrhoden
                shortcode: "3509",
            },
            {
                name: "regions_lib.3516", // Canton of Basel-City
                shortcode: "3516",
            },
            {
                name: "regions_lib.3528", // Canton of Bern
                shortcode: "3528",
            },
            {
                name: "regions_lib.3520", // Canton of Fribourg
                shortcode: "3520",
            },
            {
                name: "regions_lib.3508", // Canton of Glarus
                shortcode: "3508",
            },
            {
                name: "regions_lib.3523", // Canton of Lucerne
                shortcode: "3523",
            },
            {
                name: "regions_lib.3507", // Canton of Nidwalden
                shortcode: "3507",
            },
            {
                name: "regions_lib.3506", // Canton of Obwalden
                shortcode: "3506",
            },
            {
                name: "regions_lib.3511", // Canton of Schaffhausen
                shortcode: "3511",
            },
            {
                name: "regions_lib.3513", // Canton of Schwyz
                shortcode: "3513",
            },
            {
                name: "regions_lib.3517", // Canton of Solothurn
                shortcode: "3517",
            },
            {
                name: "regions_lib.3525", // Canton of St. Gallen
                shortcode: "3525",
            },
            {
                name: "regions_lib.3505", // Canton of Uri
                shortcode: "3505",
            },
            {
                name: "regions_lib.3527", // Canton of Vaud
                shortcode: "3527",
            },
            {
                name: "regions_lib.3512", // Canton of Zug
                shortcode: "3512",
            },
            {
                name: "regions_lib.3529", // Canton of Zurich
                shortcode: "3529",
            },
            {
                name: "regions_lib.3515", // Kanton Graubünden
                shortcode: "3515",
            },
            {
                name: "regions_lib.3518", // Thurgau
                shortcode: "3518",
            },
            {
                name: "regions_lib.3524", // Женева
                shortcode: "3524",
            },
            {
                name: "regions_lib.3514", // Невшатель
                shortcode: "3514",
            },
            {
                name: "regions_lib.3522", // Тичино
                shortcode: "3522",
            },
            {
                name: "regions_lib.3510", // Юра
                shortcode: "3510",
            },
            {
                name: "regions_lib.3526", // кантон Аргау
                shortcode: "3526",
            },
        ]
    },
    {
        shortcode: "SE", // Швеция
        name: "regions_lib.SE",
        regions: [
            {
                name: "regions_lib.3530", // Gotland County
                shortcode: "3530",
            },
            {
                name: "regions_lib.3531", // Jämtland County
                shortcode: "3531",
            },
            {
                name: "regions_lib.3545", // Jönköping County
                shortcode: "3545",
            },
            {
                name: "regions_lib.3535", // Kalmar
                shortcode: "3535",
            },
            {
                name: "regions_lib.3533", // Kronoberg County
                shortcode: "3533",
            },
            {
                name: "regions_lib.3548", // Skåne County
                shortcode: "3548",
            },
            {
                name: "regions_lib.3550", // Stockholm County
                shortcode: "3550",
            },
            {
                name: "regions_lib.3540", // Södermanland County
                shortcode: "3540",
            },
            {
                name: "regions_lib.3546", // Uppsala County
                shortcode: "3546",
            },
            {
                name: "regions_lib.3539", // Värmland County
                shortcode: "3539",
            },
            {
                name: "regions_lib.3538", // Västerbotten County
                shortcode: "3538",
            },
            {
                name: "regions_lib.3534", // Västernorrland County
                shortcode: "3534",
            },
            {
                name: "regions_lib.3537", // Västmanland County
                shortcode: "3537",
            },
            {
                name: "regions_lib.3547", // Östergötland County
                shortcode: "3547",
            },
            {
                name: "regions_lib.3532", // Блекинге
                shortcode: "3532",
            },
            {
                name: "regions_lib.3549", // Вестра-Гёталанд
                shortcode: "3549",
            },
            {
                name: "regions_lib.3541", // Даларна
                shortcode: "3541",
            },
            {
                name: "regions_lib.3542", // Евлеборг
                shortcode: "3542",
            },
            {
                name: "regions_lib.3536", // Норрботтен
                shortcode: "3536",
            },
            {
                name: "regions_lib.3544", // Халланд
                shortcode: "3544",
            },
            {
                name: "regions_lib.3543", // Эребру
                shortcode: "3543",
            },
        ]
    },
    {
        shortcode: "SJ", // Шпицберген и Ян-Майен
        name: "regions_lib.SJ",
        regions: [
            {
                name: "regions_lib.3551", // Jan Mayen
                shortcode: "3551",
            },
            {
                name: "regions_lib.3552", // Шпицберген
                shortcode: "3552",
            },
        ]
    },
    {
        shortcode: "LK", // Шри-Ланка
        name: "regions_lib.LK",
        regions: [
            {
                name: "regions_lib.3556", // Eastern Province
                shortcode: "3556",
            },
            {
                name: "regions_lib.3554", // North Central Province
                shortcode: "3554",
            },
            {
                name: "regions_lib.3555", // Province of Uva
                shortcode: "3555",
            },
            {
                name: "regions_lib.3557", // Sabaragamuwa Province
                shortcode: "3557",
            },
            {
                name: "regions_lib.3559", // Southern Province
                shortcode: "3559",
            },
            {
                name: "regions_lib.3561", // Western Province
                shortcode: "3561",
            },
            {
                name: "regions_lib.3553", // Северная провинция
                shortcode: "3553",
            },
            {
                name: "regions_lib.3558", // Северо-Западная провинция
                shortcode: "3558",
            },
            {
                name: "regions_lib.3560", // Центральная провинция
                shortcode: "3560",
            },
        ]
    },
    {
        shortcode: "EC", // Эквадор
        name: "regions_lib.EC",
        regions: [
            {
                name: "regions_lib.3571", // Provincia de Bolívar
                shortcode: "3571",
            },
            {
                name: "regions_lib.3575", // Provincia de Cotopaxi
                shortcode: "3575",
            },
            {
                name: "regions_lib.3580", // Provincia de El Oro
                shortcode: "3580",
            },
            {
                name: "regions_lib.3578", // Provincia de Esmeraldas
                shortcode: "3578",
            },
            {
                name: "regions_lib.3567", // Provincia de Francisco de Orellana
                shortcode: "3567",
            },
            {
                name: "regions_lib.3562", // Provincia de Galápagos
                shortcode: "3562",
            },
            {
                name: "regions_lib.3574", // Provincia de Imbabura
                shortcode: "3574",
            },
            {
                name: "regions_lib.3576", // Provincia de Loja
                shortcode: "3576",
            },
            {
                name: "regions_lib.3582", // Provincia de Los Ríos
                shortcode: "3582",
            },
            {
                name: "regions_lib.3583", // Provincia de Manabí
                shortcode: "3583",
            },
            {
                name: "regions_lib.3568", // Provincia de Morona-Santiago
                shortcode: "3568",
            },
            {
                name: "regions_lib.3566", // Provincia de Napo
                shortcode: "3566",
            },
            {
                name: "regions_lib.3584", // Provincia de Pichincha
                shortcode: "3584",
            },
            {
                name: "regions_lib.3572", // Provincia de Santa Elena
                shortcode: "3572",
            },
            {
                name: "regions_lib.3573", // Provincia de Santo Domingo de los Tsáchilas
                shortcode: "3573",
            },
            {
                name: "regions_lib.3570", // Provincia de Sucumbíos
                shortcode: "3570",
            },
            {
                name: "regions_lib.3565", // Provincia de Zamora-Chinchipe
                shortcode: "3565",
            },
            {
                name: "regions_lib.3581", // Provincia del Azuay
                shortcode: "3581",
            },
            {
                name: "regions_lib.3569", // Provincia del Carchi
                shortcode: "3569",
            },
            {
                name: "regions_lib.3563", // Provincia del Cañar
                shortcode: "3563",
            },
            {
                name: "regions_lib.3577", // Provincia del Chimborazo
                shortcode: "3577",
            },
            {
                name: "regions_lib.3585", // Provincia del Guayas
                shortcode: "3585",
            },
            {
                name: "regions_lib.3564", // Provincia del Pastaza
                shortcode: "3564",
            },
            {
                name: "regions_lib.3579", // Provincia del Tungurahua
                shortcode: "3579",
            },
        ]
    },
    {
        shortcode: "GQ", // Экваториальная Гвинея
        name: "regions_lib.GQ",
        regions: [
            {
                name: "regions_lib.3587", // Annobón Province
                shortcode: "3587",
            },
            {
                name: "regions_lib.3592", // Bioko Norte Province
                shortcode: "3592",
            },
            {
                name: "regions_lib.3588", // Bioko Sur Province
                shortcode: "3588",
            },
            {
                name: "regions_lib.3589", // Centro Sur Province
                shortcode: "3589",
            },
            {
                name: "regions_lib.3586", // Djibloho
                shortcode: "3586",
            },
            {
                name: "regions_lib.3591", // Kié-Ntem Province
                shortcode: "3591",
            },
            {
                name: "regions_lib.3593", // Litoral Province
                shortcode: "3593",
            },
            {
                name: "regions_lib.3590", // Wele-Nzas Province
                shortcode: "3590",
            },
        ]
    },
    {
        shortcode: "ER", // Эритрея
        name: "regions_lib.ER",
        regions: [
            {
                name: "regions_lib.3596", // Anseba Region
                shortcode: "3596",
            },
            {
                name: "regions_lib.3599", // Debub Region
                shortcode: "3599",
            },
            {
                name: "regions_lib.3598", // Gash-Barka Region
                shortcode: "3598",
            },
            {
                name: "regions_lib.3597", // Maekel Region
                shortcode: "3597",
            },
            {
                name: "regions_lib.3595", // Northern Red Sea Region
                shortcode: "3595",
            },
            {
                name: "regions_lib.3594", // Southern Red Sea Region
                shortcode: "3594",
            },
        ]
    },
    {
        shortcode: "SZ", // Эсватини
        name: "regions_lib.SZ",
        regions: [
            {
                name: "regions_lib.3602", // Hhohho
                shortcode: "3602",
            },
            {
                name: "regions_lib.3601", // Lubombo District
                shortcode: "3601",
            },
            {
                name: "regions_lib.3603", // Manzini
                shortcode: "3603",
            },
            {
                name: "regions_lib.3600", // Shiselweni District
                shortcode: "3600",
            },
        ]
    },
    {
        shortcode: "EE", // Эстония
        name: "regions_lib.EE",
        regions: [
            {
                name: "regions_lib.3609", // Валгамаа
                shortcode: "3609",
            },
            {
                name: "regions_lib.3613", // Вильяндимаа
                shortcode: "3613",
            },
            {
                name: "regions_lib.3611", // Вырумаа
                shortcode: "3611",
            },
            {
                name: "regions_lib.3616", // Ида-Вирумаа
                shortcode: "3616",
            },
            {
                name: "regions_lib.3607", // Йыгевамаа
                shortcode: "3607",
            },
            {
                name: "regions_lib.3614", // Ляэне-Вирумаа
                shortcode: "3614",
            },
            {
                name: "regions_lib.3605", // Ляэнемаа
                shortcode: "3605",
            },
            {
                name: "regions_lib.3606", // Пылвамаа
                shortcode: "3606",
            },
            {
                name: "regions_lib.3615", // Пярнумаа
                shortcode: "3615",
            },
            {
                name: "regions_lib.3612", // Рапламаа
                shortcode: "3612",
            },
            {
                name: "regions_lib.3610", // Сааремаа
                shortcode: "3610",
            },
            {
                name: "regions_lib.3617", // Тартумаа
                shortcode: "3617",
            },
            {
                name: "regions_lib.3618", // Харьюмаа
                shortcode: "3618",
            },
            {
                name: "regions_lib.3604", // Хийумаа
                shortcode: "3604",
            },
            {
                name: "regions_lib.3608", // Ярвамаа
                shortcode: "3608",
            },
        ]
    },
    {
        shortcode: "ET", // Эфиопия
        name: "regions_lib.ET",
        regions: [
            {
                name: "regions_lib.3624", // Addis Ababa
                shortcode: "3624",
            },
            {
                name: "regions_lib.3623", // Afar Region
                shortcode: "3623",
            },
            {
                name: "regions_lib.3628", // Amhara
                shortcode: "3628",
            },
            {
                name: "regions_lib.3622", // Bīnshangul Gumuz
                shortcode: "3622",
            },
            {
                name: "regions_lib.3621", // Dire Dawa
                shortcode: "3621",
            },
            {
                name: "regions_lib.3620", // Gambella Region
                shortcode: "3620",
            },
            {
                name: "regions_lib.3619", // Harari Region
                shortcode: "3619",
            },
            {
                name: "regions_lib.3629", // Oromiya
                shortcode: "3629",
            },
            {
                name: "regions_lib.3627", // Somali
                shortcode: "3627",
            },
            {
                name: "regions_lib.3626", // Southern Nations, Nationalities, and People&quot;s Region
                shortcode: "3626",
            },
            {
                name: "regions_lib.3625", // Tigray
                shortcode: "3625",
            },
        ]
    },
    {
        shortcode: "ZA", // ЮАР
        name: "regions_lib.ZA",
        regions: [
            {
                name: "regions_lib.3637", // KwaZulu-Natal
                shortcode: "3637",
            },
            {
                name: "regions_lib.3632", // North West
                shortcode: "3632",
            },
            {
                name: "regions_lib.3630", // Northern Cape
                shortcode: "3630",
            },
            {
                name: "regions_lib.3631", // Orange Free State
                shortcode: "3631",
            },
            {
                name: "regions_lib.3636", // Western Cape
                shortcode: "3636",
            },
            {
                name: "regions_lib.3635", // Восточно-Капская провинция
                shortcode: "3635",
            },
            {
                name: "regions_lib.3638", // Гаутенг
                shortcode: "3638",
            },
            {
                name: "regions_lib.3634", // Лимпопо
                shortcode: "3634",
            },
            {
                name: "regions_lib.3633", // Мпумаланга
                shortcode: "3633",
            },
        ]
    },
    {
        shortcode: "GS", // Южная Георгия и Южные Сандвичевы о-ва
        name: "regions_lib.GS",
        regions: [
        ]
    },
    {
        shortcode: "SS", // Южный Судан
        name: "regions_lib.SS",
        regions: [
            {
                name: "regions_lib.3648", // Central Equatoria
                shortcode: "3648",
            },
            {
                name: "regions_lib.3644", // Eastern Equatoria
                shortcode: "3644",
            },
            {
                name: "regions_lib.3647", // Jonglei
                shortcode: "3647",
            },
            {
                name: "regions_lib.3642", // Lakes
                shortcode: "3642",
            },
            {
                name: "regions_lib.3643", // Northern Bahr al Ghazal
                shortcode: "3643",
            },
            {
                name: "regions_lib.3640", // Unity
                shortcode: "3640",
            },
            {
                name: "regions_lib.3646", // Upper Nile
                shortcode: "3646",
            },
            {
                name: "regions_lib.3645", // Warrap
                shortcode: "3645",
            },
            {
                name: "regions_lib.3639", // Western Bahr el Ghazal State
                shortcode: "3639",
            },
            {
                name: "regions_lib.3641", // Western Equatoria
                shortcode: "3641",
            },
        ]
    },
    {
        shortcode: "JM", // Ямайка
        name: "regions_lib.JM",
        regions: [
            {
                name: "regions_lib.3660", // Clarendon
                shortcode: "3660",
            },
            {
                name: "regions_lib.3649", // Hanover Parish
                shortcode: "3649",
            },
            {
                name: "regions_lib.3659", // Manchester
                shortcode: "3659",
            },
            {
                name: "regions_lib.3657", // Parish of Saint Ann
                shortcode: "3657",
            },
            {
                name: "regions_lib.3651", // Portland
                shortcode: "3651",
            },
            {
                name: "regions_lib.3662", // Saint Andrew
                shortcode: "3662",
            },
            {
                name: "regions_lib.3661", // Saint Catherine
                shortcode: "3661",
            },
            {
                name: "regions_lib.3656", // Saint Elizabeth
                shortcode: "3656",
            },
            {
                name: "regions_lib.3658", // Saint James
                shortcode: "3658",
            },
            {
                name: "regions_lib.3654", // Saint Mary
                shortcode: "3654",
            },
            {
                name: "regions_lib.3653", // Saint Thomas
                shortcode: "3653",
            },
            {
                name: "regions_lib.3650", // Trelawny
                shortcode: "3650",
            },
            {
                name: "regions_lib.3655", // Westmoreland
                shortcode: "3655",
            },
            {
                name: "regions_lib.3652", // Кингстон
                shortcode: "3652",
            },
        ]
    },
    {
        shortcode: "JP", // Япония
        name: "regions_lib.JP",
        regions: [
            {
                name: "regions_lib.3706", // Aichi-ken
                shortcode: "3706",
            },
            {
                name: "regions_lib.3704", // Chiba
                shortcode: "3704",
            },
            {
                name: "regions_lib.3682", // Ehime
                shortcode: "3682",
            },
            {
                name: "regions_lib.3698", // Hiroshima
                shortcode: "3698",
            },
            {
                name: "regions_lib.3677", // Ishikawa-ken
                shortcode: "3677",
            },
            {
                name: "regions_lib.3686", // Kagoshima-ken
                shortcode: "3686",
            },
            {
                name: "regions_lib.3666", // Kochi
                shortcode: "3666",
            },
            {
                name: "regions_lib.3697", // Kyoto
                shortcode: "3697",
            },
            {
                name: "regions_lib.3688", // Mie-ken
                shortcode: "3688",
            },
            {
                name: "regions_lib.3680", // Nagasaki
                shortcode: "3680",
            },
            {
                name: "regions_lib.3681", // Nara-ken
                shortcode: "3681",
            },
            {
                name: "regions_lib.3676", // Oita Prefecture
                shortcode: "3676",
            },
            {
                name: "regions_lib.3668", // Saga-ken
                shortcode: "3668",
            },
            {
                name: "regions_lib.3705", // Saitama
                shortcode: "3705",
            },
            {
                name: "regions_lib.3665", // Tokushima
                shortcode: "3665",
            },
            {
                name: "regions_lib.3709", // Tokyo
                shortcode: "3709",
            },
            {
                name: "regions_lib.3673", // Toyama
                shortcode: "3673",
            },
            {
                name: "regions_lib.3707", // Ōsaka
                shortcode: "3707",
            },
            {
                name: "regions_lib.3672", // Акита
                shortcode: "3672",
            },
            {
                name: "regions_lib.3679", // Аомори
                shortcode: "3679",
            },
            {
                name: "regions_lib.3670", // Вакаяма
                shortcode: "3670",
            },
            {
                name: "regions_lib.3693", // Гифу
                shortcode: "3693",
            },
            {
                name: "regions_lib.3691", // Гумма
                shortcode: "3691",
            },
            {
                name: "regions_lib.3699", // Ибараки
                shortcode: "3699",
            },
            {
                name: "regions_lib.3678", // Иватэ
                shortcode: "3678",
            },
            {
                name: "regions_lib.3671", // Кагава
                shortcode: "3671",
            },
            {
                name: "regions_lib.3708", // Канагава
                shortcode: "3708",
            },
            {
                name: "regions_lib.3687", // Кумамото
                shortcode: "3687",
            },
            {
                name: "regions_lib.3696", // Мияги
                shortcode: "3696",
            },
            {
                name: "regions_lib.3675", // Миядзаки
                shortcode: "3675",
            },
            {
                name: "regions_lib.3694", // Нагано
                shortcode: "3694",
            },
            {
                name: "regions_lib.3695", // Ниигата
                shortcode: "3695",
            },
            {
                name: "regions_lib.3690", // Окаяма
                shortcode: "3690",
            },
            {
                name: "regions_lib.3685", // Окинава
                shortcode: "3685",
            },
            {
                name: "regions_lib.3684", // Сига
                shortcode: "3684",
            },
            {
                name: "regions_lib.3700", // Сидзуока
                shortcode: "3700",
            },
            {
                name: "regions_lib.3664", // Симане
                shortcode: "3664",
            },
            {
                name: "regions_lib.3692", // Тотиги
                shortcode: "3692",
            },
            {
                name: "regions_lib.3663", // Тоттори
                shortcode: "3663",
            },
            {
                name: "regions_lib.3667", // Фукуи
                shortcode: "3667",
            },
            {
                name: "regions_lib.3701", // Фукуока
                shortcode: "3701",
            },
            {
                name: "regions_lib.3689", // Фукусима
                shortcode: "3689",
            },
            {
                name: "regions_lib.3702", // Хоккайдо
                shortcode: "3702",
            },
            {
                name: "regions_lib.3703", // Хёго
                shortcode: "3703",
            },
            {
                name: "regions_lib.3674", // Ямагата
                shortcode: "3674",
            },
            {
                name: "regions_lib.3683", // Ямагути
                shortcode: "3683",
            },
            {
                name: "regions_lib.3669", // Яманаси
                shortcode: "3669",
            },
        ]
    },
    {
        shortcode: "IM", // о-в Мэн
        name: "regions_lib.IM",
        regions: [
            {
                name: "regions_lib.3718", // Andreas
                shortcode: "3718",
            },
            {
                name: "regions_lib.3723", // Arbory
                shortcode: "3723",
            },
            {
                name: "regions_lib.3715", // Ballaugh
                shortcode: "3715",
            },
            {
                name: "regions_lib.3729", // Braddan
                shortcode: "3729",
            },
            {
                name: "regions_lib.3710", // Bride
                shortcode: "3710",
            },
            {
                name: "regions_lib.3727", // Castletown
                shortcode: "3727",
            },
            {
                name: "regions_lib.3733", // Douglas
                shortcode: "3733",
            },
            {
                name: "regions_lib.3713", // German
                shortcode: "3713",
            },
            {
                name: "regions_lib.3712", // Jurby
                shortcode: "3712",
            },
            {
                name: "regions_lib.3722", // Laxey
                shortcode: "3722",
            },
            {
                name: "regions_lib.3716", // Lezayre
                shortcode: "3716",
            },
            {
                name: "regions_lib.3719", // Lonan
                shortcode: "3719",
            },
            {
                name: "regions_lib.3726", // Malew
                shortcode: "3726",
            },
            {
                name: "regions_lib.3725", // Marown
                shortcode: "3725",
            },
            {
                name: "regions_lib.3714", // Maughold
                shortcode: "3714",
            },
            {
                name: "regions_lib.3721", // Michael
                shortcode: "3721",
            },
            {
                name: "regions_lib.3732", // Onchan
                shortcode: "3732",
            },
            {
                name: "regions_lib.3717", // Patrick
                shortcode: "3717",
            },
            {
                name: "regions_lib.3730", // Peel
                shortcode: "3730",
            },
            {
                name: "regions_lib.3728", // Port Erin
                shortcode: "3728",
            },
            {
                name: "regions_lib.3724", // Port St Mary
                shortcode: "3724",
            },
            {
                name: "regions_lib.3731", // Ramsey
                shortcode: "3731",
            },
            {
                name: "regions_lib.3720", // Rushen
                shortcode: "3720",
            },
            {
                name: "regions_lib.3711", // Santon
                shortcode: "3711",
            },
        ]
    },
    {
        shortcode: "NF", // о-в Норфолк
        name: "regions_lib.NF",
        regions: [
        ]
    },
    {
        shortcode: "CX", // о-в Рождества
        name: "regions_lib.CX",
        regions: [
        ]
    },
    {
        shortcode: "SH", // о-в Св. Елены
        name: "regions_lib.SH",
        regions: [
            {
                name: "regions_lib.3736", // Saint Helena
                shortcode: "3736",
            },
            {
                name: "regions_lib.3735", // Остров Вознесения
                shortcode: "3735",
            },
            {
                name: "regions_lib.3734", // Острова Тристан-да-Кунья
                shortcode: "3734",
            },
        ]
    },
    {
        shortcode: "PN", // о-ва Питкэрн
        name: "regions_lib.PN",
        regions: [
        ]
    },
    {
        shortcode: "TC", // о-ва Тёркс и Кайкос
        name: "regions_lib.TC",
        regions: [
        ]
    },
];



