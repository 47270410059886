<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9202_26937)">
            <path d="M22.7992 11.9992C22.7992 6.03454 17.9639 1.19922 11.9992 1.19922C6.03454 1.19922 1.19922 6.03454 1.19922 11.9992C1.19922 17.9639 6.03454 22.7992 11.9992 22.7992C17.9639 22.7992 22.7992 17.9639 22.7992 11.9992Z" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M12.0115 17.5429L10.2955 15.6349H9.28751C8.8358 15.6349 8.38852 15.5457 7.97135 15.3725C7.55417 15.1993 7.1753 14.9454 6.85645 14.6254C6.53761 14.3055 6.28507 13.9257 6.1133 13.5079C5.94154 13.0902 5.85391 12.6426 5.85549 12.1909V10.9909C5.85548 10.0796 6.21669 9.20536 6.85997 8.55983C7.50325 7.9143 8.37618 7.55005 9.28751 7.54688H14.9875C15.8988 7.55005 16.7717 7.9143 17.415 8.55983C18.0583 9.20536 18.4195 10.0796 18.4195 10.9909V12.1909C18.4211 12.6426 18.3335 13.0902 18.1617 13.5079C17.9899 13.9257 17.7374 14.3055 17.4185 14.6254C17.0997 14.9454 16.7208 15.1993 16.3036 15.3725C15.8865 15.5457 15.4392 15.6349 14.9875 15.6349H13.7875L12.0115 17.5429Z" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
        </g>
        <defs>
            <clipPath id="clip0_9202_26937">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: "ChatIcon"
}
</script>

<style scoped>

</style>
