<template>
    <div class="wrapper">
        <div class="modal_title">Уведомления</div>
    </div>
</template>

<script>

export default {
    name: "NotificationsModal",
    components: {},
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>


<style lang="scss" scoped>


</style>
