<template>
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8121 15.398L23.7959 8.41421L22.3816 7L15.3979 13.9837L8.41421 7.00006L7 8.41427L13.9837 15.398L7 22.3816L8.41421 23.7959L15.3979 16.8122L22.3816 23.7959L23.7959 22.3817L16.8121 15.398Z" fill="#BAC5C8"/>
    </svg>
</template>

<script>
export default {
    name: "CloseIcon"
}
</script>

<style scoped>

</style>
