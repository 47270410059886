<template>
  <div class="passport-wrap">
    <div class="passport">

      <div class="modal__block-title">
        Удостоверение личности
      </div>
      <div class="modal__title">
        Данные должны совпадать с теми, что указаны в вашем удостоверении личности
      </div>
      <div class="modal__info">
        Для подтверждения личности вам необходимо загрузить один из перечисленных документов: паспорт, ID-карта, другой
        документ, подтверждающий вашу личность (официально разрешённый на территории государства, гражданином которого
        вы
        являетесь). Водительское удостоверение не может быть принято как основной документ, подтверждающий личность.
      </div>
      <div class="passport__inputs-wrap">
        <div class="inputs">
          <label for="citezenship" class="inputs__labels">Гражданство</label>
          <input-arrow/>
          <select
              name="citezenship"
              id="citezenship"
              class="inputs__forms"
              v-model="user.citezenship"
          >

            <option>Россия</option>
            <option>Казахстан</option>
            <option>Финляндия</option>
          </select>
        </div>
        <div class="inputs">
          <label for="doctype" class="inputs__labels">Тип документа</label>
          <input-arrow/>
          <select
              name="doctype"
              id="doctype"
              class="inputs__forms"
              v-model="user.doctype"
          >

            <option>Паспорт</option>
            <option>ID-карта</option>
            <option>Другое</option>
          </select>
        </div>
        <div class="inputs">
          <label for="docdate" class="inputs__labels">Дата выдачи</label>

          <input
              name="docdate"
              id="docdate"
              class="inputs__forms"
              v-model="user.docdate"
          >

        </div>
        <div class="inputs">
          <label for="docdata" class="inputs__labels">Серия и номер документа</label>

          <input
              name="docdata"
              id="docdata"
              class="inputs__forms"
              v-model="user.docdata"
          >

        </div>
        <div class="inputs">
          <label for="docwhere" class="inputs__labels">Кем выдан</label>

          <input
              name="docwhere"
              id="docwhere"
              class="inputs__forms"
              v-model="user.docwhere"
          >

        </div>
      </div>
      <div class="modal__title">
        Загрузка документов
      </div>
      <div class="modal__info">
        Загрузите изображение разворота паспорта или другого основного документа, подтверждающего личность, который вы
        указали выше. Внесённые в форму данные должны совпадать с данными в документе.
      </div>
      <div class="modal__addbutton">
        <crest-icon/>
      </div>
      <div class="base-button">
        Добавить
      </div>
    </div>
  </div>
</template>

<script>
import InputArrow from "@/assets/icons/InputArrow";
import CrestIcon from "@/assets/icons/CrestIcon";

export default {
  name: "PassportModal",
  components: {CrestIcon, InputArrow},
  data() {
    return {
      user: {
        citezenship: "Россия",
        doctype: "Паспорт",
        docdate: "26.02.1998",
        docdata: "6589 895318",
        docwhere: "Первомайским РОВД Томской области",
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

.modal {
  &__title {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
    @include respond(550px) {
      text-align: center;
    }
  }

  &__info {
    font-weight: 500;
    font-size: 13px;
    color: #BAC5C8;
    margin-bottom: 30px;
    @include respond(550px) {
      text-align: center;
    }
  }

  &__block-title {
    background: #EBEFF1;
    border-radius: 15px;
    color: rgba(35, 37, 40, 0.5);
    font-weight: 500;
    font-size: 13px;
    padding: 6px 16px;
    width: fit-content;
    margin-bottom: 35px;
  }

  &__addbutton {
    background: #EBEFF1;
    border-radius: 16px;
    width: 75px;
    height: 75px;
    display: grid;
    place-items: center;
    cursor: pointer;
  }


}

.passport {

  width: 660px;
  display: flex;
  flex-direction: column;
  @include respond(750px) {
    width: 500px;
  }
  @include respond(550px) {
    width: auto;
  }
  @include respond(400px) {


  }

  &__inputs-wrap {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    @include respond(750px) {
      flex-direction: column;

    }
    .inputs {

      input{
        width: calc(100% - 57.5px);
      }
      &:nth-last-child(1){
        input{
          width: calc(100% - 57.5px);
        }
      }
    }
  }
  .base-button{
    width: 260px;
    align-self: flex-end;
    margin-top: 30px;
    @include respond(550px){
    align-self: center;
  }
  }
}

.inputs {
  position: relative;
  width: 326px;
  background: #EBEFF1;
  border-radius: 16px;
  flex-grow: 1;
  @include respond(750px) {
    width: 100%;

  }

  &__forms {
    padding: 25px 27.5px 10px 30px;
    background: #EBEFF1;
    border-radius: 16px;
    width: 326px;
    font-weight: 500;
    font-size: 18px;
    color: #2E3338;
    border: none;
    outline: none;
    appearance: none;
    @include respond(750px) {
      width: 100%;
    }
  }

  &__labels {
    position: absolute;
    top: 10px;
    font-weight: 500;
    font-size: 10px;
    left: 30px;
    color: rgba(46, 51, 56, 0.5);
  }

  svg {
    position: absolute;
    top: 27.5px;
    right: 30px;
  }

  input {
    width: 268.5px;
  }
}
.base-button {
  padding: 20px;
  background: #3E76B5;
  border-radius: 16px;
  text-align: center;
  font-weight: 800;
  font-size: 16px;
  color: #FFFFFF;
  transition: 0.3s ease-in-out;

  &:hover {
    background: #008CE0;
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.3s ease-in-out;
  }

  &.gray {
    background: #E3EAED;
    color: rgb(35, 37, 40, 0.5);

    &:hover {
      background: #008CE0;
      cursor: pointer;
      color: #FFFFFF;
      transition: 0.3s ease-in-out;
    }
  }
}
</style>
