<template>
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" stroke="white" stroke-width="2"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M1 3.32258L6.81132 9L15 1"/>
  </svg>
</template>

<script>
export default {
name: "CheckmarkIcon"
}
</script>

<style scoped>

</style>
