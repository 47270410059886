<template>
    <div class="add-package">
        <div class="popup-title">{{ $t('add_package_popup.title_add_package_popup') }}</div>
        <div class="add-package__account-container d-flex fc jc">
            <div class="add-package__account d-flex"
                 :class="{selected:account==='main'}"
                 @click="account='main'"
            >
                <div class="add-package__account-check d-flex jc ac tc">
                    <CheckmarkIcon/>
                </div>
                <div>
                    <div class="add-package__account-title">
                        {{ $t('add_package_popup.main_account') }}
                    </div>
                    <div class="add-package__account-caption">
                <span v-if="balance>=pack.cost">
                    {{ $t('add_package_popup.title_balance') }} {{ separateThousands(balance) }} $
                </span>
                        <span v-else class="warning">
                    {{ $t('add_package_popup.insufficient_funds') }}
                </span>
                    </div>
                </div>
            </div>
            <div class="add-package__account d-flex"
                 :class="{selected:account==='partner'}"
                 @click="account='partner'">
                <div class="add-package__account-check d-flex jc ac">
                    <CheckmarkIcon/>
                    </div>
                <div>
                    <div class="add-package__account-title">
                        {{ $t('add_package_popup.partner_account') }}
                    </div>
                    <div class="add-package__account-caption">
                <span v-if="partnerBalance>=pack.cost">
                    {{ $t('add_package_popup.title_balance') }} {{ separateThousands(partnerBalance) }} $
                </span>
                        <span v-else class="warning">
                            {{ $t('add_package_popup.insufficient_funds') }}
                </span>
                    </div>
                    <div class="add-package__account-offer">
                        {{ $t('add_package_popup.plus_shares') }}
                    </div>
                </div>
            </div>
        <!--<div class="add-package__promocode-container">
            <div><span class="base-link" @click="showPromocode = !showPromocode">
            <template v-if = "!showPromocode">
                У меня есть промокод
            </template>
                <template v-else>
                    Скрыть
                </template>
        </span></div>
            <div class="add-package__promocode-wrapper d-flex ac" v-if="showPromocode">
                <div class="add-package__promocode input-wrapper">
                    <label for = "promocode">Ваш промокод</label>
                    <input type="text" id = "promocode"/>
                </div>
                <button class="base-button" @click="applyPromocode">Применить</button>
            </div>
        </div>-->

          <div v-if="fullPayment" class="add-package__input d-flex fc jc">
            <div class = "add-package__input-label tcbackground: #EBEFF1;">
                {{ $t('add_package_popup.payments_left', {pack:pack.remained_pays}) }}</div>
            <input v-model="paysNum" type="number"
                   :max="pack.remained_pays"
                   @change="checkValue()"
                   @keyup="checkValue()"
            >
          </div>
      <div class="add-package__cost d-flex fc jc tc">
        <div>{{ $t('add_package_popup.total_payable') }}</div>

        <div>{{ separateThousands(pack.cost*paysNum) }} $</div>
      </div>
      <router-link class="d-flex jc ac add-package__action base-button"
                   :to="{name: 'deposit'}"
                   v-if="notEnoughFunds"
                   @click="$emit('close')">
          {{ $t('add_package_popup.top_up') }}
      </router-link>
      <template v-else>
        <button
            v-if="!fullPayment"
            class="add-package__action base-button"
            @click="addPackage(1)"
            :class="{gray:isLoading}"
        :disabled="isLoading">
            <LoaderAnimation v-if="isLoading"/>
            {{ $t('add_package_popup.pay_btn') }}
        </button>
        <button v-else class = "add-package__action base-button"
                @click="addPackage(paysNum)"
                :class="{gray:isLoading}"
                :disabled="isLoading">
            <LoaderAnimation v-if="isLoading"/>
            {{ $t('add_package_popup.prepay_btn') }}
        </button>
      </template>
          <span class="add-package__link tc" v-if="!fullPayment&&pack.remained_pays>1&&!notEnoughFunds"
                @click="fullPayment=true">{{ $t('add_package_popup.prepay_btn') }}</span>
          <span class="add-package__link tc" v-if="fullPayment" @click="fullPayment=false">{{ $t('add_package_popup.back_btn') }}</span>
        </div>
    </div>
</template>

<script>
import CheckmarkIcon from "@/assets/icons/CheckmarkIcon";
import {mapGetters} from "vuex";
import LoaderAnimation from "@/assets/animations/LoaderAnimation";

export default {
    name: "AddPackagePopup",
    components: {LoaderAnimation, CheckmarkIcon},
    props: {pack: Object},
    emits:['close'],
    data() {
        return {
            account: 'main',
            fullPayment:false,
            showPromocode: false,
            paysNum:1,
            isLoading:false,
        }
    },
    computed: {
        ...mapGetters(
            {
                balance: 'user/balanceGetter',
                partnerBalance: 'user/partnerBalanceGetter'
            }
        ),
        notEnoughFunds() {
            const cost = this.fullPayment?this.pack.cost*this.paysNum:this.pack.cost;
            if (this.account === 'main')
              return this.balance < cost;
            return this.partnerBalance < cost;
        }
    },
    methods: {
        checkValue(){
            if (this.paysNum === ''){
                return;
            }
            if (isNaN(this.paysNum)) {
                this.paysNum = 1;
            }
            if (this.paysNum < 1){
                this.paysNum = 1;
            }
            if (this.paysNum > this.pack.remained_pays ){
                this.paysNum = this.pack.remained_pays
            }
        },
        separateThousands(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        openBalancePopup() {
            //this.$store.commit('popups/openPopup', {name: 'BalancePopup'});
        },
        addPackage(payNum=1) {
            if (this.isLoading) return;
            this.isLoading = true;

            if (this.pack.guid) {
              let qs = {packet_guid: this.pack.guid, pay_count: payNum,type:this.account}
              this.$store.dispatch('packages/payInstallment', qs)
                  .then(data=>{
                      if (this.account==='main') this.$store.commit('user/setBalance',data.data.balance);
                      else this.$store.commit('user/setPartnerBalance',data.data.balance);
                  })
                  .catch(e => console.error(e)).finally(() => this.$emit('close'));
            }
            else if (this.pack.id)
            {
              let qs = {id: this.pack.id,type:this.account}
              this.$store.dispatch('packages/buy', qs).then((data)=>{
                this.$router.push({name:'investments.my'});
                  if (this.account==='main') this.$store.commit('user/setBalance',data.data.balance);
                  else this.$store.commit('user/setPartnerBalance',data.data.balance);
              }).catch(e => console.error(e)).finally(() => this.$emit('close'));
            }
        },
    },
    mounted(){
        if (this.pack.full_payment) {
          this.paysNum = this.pack.remained_pays;
          this.fullPayment = true
        }
        else this.paysNum=1;
    }
}
</script>

<style scoped lang="scss">
@use "~@/styles/mixins.scss" as *;

.add-package {
    font-weight: 500;
    &__account {
        &-container {
            gap: 10px;
        }
        cursor: pointer;
        position: relative;
        background: #E3EAED;
        border-radius: 16px;
        padding: 13px 16px;
        flex:1 0 0;
        border: 2px solid transparent;
        &-check {
            margin-right: 16px;
            background: #fff;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            svg {
                stroke:#3E76B5;
                width: 50%;
                display: none;
            }
        }

        &-title {
            font-size: 16px;
            color: #232528;
            font-weight: 700;
            opacity: .5;
        }

        &-caption {
            font-size: 13px;
            color: #232528;
            opacity: .5;
        }

        &.selected {
            border-color: #3E76B5;

            .add-package__account-icon svg {
                display: inline-block;
            }

            .add-package__account-title, .add-package__account-caption {
                opacity: 1;
            }
            .add-package__account-check svg{
                display:inline-block;
            }
        }

        &-offer {
            font-weight: 700;
            color:#fff;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 10px;
            padding: 4px 12px;
            background: #38C1C2;
            border-radius: 0 16px;
        }
    }

    &__promocode {
        &-container{
            margin-bottom: 30px;
        }
        &-wrapper{
            margin-top:15px;
            gap:15px;
            @include respond($mobile){
                flex-direction: column;
                gap:10px;
                .input-wrapper{
                    width:100%;
                    max-width:400px;
                }
            }
        }
        text-transform: uppercase;
    }

    &__cost {
        margin:15px 0;
        color: #000;

        div:first-child {
            font-size: 13px;
            margin-bottom: 8px;
        }

        div:last-child {
            font-size: 40px;
        }
    }
    &__action{
        font-size: 16px;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
    }
  &__input{
    input{
      border-radius: 16px;
      font-weight: 500;
      font-size: 18px;
      height: 60px;
      background: #EBEFF1;
      padding: 0 15px;
      border:none;
    }
    &-label{
      font-weight: 500;
      font-size: 13px;
      color: #232528;
      margin-bottom: 7px;
    }
  }
  &__link{
    cursor:pointer;
    color: #1A4885;
    margin-top:15px;
    text-decoration: underline;
  }
}
</style>
