<template>
  <footer>
    <div class="footer">
      <a :href="require('@/files/riskWarning.pdf')" target="_blank">{{ $t('main_footer.risk_warning_title') }}</a>
      <a :href="require('@/files/personalInformation.pdf')" target="_blank">{{ $t('main_footer.personal_information') }}</a>
      <a :href="require('@/files/legalAddress.pdf')" target="_blank">{{ $t('main_footer.address') }}</a>
<!--        <a href="#">{{ $t('main_footer.aml_politics_title') }}</a>
        <a href="#">{{ $t('main_footer.legal_address_title') }}</a>-->
        <a :href="require('@/files/privacyPolicy.pdf')" target="_blank">{{ $t('main_footer.privacy_policy_title') }}</a>
        <a :href="require('@/files/returnPolicy.pdf')" target="_blank">{{ $t('main_footer.return_police') }}</a>
<!--        <a href="#">{{ $t('main_footer.personal_data_title') }}</a>-->
    </div>
  </footer>
</template>

<script>
export default {
  name: "mainFooter"
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;
footer{
    margin: auto auto 0;
}
.footer {
  margin:30px 0;
  display: flex;
  width: 480px;
flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  @include respond($mobile){
    width: auto;
    flex-direction: column;
    align-items: center;
  }
  a{
    color: #1A4885;
    font-weight: 500;
    font-size: 13px;
    transition: 0.15s ease-out;
    &:hover{
     transform: translatey(-1px);
      text-decoration: none;
      transition: 0.15s ease-out;
    }
  }
}
</style>
