<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="white" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7784_1468)">
      <path d="M6.57812 4.32792V0.815918H15.214V15.2159H6.57812V11.6959" stroke-width="2" stroke-miterlimit="10" stroke="#BAC5C8"/>
      <path d="M10.8961 8H1.78125" stroke-width="2" stroke-miterlimit="10" stroke="#BAC5C8"/>
      <path d="M4.16729 11.1199L1.10547 7.99988L4.16729 4.87988"  stroke-width="2" stroke-miterlimit="10" stroke="#BAC5C8"/>
    </g>
    <defs>
      <clipPath id="clip0_7784_1468">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "LogoutIcon"
}
</script>

<style scoped>

</style>
