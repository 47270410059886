import axios from "@/utils/axios";

export default {
    namespaced: true,
    state: () => ({
        userList: [],
        userData: {}
    }),
    getters: {
        getUserList(state){
            return state.userList;
        },
    },
    mutations: {
        setUserList(state, payload) {
            state.userList = payload;
        },
        addUserData(state, payload) {
            state.userData[payload.id] = payload;
        }
    },
    actions: {
        getUserList({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/api/admin/user/search/', {params:payload}).then(({data}) => {
                    commit('setUserList', data.data);
                    resolve(data);
                    console.log('response',data);
                }).catch(error => reject(error));
            });
        },
        getUserData({state,commit},id){
            return new Promise((resolve, reject) => {
                if (state.userData[id]) resolve(state.userData[id]);
                else
                axios.get('/api/admin/user/get/',{params:{id:id}}).then(({data})=>{
                    commit('addUserData', data);
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },
        verifyUser({state},id){
            return new Promise((resolve, reject) => {
                    axios.post('/api/admin/user/verify/',{id:id}).then(({data})=>{
                        state.userList.map(el=>{
                            if (el.id===id) el.verification_status = 'verified';
                            return el;
                        })
                        resolve(data);
                    }).catch(e=>{reject(e)})
            });
        },
        cancelVerification({state},id){
            return new Promise((resolve, reject) => {
                axios.post('/api/admin/user/cancel_verification/',{id:id}).then(({data})=>{
                    state.userList.map(el=>{
                        if (el.id===id) el.verification_status = 'not_verified';
                        return el;
                    })
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },
        getFinances(ctx,payload = null){
            return new Promise((resolve, reject) => {
                axios.get('/api/admin/finance/get_stats/',{params:payload}).then(({data})=>{
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },

        createProjectTransaction(ctx,payload){
            return new Promise((resolve, reject) => {
                axios.post('/api/admin/finance/create_project_transaction/',payload).then(({data})=>{
                    //console.log('uspeh',data);
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },

        getWithdraws(ctx,payload=null){
            return new Promise((resolve, reject) => {
                axios.get('/api/admin/withdraw/get/',{params:payload}).then(({data})=>{
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },

        setWithdrawStatus(ctx,payload){
            return new Promise((resolve, reject) => {
                axios.post('/api/admin/withdraw/set_status/',payload).then(({data})=>{
                    //console.log('ssw',data);
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },

        getStructure(ctx,payload){
            return new Promise((resolve, reject) => {
                axios.get('/api/admin/structure/get/',{params:payload}).then(({data})=>{
                    //console.log('strct',data);
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },


        getPaymentRequests(ctx,payload=null){
            return new Promise((resolve, reject) => {
                axios.get('/api/admin/payments/get_requests/',{params:payload}).then(({data})=>{
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },
        setPaymentRequestStatus(ctx,payload){
            return new Promise((resolve, reject) => {
                axios.post('/api/admin/payments/set_request_status/',payload).then(({data})=>{
                    resolve(data);
                }).catch(e=>{reject(e)})
            });
        },

        changeReferrer(ctx,payload){
            return new Promise((resolve, reject) => {
                axios.post('/api/admin/user/set_referrer/',payload)
                    .then(()=>{resolve();
                }).catch(e=>{reject(e)})
            });
        },
    }
}
