<template>
    <div class="popup__backdrop d-flex" v-if="popup"
         @mousedown="backdrop_mousedown = true"
         @mouseup="backdrop_mouseup_func"
    >
        <div class="popup"
             :class="popup.classes"
             @mousedown.stop
             @mouseup.stop
             @mouseup="backdrop_mousedown = false">
            <CloseIcon class="close-btn" @click="closePopup"/>
            <component :is="popup.name"
                       v-bind="popup.props"
                       @close="closePopup"
            >
            </component>
        </div>

    </div>

</template>

<script>
import DefaultModal from "@/components/popups/DefaultModal";
import BalancePopup from "@/components/popups/BalancePopup";
import {mapState} from "vuex";
import CloseIcon from "@/assets/icons/CloseIcon";
import AddPackagePopup from "@/components/popups/AddPackagePopup";
import CertificateModal from "@/components/popups/CertificateModal";
import PayMethodModal from "@/components/popups/PayMethodModal";
import ContributedFundsModal from "@/components/trashPopup/ContributedFundsModal.vue";
import StructureModal from "@/components/popups/StructureModal";
import WithdrawModal from "@/components/trashPopup/WithdrawModal.vue";
import PurchasedPackagesModal from "@/components/trashPopup/PurchasedPackagesModal.vue";
import SupportChatModal from "@/components/trashPopup/SupportChatModal.vue";
import IdentityCardModal from "@/components/popups/IdentityCardModal";
import PersonModal from "@/components/trashPopup/PersonModal.vue";
import PassportModal from "@/components/PassportModal";
import VerificationModal from "@/components/VerificationModal";
import Verification__Modal from "@/components/trashPopup/Verification__Modal.vue";
import EditEmailModal from "@/components/popups/EditEmailModal";
import EditPhoneModal from "@/components/popups/EditPhoneModal";
import AddressModal from "@/components/popups/AddressModal";
import OtherContactsModal from "@/components/trashPopup/OtherContactsModal.vue";
import PaymentSchedulePopup from "@/components/popups/PaymentSchedulePopup";
import PartnerToMainConvert from "@/components/popups/PartnerToMainConvert";
import DocumentsModal from "@/components/trashPopup/DocumentsModal.vue";
import NotificationsModal from "@/components/trashPopup/NotificationsModal.vue";
import LogsModal from "@/components/trashPopup/LogsModal.vue";
import EditWalletModal from "@/components/popups/EditWalletModal";
import WithdrawFromPartnerAccount from "@/components/popups/WithdrawFromPartnerAccount";
import PartnerToPartnerTransferUserSelect from "@/components/popups/PartnerToPartnerTransferUserSelect";
import PartnerToPartnerSend from "@/components/popups/PartnerToPartnerSend";
import TransactionsHistoryModal from "@/components/popups/TransactionsHistoryModal";
import ContactsModal from "@/components/popups/ContactsModal.vue";
import ChangeReferrerModal from "@/components/popups/ChangeReferrerModal.vue";
import TextModal from "@/components/popups/TextModal.vue";

export default {
    name: "PopupWrapper",
    components:{
        PartnerToPartnerSend,
        PartnerToPartnerTransferUserSelect,
        PartnerToMainConvert,
        AddPackagePopup,
        PaymentSchedulePopup,
        CloseIcon,
        BalancePopup,
        PayMethodModal,
        ContributedFundsModal,
        WithdrawModal,
        PurchasedPackagesModal,
        StructureModal,
        SupportChatModal,
        DocumentsModal,
        NotificationsModal,
        LogsModal,
        VerificationModal,
        Verification__Modal,
        PassportModal,
        IdentityCardModal,
        PersonModal,
        EditEmailModal,
        EditPhoneModal,
        AddressModal,
        OtherContactsModal,
        EditWalletModal,
        DefaultModal,
        WithdrawFromPartnerAccount,
        TransactionsHistoryModal,
        CertificateModal,
        ContactsModal,
        ChangeReferrerModal,
        TextModal
    },
    data() {
        return {
            backdrop_mousedown: false,
            backdrop_mouseup: false,
        }
    },
    computed:{
        ...mapState({
            popup: state => state.popups.popup,
        }),
    },

    methods:{
        backdrop_mouseup_func(){
            if (this.backdrop_mousedown === true){
                this.$store.commit('popups/closePopup');
            }
            this.backdrop_mousedown = false;
            this.backdrop_mouseup = false;
        },
        closePopup(){
            //location.reload();
            this.$store.commit('popups/closePopup');

        },
    },
}
</script>

<style lang = "scss">
@use "~@/styles/mixins.scss" as *;
.popup{
    &.referralModal{
        width:  auto;
    }
    &__backdrop{
        background: rgba(46, 51, 56, 0.5);
        backdrop-filter: blur(20px);
        position:fixed;
        z-index: 100;
        width:100%;
        height: 100vh;
        left:0;top:0;
    }
    .close-btn {
        position:absolute;
        top:10px;
        right: 10px;
        cursor:pointer;
    }
    position:relative;
    background: #fff;
    width:100%;
    max-width: 720px;
    margin:auto;
    border-radius:16px;
    padding:30px;
    box-sizing: border-box;
    max-height: calc(100vh - 10px);
    overflow:auto;
    @include respond(730px){
        max-width:calc(100vw - 10px);
    }
    &.mini{
      max-width: 360px;
        @include respond(370px){
            max-width:calc(100vw - 10px);

        }
    }
    &.big{
      max-width: 900px;
    }
}


.modal_title{
    display: inline-block;
    color: rgba(46, 51, 56, 0.5);
    padding: 6px 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    background: #EBEFF1;
    border-radius: 15px;
    margin-bottom: 30px;
}
</style>
