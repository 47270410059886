<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7400_946)">
      <path opacity="0.5"
            d="M9.00001 2.04004C10.3845 2.04004 11.7379 2.45058 12.889 3.21975C14.0401 3.98892 14.9374 5.08217 15.4672 6.36125C15.997 7.64033 16.1356 9.0478 15.8655 10.4057C15.5954 11.7635 14.9287 13.0108 13.9498 13.9898C12.9708 14.9688 11.7235 15.6354 10.3656 15.9055C9.00777 16.1756 7.6003 16.037 6.32122 15.5072C5.04214 14.9774 3.94889 14.0802 3.17972 12.929C2.41055 11.7779 2.00001 10.4245 2.00001 9.04004C2.00001 7.18352 2.7375 5.40305 4.05025 4.09029C5.36301 2.77754 7.14349 2.04004 9.00001 2.04004ZM9.00001 0.0400391C7.21997 0.0400391 5.47991 0.567883 3.99987 1.55682C2.51983 2.54575 1.36628 3.95135 0.685095 5.59589C0.00390661 7.24042 -0.174333 9.05002 0.172934 10.7959C0.520201 12.5417 1.37737 14.1453 2.63604 15.404C3.89471 16.6627 5.49836 17.5198 7.24419 17.8671C8.99002 18.2144 10.7996 18.0361 12.4442 17.355C14.0887 16.6738 15.4943 15.5202 16.4832 14.0402C17.4722 12.5601 18 10.8201 18 9.04004C18 6.65309 17.0518 4.36391 15.364 2.67608C13.6761 0.988251 11.387 0.0400391 9.00001 0.0400391Z"
            fill="white"/>
      <path opacity="0.5" d="M10.4703 5L7.07031 9L10.4703 13" stroke="white" stroke-width="2"
            stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_7400_946">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "hideIcon"
}
</script>

<style scoped>

</style>