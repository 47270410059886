<template>
    <div class="wrapper">
        <div class="modal_title">Дополнительные контакты</div>

        <div v-if="!successful">

            <div class="add">
                <div class="t">Добавить:</div>
                <div class="field_group d-flex">
                    <TextField v-model="newContact_id"
                               :class_wrap="'field_newContact_id'"
                               :class_input="'gray'"
                               :label="'Выбрать мессенджер'"
                               :type="'select'"
                               :selectOptions="contactsOptions"
                    />
                    <TextField v-model=newContact_val
                               :class_input="'gray'"
                               :label="'Ссылка/контакт'"
                    ></TextField>
                    <ItemIcon class="add_icon" @click="addNew"></ItemIcon>
                </div>
                <div class="form_error" v-if="error">{{error}}</div>
            </div>

            <div class="fields_wrap contact_list d-flex">
                <div class="field_group d-flex" v-for="(c,index) in contacts" :key="index">
                    <div class="contact_name">{{c.id}}:</div>
                    <TextField v-model="c.val"
                               :class_input="'gray'"
                               :label="c.id"
                    ></TextField>
                    <svg class="delete_icon"
                         @click="deleteItem(c.id)"
                         xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px"><path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z"/></svg>
                </div>
            </div>


            <div class="button_wrap">
                <button class="base-button" :disabled="!valid" @click="submit">Сохранить</button>
            </div>
        </div>

        <div class="successful" v-if="successful">
            <p>Контакты сохранены</p>
            <button class="base-button btn_close" @click="$emit('close')">Закрыть</button>
        </div>

    </div>
</template>

<script>
import TextField from "@/components/common/TextField.vue";
import { useI18n } from "vue-i18n";
import {mapGetters} from "vuex";
import ItemIcon from '@/assets/icons/ItemIcon.vue'

export default {
    name: "OtherContactsModal",
    components: {
        TextField,
        ItemIcon
    },
    setup() {
        const {t} = useI18n({useScope: 'global'});
        return {
            t,
        }
    },
    data() {
        return {
            contactsOptions: {
                'VK' : 'VK',
                'Facebook' : 'Facebook',
                'Instagram' : 'Instagram',
                'ok' : 'Одноклассники',
                'LinkedIn' : 'LinkedIn',
                'Telegram' : 'Telegram',
                'WhatsApp' : 'WhatsApp',
                'QZone' : 'QZone',
                'SinaWeibo' : 'Sina Weibo',
                'Tumblr' : 'Tumblr',
                'TikTok' : 'TikTok',
                'WeChat' : 'WeChat',
                'Snapchat' : 'Snapchat',
                'Discord' : 'Discord',
                'Viber' : 'Viber',
                'Line' : 'Line',
                'Zalo' : 'Zalo',
            },

            valid: true,
            error: '',

            successful: false,

            newContact_id: '',
            newContact_val: '',
            contacts: [
                {id:'VK', val: 'https://vk.com/1'},
                {id:'Telegram', val: '@ssdfdsfdf'},
            ],

        }
    },
    computed: {
        ...mapGetters({
            user: 'user/userGetter',
        })
    },
    mounted() {
        this.contacts.forEach(contact => {
            delete this.contactsOptions[contact.id];
        });
    },
    methods: {
        addNew(){
            //console.log('addNew()');
            if (this.newContact_id === '' || this.newContact_val === ''){
                this.error = 'Оба поля выше должны быть заполнены';
                return false;
            }else{
                this.error = '';
            }
            this.contacts.push({id:this.newContact_id, val: this.newContact_val});
            this.contacts.forEach(contact => {
                delete this.contactsOptions[contact.id];
            });
            this.newContact_id = '';
            this.newContact_val = '';
        },
        deleteItem(id){
            this.contacts = this.contacts.filter(contact => contact.id !== id);
        },
        submit(){
            //console.log('submit()')
            //console.log(this.contacts)
            // запрос не сервер
        },
    },
}
</script>


<style lang="scss" scoped>
.text1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 10px;
}
.text2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #7e7e7e;
    margin-bottom: 20px;
}


.fields_wrap{
    gap: 10px;
    flex-wrap: wrap;
}
.field_group {
    width: 100%;
    display: flex;
    gap: 10px;
}


.contact_list{
    .field_group {
        align-items: center;
    }
}
.contact_name {
    font-weight: 600;
    width: 115px;
}
.delete_icon{
    cursor: pointer;
    &:hover{
        fill: #008CE0;
    }
}

.add {
    margin-bottom: 20px;
    .t{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 13px;
    }
    .field_group {
        align-items: center;
    }
}
.add_icon{
    cursor: pointer;
    &:hover :deep(circle){
        fill: #008CE0!important;
    }
}
.field_newContact_id{
    width: 255px
}




.button_wrap{
    margin-top: 30px;
    display: flex;
    justify-content: right;
}

.successful{
    .base-button{
        margin-top: 30px;
    }
}


.form_error {
    color: red;
}
</style>
