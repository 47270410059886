<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M7.00931 9.71997L9.14932 7.56998L7.30929 5.72998L6.00931 7.06998C4.82931 8.25998 11.7793 15.22 13.0093 14.07L14.3593 12.72L12.5093 10.88L10.2893 13.02" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
name: "VerPhone"
}
</script>

<style scoped>

</style>