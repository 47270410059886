<template>
    <router-view/>
</template>

<script>

export default {




}
</script>

<style lang="scss">
@import './assets/tailwind.css';
@import 'styles/global.scss';

</style>
