<template>
    <div class="wrapper">
        <div class="modal_title">{{ $t('verify_popup.title_verify_popup') }}</div>


        <div class="text1">
            {{ $t('verify_popup.warning_text') }}
        </div>
        <div class="text2">
            {{ $t('verify_popup.text_1') }}
        </div>

        <div class="fields_wrap d-flex">
            <div class="fields_wrap d-flex">
                <div class="field_group">
                    <TextField v-model="document.first_name"
                               :class_input="'gray'"
                               :label="this.$t('verify_popup.first_name')"
                               :readonly="isLoading"
                    >
                        <div class="warning" v-if="document.first_name && document.first_name.length >30">
                            {{$t('verify.error_too_many_symbols_30')}}
                        </div>
                    </TextField>


                    <TextField v-model="document.second_name"
                               :class_input="'gray'"
                               :label="this.$t('verify_popup.last_name')"
                               :readonly="isLoading"
                    >
                        <div class="warning" v-if="document.second_name && document.second_name.length >30">
                            {{$t('verify.error_too_many_symbols_30')}}
                        </div>
                    </TextField>

                </div>

                <div class="field_group">
                    <TextField v-model="document.third_name"
                               :class_input="'gray'"
                               :label="this.$t('verify_popup.third_name')"
                               :readonly="isLoading"
                    >
                        <div class="warning" v-if="document.third_name && document.third_name.length >30">
                            {{$t('verify.error_too_many_symbols_30')}}
                        </div>
                    </TextField>

                    <TextField :model-value="date_birthday?date_birthday.toString():''"
                               :class_wrap="'field_birthday field_datepicker'"
                               :class_input="'gray'"
                               :label="this.$t('verify_popup.date_of_birth')"
                               :readonly="isLoading"
                    >
                        <Datepicker
                            :disabled="isLoading"
                            v-model="date_birthday"
                            position="left"
                            :transitions="false"
                            format="dd.MM.yyyy"
                            :enableTimePicker="false"
                            monthNameFormat="long"
                            locale="en"
                            :cancelText="$t('table_data.title_cancel')"
                            :selectText="$t('table_data.title_select')"
                            :start-date="new Date(2000, 1)"
                            :minDate="new Date(new Date().getFullYear() - 90, 0, 1)"
                            :maxDate="new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()+1)"
                            prevent-min-max-navigation
                        >
                        </Datepicker>
                    </TextField>
                </div>
            </div>
            <div class="field_group">
                <TextField v-model="country"
                           :class_wrap="'field_document_citizenship'"
                           :class_input="'gray'"
                           :label="this.$t('verify_popup.citizenship')"
                           :type="'select'"
                           :selectOptions="countryOptions"
                           :readonly="isLoading"
                />

                <TextField v-model="document.type"
                           :class_wrap="'field_document_type'"
                           :class_input="'gray'"
                           :label="this.$t('verify_popup.type_document')"
                           :type="'select'"
                           :selectOptions="documentTypeOptions"
                           :readonly="isLoading"
                />
            </div>

            <div class="field_group">
                <TextField :model-value="date_receipt?date_receipt.toString():''"
                           :class_wrap="'field_document_dt_receipt field_datepicker'"
                           :class_input="'gray'"
                           :label="this.$t('verify_popup.date_issued')"
                           :readonly="isLoading">

                    <Datepicker v-model="date_receipt"
                                :disabled="isLoading"
                                position="left"
                                :transitions="false"
                                format="dd.MM.yyyy"
                                :enableTimePicker="false"
                                monthNameFormat="long"
                                locale="en"
                                :cancelText="$t('table_data.title_cancel')"
                                :selectText="$t('table_data.title_select')"
                                :start-date="new Date(2010, 1)"
                                :minDate="new Date(new Date().getFullYear() - 90, 0, 1)"
                                :maxDate="new Date(new Date())"
                                prevent-min-max-navigation
                    >
                    </Datepicker>
                </TextField>

                <TextField v-model="document.number"
                           :class_input="'gray'"
                           :label="this.$t('verify_popup.series_number_document')"
                           :readonly="isLoading"
                >
                    <div class="warning" v-if="document.number && document.number.length >20">
                        {{$t('verify.error_too_many_symbols_20')}}
                    </div>
                </TextField>
            </div>

            <TextField v-model="document.issued_by"
                       :class_wrap="'field_document_issued_by'"
                       :class_input="'gray'"
                       :label="this.$t('verify_popup.by_issued_whom')"
                       :readonly="isLoading"
            ><div class="warning" v-if="document.issued_by && document.issued_by.length >200">
                {{$t('verify.error_too_many_symbols_200')}}
            </div></TextField>

        </div>


        <form class="upload_files">
            <div class="text1">
                {{ $t('verify_popup.download_document') }}
            </div>
            <div class="text2">
                <div>{{ $t('verify_popup.image_filesize') }} {{MAX_FILE_SIZE/(1024*1024) + 'Mb'}}</div>
                <div style="color:red" v-if="fileSizeExceedsLimit">{{ $t('verify_popup.size_limit') }}</div>
                {{ $t('verify_popup.download_text') }}
                <div>
                    {{ $t('verify_popup.download_text_1') }}
                </div>
                <div>
                    {{ $t('verify_popup.download_text_2') }}
                </div>
                <div>
                    {{ $t('verify_popup.download_text_3') }}
                </div>
            </div>

            <div class="choose_file d-flex">
                <input type="file"
                       id="file"
                       style="display:none;"
                       @change="onAttachmentChange"
                       accept="image/png, image/gif, image/jpeg"
                       multiple
                       ref="files"
                       :disabled="isLoading"
                >

                <div class="preview_wrap" v-for="(photo,i) in photos" :key="i">
                    <div class="preview" :style="{ 'background-image': imageUrl(photo) }"></div>
                    <svg class="delete_file"
                         @click="deleteFile(photo.name)"
                         width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 16C0 7.16344 7.16344 0 16 0H40.0015V12.9999C40.0015 21.8365 32.8381 28.9999 24.0016 28.9999H0V16Z"
                            fill="#EBEFF1"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M21.0039 6.83008V5H19.0039V6.83008H13H12V7.83008V12.4301V13.4301H13H13.7266V22.9999V23.9999H14.7266H25.2843H26.2843V22.9999V13.4301H27.0015H28.0015V12.4301V7.83008V6.83008H27.0015H21.0039ZM24.2843 13.4301H15.7266V21.9999H24.2843V13.4301ZM26.0015 8.83008V11.4299H25.2843H14.7266H14V8.83008H26.0015ZM19.2576 14.8799V20.1899H17.2576V14.8799H19.2576ZM22.7461 20.1899V14.8799H20.7461V20.1899H22.7461Z"
                              fill="#BAC5C8"/>
                    </svg>
                </div>

                <label class="choose_file_label" for="file">
                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="75" height="75" rx="16" fill="#EBEFF1"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M36 38.3171V48.1337H38V38.3171L47.8165 38.3171V36.3171L38 36.3171V26.5007H36V36.3171L26.1836 36.3171V38.3171L36 38.3171Z"
                              fill="#BAC5C8"/>
                    </svg>
                </label>
            </div>

            <!--<div class="preview_wrap" v-if="imageUrl" >
                <div class="preview" :style="{ 'background-image': imageUrl }"></div>
                <svg class="delete_file"
                     @click="deleteFile()"
                     width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 16C0 7.16344 7.16344 0 16 0H40.0015V12.9999C40.0015 21.8365 32.8381 28.9999 24.0016 28.9999H0V16Z" fill="#EBEFF1"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0039 6.83008V5H19.0039V6.83008H13H12V7.83008V12.4301V13.4301H13H13.7266V22.9999V23.9999H14.7266H25.2843H26.2843V22.9999V13.4301H27.0015H28.0015V12.4301V7.83008V6.83008H27.0015H21.0039ZM24.2843 13.4301H15.7266V21.9999H24.2843V13.4301ZM26.0015 8.83008V11.4299H25.2843H14.7266H14V8.83008H26.0015ZM19.2576 14.8799V20.1899H17.2576V14.8799H19.2576ZM22.7461 20.1899V14.8799H20.7461V20.1899H22.7461Z" fill="#BAC5C8"/>
                </svg>
            </div>-->

        </form>


        <div class="button_wrap">
            <button class="base-button" :disabled="!valid || isLoading " @click="submit">
                <LoaderAnimation v-if="isLoading"/>
                {{ $t('common.send') }}
            </button>
        </div>


    </div>
</template>

<script>
import {countries} from "@/data/country";
import TextField from "@/components/common/TextField";
import {mapActions, mapGetters} from "vuex";
import Datepicker from '@vuepic/vue-datepicker';
import {formatDate} from "@/utils/helpers";
import moment from "moment";
import LoaderAnimation from "@/assets/animations/LoaderAnimation";

export default {
    name: "IdentityCardModal",
    components: {
        LoaderAnimation,
        TextField,
        Datepicker
    },
    data() {
        return {
            MAX_FILE_SIZE: 15728640, // 15MB
            date_birthday: null,
            date_receipt: null,
            documentTypeOptions: {
                'passport': this.$t('verify_popup.passport'),
                'id_card': this.$t('verify_popup.id_card'),
            },


            isLoading: false,
            country: '',
            document: {
                'first_name': '',
                'second_name': '',
                'third_name': '',
                'type': '',
                'citizenship': '',
                'ser': '',
                'issued_by': '',
                'dt_receipt': null,
                'photo': [],
            },
            photos: []
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/userGetter',
        }),
        countryOptions(){
            return countries.reduce((obj,el,i)=>{obj[i]=this.$t(el.name);return obj},{})
        },
        valid() {
            return !this.fileSizeExceedsLimit&&this.date_birthday&&this.date_receipt && this.document.first_name && this.document.first_name.length < 30 && this.document.second_name && this.document.second_name.length < 30 && this.document.type && this.country && this.document.number && this.document.number.length < 20 && this.document.issued_by &&  this.document.issued_by.length < 200 &&this.photos.length;
        },
        fileSizeExceedsLimit(){
            return this.photos.find(el=>el.size > this.MAX_FILE_SIZE);
        }
    },
    methods: {
        formatDate,
        ...mapActions({
            uploadDocumentFile: 'user/uploadDocumentFile',
            createDocument: 'user/createDocument',
            createVerificationRequest: 'user/createVerificationRequest',
        }),
        onAttachmentChange(e) {
            if (this.isLoading) return;
            if (e.target.files.length > 0) {
                this.photos = this.photos.concat(Array.from(e.target.files));
                this.document.photo = e.target.files;
            }
        },
        imageUrl(file) {
            const url = URL.createObjectURL(file);
            return `url(${url})`;
        },
        deleteFile(name) {
            if (this.isLoading) return;
            this.photos = this.photos.filter(el => el.name !== name);
        },
        submit() {
            if (this.isLoading) return;
            this.isLoading = true;

            this.document.dt_receipt = moment(this.date_receipt).format("X");
            this.document.photo = [].concat(this.photos);

            this.document.birthday = moment(this.date_birthday).format("X");
            this.document.citizenship = countries[this.country]&&countries[this.country].shortcode ?countries[this.country].shortcode:'';
            this.createVerificationRequest(this.document).then(
                () => {
                    this.$store.commit('user/setVerificationStatus', 'pending');
                    this.$store.commit('popups/openPopup', {
                        name: 'DefaultModal',
                        props: {text: this.$t('verify_popup.send_text')}
                    })

                }
            ).catch(e => console.log('verification_error', e))
                .finally(() => {
                    this.isLoading = false;

                });

        },

    },
    mounted(){
        this.document.first_name = this.user.first_name;
        this.document.second_name = this.user.second_name;
        this.document.third_name =  this.user.third_name;
    }


}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;
.text1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 10px;
}

.text2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #7e7e7e;
    margin-bottom: 20px;
}


.fields_wrap {
    gap: 10px;
    flex-wrap: wrap;
}


.field_group {
    width: 100%;
    display: flex;
    gap: 10px;

    .field_wrap {
        width: 50%;

    }
    @include respond(650px){
        flex-wrap:wrap;

    }
}
@include respond(650px){
    .field_wrap{
        width:100% !important;
    }
}

.field_document_issued_by {
    width: 100%;
}


.field_datepicker {
    :deep(.field) {
        color: transparent;
    }

    .dp__main {
        position: absolute;
        background: transparent;
        top: 0;
        left: 0;
        width: 100%;
        cursor: pointer;
    }

    :deep(.dp__input_wrap) {
        padding: 26px 15px 11px 30px;
    }


    :deep(.dp__input) {
        background: none;
        color: #000;
        outline: none;
        text-decoration: none;
    }

    :deep(.dp__input_icon) {
        width: 25px;
        height: 25px;
        display: block;
        right: 15px;
        left: auto;
        padding: 0;
    }
}


.upload_files {
    margin-top: 30px;
    margin-bottom: 30px;
}

.choose_file {
    flex-wrap: wrap;
    gap: 10px;
}

.choose_file_label {
    display: inline-block;
    cursor: pointer;
}

.preview_wrap {
    position: relative;
    width: 75px;
    height: 75px;

    .delete_file {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;

        &:hover {
            path:nth-child(2) {
                fill: #000000;
            }
        }
    }
}

.preview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border: 2px solid #EBEFF1;
    border-radius: 16px;
}


.button_wrap {
    margin-top: 30px;
    display: flex;
    justify-content: right;
}

.successful {
    .base-button {
        margin-top: 30px;
    }
}

</style>
