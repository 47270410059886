<template>
    <div class="wrapper">
        <div class="modal_title">
            {{ $t('edit_phone_popup.title_edit_phone_popup') }}
        </div>

        <div v-if="!successful">
            <p>
                {{ $t('edit_phone_popup.text_1') }}
            </p>
            <p>
                {{ $t('edit_phone_popup.your_current_phone') }}{{phone}}
            </p>
            <p>
                {{ $t('edit_phone_popup.text_warning') }}
            </p>

            <TextField v-model="newPhone"
                       :class_wrap="isPhoneValidClass()"
                       :class_input="'gray'"
                       :label="this.$t('edit_phone_popup.label_new_number')"
                       :readonly="codeSended"
            ></TextField>

            <div class="need_password">
                <p class="modal__text">{{ $t('edit_phone_popup.text_enter_password') }}</p>
                <TextField v-model="password"
                           :type="'password'"
                           :class_wrap="'need_password'"
                           :class_input="'gray'"
                           :label=" $t('common.field_password')"
                           :autocomplete="'off'"
                           :readonly="codeSended"
                ></TextField>
                <p class="form_error">{{ errorPassword }}</p>
            </div>

            <div class="confirm_fields " v-if="confirm">
                <p class="modal__text">{{ $t('edit_phone_popup.text_code') }}</p>
                <TextField v-model="confirmCode"
                           :class_input="'gray'"
                           :label="this.$t('edit_phone_popup.label_code')"
                           :autocomplete="'off'"
                ></TextField>
                <p class="form_error">{{ errorConfirmCode }}</p>
            </div>


            <button class="base-button submit_btn"
                    @click="changeUserData"
                    :disabled="buttonDisable"
            >{{ $t('common.send') }}</button >

            <div class="form_error">{{errorMessage}}</div>

        </div>


        <div class="successful" v-if="successful">
            <p>{{ $t('edit_phone_popup.phone_successfully') }}</p>
            <button class="base-button btn_close" @click="$emit('close')">{{ $t('common.close') }}</button>
        </div>


    </div>
</template>


<script>
    import { useI18n } from "vue-i18n";
    import TextField from '@/components/common/TextField.vue'

    import {mapGetters, mapMutations} from "vuex";

    export default {
        name: "EditPhoneModal",
        components: {
            TextField
        },
        setup(){
            const {t} = useI18n({useScope: 'global'});
            return {t};
        },
        data() {
            return {
                phone: '',
                newPhone: '',
                needPassword: false,
                password: '',
                codeSended: false,
                confirm: false,
                confirmCode: '',
                errorPassword: '',
                errorConfirmCode: '',
                errorMessage: '',
                buttonDisable: true,
                successful: false,
            }
        },
        computed:{
            ...mapGetters({
                user:'user/userGetter',
            })
        },
        created() {
            this.phone = this.user.phone;
        },
        watch: {
            newPhone(){
                this.buttonDisable = !(this.isPhoneValid() && this.password !== '');
            },
            password(){
                this.buttonDisable = !(this.isPhoneValid() && this.password !== '');
            },
            confirmCode(){
                this.buttonDisable = this.confirmCode === '';
                this.errorConfirmCode = '';
            }
        },
        methods: {
            ...mapMutations({
                setUserData: 'user/setUserData',
            }),
            isPhoneValidClass(){
                return this.isPhoneValid() ? 'field_success' : 'field_error';
            },
            isPhoneValid() {
                const PHONE_REGEXP = /^[+]{1}\d{10,13}$/
                return PHONE_REGEXP.test(this.newPhone);
            },
            changeUserData() {
                this.errorPassword = '';
                this.errorMessage = '';
                if (!this.codeSended) {  // step1
                    this.changeInit();
                }else{  // step2
                    this.changeConfirm();
                }
            },
            changeInit(){
                this.buttonDisable = true;
                this.$store.dispatch('user/changePhoneInit', {phone: this.newPhone.replace('+',''), password: this.password}).then(() => {
                    //console.log('data', data);
                    this.confirm = true;
                    this.codeSended = true;
                }).catch(e => {
                    if (e.response && e.response.data) {
                        console.log(e.response.data);
                        if (e.response.data.error === 'wrong password') {
                            // this.errorPassword = "Не верный пароль";
                            this.errorPassword = this.t('common.wrong_password');
                        }else if (e.response.data.errors.phone[0] === 'The phone has already been taken.') {
                            this.errorPassword = this.t('edit_phone_popup.phone_already_taken');
                        }else if (e.response.data.message === 'The given data was invalid.') {
                            this.errorMessage = this.$t('edit_phone_popup.error_data');
                        }
                        this.buttonDisable = false;
                    }
                });
            },
            changeConfirm(){
                this.buttonDisable = true;
                this.$store.dispatch('user/changePhoneConfirm', {code: this.confirmCode}).then(() => {
                    //console.log('data', data);
                    this.successful = true;
                    this.setUserData({name:'phone', value: this.newPhone});
                }).catch(e => {
                    if (e.response && e.response.data) {
                        if (e.response.data.status === 'error') {
                            this.errorConfirmCode = this.$t('edit_phone_popup.error_code');
                        }
                        if (e.response.data.message === 'The given data was invalid.') {
                            this.errorMessage = this.$t('edit_phone_popup.error_data');
                        }
                        this.buttonDisable = false;
                    }
                });
            },


        },
    }
</script>

<style lang="scss" scoped>
    @use "~@/styles/mixins.scss" as *;
    @import "./src/styles/form.scss";

    p{
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
    }

    .need_password{
        margin-top: 20px;
    }
    .submit_btn{
        margin-top: 30px;
        width: 300px;
        float: right;
    }

    .successful {
        margin-top: 10px;
    }
    .btn_close{
        width: 300px;
        margin: 40px auto 0;
        display: block;
    }

</style>
