<template>
    <div class="wrapper">
        <div class="modal_title">
            {{title}}
        </div>
        <div>
            <TextField v-model="text"
                       :class_input="'gray'"
                       :label="label"/>
        </div>
        <div class = "d-flex ac"
             style="margin-top:30px;gap:15px;"
        >
            <button class="base-button submit_btn"
                    @click="confirm"
            >Ок</button >
            <button class="base-button btn_close"
                    @click="$emit('close')">{{ $t('common.close') }}
            </button>
        </div>
    </div>
</template>

<script>
import TextField from "@/components/common/TextField";
export default {
    name: "TextModal",
    components: {TextField},
    props: {
        callback: Function,
        title: String,
        label: String
    },
    data(){
        return {
            text:''
        }
    },
    methods:{
        confirm(){
            this.callback(this.text);
            setTimeout(()=>{
                this.$emit('close');
            },500);
        }
    }
}
</script>

<style scoped>

</style>
