<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.4 7.43193V10.6659C9.4 12.0699 8.464 12.9999 7.066 12.9999H3.334C1.936 12.9999 1 12.0699 1 10.6659V6.00393C1 4.59992 1.936 3.66992 3.334 3.66992H5.632C6.25 3.66992 6.844 3.91592 7.282 4.35392L8.716 5.78193C9.154 6.21993 9.4 6.81393 9.4 7.43193Z" stroke="#BAC5C8" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.0001 4.76801V8.00202C13.0001 9.40002 12.0641 10.336 10.6661 10.336H9.4001V7.43202C9.4001 6.81401 9.1541 6.22001 8.7161 5.78201L7.2821 4.35401C6.8441 3.91601 6.2501 3.67001 5.6321 3.67001H4.6001V3.33401C4.6001 1.936 5.5361 1 6.9341 1H9.2321C9.8501 1 10.4441 1.246 10.8821 1.684L12.3161 3.11801C12.7541 3.55601 13.0001 4.15001 13.0001 4.76801Z" stroke="#BAC5C8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "copyIcon"
}
</script>

<style scoped>

</style>
