<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12.5" r="12" v-bind:style="{fill: color}"/>
        <path d="M6.75 11.2419L11.1085 15.5L17.25 9.5" stroke="white" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: "ItemIcon",
    props: {
        color: {
            type: String,
            default: "#BAC5C8",
        },
    }
}
</script>

<style scoped>

</style>
