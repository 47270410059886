<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7803_1233)">
      <path d="M9 2C10.3845 2 11.7379 2.41054 12.889 3.17971C14.0401 3.94888 14.9373 5.04213 15.4672 6.32121C15.997 7.6003 16.1356 9.00776 15.8655 10.3656C15.5954 11.7235 14.9287 12.9708 13.9497 13.9497C12.9708 14.9287 11.7235 15.5954 10.3656 15.8655C9.00777 16.1356 7.6003 15.997 6.32122 15.4672C5.04213 14.9373 3.94888 14.0401 3.17971 12.889C2.41054 11.7378 2 10.3845 2 9C2 7.14348 2.7375 5.36301 4.05026 4.05025C5.36301 2.7375 7.14349 2 9 2ZM9 0C7.21997 0 5.47992 0.527844 3.99987 1.51678C2.51983 2.50571 1.36627 3.91131 0.685084 5.55585C0.00389528 7.20038 -0.174329 9.00998 0.172938 10.7558C0.520205 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.387 0 9 0Z" fill="#BAC5C8"/>
      <path d="M8.84085 11.7598C8.60154 11.7598 8.3676 11.8307 8.16862 11.9637C7.96964 12.0966 7.81455 12.2856 7.72297 12.5067C7.63138 12.7278 7.60742 12.9711 7.65411 13.2058C7.7008 13.4405 7.81603 13.6561 7.98526 13.8254C8.15448 13.9946 8.37008 14.1098 8.6048 14.1565C8.83952 14.2032 9.08281 14.1792 9.30391 14.0877C9.525 13.9961 9.71398 13.841 9.84694 13.642C9.97989 13.443 10.0509 13.2091 10.0509 12.9698C10.0509 12.6489 9.92338 12.3411 9.69646 12.1142C9.46954 11.8872 9.16177 11.7598 8.84085 11.7598Z" fill="#BAC5C8"/>
      <path d="M6.57031 7.99992V6.99992C6.57031 6.51458 6.76312 6.04911 7.10631 5.70592C7.4495 5.36273 7.91497 5.16992 8.40031 5.16992H9.86031C10.2767 5.16992 10.676 5.33534 10.9705 5.62977C11.2649 5.9242 11.4303 6.32353 11.4303 6.73992V6.73992C11.433 6.97997 11.3793 7.2173 11.2733 7.43272C11.1673 7.64813 11.0121 7.83559 10.8203 7.97993L10.0603 8.56992C9.68026 8.86645 9.37284 9.24574 9.16144 9.67896C8.95003 10.1122 8.8402 10.5879 8.84031 11.0699V11.0699" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_7803_1233">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>


</template>

<script>
export default {
name: "QuestionMark"
}
</script>

<style scoped>

</style>