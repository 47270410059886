<template>
    <div class="wrapper">
        <div class="modal_title">
            {{ $t('withdraw_from_partner_popup.title_withdraw_from_partner_popup') }}
        </div>
        <div class="withdraw__partner-title">
            {{ $t('withdraw_from_partner_popup.partner_account') }}
        </div>
        <div class="withdraw__partner-max">
            {{ partnerBalance }} $
        </div>
        <div class="withdraw__partner-wallet-title">
            {{ $t('withdraw_from_partner_popup.your_wallet') }}
        </div>
        <div class="withdraw__partner-wallet">
            {{ userWallet }} <EditIcon @click="changeWallet" />
        </div>
          <div class="withdraw__partner-amount">
            <input type="number" min="0" :placeholder="$t('withdraw_from_partner_popup.enter_sum_output')" :max="partnerBalance" step="1" class v-model="amount"/>
            <div v-if="errorAmount" class="red-alert error-amount">{{errorAmount}}</div>
        </div>
        <div class="withdraw__partner-send">
            <button :disabled="isLoading || !sumCorrect"  class="base-button" @click="withdrawConfirm">{{ $t('withdraw_from_partner_popup.output_btn') }}</button>
        </div>
        <div class="withdraw__partner-decline">
            <div class="cancel" @click="close">{{ $t('common.cancel') }}</div>

        </div>

    </div>
</template>

<script>

import {mapGetters} from "vuex";
import EditIcon from "@/assets/icons/EditIcon";

export default {
    name: "WithdrawFromPartnerAccount",
    components: {EditIcon},
    data() {
        return {
            amount: '',
            errorAmount: '',
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters(
            {
                partnerBalance: 'user/partnerBalanceGetter',
                userWallet: 'user/walletGetter',
                allowWithdrawal: 'balance/allowedWithdrawalByRule200'

            }
        ),
        sumCorrect(){
            return (this.amount > 0);
        },
    },
    methods: {
        changeWallet() {
            this.$store.commit('popups/openPopup', {
                name: 'EditWalletModal',
                props: {source: 'WithdrawFromPartnerAccount'}
            })

        },

        withdrawConfirm() {
            this.errorAmount = '';

            if (this.isLoading) return;

            if (this.amount <= 0){
                this.errorAmount = this.$t('withdraw_from_partner_popup.enter_sum');
                return;
            }
            if (this.amount < 50){
                this.errorAmount = this.$t('withdraw_from_partner_popup.min_sum');
                return;
            }
            if (this.partnerBalance < this.amount){
                this.errorAmount = this.$t('withdraw_from_partner_popup.insufficient_funds')
                return;
            }
            this.isLoading = true;
            this.$store.dispatch('user/fetchWithdraw', {wallet: this.userWallet, amount: this.amount}).then((data) => {
                this.$store.commit('user/setPartnerBalance', this.partnerBalance - this.amount)
                this.$store.dispatch('balance/getTransactions');
                this.$store.commit('popups/openPopup', {
                    name: 'DefaultModal',
                    props: {text: this.$t('withdraw_from_partner_popup.withdraw_text', {id: data.id, amount: data.amount, wallet: data.wallet  })}
                })

            }).catch((error)=>{
                this.errorMessage  =  error.response.data.error;
                }

            ).finally(()=>{
                this.isLoading = false;
                }

            )
        },


        close() {
            this.$emit('close');
        }


    },
    mounted() {
        if (!this.allowWithdrawal) {
            this.$store.commit('popups/openPopup', {
                name: 'DefaultModal',
                props: {text: this.$t('transferModals.min200')}
            })
        } else {
            if (!this.userWallet) {
                this.changeWallet()
            }
        }
    }
}


</script>

<style lang="scss" scoped>
.withdraw {
    &__partner {
        &-title {
            font-size: 13px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 8px;
        }

        &-max {
            font-size: 40px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
        }

        &-wallet {
            text-align: center;
            font-weight:700;
            font-size:20px;
            margin-bottom:20px;
            overflow:hidden;

            &-title{
                font-weight:500;
                margin-bottom:10px;
                text-align:center;
            }
        }

        &-amount {
            margin-bottom: 30px;
            position:relative;

            input {
                width: 100%;
                position: relative;
                background: #EBEFF1;
                border-radius: 16px;
                border: none;
                outline: none;
                padding: 18px 30px 18px;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
            }
            .error-amount{
                position:absolute;
                top:100%;
                left:0;
                max-width:100%;
            }

        }

        &-change {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;

            .base-button {
                width: 100%;
            }

        }
        &-send{
            width:100%;
            margin-bottom:30px;
            .base-button{
                width:100%;
            }
        }
        &-decline{
            display:flex;
            justify-content:center;
            align-items:center;
            color:#BAC5C8;
            text-decoration:underline;
            cursor:pointer;

        }
    }
}
</style>
