<template>
    <div class="wrapper">
        <div class="modal_title">
            Смена рефовода
        </div>
        <div style="margin-bottom:10px">Пользователь: {{name}}</div>
        <div>
            <TextField v-model="phone"
                       @change="errorMessage=''"
                       :class_input="'gray'"
                       label="Телефон нового рефовода"/>
        </div>
        <div class="form_error" style="margin-top:10px" v-if="errorMessage">{{errorMessage}}</div>
        <div class = "d-flex ac"
             style="margin-top:30px;gap:15px;"
        >
            <button class="base-button submit_btn"
                    @click="changeReferrer"
                    :disabled="!phone"
            >Ок</button >
            <button class="base-button btn_close" @click="$emit('close')">{{ $t('common.close') }}</button>
        </div>
    </div>
</template>

<script>
import TextField from "@/components/common/TextField";
export default {
    name: "ChangeReferrerModal",
    components: {TextField},
    props:{
        name:String,
        id:Number
    },
    data(){
        return {
            phone:'',
            errorMessage:''
        }
    },
    methods:{
        changeReferrer(){
            const qs = {
                id:this.id,
                phone:this.phone.replace(/\D/g,'')
            };
            this.$store.dispatch('admin/changeReferrer',qs)
            .then(()=>{this.$emit('close')})
            .catch(e=> {
                console.log(e);
                this.errorMessage = e.response.data.message;
            });
        }
    }
}
</script>

<style scoped>

</style>
