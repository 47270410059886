<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.7992 12.0002C22.7992 6.03552 17.9639 1.2002 11.9992 1.2002C6.03454 1.2002 1.19922 6.03552 1.19922 12.0002C1.19922 17.9649 6.03454 22.8002 11.9992 22.8002C17.9639 22.8002 22.7992 17.9649 22.7992 12.0002Z" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M8.41195 11.6649L10.98 9.08495L8.77193 6.87695L7.21195 8.48495C5.79595 9.91295 14.1359 18.2649 15.6119 16.8849L17.232 15.2649L15.0119 13.0569L12.3479 15.6249" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "PhoneIcon"
}
</script>

<style scoped>

</style>