<template>
    <div class="sidebar-profile d-flex gap-2.5">
        <div class="sidebar-profile-items d-flex">
            <div class="flex flex-wrap gap-2.5">
            <div class="sidebar-profile-item">
                <div class="sidebar-profile-icon d-flex jc ac">
                    <ProfileIcon/>
                </div>
                <div class = "sidebar-profile-name-group">
                    <router-link :to = "{name:'profile'}" class="sidebar-profile-name d-flex ac">
                        <div>{{ user.first_name }}</div>
                        <span>|</span>
                      <div class="sidebar-profile-name-balance">{{ user.balance }} $</div>
                    </router-link>
                </div>
            </div>
            <LanguageSwitcher/>
            </div>
            <notificationsPanel/>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import ProfileIcon from "@/assets/icons/ProfileIcon";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";
import NotificationsPanel from "@/components/common/NotificationsPanel.vue";
//import ArrowRight from "@/assets/icons/ArrowRight";

export default {
    name: "MainProfile",
    components: {
        NotificationsPanel,
//ArrowRight,
        LanguageSwitcher, ProfileIcon},
    data() {
        return {}
    },
    computed: {
        ...mapState({
            user: state => state.user.data,
        }),
    },
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

.sidebar {
    &-profile {
        padding: 74px 0 32px 70px;
        @include respond($desktop) {
            padding-left: 10px;
        }
        @include respond($laptop){
            padding:25px;
            position:fixed;
            right:0;top:0;
            background: #2E3338;
            width:100%;
            justify-content: flex-end;
            gap:30px;
            align-items:center;
        }
        @include respond(450px){
            gap:20px;
        }
        &-items{
            display: flex;
            //gap: 40px;
            align-items: center;
            justify-content:space-between;
            width:100%;
            &::v-deep(.block-switch){
                min-width: auto;
            }
            &::v-deep(.switch-content){
              position: static;
                height: 40px;
                display: flex;
                align-items: center;
                @include respond($laptop){
                    width: 65px;
                }
            }
            @include respond($laptop){
                justify-content: flex-end;
                margin-right: 70px;
                gap: 18px;
            }
        }
        &-item{
            display: flex;
            align-items: center;
            padding: 7px 12px 7px 8px;
            background: #454A50;
            border-radius: 16px;
            height: 40px;
            @include respond(600px){
                display: none;
            }
        }
        &-icon {
            margin-right: 4px;
            @include respond(450px){
                display:none;
            }
        }
        &-icon,&-logout{
            font-size: 18px;
            height: 1.25em;
        }
        &-logout{
            cursor:pointer;
            left: 233px;
            &:hover{
                svg{
                    stroke:#7ECAE0;
                }
            }
        }
        &-name {
            display: flex;

            gap: 9px;
            &-balance{
                justify-content: center;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-group {
                font-weight: 500;
                font-size: 13px;
                color: rgba(255, 255, 255, 0.5);
                &>*+div{
                    margin-top:5px;
                    @include respond($laptop){
                        margin:0 0 0 15px;
                    }
                    @include respond(450px){
                        margin-left:10px;
                    }
                }
                @include respond($laptop){
                    display:flex;
                }
                @include respond(450px){
                    font-size: 12px;
                }
            }
            &>div:first-child{
             width:min-content;
            }
            font-size: 11px;
            line-height: 140%;
            @include respond($mobile){
                font-size:15px;
            }
            line-height: 1.25;
            text-decoration: none;
            color: #fff;
            svg{
                margin-left:10px;
            }
            &:hover{
                color:#7ECAE0;
                svg{
                    stroke:#7ECAE0;
                }
            }
        }
    }
}
</style>
