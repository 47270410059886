Модалка с пошаговой формой. Не используется. Оставить пока для примера.





<template>
    <div class="wrapper">
        <div class="modal_title">Верификация</div>

        <div class="steps d-flex jb">
            <div class="active" @click="setStep(0)">
                <VerPhone/>
            </div>
            <div @click="setStep(1)"
                 :class="{'active': currentStep === 'personal' || currentStep === 'passport' || currentStep === 'address' }">
                <VerPersonal/>
            </div>
            <div @click="setStep(2)"
                 :class="{'active':  currentStep === 'passport' || currentStep === 'address' }">
                <VerPasport/>
            </div>
            <div @click="setStep(3)"
                 :class="{'active': currentStep === 'address' }">
                <VerAddress/>
            </div>
        </div>


        <div class="body">

            <section class="step_item" v-show="currentStep === 'phone'">
                <div class="step_title">Номер телефона</div>
                <div class="text1">
                    Необходимо верифицировать номер вашего телефона
                </div>
                <div class="text2">
                    Подтвердите свой номер телефона по SMS или по звонку. SMS придёт в течение минуты.
                    Если по какой-то причине сообщение вам не приходит, попробуйте второй способ (по звонку).
                    Если и в этом случае вы не можете подтвердить номер телефона, напишите в нашу службу
                    технической поддержки (онлайн-чат в правом нижнем углу любой страницы личного кабинета).
                </div>
                <TextField v-model="phone"
                           :class_input="'gray'"
                           :label="'Номер телефона'"
                           :readonly="user.phone_verified_at !== null"
                ></TextField>
                <a href="#" v-if="user.phone_verified_at !== null">Изменить номер</a>
            </section>




            <section class="step_item" v-show="currentStep === 'personal'">
                <div class="step_title">Персональные данные</div>
                <div class="text1">
                    Данные должны совпадать с теми, что указаны в вашем удостоверении личности
                </div>
                <div class="text2">
                    Введите свои основные персональные данные: имя, пол и дату рождения.
                    Также укажите свою фамилию и отчество, если они у вас есть.
                    Если у вас только имя и нет фамилии, то включите переключатель «Нет фамилии».
                    При отсутствии отчества соответствующая графа исчезает автоматически.
                </div>

                <div class="fields_wrap d-flex">
                    <div class="field_group">
                        <TextField v-model="first_name"
                                   :class_input="'gray'"
                                   :label="'Имя'"
                        ></TextField>
                        <TextField v-model="first_name_lat"
                                   :class_input="'gray'"
                                   :label="'Имя латиницей'"
                        ></TextField>
                    </div>
                    <div class="field_group">
                        <TextField v-model="second_name"
                                   :class_input="'gray'"
                                   :label="'Фамилия'"
                        ></TextField>
                        <TextField v-model="second_name_lat"
                                   :class_input="'gray'"
                                   :label="'Фамилия латиницей'"
                        ></TextField>
                    </div>

                    <div class="field_group">
                        <TextField v-model="third_name"
                                   :class_input="'gray'"
                                   :label="'Отчество'"
                        ></TextField>
                    </div>

                    <TextField v-model="mother_country"
                               :class_wrap="'field_mother_country'"
                               :class_input="'gray'"
                               :label="'Страна рождения'"
                               :type="'select'"
                               :selectOptions="countyOptions"
                    />

                    <div class="field_group" >
                        <TextField v-model="birthday"
                                   :class_wrap="'field_birthday field_datepicker'"
                                   :class_input="'gray'"
                                   :label="'Дата рождения'"
                        >
                            <Datepicker v-model="date_birthday"
                                        :flow="flow"
                                        position="left"
                                        :transitions="false"
                                        format="dd.MM.yyyy"
                                        :enableTimePicker="false"
                                        monthNameFormat="long"
                                        locale="ru"
                                        cancelText="Отмена"
                                        selectText="Выбрать"
                                        @update:modelValue="birthday = formatDate(date_birthday)"
                            >
                            </Datepicker>
                        </TextField>
                        <TextField v-model="gender"
                                   :class_wrap="'field_gender'"
                                   :class_input="'gray'"
                                   :label="'Пол'"
                                   :type="'select'"
                                   :selectOptions="genderOptions"
                        />
                    </div>
                </div>

            </section>




            <section class="step_item" v-show="currentStep === 'passport'">
                <div class="step_title">Удостоверение личности</div>
                <div class="text1">
                    Данные должны совпадать с теми, что указаны в вашем удостоверении личности
                </div>
                <div class="text2">
                    Для подтверждения личности вам необходимо загрузить один из перечисленных документов:
                    паспорт, ID-карта, другой документ, подтверждающий вашу личность
                    (официально разрешённый на территории государства, гражданином которого вы являетесь).
                    Водительское удостоверение не может быть принято как основной документ, подтверждающий личность.
                </div>

                <div class="fields_wrap d-flex">

                    <div class="field_group">
                        <TextField v-model="citizenship"
                                   :class_wrap="'field_citizenship'"
                                   :class_input="'gray'"
                                   :label="'Гражданство'"
                                   :type="'select'"
                                   :selectOptions="countyOptions"
                        />
                        <TextField v-model="document_type"
                                   :class_wrap="'field_document_type'"
                                   :class_input="'gray'"
                                   :label="'Тип документа'"
                                   :type="'select'"
                                   :selectOptions="documentTypeOptions"
                        />
                    </div>

                    <div class="field_group">
                        <TextField v-model="document_date"
                                   :class_wrap="'field_document_date field_datepicker'"
                                   :class_input="'gray'"
                                   :label="'Дата выдачи'"
                        >
                            <Datepicker v-model="date_document_date"
                                        :flow="flow"
                                        position="left"
                                        :transitions="false"
                                        format="dd.MM.yyyy"
                                        :enableTimePicker="false"
                                        monthNameFormat="long"
                                        locale="ru"
                                        cancelText="Отмена"
                                        selectText="Выбрать"
                                        @update:modelValue="document_date = formatDate(date_document_date)"
                            >
                            </Datepicker>
                        </TextField>
                        <TextField v-model="document_number"
                                   :class_input="'gray'"
                                   :label="'Серия и номер документа'"
                        ></TextField>
                    </div>

                    <TextField v-model="document_issued_by"
                               :class_wrap="'field_document_issued_by'"
                               :class_input="'gray'"
                               :label="'Кем выдан'"
                    ></TextField>





                </div>


                <div class="fields_wrap d-flex">
                    <div class="text1">
                        Загрузка документов
                    </div>
                    <div class="text2">
                        Загрузите изображение разворота паспорта или другого основного документа,
                        подтверждающего личность, который вы указали выше.
                        Внесённые в форму данные должны совпадать с данными в документе.
                    </div>
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                    <div class="upload_files">
                        <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="75" height="75" rx="16" fill="#EBEFF1"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M36 38.3171V48.1337H38V38.3171L47.8165 38.3171V36.3171L38 36.3171V26.5007H36V36.3171L26.1836 36.3171V38.3171L36 38.3171Z" fill="#BAC5C8"/>
                        </svg>
                    </div>
                    document_photo
                </div>



                <form @submit.prevent="submit">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Name" v-model="name">
                    </div>
                    <div class="form-group">
                        <div class="custom-file">
                            <input type="file"
                                   class="custom-file-input"
                                   id="customFile"
                                   @change="onAttachmentChange"
                            >
                            <label class="custom-file-label" for="customFile">Choose file</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>
                </form>

            </section>




            <section class="step_item" v-show="currentStep === 'address'">
                <div class="step_title">Адрес регистрации</div>


            </section>






            <section class="step_item" v-show="currentStep === 'confirm'">
                <div class="step_title">Подтверждение информации</div>
                <div class="text1">
                    Перед отправкой внимательно проверьте все данные, которые вы указали.
                </div>
                <div class="text2">

                </div>
                <TextField v-model="name"
                           :class_input="'gray'"
                           :label="'Имя'"
                ></TextField>

            </section>


        </div>


        <div class="step-nav">
            <div class="button_back" v-if="currentStep !== 'phone'" @click="setStep('prev')">
                <StepBackIcon></StepBackIcon>
            </div>

            <button type="submit" class="base-button" v-if="currentStep !== 'confirm'"
                     v-text="'Далее'" @click="setStep('next')"/>

            <button type="submit" class="base-button" v-if="currentStep === 'confirm' "
                     v-text="'Отправить'" :disabled="!valid"/>
        </div>


    </div>
</template>

<script>
import VerPhone from "@/assets/icons/VerPhone.vue";
import VerPersonal from "@/assets/icons/VerPersonal.vue";
import VerPasport from "@/assets/icons/VerPasport.vue";
import VerAddress from "@/assets/icons/VerAddress.vue";
import TextField from "@/components/common/TextField.vue";
import StepBackIcon from '@/assets/icons/StepBackIcon.vue';
import {mapGetters} from "vuex";
import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'
import {ref} from 'vue';
import axios from "axios";

export default {
    name: "Verification__Modal",
    components: {
        VerAddress, VerPasport, VerPersonal, VerPhone,
        StepBackIcon,
        TextField,
        Datepicker
    },
    setup() {
        const date_birthday = ref();
        const date_document_date = ref();
        const flow = ref(['year', 'month', 'calendar']);

        const dp = ref();

        const selectDate = () => {
            //console.log('selectDate()');
            ref.value.selectDate();
        }
        return {
            date_birthday,
            date_document_date,
            flow,
            dp,
            selectDate,
        }
    },
    data(){
        return{
            steps: [
                "phone",
                "personal",
                "passport",
                "address",
                "confirm",
            ],
            currentStep: 'passport',

            countyOptions: {
                'ru' : 'Россия',
                'kz' : 'Казахстан',
                'by' : 'Беларусь',
            },
            genderOptions: {
                'male' : 'Мужской',
                'female' : 'Женский'
            },
            documentTypeOptions: {
                "passport" : "Паспорт",
                "id_card" : "ID карта",
            },

            valid: false,


            phone: '',
            first_name: '',
            first_name_lat: '',
            second_name: '',
            second_name_lat: '',
            third_name: '',
            third_name_lat: '',

            mother_country: 'ru',
            birthday: '',
            gender: 'male',

            citizenship: '',
            document_type: '',
            document_date: '',
            document_number: '',
            document_issued_by: '',
            document_photo: '',



            name: null,
            document: null,

        }
    },
    computed:{
        ...mapGetters({
            user:'user/userGetter',
        })
    },
    created() {
        this.phone = this.user.phone;
        this.first_name = this.user.first_name;
    },
    methods: {
        submit () {
            const config = {'content-type': 'multipart/form-data'}
            const formData = new FormData()
            formData.append('name', this.name)
            formData.append('document', this.document)

            axios.post('/api/v1/user/upload_document_file', formData, config)
                .then(response => console.log(response.data.message))
                .catch(error => console.log(error))
        },
        onAttachmentChange (e) {
            this.document = e.target.files[0]
        },


        /*
        updateDate(date){
            console.log('updateDate()', date);
            let d = new Date(date);
            this.birthday = [
                d.getDate().toString().padStart(2, '0'),
                (d.getMonth() + 1).toString().padStart(2, '0'),
                d.getFullYear(),
            ].join('.')
        },
        */
        formatDate(date){
            //console.log('formatDate()', date);
            let d = new Date(date);
            return [
                d.getDate().toString().padStart(2, '0'),
                (d.getMonth() + 1).toString().padStart(2, '0'),
                d.getFullYear(),
            ].join('.')
        },
        setStep(delta){
            if (delta === 'next')
                this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1];
            else if (delta === 'prev')
                this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1];
            else
                this.currentStep = this.steps[delta];
        }
    }
}
</script>

<style lang="scss" scoped>

.steps {
    align-items: center;
    position: relative;
    margin-bottom: 25px;
    &:before {
        position: absolute;
        content: "";
        height: 2px;
        background: #EBEFF1;
        width: calc(100% - 20px);
        top: 20px;
        left: 0;
        z-index: 0;
    }
    div {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background: #EBEFF1;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        position: relative;
    }
    .active{
        background: #3E76B5;
        :deep(svg path) {
            stroke: #ffffff !important;
        }
    }
}


.steps .active svg path {
    stroke: #ffffff !important;
}

.step_title{
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.44px;
    margin-bottom: 35px;
}
.text1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 10px;
}
.text2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #BAC5C8;
    margin-bottom: 20px;
}



.fields_wrap{
    gap: 10px;
    flex-wrap: wrap;
}
.field_group {
    width: 100%;
    display: flex;
    gap: 10px;
    .field_wrap {
        width: 50%;
    }
}


.field_mother_country {
    width: 100%;
}


.field_datepicker {
    :deep(.field) {
        color: transparent;
    }

    .dp__main {
        position: absolute;
        background: transparent;
        top: 0;
        left: 0;
        width: 100%;
        cursor: pointer;
    }

    :deep(.dp__input_wrap) {
        padding: 26px 15px 11px 30px;
    }


    :deep(.dp__input) {
        background: none;
        color: #000;
        outline: none;
        text-decoration: none;
    }

    :deep(.dp__input_icon) {
        width: 25px;
        height: 25px;
        display: block;
        right: 15px;
        left: auto;
        padding: 0;
    }
}




.button_back{
    cursor: pointer;
}

</style>
