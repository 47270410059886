<template>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9293_27796)">
            <path d="M26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26C20.1797 26 26 20.1797 26 13Z" fill="#3E76B5"/>
            <path d="M17.4498 25.1998C14.5789 26.2661 11.4206 26.2661 8.54977 25.1998V19.4498L4.18977 22.5398C2.84998 21.3083 1.78365 19.8093 1.05977 18.1398L2.05977 17.4498H0.79977C-0.26659 14.5789 -0.26659 11.4206 0.79977 8.54977H2.26977L1.11977 7.73977C1.85639 6.07582 2.93671 4.58655 4.28977 3.36977L8.54977 6.36977V0.79977C11.4206 -0.26659 14.5789 -0.26659 17.4498 0.79977V6.79977L21.9298 3.60977C23.2468 4.8441 24.2923 6.33918 24.9998 7.99977L24.2398 8.52977H25.1898C26.2561 11.4006 26.2561 14.5589 25.1898 17.4298H24.2798L24.9998 17.9998C24.3105 19.6587 23.2897 21.1594 21.9998 22.4098L17.4498 19.1998V25.1998Z" fill="#EBEFF1"/>
            <path d="M6.8101 17.4501H8.5501V18.6601L3.7001 22.0701C3.23242 21.5895 2.80123 21.0748 2.4101 20.5301L6.8101 17.4501ZM23.7101 5.6401C23.3289 5.09307 22.9077 4.57499 22.4501 4.0901L17.4501 7.5901V8.5901H19.5601L23.7101 5.6401ZM1.3801 7.2001L3.3201 8.5501H6.8001L2.4101 5.4801C2.01884 6.02334 1.67427 6.59872 1.3801 7.2001ZM15.7501 15.7501H25.7501C26.1502 13.9386 26.1502 12.0616 25.7501 10.2501H15.7501V0.3001C13.9386 -0.100033 12.0616 -0.100033 10.2501 0.3001V10.3001H0.3001C-0.100033 12.1116 -0.100033 13.9886 0.3001 15.8001H10.3001V25.8001C12.1116 26.2002 13.9886 26.2002 15.8001 25.8001L15.7501 15.7501ZM23.6401 20.4601C24.0134 19.9075 24.3476 19.3294 24.6401 18.7301L22.8101 17.4501H19.3401L23.6401 20.4601Z" fill="#FF3D00"/>
        </g>
        <defs>
            <clipPath id="clip0_9293_27796">
                <rect width="26" height="26" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
  name: "EventsIcon"
}
</script>

<style scoped>

</style>
