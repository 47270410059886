<template>
    <div class="wrapper">
        <div class="modal_title">Персональные данные</div>


        <div v-if="!successful">
            <div class="attention" v-if="attention">
                <div class="text1">
                    <b>Внимание!</b> <br>
                    После изменения этих данных, аккаунт будет необходимо снова верифицировать. <br>
                    Не изменяйте эти данные без необходимости!
                </div>
                <button style="margin-top: 30px;" class="base-button" @click="attention=false">Продолжить</button>
            </div>

            <div class="text1">
                Данные должны совпадать с теми, что указаны в вашем удостоверении личности
            </div>
            <div class="text2">
                Введите свои основные персональные данные: имя, пол и дату рождения.
                Также укажите свою фамилию и отчество, если они у вас есть.
                Если у вас только имя и нет фамилии, то включите переключатель «Нет фамилии».
                При отсутствии отчества соответствующая графа исчезает автоматически.
            </div>

            <div class="fields_wrap d-flex">
                <div class="field_group">
                    <TextField v-model="user_.first_name"
                               :class_input="'gray'"
                               :label="'Имя'"
                    ></TextField>
<!--                    <TextField v-model="user_.first_name_lat"
                               :class_input="'gray'"
                               :label="'Имя латиницей'"
                    ></TextField>-->
                </div>
                <div class="field_group">
                    <TextField v-model="user_.second_name"
                               :class_input="'gray'"
                               :label="'Фамилия'"
                    ></TextField>
<!--                    <TextField v-model="user_.second_name_lat"
                               :class_input="'gray'"
                               :label="'Фамилия латиницей'"
                    ></TextField>-->
                </div>

                <div class="field_no_third_name">
                    <input id="no_third_name" type="checkbox" v-model="no_third_name">
                    <label for="no_third_name" >Нет отчества</label>
                </div>
                <div class="field_group" v-if="!no_third_name">
                    <TextField v-model="user_.third_name"
                               :class_input="'gray'"
                               :label="'Отчество'"
                    ></TextField>
<!--                    <TextField v-model="user_.third_name_lat"
                               :class_input="'gray'"
                               :label="'Отчество латиницей'"
                    ></TextField>-->

                </div>

<!--                <TextField v-model="user_.mother_country"
                           :class_wrap="'field_mother_country'"
                           :class_input="'gray'"
                           :label="'Страна рождения'"
                           :type="'select'"
                           :selectOptions="countyOptions"
                />-->

                <div class="field_group" >
                    <TextField v-model="date_birthday"
                               :class_wrap="'field_birthday field_datepicker'"
                               :class_input="'gray'"
                               :label="'Дата рождения'"
                    >
                        <Datepicker v-model="date_birthday"
                                    :flow="flow"
                                    position="left"
                                    :transitions="false"
                                    format="dd.MM.yyyy"
                                    :enableTimePicker="false"
                                    monthNameFormat="long"
                                    locale="ru"
                                    cancelText="Отмена"
                                    selectText="Выбрать"
                                    @update:modelValue="user_.birthday = formatDate(date_birthday)"
                        >
                        </Datepicker>
                    </TextField>
<!--                    <TextField v-model="user_.gender"
                               :class_wrap="'field_gender'"
                               :class_input="'gray'"
                               :label="'Пол'"
                               :type="'select'"
                               :selectOptions="genderOptions"
                    />-->
                </div>
            </div>


            <div class="button_wrap">
                <button class="base-button" :disabled="!valid" @click="submit">Сохранить</button>
            </div>

        </div>

        <div class="successful" v-if="successful">
            <p>Данные изменены. Необходима верификация</p>
            <button class="base-button btn_close" @click="$emit('close')">Закрыть</button>
        </div>


    </div>
</template>


<script>
import TextField from "@/components/common/TextField.vue";
import {mapMutations, mapGetters} from "vuex";
import Datepicker from '@vuepic/vue-datepicker';
import { useI18n } from "vue-i18n";

import {ref} from 'vue';

export default {
    name: "PersonModal",
    components: {
        TextField,
        Datepicker
    },
    setup() {
        const {t} = useI18n({useScope: 'global'});

        const date_birthday = ref();
        const flow = ref(['year', 'month', 'calendar']);
        const dp = ref();
        const selectDate = () => {
            ref.value.selectDate();
        }

        return {
            t,
            date_birthday,
            flow,
            dp,
            selectDate,
        }
    },
    data(){
        return{
            countyOptions: {
                'ru' : 'Россия',
                'kz' : 'Казахстан',
                'by' : 'Беларусь',
            },
            genderOptions: {
                'male' : 'Мужской',
                'female' : 'Женский'
            },
            documentTypeOptions: {
                "passport" : "Паспорт",
                "id_card" : "ID карта",
            },

            attention: false,

            valid: true,
            successful: false,

            no_third_name: false,

            user_ : {
                first_name: '',
                first_name_lat: '',
                second_name: '',
                second_name_lat: '',
                third_name: '',
                third_name_lat: '',
                mother_country: '',
                birthday: '',
                gender: '',
            }

        }
    },
    computed:{
        ...mapGetters({
            user:'user/userGetter',
        })
    },
    created() {
        this.user_.first_name = this.user.first_name;
        this.user_.first_name_lat = this.user.first_name_lat;
        this.user_.second_name = this.user.second_name;
        this.user_.second_name_lat = this.user.second_name_lat;
        this.user_.third_name = this.user.third_name;
        this.user_.third_name_lat = this.user.third_name_lat;
        this.user_.mother_country = this.user.mother_country;
        this.user_.birthday = this.user.birthday;
        this.user_.gender = this.user.gender;

        this.date_birthday = this.user.birthday;

        if (this.user.third_name === '-'){
            this.no_third_name = true;
        }

        if (this.user.documents_verified_at !== null){
            this.attention = true;
        }
    },
    methods: {
        ...mapMutations({
            setUser: 'user/setUser',
        }),

        formatDate(date){
            //console.log('formatDate()', date);
            let d = new Date(date);
            return [
                d.getFullYear(),
                (d.getMonth() + 1).toString().padStart(2, '0'),
                d.getDate().toString().padStart(2, '0'),
            ].join('-');
        },
        submit(){
            //console.log('submit()')
            //console.log(this.user_)
            if (this.no_third_name){
                this.user_.third_name = '-';
                this.user_.third_name_lat = '-';
            }
            this.$store.dispatch('user/edit', this.user_)
                .then((data) => {
                    //console.log('data', data);
                    //console.log('user edited')
                    this.setUser(data.data);
                    this.successful = true;

                    ///  Уведомление о необходимости повторной верификации

                })
                .catch(e => {
                    console.log(e);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.text1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 10px;
}
.text2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #7e7e7e;
    margin-bottom: 20px;
}



.fields_wrap{
    gap: 10px;
    flex-wrap: wrap;
}
.field_group {
    width: 100%;
    display: flex;
    gap: 10px;
    .field_wrap {
        width: 50%;
    }
}

.field_no_third_name{
    display: flex;
    align-items: center;
    label{
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
    }
}

.field_mother_country {
    width: 100%;
}


.field_datepicker {
    :deep(.field) {
        color: transparent;
    }
    .dp__main {
        position: absolute;
        background: transparent;
        top: 0;
        left: 0;
        width: 100%;
        cursor: pointer;
    }
    :deep(.dp__input_wrap) {
        padding: 26px 15px 11px 30px;
    }
    :deep(.dp__input) {
        background: none;
        color: #000;
        outline: none;
        text-decoration: none;
    }
    :deep(.dp__input_icon) {
        width: 25px;
        height: 25px;
        display: block;
        right: 15px;
        left: auto;
        padding: 0;
    }
}


.button_wrap{
    margin-top: 30px;
    display: flex;
    justify-content: right;
}

.successful{
    .base-button{
        margin-top: 30px;
    }
}



.attention {
    position: absolute;
    background: #fff;
    border: 1px solid #eee;
    top: 86px;
    bottom: 0;
    z-index: 3;
    left: 0;
    right: 0;
    padding: 30px;
    height: 100%;
}

</style>
