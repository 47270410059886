export const separateThousands = x=>x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
export const formatDate = (date,format='full')=>{
    let d = new Date(date,);
    // return ("0"+d.getDate()).slice(-2)  + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + d.getFullYear() + " " +
    //     ("0"+d.getHours()).slice(-2) + ":" + ("0"+d.getMinutes()).slice(-2);
    const first_part = [
        d.getDate().toString().padStart(2, '0'),
        (d.getMonth() + 1).toString().padStart(2, '0'),
        d.getFullYear(),
    ].join('.');
    const second_part = d.getHours().toString().padStart(2, '0') + ":" + d.getMinutes().toString().padStart(2, '0');
    if (format==='full')
        return first_part+' '+second_part;
    if (format==='date')
        return first_part;
    if (format==='time')
        return second_part;
}
