<template>
  <div class="transactions-history">
    <div class="transactions-history__title">
      {{ title }}
    </div>
    <div class="transactions-history__table">
      <div class="transactions-history__row header d-flex">
        <div class="transactions-history__col" v-for="(header,i) in headers" :key="'h'+i">
          {{ header }}
        </div>
      </div>
      <div v-if = "!rows.length" class = "transactions-history__table-empty d-flex jc ac tc">
        Записей не найдено
      </div>
      <div class="transactions-history__row d-flex" v-for="(row,i) in rows" :key=i>
        <div class="transactions-history__col" v-for="(col,j) in row" :key='i*10000+j'>
          {{ col }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionsHistoryModal",
  props: {
    title: String,
    headers: Array,
    rows: Array
  }
}
</script>

<style scoped lang="scss">
.transactions-history {
  &__title {
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    padding: 5px 15px;
    transform: translateY(-15px);
    background: #EBEFF1;
    color: rgba(46, 51, 56, 0.5);
    border-radius: 15px;
  }
&__table{
  margin:30px 0;
  max-height: 400px;
  overflow: auto;
  &-empty{
    min-height: 100px;
    font-size: 16px;
  }
}
  &__row {
    border-bottom: 1px solid #e4e3ed;
    padding: 15px 0;
    font-weight: 500;
    font-size: 13px;
    color: #232528;

    &.header {
      color: rgba(46, 51, 56, 0.5);
      font-size: 11px;
      border-bottom: none;
      padding: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__col {
    &:last-child {
      min-width: 65px;
      text-align: right;
    }

    &:nth-last-child(2) {
      min-width: 90px;
    }

    &:nth-last-child(3) {
      min-width: 150px;
    }

    &:first-child {
      margin-right: auto;
    }
  }
}
</style>