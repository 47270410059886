<template>
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_10129_6657)">
            <path d="M24.006 0.5H23.994C10.761 0.5 3.14908e-05 11.264 3.14908e-05 24.5C-0.00818581 29.555 1.59198 34.4816 4.56903 38.567L1.57803 47.483L10.803 44.534C14.7161 47.1301 19.3101 48.5101 24.006 48.5C37.239 48.5 48 37.733 48 24.5C48 11.267 37.239 0.5 24.006 0.5Z" fill="#BAC5C8"/>
            <path d="M37.9697 34.3906C37.3907 36.0256 35.0927 37.3816 33.2597 37.7776C32.0057 38.0446 30.3677 38.2576 24.8537 35.9716C17.8007 33.0496 13.2587 25.8826 12.9047 25.4176C12.5657 24.9526 10.0547 21.6226 10.0547 18.1786C10.0547 14.7346 11.8037 13.0576 12.5087 12.3376C13.0877 11.7466 14.0447 11.4766 14.9627 11.4766C15.2597 11.4766 15.5267 11.4916 15.7667 11.5036C16.4717 11.5336 16.8257 11.5756 17.2907 12.6886C17.8697 14.0836 19.2797 17.5276 19.4477 17.8816C19.6187 18.2356 19.7897 18.7156 19.5497 19.1806C19.3247 19.6606 19.1267 19.8736 18.7727 20.2816C18.4187 20.6896 18.0827 21.0016 17.7287 21.4396C17.4047 21.8206 17.0387 22.2286 17.4467 22.9336C17.8547 23.6236 19.2647 25.9246 21.3407 27.7726C24.0197 30.1576 26.1917 30.9196 26.9687 31.2436C27.5477 31.4836 28.2377 31.4266 28.6607 30.9766C29.1977 30.3976 29.8607 29.4376 30.5357 28.4926C31.0157 27.8146 31.6217 27.7306 32.2577 27.9706C32.9057 28.1956 36.3347 29.8906 37.0397 30.2416C37.7447 30.5956 38.2097 30.7636 38.3807 31.0606C38.5487 31.3576 38.5487 32.7526 37.9697 34.3906Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_10129_6657">
                <rect width="48" height="48" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "whatsappIcon"
    }
</script>

<style scoped>

</style>
