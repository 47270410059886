<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.71L10.62 6.25L11.02 6.87L11.73 7.08L14.61 7.95L12.77 10.28L12.32 10.86V11.59L12.42 14.79L9.82001 13.56L8.97 13.16L8.12 13.56L5.51999 14.79L5.62 11.59V10.86L5.17001 10.28L3.39001 8L6.26999 7.13L6.98001 6.92L7.38 6.3L9 3.71ZM9 0L5.70001 5.17L0 6.88L3.64999 11.52L3.44 18L9 15.37L14.56 18L14.35 11.52L18 6.88L12.3 5.17L9 0Z" fill="#BAC5C8"/>
  </svg>

</template>

<script>
export default {
  name: "StarIcon"
}
</script>

<style scoped>

</style>