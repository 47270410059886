import axios from "@/utils/axios";

export default {
    namespaced: true,
    state: () => ({
        links: [],
    }),
    getters: {
        getLinks(state){
            return state.links;
        }
    },
    mutations: {
        setLinks(state, payload) {
            state.links = payload;
        },
        addLink(state,payload){
            state.links.push(payload);
        },
        removeLink(state,code){
            state.links = state.links.filter(el=>el.code!==code);
        }
    },
    actions: {
        initReferralLinks({commit}) {

            return new Promise((resolve, reject) => {
                axios.get('/api/v1/referral/get/').then(({data}) => {
                    //console.log('12312',data);
                    commit('setLinks', data);
                    resolve(data);
                }).catch(error => reject(error));
            });

        },
        createRefLink({commit},payload) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/referral/create/',{name:payload}).then(({data}) => {
                    commit('addLink',Object.assign(data,{invest:0, partners_count:0, reg_count:0, visits_count:0}));
                    resolve(data);
                }).catch(error => reject(error));
            });
        },
        deleteRefLink({commit},code) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/referral/delete/',{code:code}).then(() => {
                    commit('removeLink',code);
                    resolve();
                }).catch(error => reject(error));
            });
        },
        incrementVisits(state,payload){
            //console.log(1,payload);
            return new Promise((resolve, reject) => {
                axios.post('api/v1/referral/visit/',{code:payload}).then(({data}) => {
                    //console.log(2,data);
                    resolve(data);
                }).catch(error => reject(error));
            });


        }

    }
}
