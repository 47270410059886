import axios from "axios";
import store from "@/store";

import config_local from '@/config_local'


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;
export default (function() {
    const instance = axios.create({
        //НЕ МЕНЯТЬ!!!
        baseURL: config_local.axios_baseURL
        //baseURL: '',
        //НЕ МЕНЯТЬ!!!
    });
    instance.interceptors.request.use(request => {

        /// после обновления axios ругается на эти строчки:
        /// TypeError: Cannot set properties of undefined (setting 'Accept')
        //request.headers['Accept']       = 'application/json';
        //request.headers['Content-Type'] = 'application/json';

        //request.headers['Authorization'] = 'Bearer O0K8kIzvTxTwPilEdMSUXo6cUOpUxExrLi7I7ct2';
        return request;
    });
    instance.interceptors.response.use((response)=>{
        return response;
    },(error)=>{
        console.log(error);
        if (error.response.status===401)
        {
            store.dispatch('user/clearUser');
        }
        return Promise.reject(error);
    })
    return instance;
})();
