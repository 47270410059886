<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7803_1356)">
      <path d="M3.44045 7.55957C3.72766 7.55958 4.0084 7.64488 4.24707 7.80466C4.48573 7.96443 4.67157 8.19148 4.78102 8.45702C4.89046 8.72255 4.91858 9.01461 4.86182 9.29615C4.80506 9.57769 4.66596 9.83604 4.46218 10.0384C4.25839 10.2408 3.99909 10.3781 3.71716 10.4329C3.43523 10.4877 3.14338 10.4576 2.8786 10.3463C2.61383 10.235 2.38807 10.0476 2.22995 9.80785C2.07183 9.56809 1.98848 9.28677 1.99046 8.99957C1.99046 8.61766 2.14217 8.25139 2.41223 7.98134C2.68228 7.71129 3.04855 7.55957 3.43046 7.55957H3.44045ZM3.43046 5.55957C2.74769 5.55957 2.08027 5.76217 1.51271 6.1417C0.945141 6.52124 0.502955 7.06065 0.242129 7.69164C-0.0186971 8.32262 -0.0864362 9.01681 0.0474954 9.68632C0.181427 10.3558 0.511002 10.9705 0.994494 11.4526C1.47799 11.9347 2.09365 12.2625 2.76354 12.3945C3.43344 12.5265 4.12743 12.4567 4.75766 12.1941C5.38789 11.9314 5.92601 11.4877 6.3039 10.919C6.68178 10.3503 6.88244 9.68234 6.88046 8.99957C6.88046 8.08723 6.51803 7.21225 5.8729 6.56712C5.22778 5.922 4.3528 5.55957 3.44045 5.55957H3.43046Z" fill="#BAC5C8"/>
      <path d="M14.5498 7.55958C14.8375 7.55761 15.1192 7.64122 15.3591 7.79979C15.5991 7.95835 15.7865 8.18471 15.8975 8.45006C16.0085 8.71542 16.0381 9.00779 15.9824 9.28999C15.9268 9.57219 15.7885 9.83147 15.5851 10.0349C15.3817 10.2382 15.1224 10.3765 14.8402 10.4322C14.558 10.4878 14.2657 10.4583 14.0003 10.3473C13.7349 10.2363 13.5086 10.0489 13.35 9.80891C13.1915 9.56893 13.1079 9.28721 13.1098 8.99959C13.1125 8.61848 13.265 8.25373 13.5345 7.98424C13.804 7.71476 14.1687 7.5622 14.5498 7.55958ZM14.5498 5.55958C13.8666 5.55761 13.1982 5.75852 12.6294 6.13685C12.0605 6.51518 11.6167 7.0539 11.3544 7.68471C11.092 8.31552 11.0228 9.01002 11.1556 9.68018C11.2884 10.3503 11.6172 10.966 12.1003 11.4491C12.5834 11.9322 13.1991 12.261 13.8692 12.3938C14.5394 12.5266 15.2339 12.4574 15.8647 12.195C16.4955 11.9327 17.0342 11.4889 17.4126 10.9201C17.7909 10.3512 17.9918 9.68278 17.9898 8.99959C17.9898 8.547 17.9005 8.09885 17.727 7.68084C17.5535 7.26283 17.2992 6.88316 16.9787 6.5636C16.6583 6.24403 16.2778 5.99085 15.8593 5.81856C15.4408 5.64628 14.9924 5.55827 14.5398 5.55958H14.5498Z" fill="#BAC5C8"/>
      <path d="M8.98907 2.00001C9.27627 1.99803 9.55758 2.08139 9.79735 2.23951C10.0371 2.39763 10.2245 2.62339 10.3358 2.88816C10.4471 3.15293 10.4772 3.44479 10.4224 3.72671C10.3676 4.00864 10.2303 4.26794 10.0279 4.47173C9.82553 4.67552 9.56719 4.81461 9.28564 4.87138C9.0041 4.92814 8.71205 4.90002 8.44651 4.79057C8.18097 4.68113 7.95393 4.49529 7.79415 4.25662C7.63438 4.01796 7.54907 3.73722 7.54906 3.45001C7.54906 3.06717 7.70045 2.69987 7.97022 2.42823C8.23999 2.15659 8.60624 2.00265 8.98907 2.00001ZM8.98907 1.45788e-05C8.30672 1.45788e-05 7.6397 0.202352 7.07235 0.581443C6.505 0.960534 6.0628 1.49935 5.80168 2.12976C5.54056 2.76017 5.47224 3.45384 5.60535 4.12308C5.73847 4.79231 6.06705 5.40704 6.54955 5.88953C7.03204 6.37202 7.64677 6.70061 8.316 6.83372C8.98524 6.96684 9.67892 6.89852 10.3093 6.6374C10.9397 6.37628 11.4785 5.93408 11.8576 5.36673C12.2367 4.79938 12.4391 4.13236 12.4391 3.45001C12.4404 2.99658 12.352 2.54737 12.1791 2.1282C12.0062 1.70903 11.7522 1.32818 11.4315 1.00755C11.1109 0.686927 10.7301 0.432852 10.3109 0.25994C9.89171 0.0870292 9.4425 -0.00130546 8.98907 1.45788e-05Z" fill="#BAC5C8"/>
      <path d="M8.99055 13.1201C9.27535 13.1201 9.55376 13.2046 9.79057 13.3628C10.0274 13.521 10.2119 13.7459 10.3209 14.0091C10.4299 14.2722 10.4584 14.5617 10.4029 14.8411C10.3473 15.1204 10.2102 15.377 10.0088 15.5784C9.8074 15.7797 9.55081 15.9169 9.27148 15.9725C8.99215 16.028 8.70261 15.9995 8.43949 15.8905C8.17636 15.7815 7.95146 15.597 7.79323 15.3602C7.635 15.1233 7.55055 14.8449 7.55055 14.5601C7.55055 14.1782 7.70226 13.8119 7.97231 13.5419C8.24237 13.2718 8.60864 13.1201 8.99055 13.1201ZM8.99055 11.1201C8.31061 11.1221 7.6465 11.3255 7.08212 11.7047C6.51773 12.0839 6.07838 12.6219 5.81954 13.2506C5.56071 13.8794 5.49401 14.5707 5.62787 15.2373C5.76173 15.904 6.09015 16.516 6.57163 16.9961C7.05312 17.4762 7.66609 17.8028 8.33311 17.9347C9.00013 18.0667 9.69128 17.998 10.3193 17.7373C10.9473 17.4766 11.4839 17.0357 11.8615 16.4702C12.239 15.9048 12.4405 15.2401 12.4405 14.5601C12.4405 14.1075 12.3512 13.6594 12.1777 13.2414C12.0042 12.8234 11.75 12.4437 11.4295 12.1241C11.109 11.8046 10.7286 11.5514 10.3101 11.3791C9.89154 11.2068 9.44314 11.1188 8.99055 11.1201Z" fill="#BAC5C8"/>
      <path d="M11.4004 4C12.5313 4.55115 13.4436 5.46702 13.9904 6.6" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M3.99023 6.6C4.53956 5.4654 5.45563 4.54933 6.59023 4" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M6.59047 14.0004C5.44887 13.4575 4.52773 12.5399 3.98047 11.4004" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M13.9909 11.4102C13.4403 12.5473 12.5201 13.464 11.3809 14.0102" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_7803_1356">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
name: "CommunityIcon"
}
</script>

<style scoped>

</style>