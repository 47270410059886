export default [

    {
        path: '/partners/education/introduction-ru',
        name: 'introduction-ru',
        component: () => import('@/components/education/russian/IntroductionPage.vue'),
    },
    {
        path: '/partners/education/chapterOne-ru',
        name: 'chapterOne-ru',
        component: () => import('@/components/education/russian/ChapterOnePage.vue'),
    },
    {
        path: '/partners/education/chapterTwo-ru',
        name: 'chapterTwo-ru',
        component: () => import('@/components/education/russian/ChapterTwoPage.vue'),
    },
    {
        path: '/partners/education/chapterThree-ru',
        name: 'chapterThree-ru',
        component: () => import('@/components/education/russian/ChapterThreePage.vue'),
    },
    {
        path: '/partners/education/chapterFour-ru',
        name: 'chapterFour-ru',
        component: () => import('@/components/education/russian/ChapterFourPage.vue'),
    },
    {
        path: '/partners/education/chapterFive-ru',
        name: 'chapterFive-ru',
        component: () => import('@/components/education/russian/ChapterFivePage.vue'),
    },
    {
        path: '/partners/education/chapterSix-ru',
        name: 'chapterSix-ru',
        component: () => import('@/components/education/russian/ChapterSixPage.vue'),
    },
    {
        path: '/partners/education/chapterSeven-ru',
        name: 'chapterSeven-ru',
        component: () => import('@/components/education/russian/ChapterSevenPage.vue'),
    },
    {
        path: '/partners/education/chapterEight-ru',
        name: 'chapterEight-ru',
        component: () => import('@/components/education/russian/ChapterEightPage.vue'),
    },
    {
        path: '/partners/education/chapterNine-ru',
        name: 'chapterNine-ru',
        component: () => import('@/components/education/russian/ChapterNinePage.vue'),
    },
    {
        path: '/partners/education/introduction-en',
        name: 'introduction-en',
        component: () => import('@/components/education/english/IntroductionPage.vue'),
    },
    {
        path: '/partners/education/chapterOne-en',
        name: 'chapterOne-en',
        component: () => import('@/components/education/english/ChapterOnePage.vue'),
    },
    {
        path: '/partners/education/chapterTwo-en',
        name: 'chapterTwo-en',
        component: () => import('@/components/education/english/ChapterTwoPage.vue'),
    },
    {
        path: '/partners/education/chapterThree-en',
        name: 'chapterThree-en',
        component: () => import('@/components/education/english/ChapterThreePage.vue'),
    },
    {
        path: '/partners/education/chapterFour-en',
        name: 'chapterFour-en',
        component: () => import('@/components/education/english/ChapterFourPage.vue'),
    },
    {
        path: '/partners/education/chapterFive-en',
        name: 'chapterFive-en',
        component: () => import('@/components/education/english/ChapterFivePage.vue'),
    },
    {
        path: '/partners/education/chapterSix-en',
        name: 'chapterSix-en',
        component: () => import('@/components/education/english/ChapterSixPage.vue'),
    },
    {
        path: '/partners/education/chapterSeven-en',
        name: 'chapterSeven-en',
        component: () => import('@/components/education/english/ChapterSevenPage.vue'),
    },
    {
        path: '/partners/education/chapterEight-en',
        name: 'chapterEight-en',
        component: () => import('@/components/education/english/ChapterEightPage.vue'),
    },
    {
        path: '/partners/education/chapterNine-en',
        name: 'chapterNine-en',
        component: () => import('@/components/education/english/ChapterNinePage.vue'),
    },


]
