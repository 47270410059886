<template>
    <svg width="22" height="16" viewBox="0 0 22 16" stroke="#111212" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 8C2.51143 10.0023 3.67356 11.7771 5.30313 13.0446C6.9327 14.312 8.93701 15 11 15C13.063 15 15.0673 14.312 16.6969 13.0446C18.3264 11.7771 19.4886 10.0023 20 8C19.4886 5.99767 18.3264 4.22286 16.6969 2.95542C15.0673 1.68797 13.063 1 11 1C8.93701 1 6.9327 1.68797 5.30313 2.95542C3.67356 4.22286 2.51143 5.99767 2 8Z"  stroke-width="2" stroke-miterlimit="10"/>
        <path d="M11 11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11Z" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
</template>

<script>
export default {
    name: "EyeIcon"
}
</script>

<style scoped>

</style>
