<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20Z" fill="#BAC5C8"/>
    <path d="M20.0016 19.9558C22.4316 19.9558 24.4016 17.9858 24.4016 15.5558C24.4016 13.1257 22.4316 11.1558 20.0016 11.1558C17.5715 11.1558 15.6016 13.1257 15.6016 15.5558C15.6016 17.9858 17.5715 19.9558 20.0016 19.9558Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M12.957 28.4443C12.957 26.576 13.6992 24.7843 15.0203 23.4632C16.3414 22.1421 18.1332 21.3999 20.0015 21.3999C21.8698 21.3999 23.6616 22.1421 24.9827 23.4632C26.3037 24.7843 27.0459 26.576 27.0459 28.4443" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "AvatarIcon"
}
</script>

<style scoped>

</style>