<template>
    <div class="d-flex ac fc">
    <div class='tc' v-html="text"></div>
    <div @click="close" class="mt30 base-button mw30">{{ $t('common.ok') }}</div>
    </div>
</template>

<script>
export default {
    name: "DefaultModal",
    props:{
        text:{
            default:''
        },
    },
    methods:{
      close(){
          this.$emit('close');
        }
    }
}
</script>

<style scoped>
.mw30{
    min-width:30%;
}
</style>
