<template>
    <div class="wrapper">

        <div class="modal_title">{{ $t('address_popup.title_address_popup') }}</div>


        <div class="text1">
            {{ $t('address_popup.text_1') }}
        </div>
        <div class="text2">
            {{ $t('address_popup.text_2') }}
        </div>

        <div class="fields_wrap d-flex">

            <div class="field_group">

                <TextField tabindex="-1"
                           v-model="country"

                           :class_wrap="'field_country'"
                           :class_input="'gray'"
                           :label="this.$t('address_popup.label_country')"
                           :type="'select'"
                           :selectOptions="allCountries"
                />
                <TextField v-model="region"
                           :class_wrap="'field_country'"
                           :class_input="'gray'"
                           :label="this.$t('address_popup.label_region')"
                           :type="'select'"
                           :selectOptions="curRegions"

                />
                <!--<TextField v-model="address.region"
                          :class_input="'gray'"
                          :label="'Регион'"
               ></TextField>-->
            </div>


            <div class="field_group">
                <TextField v-model="city"
                           :class_input="'gray'"
                           :label="this.$t('address_popup.label_city')"
                >
                    <div class="warning" v-if="city && city.length >30">
                        {{ $t('verify.error_too_many_symbols_30') }}
                    </div>
                </TextField>
                <TextField v-model="index"
                           :class_input="'gray'"
                           :label="this.$t('address_popup.label_index')"
                >
                    <div class="warning" v-if="index && index.length >30">
                        {{ $t('verify.error_too_many_symbols_30') }}
                    </div>
                </TextField>
            </div>

            <TextField v-model="street"
                       :class_wrap="'field_street'"
                       :class_input="'gray'"
                       :label="this.$t('address_popup.label_street_house_apartment')"
            >
                <div class="warning" v-if="street && street.length >80">
                    {{ $t('verify.error_too_many_symbols_80') }}
                </div>
            </TextField>

        </div>

        <div class="button_wrap">
            <button class="base-button" :disabled="!valid || isLoading" @click="submit">
                <LoaderAnimation v-if="isLoading"/>
                {{ $t('address_popup.save_btn') }}
            </button>
        </div>


    </div>
</template>

<script>
import TextField from "@/components/common/TextField";
import {useI18n} from "vue-i18n";
import {mapGetters, mapMutations} from "vuex";
import {countries} from "@/data/country";
import LoaderAnimation from "@/assets/animations/LoaderAnimation";

export default {
    name: "AddressModal",
    components: {
        LoaderAnimation,
        TextField,
    },
    setup() {
        const {t} = useI18n({useScope: 'global'});
        return {
            t,
        }
    },

    data() {
        return {
            isLoading: false,

            valid: false,
            successful: false,


            country: '',
            region: '',
            city: '',
            street: '',
            index: '',


        }
    },
    computed: {
        ...mapGetters({
            user: 'user/userGetter',
        }),
        allCountries() {
           // console.log(countries);

            return countries.reduce((obj, el, i) => {
                obj[i] = this.$t(el.name);
                return obj
            }, {})

        },
        curRegions() {

            if (!countries[this.country]) return [];
            return countries[this.country].regions.map(el => this.$t(el.name));
        }

    },

    updated() {
        this.valid =
            (this.country || this.country === 0) &&
            (this.region || this.region === 0)  &&
            this.city  &&
            this.street &&
            this.index  &&
            this.city.length < 30 &&
            this.index.length < 30 &&
            this.street.length < 80
    },
    methods: {
        ...mapMutations({
            setUser: 'user/setUser',
        }),
        submit() {
            const address = {
                country: countries[this.country].shortcode,
                region: countries[this.country].regions[this.region].shortcode,
                city: this.city,
                street: this.street,
                index: this.index,
                full_address: this.$t(countries[this.country].name) + ' ' + this.$t(countries[this.country].regions[this.region].name) + ' ' + this.city + ' ' + this.street + ' ' + this.index
            }
            //console.log('address', address);

            this.isLoading = true;
            this.$store.dispatch('user/edit', address)
                .then((data) => {
                    console.log(data.data );
                    //this.setUser(data.data);
                    this.$store.commit('popups/openPopup', {
                        name: 'DefaultModal',
                        props: {text: this.$t('address_popup.address_changed')}
                    })
                    location.reload();
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(()=>{this.$store.dispatch('authentificate'); this.isLoading = false});
        },
    },
    watch: {
        country() {
            //console.log(this.country);

        }

    },
    mounted() {

        this.country = countries.findIndex(el => el.shortcode === this.user.country);
        if (this.country === -1) this.country = '';
        if (countries[this.country] && countries[this.country].regions) {
            this.region = countries[this.country].regions.findIndex(el => el.shortcode === this.user.region);
        }

        this.city = this.user.city;
        this.street = this.user.street;
        this.index = this.user.index;

    },
}
</script>


<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

.text1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 10px;
}

.text2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #7e7e7e;
    margin-bottom: 20px;
}


.fields_wrap {
    gap: 10px;
    flex-wrap: wrap;
}

.field_group {
    width: 100%;
    display: flex;
    gap: 10px;

    .field_wrap {
        width: 50%;
    }

    @include respond($tablet) {
        flex-direction: column;
        .field_wrap {
            width: 100%;
        }
    }
}

.field_street {
    width: 100%;
}


.button_wrap {
    margin-top: 30px;
    display: flex;
    justify-content: right;
}

.successful {
    .base-button {
        margin-top: 30px;
    }
}

</style>
