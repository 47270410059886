export default {
    namespaced: true,
    state: () => ({
        popup: null
    }),
    getters: {

    },
    mutations: {
        openPopup(state, popup){
            state.popup = popup;
        },
        closePopup(state){
            state.popup = null;
        }
    },
}
