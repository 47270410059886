<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 2V16H2V2H16ZM18 0H0V18H18V0Z" fill="#BAC5C8"/>
    <path d="M4.5 6.58008H13.5" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M4.5 11.4702H13.5" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
  </svg>

</template>

<script>
export default {
  name: "NewsIcon",

}
</script>

<style scoped>

</style>
