<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12Z"
              fill="#E3EAED"/>
        <path d="M11.9994 11.9734C13.4574 11.9734 14.6394 10.7914 14.6394 9.33336C14.6394 7.87533 13.4574 6.69336 11.9994 6.69336C10.5413 6.69336 9.35938 7.87533 9.35938 9.33336C9.35938 10.7914 10.5413 11.9734 11.9994 11.9734Z"
              stroke="#2E3338" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M7.77344 17.0665C7.77344 15.9455 8.21874 14.8705 9.01139 14.0778C9.80405 13.2852 10.8791 12.8398 12.0001 12.8398C13.1211 12.8398 14.1962 13.2852 14.9888 14.0778C15.7815 14.8705 16.2268 15.9455 16.2268 17.0665"
              stroke="#2E3338" stroke-width="2" stroke-miterlimit="10"/>
    </svg>
</template>

<script>
export default {
    name: "ProfileIcon"
}
</script>

<style scoped>

</style>
