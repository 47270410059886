<script setup>
import {camel2title, axios} from '@/utils/utils.js'
import useSteps from '@/utils/useSteps.js'
import {ref, computed} from 'vue'

const Country = {
    'RU': 'Россия',
    'notRU': 'Другая страна',
}
const Gender = {
    'male': 'Мужской',
    'female': 'Женский',
}
const docs = {
    'passport': 'Паспорт',
    'other': 'Другое',
}
const formData = ref({})

const oblast = computed(() => {
    if (formData.value.country === 'Казахстан') {
        return ['Казахстанская область', 'Другая область']
    }
    return ['Томская область', 'Московская область']
})

const isHidden = {}
const checkmark = '<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2.84615L5.09091 7L11 1" stroke="#EBEFF1" stroke-width="2"/></svg>'
const inputarrow = '<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 1.5L7 6.5L13 1.5" stroke="#3E76B5" stroke-width="2" stroke-miterlimit="10"/> </svg>'
const fileupload = '<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 12.3174V22.1339H12V12.3174L21.8165 12.3174V10.3174L12 10.3174V0.500977H10V10.3174L0.183594 10.3174V12.3174L10 12.3174Z" fill="#BAC5C8"/></svg>'
const {steps, visitedSteps, activeStep, setStep, stepPlugin} = useSteps()

// NEW: submit handler, which posts to our fake backend.
const submitApp = async (formData, node) => {
    //console.log(formData, node)
    try {
        const res = await axios.post(formData)
        node.clearErrors()
        //console.log(formData, node)
        alert('Your application was submitted successfully!')
    } catch (err) {
        node.setErrors(err.formErrors, err.fieldErrors)
    }
}

const checkStepValidity = (stepName) => {
    return (steps[stepName].errorCount > 0 || steps[stepName].blockingCount > 0) && visitedSteps.value.includes(stepName)
}

/* eslint-disable */
</script>

<template>
    <div class="verification-info-wrap">
        <div class="verification-info">
            <div class="modal__block-title">
                Личные данные
            </div>
            <div class="verification__steps">
                <div
                    class="active-step"

                >
                    <ver-phone/>
                </div>
                <div
                    :class="{'active-step': activeStep == 'personalInfo' || activeStep == 'passport' || activeStep == 'address' }"

                >
                    <ver-personal/>
                </div>
                <div
                    :class="{'active-step':  activeStep == 'passport' || activeStep == 'address' }"


                >
                    <ver-pasport/>
                </div>
                <div
                    :class="{'active-step': activeStep == 'address' }"

                >
                    <ver-address/>
                </div>
            </div>

            <FormKit
                type="form"
                #default="{ value, state: { valid } }"
                :plugins="[stepPlugin]"
                @submit="submitApp"
                :actions="false"
            >
                <ul class="steps">
                    <li
                        v-for="(step, stepName) in steps"
                        :class="['step', { 'has-errors': checkStepValidity(stepName) }]"
                        @click="activeStep = stepName"
                        :data-step-valid="step.valid && step.errorCount === 0"
                        :data-step-active="activeStep === stepName"
                    >
      <span
          v-if="checkStepValidity(stepName)"
          class="step--errors"
          v-text="step.errorCount + step.blockingCount"
      />
                        {{ camel2title(stepName) }}
                    </li>
                </ul>

                <!-- .form-body solely for styling -->
                <div class="form-body">
                    <section v-show="activeStep === 'phoneNumber'">
                        <div class="versteps__title">Номер телефона</div>
                        <div class="modal__title">
                            Необходимо верифицировать номер вашего телефона
                        </div>
                        <div class="modal__info">
                            Подтвердите свой номер телефона по SMS или по звонку. SMS придёт в течение минуты. Если по
                            какой-то
                            причине сообщение вам не приходит, попробуйте второй способ (по звонку). Если и в этом
                            случае вы не
                            можете подтвердить номер телефона, напишите в нашу службу технической поддержки (онлайн-чат
                            в правом
                            нижнем углу любой страницы личного кабинета).
                        </div>
                        <FormKit
                            type="group"
                            id="phoneNumber"
                            name="phoneNumber"

                        >


                            <FormKit
                                type="tel"
                                name="номер телефона"
                                validation="required|number|"
                                validation-visibility="live"

                            />

                        </FormKit>
                    </section>

                    <section v-show="activeStep === 'personalInfo'">
                        <div class="versteps__title">Персональные данные</div>
                        <div class="modal__title">
                            Данные должны совпадать с теми, что указаны в вашем удостоверении личности
                        </div>
                        <div class="modal__info">
                            Введите свои основные персональные данные: имя, пол и дату рождения. Также укажите свою
                            фамилию и отчество, если они у вас есть. Если у вас только имя и нет фамилии, то включите
                            переключатель «Нет фамилии». При отсутствии отчества соответствующая графа исчезает
                            автоматически.
                        </div>
                        <FormKit
                            id="personalInfo"
                            type="group"
                            name="personalInfo"
                        >

                            <div class="versteps-inputwrap">
                                <FormKit
                                    type="text"
                                    label="Имя"
                                    name="name"
                                    validation="required"
                                    validation-visibility="live"

                                />
                                <FormKit
                                    type="text"
                                    label="Имя латиницей"
                                    name="namelat"
                                    validation="required"
                                    validation-visibility="live"
                                />
                            </div>


                            <div class="vercheckbox">
                                <div
                                    class="vercheckbox-checkmark"
                                    :class="{'vercheckbox-checkmark-on': !isHidden }"
                                >
                                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 2.84615L5.09091 7L11 1" stroke="#000" stroke-width="2"/>
                                    </svg>
                                </div>

                                <FormKit
                                    type="checkbox"
                                    label=""
                                    help=""
                                    :suffix-icon="checkmark"
                                    name="nosurname"
                                    validation=""
                                    @change="isHidden = !isHidden"
                                />
                                <span>Нет фамилии</span>


                            </div>
                            <div class="versteps-inputwrap">
                                <FormKit
                                    v-if="isHidden"
                                    type="text"
                                    label="Фамилия"
                                    name="surname"
                                    validation=""
                                />
                                <FormKit
                                    v-if="isHidden"
                                    type="text"
                                    label="Фамилия латиницей"
                                    name="surnamelat"
                                    validation=""
                                />
                                <FormKit
                                    type="text"
                                    label="Отчество"
                                    name="surname"
                                    validation="required"
                                    validation-visibility="live"
                                />
                                <FormKit
                                    :suffix-icon="inputarrow"
                                    type="select"
                                    label="Страна рождения"
                                    name="borncountry"
                                    :options="Country"
                                    validation="required"
                                    validation-visibility="live"
                                />
                                <FormKit
                                    type="date"
                                    label="Дата рождения"
                                    :validation="[['required'], ['before_date', new Date(Date.now())]]"
                                    name="dateofbirth"
                                                                        validation-visibility="live"
                                />
                                <FormKit
                                    :suffix-icon="inputarrow"
                                    type="select"
                                    label="Пол"
                                    name="gender"
                                    :options="Gender"
                                    validation="required"
                                    validation-visibility="live"
                                />
                            </div>
                        </FormKit>
                    </section>

                    <section v-show="activeStep === 'passport'">
                        <div class="versteps__title">Удостоверение личности</div>
                        <div class="modal__title">
                            Данные должны совпадать с теми, что указаны в вашем удостоверении личности
                        </div>
                        <div class="modal__info">
                            Для подтверждения личности вам необходимо загрузить один из перечисленных документов:
                            паспорт, ID-карта, другой документ, подтверждающий вашу личность (официально разрешённый на
                            территории государства, гражданином которого вы являетесь). Водительское удостоверение не
                            может быть принято как основной документ, подтверждающий личность.
                        </div>
                        <FormKit
                            id="passport"
                            type="group"
                            name="passport"
                        >
                            <div class="versteps-inputwrap inputgrow">
                                <FormKit
                                    :suffix-icon="inputarrow"
                                    type="select"
                                    label="Гражданство"
                                    name="citizenship"
                                    :options="Country"
                                    validation="required"
                                    validation-visibility="live"
                                />
                                <FormKit
                                    :suffix-icon="inputarrow"
                                    type="select"
                                    label="Тип документа"
                                    name="docstype"
                                    :options="docs"
                                    validation="required"
                                    validation-visibility="live"
                                />
                                <FormKit
                                    type="date"
                                    label="Дата выдачи"
                                    :validation="[['required'], ['before_date', new Date(Date.now())]]"
                                    name="docsdate"
                                />
                                <FormKit
                                    type="tel"
                                    label="Серия и номер документа"
                                    name="docsnumber"
                                    validation="required"
                                    validation-visibility="live"
                                />
                                <FormKit
                                    type="text"
                                    label="Кем выдан"
                                    name="whogave"
                                    validation="required"
                                    validation-visibility="live"
                                />
                            </div>
                            <div class="modal__title mt30">
                                Загрузка документов
                            </div>
                            <div class="modal__info">Загрузите изображение разворота паспорта или другого основного
                                документа, подтверждающего личность, который вы указали выше. Внесённые в форму данные
                                должны совпадать с данными в документе.
                            </div>
                            <FormKit
                                :prefix-icon="fileupload"
                                type="file"
                                name="docsphoto"
                                label=""
                                accept=".img,.png,.jpg,.jpeg,.webp"
                                help=""
                                data-type="file"
                                :outer-class="{
    'formkit-wrapper': false
  }"

                                multiple

                            />

                        </FormKit>
                    </section>
                    <section v-show="activeStep === 'address'">
                        <div class="versteps__title">Адрес регистрации</div>
                        <div class="modal__title">
                            Данные должны совпадать с теми, что указаны вами в анкете на предыдущем шаге
                        </div>
                        <div class="modal__info">
                            Для подтверждения адреса регистрации необходимо загрузить один из перечисленных документов,
                            где указаны ваши ФИО и адрес, а также есть печать с подписью или уникальный номер документа:
                            страница паспорта с адресом регистрации, ID карта с адресом, справка о регистрации, счёт за
                            коммунальные услуги, счёт за интернет, справка из полиции, или любой другой документ с
                            указанием имени и фамилии, который официально подтверждает адрес вашей регистрации.
                        </div>
                        <FormKit
                            id="address"
                            type="group"
                            name="address"
                            v-model="formData"
                            @submit="submitApp"
                        >
                            <div class="versteps-inputwrap inputgrow">
                                <FormKit
                                    type="select"
                                    label="Страна"
                                    name="country"
                                    :options="['Россия', 'Казахстан']"
                                    validation="required"
                                    validation-visibility="live"
                                />
                                <FormKit
                                    type="select"
                                    label="Область\Район"
                                    name="oblast"
                                    :options="oblast"
                                    validation="required"
                                    validation-visibility="live"
                                />
                                <FormKit
                                    type="text"
                                    label="Адрес"
                                    name="regaddress"
                                    validation="required"
                                    validation-visibility="live"
                                />
                            </div>
                        </FormKit>
                    </section>

                    <!-- NEW: Adds Next / Previous navigation buttons. -->
                    <div class="step-nav">
                        <div class="base-button" v-if="activeStep != 'phoneNumber'" @click="setStep(-1)"
                             v-text="'Назад'"/>

                        <FormKit type="submit" class="base-button" data-type="nextStep" v-if="activeStep != 'address' "
                                 v-text="'Далее'" @click="setStep(1)"/>
                        <FormKit type="submit" class="base-button" data-type="nextStep" v-if="activeStep == 'address' "
                                 v-text="'Завершить'" :disabled="!valid"/>
                    </div>

                    <details>
                        <summary>value</summary>
                        <pre>{{ value }}</pre>
                    </details>
                </div>


            </FormKit>

        </div>
    </div>
</template>


<script>
import VerPhone from "@/assets/icons/VerPhone";
import VerPersonal from "@/assets/icons/VerPersonal";
import VerPasport from "@/assets/icons/VerPasport";
import VerAddress from "@/assets/icons/VerAddress";


export default {
    name: "VerificationModal",
    components: {VerAddress, VerPasport, VerPersonal, VerPhone},

    computed: {},

    methods: {}
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

.steps {
    display: none;
}

.versteps {
    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 35px;
        margin-top: 25px;
    }
}

.vercheckbox {
    display: flex;
    gap: 17px;
    align-items: center;
    position: relative;
    margin-top: 13px;
    margin-bottom: 13px;

    &-checkmark {
        display: none;

        &-on {
            display: block;
            position: absolute;
            left: 6px;
            top: 2px;
        }
    }

}

.modal {
    &__title {
        font-weight: 500;
        font-size: 18px;
        color: #000;
        margin-bottom: 10px;
        @include respond(550px) {
            text-align: center;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 13px;
        color: #000000;
    }

    &__info {
        font-weight: 500;
        font-size: 13px;
        color: #BAC5C8;
        margin-bottom: 30px;
        @include respond(550px) {
            text-align: center;
        }
    }

    &__block-title {
        background: #EBEFF1;
        border-radius: 15px;
        color: rgba(35, 37, 40, 0.5);
        font-weight: 500;
        font-size: 13px;
        padding: 6px 16px;
        width: fit-content;
        margin-bottom: 35px;
    }

    &__addbutton {
        background: #EBEFF1;
        border-radius: 16px;
        width: 75px;
        height: 75px;
        display: grid;
        place-items: center;
        cursor: pointer;
        margin-bottom: 30px;
    }


}

.verification {


    width: 660px;
    display: flex;
    flex-direction: column;
    @include respond(750px) {
        width: 500px;
    }
    @include respond(550px) {
        width: auto;
    }
    @include respond(400px) {
        width: 326px;
        padding-left: 5px;
        padding-right: 5px;
    }

    &__steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: "";
            border: 2px solid #EBEFF1;
            width: calc(100% - 20px);
            top: 20px;
            left: 0;
            z-index: -1;
        }

        .active-step {
            background: #3E76B5;

            svg {
                filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(2%) hue-rotate(236deg) brightness(107%) contrast(100%);
            }
        }

        div {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background: #EBEFF1;
            display: grid;
            place-items: center;
        }

    }

    &__contacts-name-wrap {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin-top: 25px;
        margin-bottom: 30px;
    }

    &__inputs-wrap {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @include respond(750px) {
            flex-direction: column;
        }

        .inputs {
            &:nth-last-child(1) {
                input {
                    width: calc(100% - 57.5px);
                }
            }
        }
    }

    .base-button {
        width: 260px;
        align-self: flex-end;
        margin-top: 30px;
        @include respond(550px) {
            align-self: center;
        }
    }
}

.inputs {
    position: relative;
    width: 326px;
    background: #EBEFF1;
    border-radius: 16px;
    flex-grow: 1;
    @include respond(750px) {
        width: 100%;

    }

    &__forms {
        width: 298px;
        height: 60px;
        padding-right: 31px;
        padding-left: 31px;
        background: #EBEFF1;
        border-radius: 16px;
        font-weight: 500;
        font-size: 18px;
        color: #2E3338;
        border: none;
        outline: none;
        appearance: none;
        @include respond(750px) {
            width: 100%;
        }
    }

    &__labels {
        position: absolute;
        top: 10px;
        font-weight: 500;
        font-size: 10px;
        left: 30px;
        color: rgba(46, 51, 56, 0.5);
    }

    svg {
        position: absolute;
        top: 27.5px;
        right: 30px;
    }

    input {
        width: 268.5px;
    }
}

.base-button {
    padding: 20px;
    background: #3E76B5;
    border-radius: 16px;
    text-align: center;
    font-weight: 800;
    font-size: 16px;
    color: #FFFFFF;
    transition: 0.3s ease-in-out;

    &:hover {
        background: #008CE0;
        cursor: pointer;
        color: #FFFFFF;
        transition: 0.3s ease-in-out;
    }

    &.gray {
        background: #E3EAED;
        color: rgb(35, 37, 40, 0.5);

        &:hover {
            background: #008CE0;
            cursor: pointer;
            color: #FFFFFF;
            transition: 0.3s ease-in-out;
        }
    }
}
</style>
