import axios from "@/utils/axios";
import router from "@/router";

export default {
    namespaced: true,
    state: () => ({
        data: null,
        errors: [],
        partsInfo:{},
        wallet: null,
        allowWithdrawal:true,
        isAdmin:false,
        isSupport:false,
        verificationStatus:'not_verified',
        documentSigned:false,
        partnerSigned:false,
    }),
    getters: {

        isDocumentSigned(state){

            return state.documentSigned;
        },
        isPartnerSigned(state){

            return state.partnerSigned;
        },
        getVerificationStatus(state){
          return state.verificationStatus;
        },
        allowWithdrawalGetter(state){
            return state.allowWithdrawal;
        },
        userGetter(state) {
            return state.data;
        },
        isAdmin(state){
            return state.isAdmin;
        },
        isSupport(state){
            return state.isSupport;
        },
        getUserPartsInfo(state) {
            return state.partsInfo;
        },
        walletGetter(state) {
            return state.wallet;
        },
        balanceGetter(state) {
            if (state.data && state.data.balance) return state.data.balance;
            return 0;
        },
        partnerBalanceGetter(state) {
            if (state.data && state.data.balance_partner) return state.data.balance_partner;
            return 0;
        },
        /*partsNotPayed(state){
            if (!state.data || !state.data.packages) return false;
            return state.data.packages.filter(el => {
                return !el.paid_dt
            }).map(el => el.parts_count).reduce((sum, el) => {
                return sum + el
            }, 0);
        },
        partsPayed(state) {
            if (!state.data || !state.data.packages) return false;
            return state.data.packages.filter(el => {
                return el.paid_dt
            }).map(el => el.parts_count).reduce((sum, el) => {
                return sum + el
            }, 0);
        },
        partsSigned(state) {
            if (!state.data || !state.data.packages) return false;
            return state.data.packages.map(el => el.parts_count).reduce((sum, el) => {
                return sum + el
            }, 0);
            // return state.data.packages.filter(el=>{return el.paid_dt && el.is_parts_registered}).map(el=>el.parts_count).reduce((sum,el)=>{return sum+el},0);
        },
        paymentComing(state) {
            if (!state.data || !state.data.packages) return false;
            const upcomingPackages = state.data.packages.filter(el => {
                return !el.paid_dt && !el.is_canceled
            }).sort((a, b) => {
                return a.pay_need_dt > b.pay_need_dt ? 1 : -1
            });
            if (!upcomingPackages.length) return false;
            return upcomingPackages[0];
        },*/
        invalidCredentials(state) {
            return state.invalidCredentials
        },
        errors(state) {
            return state.errors
        },
    },
    mutations: {
        setVerificationStatus(state,status){
            return state.verificationStatus = status;
        },
        setUser(state, user) {
            state.data = user;
            state.partsInfo = user.parts;
            state.isAdmin = user.is_admin;
            state.isSupport = user.is_support;
            state.verificationStatus = user.verification_status;
            state.documentSigned = user.document_verified;
            state.partnerSigned = user.partner_verified;

        },
        setUserData(state, data) {  // Single field // data.name and data.value
            state.data[data.name] = data.value;
        },

        setUserPartsInfo(state,data){
            state.partsInfo = Object.assign({},state.partsInfo,data);
        },

        setBalance(state, balance) {
            //console.log('balancet');
            state.data.balance = balance;
        },
        setPartnerBalance(state,payload){
            state.data.balance_partner = payload;
        },
        changePartnerBalance(state,payload){
            state.data.partner_balance = state.data.partner_balance + payload;
        },
        setErrors(state, errors) {
            errors.forEach(function (item, index) {
                if (item === "The email field is required.") {
                    errors[index] = "Введите E-mail";
                } else if (item === "The email must be a valid email address.") {
                    errors[index] = "Проверьте правильность ввода E-mail адреса";
                } else if (item === "Not such a user") {
                    errors[index] = "Пользователь не найден";
                }
            });
            state.errors = errors
        },
        setInvalidCredentials(state, invalidCredentials) {
            state.invalidCredentials = invalidCredentials
        },
        setWallet(state, payload) {
            state.wallet = payload;
        },


    },
    actions: {
        test() {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/cron/check_need_cancel_overdue_packets/').then({
                    //console.log(response);
                }).catch(error => reject(error));
            });
        },

        checkVerificationStatus(){
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/user/get_user_verification_status/').then(response => {
                    //console.log(response);
                    resolve(response);
                }).catch(error => reject(error));
            });
        },

        authentificate({commit}) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/user/me').then(response => {
                    //console.log('me',response.data);
                    const {data} = response;
                    commit('setUser', data);
                    commit('setWallet', data.wallet)
                    resolve(response);
                }).catch(error => reject(error));
            });
        },

        clearUser() {
            this.state.data = null;
            router.push('login');
        },

        login({commit}, user) {
            return new Promise((reslove, reject) => {
                axios.get('/sanctum/csrf-cookie').then(() => {
                    return axios.post('/api/v1/auth/login', user);
                }).then((result) => {
                    const {data} = result;
                    commit('setUser', data);
                    reslove(data);
                }).catch(e => reject(e));
            });
        },
        register(context, user) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/auth/register', user).then(response => {
                    resolve(response);
                }).catch(error => reject(error));
            });
        },
        logout() {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/auth/logout').then(response => {

                    resolve(response);
                }).catch(error => reject(error));
            });
        },


        edit(context, user) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/edit', user).then(response => {
                    resolve(response);
                }).catch(error => reject(error));
            });
        },

        uploadPhoto(ctx, photo) {
            const config = {'content-type': 'multipart/form-data'}
            const formData = new FormData()
            formData.append('photo', photo)
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/edit', formData, config).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },


        verifyEmail(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/auth/email-verification', {
                        params: payload
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },

        resetPassword(ctx, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/v1/auth/reset-password', payload)
                    .then((response) => {
                        if (response.data) {
                            window.location.replace("/login")
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            ctx.commit('setErrors', error.response.data.errors)
                        } else if (error.response.status === 500)
                            ctx.commit('setInvalidCredentials', error.response.data.error)
                    })
            })
        },
        forgotPassword(ctx, user) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/v1/auth/forgot-password', {
                        email: user.email
                    })
                    .then(response => {
                        if (response.data) {
                            if (response.data.message === 'Email sent') resolve(response);
                            else reject(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {

                        // if (error.response.status === 422) {
                        //     console.log('here');
                        //     ctx.commit('setErrors', [error.response.data.message])
                        // } else if (error.response.status === 500)
                        //     // ctx.commit('setInvalidCredentials', error.response.data.error)
                        //     ctx.commit('setErrors', [error.response.data.message])
                        // else {
                        //     ctx.commit('setErrors', [error.response.data.message])
                        // }
                        reject(error);
                    })
            })
        },
        checkToken(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/auth/check_token', data).then(response => {
                    // const {data} = response;
                    // ctx.commit('setUser', data);
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },


        changePassword(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/change_password', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        changeEmailInit(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/change_email_init', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        changeEmailConfirm(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/change_email_confirm', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        changePhoneInit(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/change_phone_init', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        changePhoneConfirm(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/change_phone_confirm', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },


        uploadDocumentFile(ctx, document) {
            const config = {'content-type': 'multipart/form-data'}
            const formData = new FormData()
            formData.append('document', document)
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/upload_document_file', formData, config).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        createDocument(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/create_document', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        createVerificationRequest(ctx, data) {
            console.log(data);
            const config = {'content-type': 'multipart/form-data'}
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
                if (key==='photo') value.forEach(el=>{
                    formData.append('photo[]', el,el.name);
                })
                else
                    formData.append(key, value);
            }
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/create_verification_request', formData, config).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        cancelVerificationRequest(){
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/cancel_verification_request').then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },

        fetchChangeWallet({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/change_wallet', payload).then(response => {
                    commit('setWallet', payload.wallet);
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        fetchWithdraw({state, commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/withdraw/create/', payload).then(response => {
                    commit('setPartnerBalance', state.data.balance_partner - payload.amount);
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        getUserManager() {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/team/user_manager').then(response => {
                    console.log('response',response);
                    resolve(response.data);
                }).catch(error => {console.log(error);reject(error)});
            });
        },

        getUserByEmail(ctx,payload) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/user/get_user_by_email/', {params:payload}).then(response => {
                    resolve(response.data);
                }).catch(error => {console.log(error);reject(error)});
            });
        },
        sendPartnerToPartner({state, commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/transfer/partner_to_partner', payload).then(response => {
                    commit('setPartnerBalance', state.data.balance_partner - payload.amount);
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },


    }
};
