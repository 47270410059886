export default [
    {
        path: '/PrivetVolodya1',
        name: 'privetVolodya1',
        component: () => import('@/pages/LoginPage.vue'),
    },
    {
        path: '/PrivetVolodya2',
        name: 'privetVolodya2',
        component: () => import('@/pages/LoginPage.vue'),
    }]
