export default {
    namespaced:true,
    state: () => ({
        width:0,
    }),
    getters:{
        getWidth(state) {
            return state.width;
        },
    },
    mutations: {
        setWidth(state,width){
            state.width = width;
        },
    }
}
