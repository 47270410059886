<template>
    <div v-for="(page, i) in languageList" :key="i" class="block-switch">
        <div class="switch-content" ref="content-width">
            <div class="content-item-button">
                <!-- v-on:click="page.showLang = !page.showLang"--->
                <div
                     class="dropdown-button">
                    <span v-if="lang==='ru'" class="dropdown-items" @click="switchLang('en')"><RuLangIcon></RuLangIcon>RU</span>
                    <span v-if="lang==='en'" class="dropdown-items" @click="switchLang('ru')"><EnLangIcon></EnLangIcon>EN</span>
                </div>
            </div>
<!--            <div v-if="page.showLang === true" class="language-switcher">-->
<!--                <div class="language-switcher-wrapper">-->
<!--                    <div v-if="lang !== 'ru'" v-on:click="page.showLang = false" @click="switchLang('ru')"-->
<!--                         :class="{'current': lang === 'ru'}" class="dropdown-items">-->
<!--                        <RuLangIcon/>-->
<!--                        {{ page.ru }}-->
<!--                    </div>-->
<!--                    <div v-if="lang !== 'en'" v-on:click="page.showLang = false" @click="switchLang('en')"-->
<!--                         :class="{'current': lang === 'en'}" class="dropdown-items">-->
<!--                        <EnLangIcon/>-->
<!--                        {{ page.en }}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {ref} from 'vue'
import VueCookies from 'vue-cookies'

const {locale} = useI18n({useScope: 'global'});

let lang = ref(locale.value);

const switchLang = (lang_) => {
    locale.value = lang_;
    lang.value = lang_;
    // localStorage.setItem('lang', lang_);
    VueCookies.set('lang', lang_, 60 * 60 * 24 * 90, '/');
    location.reload();
}

</script>


<script>
import RuLangIcon from "@/assets/icons/Ru-LangIcon.vue";
import EnLangIcon from "@/assets/icons/En-LangIcon.vue";

export default {
    components: {RuLangIcon, EnLangIcon},
    data() {
        return {
            showLang: false,
            languageList: [{
                ru: 'RU',
                ruIcon: RuLangIcon,
                en: 'EN',
                enIcon: RuLangIcon
            }],
        }
    },
    props :{
        locales : Array
    }
}
</script>


<style scoped lang="scss">
@use "~@/styles/mixins.scss" as *;

.block-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    min-width:70px;
    flex-direction:column;
}

.switch-content {
    display: grid;
    background-color: #454A50;
    border-radius: 16px;
    padding: 5px;
    gap: 10px;
    position: absolute;
    color: #FFFFFF;
}

.dropdown-items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
}

.language-switcher {
    display: grid;
    gap: 15px;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
    border-radius: 16px;

    div {
        cursor: pointer;
    }

    .current {
        font-weight: 700;
        color: #232528;
    }
}
</style>
