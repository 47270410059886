<template>
    <div class="certificate-modal">
        <div class="certificate-modal__content-wrapper"
             @scroll="checkScrollPosition"
             ref="content">
            <div class="certificate-modal__content"
                 v-if="html"
                 v-html="html">
            </div>
        </div>
        <div class="certificate-modal__controls d-flex ac je">
            <button class="base-button"
                    :disabled="!scrolled"
                    @click="submit"
                    v-if="toSubmit">{{ $t('certificate_popup.sign_btn') }}
            </button>
            <button class='base-button' @click="$emit('close')">{{ $t('common.ok') }}</button>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "CertificateModal",
    props: {
        id: Number,
        toSubmit: Boolean
    },
    data() {
        return {
            html: '',
            scrolled: false
        }
    },
    computed: {
        ...mapState({
            investmentAgreement: state => state.certificates.investmentAgreement,
            partnerAgreement: state => state.certificates.partnerAgreement,
        }),
    },
    methods: {
        getContent() {
            this.$store.dispatch('certificates/getCertificate', this.id).then(({data}) => {
                this.html = data.html;
            });
        },
        submit() {
            if (!this.toSubmit) return;
            this.$store.dispatch('certificates/submitCertificate', this.id).then(() => {
                if (this.id === this.investmentAgreement.id) this.$store.commit('certificates/updateInvestmentAgreement', {
                    verified: 1,
                    updated_at: Date.now()
                });
                else if (this.id === this.partnerAgreement.id) this.$store.commit('certificates/updatePartnerAgreement', {
                    verified: 1,
                    updated_at: Date.now()
                });
                this.$store.dispatch('user/authentificate');
                this.$emit('close');
            });
        },
        checkScrollPosition() {
            if (this.$refs.content.scrollTop > this.$refs.content.clientHeight)
                this.scrolled = true;
        }
    },
    mounted() {
        this.getContent();
        this.$nextTick(() => {
            this.checkScrollPosition();
        });

    }
}
</script>

<style scoped lang="scss">
.certificate-modal {

    &__content-wrapper {
        margin: 20px 0;
        padding: 10px;
        max-height: calc(min(700px, 80vw));
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: block;
            width: 5px;
        }

        // #E3EAED
        &::-webkit-scrollbar-track {
            border-radius: 6px;
            background: none;
        }

        &::-webkit-scrollbar-thumb {
            background: #BAC5C8;
            border-radius: 6px;
            border: 2px solid #BAC5C8;
            border-right: 0;
        }
    }

    &__controls {
        gap: 10px;
    }
}
</style>
