<template>
    <div class="wrapper">
      <!--:class = "{active:currentUser.id===user.id,}"-->
        <div class="modal_title">Структура</div>
        <div class = "structure d-flex">
          <div class = "structure__col" v-for = "(col,i) in structure" :key="'c'+i">
            <div class = "structure__user"
                 v-for = "user in col" :key="user.id"
                 @click = "currentUser = user"
                 :class = "{active:currentUser&&currentUser.id===user.id,
                 relative:currentUser&&(currentUser.referral_user_id === user.id||user.referral_user_id===currentUser.id)}">
              <div class = "structure__user-name">{{user.first_name+' '+user.second_name+' '+user.third_name}}</div>
              <div class = "structure__user-stats">{{getStatusName(user.level_id)+', '+user.ref_count+' партнеров'}}</div>
              <TriangleDown v-if="user.ref_count"/>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import TriangleDown from "@/assets/icons/TriangleDown.vue";
import {mapState} from "vuex";
export default {
    name: "StructureModal",
    components: {TriangleDown},
    props:{
      structure:Array,
      currentId:Number
    },
    data() {
        return {
          currentUser:null
        }
    },
    computed:{
      ...mapState({
        statuses: state => state.planes.statuses
      })
    },
    methods:{
      getStatusName(level){
        if (!this.statuses) return 'Уровень '+level;
        const status =  this.statuses.find(el=>el.id===level);
        return status?status.name:'Уровень '+level;
      },
    },
    mounted(){
      //console.log(this.structure);
      this.structure.forEach(col=>{
        col.forEach(user=>{
          if (user.id===this.currentId) this.currentUser = user;
        })
      });
    }
}
</script>


<style lang="scss" scoped>
.structure{
  overflow:auto;

  &__col{
    width:33%;
    flex: 0 0 auto;
    padding:0 15px;
    &+&{
      border-left: 1px solid #E3EAED;
    }
    overflow:auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #BAC5C8;;
    }
  }
  &__user{
    position:relative;
    margin-bottom:5px;
    cursor:pointer;
    border-radius: 16px;
    padding:15px 30px 15px 15px;
    color: rgba(46, 51, 56, 0.5);
    font-weight: 500;
    &-name{
      font-size: 14px;
    }
    &-stats{
      font-size:12px;
      margin-top:2px;
    }
    svg{
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) scale(1.5) rotate(90deg);
      &::v-deep path{
        stroke:#BAC5C8;
      }
    }
    &.active{
      color:#fff;
      background: #3E76B5;
      .structure__user-name{
        font-weight: 700;
      }
      svg{
        display:none;
      }
    }
    &.relative{
      background: #EBEFF1;
      .structure__user-name{
        color:#232528;
        font-weight: 700;
      }
    }
  }
}

</style>
