<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 2V16H2V2H16ZM18 0H0V18H18V0Z" fill="#BAC5C8"/>
    <path d="M15.9997 7.08984V10.9099H9.42969V7.08984H15.9997ZM17.9997 5.08984H7.42969V12.9099H17.9997V5.08984Z" fill="#BAC5C8"/>
    <path d="M11.9992 7.72985C11.7468 7.72388 11.4984 7.79327 11.2857 7.92914C11.0729 8.06502 10.9055 8.26121 10.8047 8.49269C10.704 8.72416 10.6745 8.9804 10.7201 9.2287C10.7656 9.477 10.8842 9.70609 11.0605 9.88671C11.2369 10.0673 11.4631 10.1913 11.7102 10.2428C11.9574 10.2943 12.2142 10.2709 12.448 10.1758C12.6819 10.0806 12.882 9.91789 13.0229 9.70844C13.1638 9.49899 13.2391 9.25229 13.2392 8.99984C13.2393 8.66815 13.1096 8.34959 12.8779 8.11226C12.6462 7.87494 12.3308 7.73768 11.9992 7.72985Z" fill="#BAC5C8"/>
  </svg>

</template>

<script>
export default {
  name: "WalletsIcon"
}
</script>

<style scoped>

</style>