<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7803_1430)">
      <path d="M5.49906 7.92C7.40997 7.92 8.95906 6.37091 8.95906 4.46C8.95906 2.54909 7.40997 1 5.49906 1C3.58816 1 2.03906 2.54909 2.03906 4.46C2.03906 6.37091 3.58816 7.92 5.49906 7.92Z" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M10 18.0002V10.4502H1V18.0002" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M16.9883 18.0002V10.4502H12.4883" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
      <path d="M10.8516 7.50008C11.3527 7.78083 11.9172 7.92885 12.4916 7.93008C13.0222 7.9351 13.5468 7.81836 14.0253 7.58885C14.5037 7.35934 14.9231 7.02316 15.2513 6.60617C15.5794 6.18918 15.8076 5.70248 15.9182 5.18351C16.0288 4.66454 16.0189 4.12711 15.8893 3.61256C15.7596 3.09801 15.5137 2.62004 15.1704 2.21541C14.8272 1.81077 14.3957 1.49026 13.9091 1.27851C13.4226 1.06676 12.894 0.969405 12.3639 0.993948C11.8338 1.01849 11.3165 1.16427 10.8516 1.42008" stroke="#BAC5C8" stroke-width="2" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_7803_1430">
        <rect width="17.99" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "PartnerIcon"
}
</script>

<style scoped>

</style>